<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.SummerWorkProgress") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
         <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>

      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pl-0">
            <v-sheet class="px-2 chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalStudents") }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ totals.total_student }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-container>
        <v-row>
          <table class="report-t">
            <thead>
              <tr>
                <th>{{ $t("reportpublic.SummerType") }}</th>
                <th>{{ $t("reportpublic.TotalSchools") }}</th>
                <th>{{ $t("reportpublic.Workers") }}</th>
                <th>{{ $t("reportpublic.TotalMalesStudents") }}</th>
                <th>{{ $t("reportpublic.TotalFemalesStudents") }}</th>
                <th>{{ $t("reportpublic.TotalStudents") }}</th>
              </tr>
            </thead>
            <tbody v-for="(val, key) in data" :key="key">
              <tr>
                <td>{{ val.school_type }}</td>
                <td class="text-center">{{ val.total_type_schools }}</td>
                <td class="text-center">{{ val.total_type_employees }}</td>
                <td class="text-center">{{ val.total_type_student_male }}</td>
                <td class="text-center">{{ val.total_type_student_fmale }}</td>
                <td class="text-center">{{ val.student_number_type }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>{{ $t("reportpublic.GrandTotal") }}</th>
                <th>{{ totals.total_schools }}</th>
                <th>{{ totals.total_employees }}</th>
                <th>{{ totals.total_student_male }}</th>
                <th>{{ totals.total_student_fmale }}</th>
                <th>{{ totals.total_student }}</th>
              </tr>
            </tfoot>
          </table>
        </v-row>
      </v-container>
      <v-row class="flex-chart mt-2">
        <v-col v-for="(op, key) in options" :key="key">
          <v-card class="px-2 pt-1 my-4" style="height: 300px">
            <chart :option="op" autoresize />
          </v-card>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  data() {
    return {
      data: [],
      totals: {},
      options: [],
    };
  },
 async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      await  this.axios
        .get(
          `${this.base_url}/public-reports/daily-reports/summer-work-progress`)
        .then((response) => {
          this.data = response.data.results.data;
          this.totals = response.data.results.totals;
        });
      this.options = this.data.map((e) => {
        return {
          dataset: [
            {
              dimensions: [
                "name",
                this.$t("reportpublic.TotalSchools"),
                this.$t("reportpublic.Workers"),
                this.$t("reportpublic.MaleStudents"),
                this.$t("reportpublic.FemaleStudents"),
              ],
              source: [
                [
                  e.school_type,
                  e.total_type_schools,
                  e.total_type_employees,
                  e.total_type_student_male,
                  e.total_type_student_fmale,
                ],
              ],
            },
            {
              transform: {
                type: "sort",
                config: { dimension: "constant", order: "desc" },
              },
            },
          ],
          xAxis: {
            type: "category",
            axisLabel: {
              fontSize: 15,
              fontWeight: "bold",
            },
          },
          yAxis: {
            type: "value",
            position: "right",
          },
          series: [
            {
              type: "bar",
              barWidth: 40,
              itemStyle: { borderRadius: [4, 4, 0, 0] },
              label: {
                show: true,
                position: "top",
              },
            },
            {
              type: "bar",
              barWidth: 40,
              itemStyle: { borderRadius: [4, 4, 0, 0] },
              label: {
                show: true,
                position: "top",
              },
            },
            {
              type: "bar",
              barWidth: 40,
              itemStyle: { borderRadius: [4, 4, 0, 0] },
              label: {
                show: true,
                position: "top",
              },
            },
            {
              type: "bar",
              barWidth: 40,
              itemStyle: { borderRadius: [4, 4, 0, 0] },
              label: {
                show: true,
                position: "top",
                textStyle: {
                  fontSize: 8,
                },
              },
            },
          ],
          legend: {
            bottom: "bottom",
            top: "80%",
            left: "center",
            itemHeight: 10,
            itemWidth: 13,
          },

          toolbox: {
            feature: {
              saveAsImage: {
                title: this.$t("dashboard.save-as-png"),
              },
            },
          },
          tooltip: {
            formatter: function (params) {
              return (
                "<h5>" +
                params.name +
                "</h5>" +
                params.value[params.componentIndex + 1] +
                "&nbsp;&nbsp;&nbsp; " +
                params.marker +
                " " +
                params.seriesName
              );
            },
          },
          grid: {
            bottom: "50%",
            height: "60%",
            top: "10%",
          },
        };
      });
    },

    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },

  computed: {
    
  },

  components: {
    ReportHeader,
  },
};
</script>

<template>

  <VCard 
      v-if="
        $checkPermissions('summer_center.add_activityoperation',['sys_admin','summer_admin','user']) ||
        $checkPermissions('summer_center.change_activityoperation',['sys_admin','summer_admin','user'])
      "
  >
    <v-card-text class="pb-0">
      <VForm ref="form">
        <VRow class="mt-2">
          <VCol cols="12" xs="12" md="5" lg="5">
            <VAutocomplete
              v-model="activity_operation.fk_week"
              :items="weeks"
              prepend-inner-icon="mdi-calendar-week"
              clearable
              item-title="week_name"
              item-value="id"
              :label="$t('summer.calendar.week-name')"
              persistent-hint
              :rules="week_rule"
              density="compact"
              @update:modelValue="activity_operation.date_time_operation=null,activity_operation.fk_week?getDateList():activity_operation.date_time_operation=null"
            ></VAutocomplete>
          </VCol>
          <VCol  cols="12" xs="12" md="5" lg="5">
            <VAutocomplete
                v-model="activity_operation.date_time_operation"
                :items="listWeeks"
                item-title="week_name"
                item-value="id"
                density="compact"
                :placeholder="$t('summer.date-activity-opreation')"
                clearable
                prepend-inner-icon="mdi-calendar-clock-outline"
                :label="$t('summer.date-activity-opreation')"
                persistent-hint
                :rules="date_time_operation_rule"
                @update:modelValue="getactivityOpreations"
              ></VAutocomplete>
          </VCol>
        </VRow>
        <VRow class="mt-2">
          <VCol cols="12" xs="12" md="10" lg="10">
            <VRadioGroup
              inline
              :label="$t('activity.iteration')"
              v-model="iteration"
              density="compact"
              @update:modelValue="getactivityOpreations"
            >
              <VRadio
                v-for="item in activity_iterations"
                :key="item"
                :label="item.name"
                :value="item.id"
                class="mx-3"
              ></VRadio>
            </VRadioGroup>
          </VCol>
        </VRow>
        <v-progress-linear 
          color="indigo" 
          :reverse="$i18n.locale=='ar'"
          height="3px" 
          indeterminate 
          v-if="loading"
        >
        </v-progress-linear>
        <v-table 
          density="compact" 
          class="border elevation-1 rounded" 
          v-if="activityOpreations.length>0"
          fixed-header
          :height="activityOpreations?.length>9?420:null"
          >
          <thead>
            <tr>
              <th>{{$t('globals.number')}}</th>
              <th>{{$t('activity.name')}}</th>
              <th>{{$t('activity.execution')}}</th>
              <th>{{$t('activity.is_run')}}</th>
              <th>{{$t('globals.note')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(activity,index) in activityOpreations" :key="activity">
              <td>{{index+1}}</td>
              <td>{{activity.activity_name}}</td>
              <td>{{activity.activity_execution}}</td>
              <td>
                <v-checkbox 
                  color="indigo"
                  v-model="activity.is_done"
                  density="compact"
                  hide-details
                  >
                </v-checkbox>
              </td>
              <td class="w-25">
                <v-text-field 
                  v-model="activity.note"
                  density="compact"
                  variant="underlined"
                  color="indigo"
                  counter="250"
                  :placeholder="$t('globals.note')"
                  :rules="note_rule"
                  hide-details
                >

                </v-text-field>
              </td>
            </tr>
          </tbody>
        </v-table>
        <v-card
          v-if="!loading&&activityOpreations.length <1"
          class="pa-2 text-center mb-2"
        >
          <span >
            {{ $t("globals.not-found-items") }}
          </span>
        </v-card>
      </VForm>
    </v-card-text>
    <VCardActions
      class="px-3"
      v-if="activityOpreations.length>0"
    >
      <VBtn
        :loading="save_loading"
        v-if="
          $checkPermissions('summer_center.add_activityoperation',['sys_admin','summer_admin','user']) ||
          $checkPermissions('summer_center.change_activityoperation',['sys_admin','summer_admin','user'])
        "
        class="bg-indigo"
        @click="saveActivityOperations()"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.save") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        :loading="loading"
        v-if="
          is_update &&
          $checkPermissions('summer_center.change_activityoperation',['sys_admin','summer_admin','user'])
        "
        class="bg-success"
        @click="updateDirectorate()"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
const englishOnly = helpers.regex(/^[a-zA-Z\-'\s]+$/);
const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);
export default {
  data() {
    return {
      listWeeks:[],
      opreationsavtive: null,
      activityOpreations: [],
      loading: false,
      save_loading:false,
      v$: useValidate(),
      activity_operation:{
        fk_week:null,
        date_time_operation:null,
      },
      iteration: 1,
      is_update: false,
      updated_id: undefined,
      del_dialog: false,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.calendar.week-name"), key: "week_data_name" },
        {
          title: this.$t("summer.date-activity-opreation"),
          key: "date_time_operation",
        },
        { title: this.$t("summer.activity.name"), key: "activity_name" },
        { title: this.$t("activity.is_run"), key: "is_done" },
        { title: this.$t("globals.description"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],

      selectedHead: [
        "week_data_name",
        "date_time_operation",
        "activity_name",
        "is_done",
        "actions",
      ],
      note_rule:[
        (value) => value==undefined || (value.length <= 250) || `${this.$t('globals.biggest_number_of_character')} 250`,
      ],
      week_rule:[
        (value) => !!value || this.$t('globals.required-field'),
      ],
      date_time_operation_rule:[
        (value) => !!value || this.$t('globals.required-field'),
      ]


      
    };
  },

  computed: {
    ...mapState({
      weeks: (state) => state.summer.weeks,
      user: (state) => state.User,
      activity_iterations: (state) => state.summer_globals.activity_iterations,
    }),

    

    ...mapGetters({
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },
  validations() {
    return {
      
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);

      await this.getactivityOpreations();
      await this.getWeeks();
      await this.fetchWeek();
      await this.getActivityIterations();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchWeek() {
      await this.axios.get(`${this.base_url}statistics/get-current-date`).then((response) => {
          this.activity_operation.fk_week = response.data.week_id;
          this.activity_operation.date_time_operation =  response.data.current_date;
          if(response.data?.week_id){
            this.getDateList()
          }
        });
    },
    async getDateList() {
      await this.axios
      .get(this.base_url + "api/summer/get-work-days", {
        params: {
          week_id: this.activity_operation.fk_week,
        },
      })
      .then((response) => {
        this.listWeeks = response.data.date;
      })
      .catch((error) => {
        this.$emit("errorAlert", this.$t("globals.error_in_data"));
      });
   },
    
    ...mapActions({
      getWeeks: "summer/getWeeks",
      getActivityIterations: "summer_globals/getActivityIterations",
    }),
    resetForm() {
      this.activity_operation.fk_week=null
      this.activity_operation.date_time_operation=null
      this.getactivityOpreations()
    },
    
    async saveActivityOperations() {
        const {valid} = await this.$refs.form.validate()
        this.activityOpreations.data_entry = this.user;
        if(valid){
          this.save_loading=true
          this.save_loading = true;
          let result = await this.axios
            .post(
              this.base_url + "api/summer/activity-operation",
              this.activityOpreations.filter(item=>item.is_done==true),
              {
                params:{
                  fk_week:this.activity_operation.fk_week,
                  date_time_operation:this.activity_operation.date_time_operation,
                  iteration:this.iteration,
                }
              }
            )
            .then((responce) => {
              this.save_loading = false;
              this.$emit("successAlert", this.$t("alert.success.save"));
              // this.resetForm();
              this.getactivityOpreations()
            })
            .catch((error) => {
              this.save_loading = false;
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
              this.getactivityOpreations();
            });
        }else{
           this.$emit("errorAlert", this.$t("globals.error_in_data"));
        }
    },
    async getactivityOpreations() {
      this.loading=true
      await this.axios
        .get(this.base_url + "api/summer/activity-operation", {
          params: {
            date_time_operation:this.activity_operation.date_time_operation,
            iteration:this.iteration !=null ?this.iteration:1,
          },
        })
        .then((response) => {
          this.loading=false
          if (response.data) {
            this.activityOpreations = response.data;

          }
        }).catch(error=>{
          this.loading=false
          if(error.response.data&&error.response.data=="date_error"){
            this.$emit("errorAlert", this.$t('alert.failure.entry-date'));
          }else{
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          }
        })
    },
  },
  watch: {
    
  },
};
</script>

<style scoped>
.custom_list_item:hover {
  background-color: red !important;
  color: white;
  cursor: pointer;
}
.custom_list_item:active {
  background-color: red !important;
  color: white;
  cursor: pointer;
}
</style>

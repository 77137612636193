<template>
<v-expansion-panels  >
  <v-expansion-panel   class="mb-2" >
    <v-expansion-panel-title class="px-4">
        <span>{{$t('globals.select-criteria')}}</span>
    </v-expansion-panel-title>
    <v-expansion-panel-text >
      <v-row class="py-2" >
        <v-col cols="12" md="4" sm="6" class="mb-1" v-if="role>=0&&role<=1">
          <VAutocomplete
            :items="filterGovernorates(1)"
            v-model="fk_governorate"
            item-title="name_ar"
            item-value="id"
            :label="$t('globals.governorate')"
            persistent-hint
            density="compact"
            hide-details
            clearable
            :no-data-text="$t('governorate.not-found')"
            prepend-inner-icon="mdi-city"
            @update:modelValue="fk_directorate=null,fk_summer=null"
            >
          </VAutocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mb-1" v-if="role>=0&&role<=2">
          <VAutocomplete
            :items="filterDirectorates(fk_governorate)"
            v-model="fk_directorate"
            item-title="name_ar"
            item-value="id"
            :label="$t('globals.directorate')"
            persistent-hint
            hide-details
            density="compact"
            clearable
            :no-data-text="$t('directorate.not-found')"
            prepend-inner-icon="mdi-map-outline"
            @update:modelValue="getSummerSchools(),fk_summer=null"
          ></VAutocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mb-1" v-if="role>=0&&role<=3">
          <VAutocomplete
              v-model="fk_summer"
              item-value="id"  
              item-title="name_ar"
              prepend-inner-icon="mdi-domain"    
              :items="summer_schools"
              :loading="loadingSchools"
              :label="$t('globals.summer-centre')"
              persistent-hint
              hide-details
              density="compact"
              :no-data-text="$t('summer.not-found')"
              
              clearable
          ></VAutocomplete>
        </v-col>
        <v-col cols="12" md="5" sm="4">
          <v-autocomplete
            color="indigo"
            v-model="fk_level"
            density="compact"
            :item-title="classesName"
            item-value="id"
            prepend-inner-icon="mdi-stairs-box"
            clearable
            hide-details="auto"
            :items="classes"
            :label="$t('summer.level.name_ar')"
            @update:modelValue="fk_division = null"
          />
        </v-col>
        <v-col cols="12" md="5" sm="4">
          <v-autocomplete
            color="indigo"
            v-model="fk_division"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-shape-outline"
            clearable
            hide-details="auto"
            :items="getClassDivisions(fk_level)"
            :label="$t('division.select')"
          />
        </v-col>

        <v-col cols="4" md="1" class="mt-2 ">
          <v-btn
            @click="getDropoutStudents"
            class="bg-indigo"
            size="small"
          >
            <span class="text-white">
              {{ $t("globals.show") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-text>
  </v-expansion-panel>
</v-expansion-panels> 
  <v-card  class="justify-center" :loading="loading"  >
    <v-card-text class="mt-4 bp-0">
      <v-row  >
          <VCol cols="6" class="mb-2">
              <v-text-field
                color="indigo"
                v-model="search_student"
                @input="getDropoutStudents"
                density="compact"
                hide-details="auto"
                prepend-inner-icon="mdi-magnify"
                :label="$t('globals.student-search')"
              />
            </VCol>
        <v-col cols="12">
          <!-- <v-card class="pa-2 ma-auto" > -->
          <v-form ref="form">
            <v-card :disabled="loading" v-if="students?.length>0" class="mb-2" >
              <v-table 
                density="compact"
                :height="students?.length>9?420:null"
                fixed-header
                >
                <thead>
                  <tr>
                    <th>{{ $t("globals.number") }}</th> 
                    <th>{{ $t("school.student_name") }}</th>
                    <th>{{ $t("summer.summer-camp") }}</th>
                    <th>{{ $t("globals.directorate") }}</th>
                    <th>{{ $t("globals.governorate") }}</th>
                    <th>
                      {{ $t("summer.dropout-reasons") }}
                    </th>
                    <th >{{ $t("summer.dropout-date") }}</th>
                    <!-- <th style="width:30%;">{{$t('globals.note')}}</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student, index) in students" :key="student">
                    <td>{{ index + 1 }}</td>
                    <td style="min-width:200px">{{ student.student_name }}</td>
                    <td >{{ student.fk_summer }}</td>
                    <td >{{ student.directorate }}</td>
                    <td >{{ student.governorate }}</td>
                    <td  style="min-width:150px">
                      {{student.drop_out_reason}}
                    </td>
                    <td>{{ student.drop_out_date }}</td>
                  </tr>
                </tbody>
              </v-table>
              <v-divider/>
              <div class="d-flex mt-1 "  id="pagination-bar" >
                <v-pagination  
                    v-model="page" 
                    :length="length"   
                    density="compact"
                    show-first-last-page
                    >
                </v-pagination>
                <div class="d-flex ">
                  <v-select 
                    v-model="perPage" 
                    class="pa-0" 
                    :items="itemsPerPage"  
                    item-value="value" 
                    item-title="text" 
                    density="compact" 
                    hide-details
                    variant="text"
                    >
                  </v-select>
                  <span class="mt-2 px-2">{{$t('globals.per-page')}} {{$t('globals.from')}} [ {{totalItems}} ] {{$t('globals.item')}}</span>
                </div>
              </div>
            </v-card>
            <v-card v-else class="pa-2 my-2 text-center" >
              <span >
                {{ $t("globals.not-found-items") }}
              </span>
            </v-card>
          </v-form>
          <!-- </v-card> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      await this.getClasses();
      await this.getDivisions();
      await this.getClassAssignHall();
      await this.getDropoutStudents();
      if(this.role==2){
        this.fk_governorate = this.user_governorate;
      }
      if(this.role==3){
        this.fk_directorate = this.user_directorate;
        await this.getSummerSchools()
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      loading: false,
      perPage:10,
      itemsPerPage: [
        {value:10,text:"10"},
        {value:25,text:"25"},
        {value:50,text:"50"},
        {value:100,text:"100"},
        {value:null,text:this.$t("globals.all")},
      ],
      summer_schools:[],
      fk_governorate:null,
      fk_directorate:null,
      fk_summer:null,
      pagination:{},
      page:1,
      length:null,
      totalItems:null,
      fk_level: undefined,
      fk_division: undefined,
      fk_reason: [],
      students: [],
      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.summer.levels,
      divisions: (state) => state.summer.divisions,
      place_type: (state) => state.summer_globals.drop_out_reason,
      role:(state)=>state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
    }),
    ...mapGetters({
      getDivisionName: "summer/getDivisionName",
      getClassDivisions: "summer/getClassDivisions",
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    classesName() {
      return this.$i18n.locale == "ar" ? "level_with_phase_name" : "name_en";
    },
  },
  methods: {
    ...mapActions({
      getDivisions: "summer/getDivisions",
      getClasses: "summer/getLevels",
      getClassAssignHall: "summer/getClassAssignHall",
      getDropOutReason: "summer_globals/getDropOutReason",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    async getSummerSchools(){
      this.loadingSchools=true
      await this.axios.get(`${this.base_url}api/summer/school-select-list`, {
      params:{
        directorate:this.fk_directorate,
        governorate:this.fk_governorate
      }
      }).then(response=>{
        this.loadingSchools=false
        this.summer_schools=response.data
      }).catch(err=>{
        this.loadingSchools=false
      })
    },
    async getDropoutStudents() {
        this.loading=true
        await this.axios
          .get(`${this.base_url}api/summer/report/dropout-students`, {
            params: {
              level: this.fk_level,
              division: this.fk_division,
              page: this.page,
              page_size: this.perPage,
              search_txt:this.search_student

            },
          })
          .then((response) => {
            this.loading=false
            this.students = response.data.results;
            this.pagination=response.data.pagination
            this.length=this.pagination?.num_pages
            this.itemsPerPage[4]['value']=this.pagination?.count
            this.totalItems=this.pagination?.count
          }).catch(()=>{
            this.loading=false
          })
    },
  },
  watch:{
    perPage() {
      this.page=1
      this.getDropoutStudents();
    },
    page() {
      this.getDropoutStudents(this.page);
    },
  }
};
</script>
<style scoped>
#pagination-bar *{
  font-size: 0.95rem ;
}
</style>

<template>
<v-expansion-panels v-if="role <= 2" class="mb-2">
    <v-expansion-panel>
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <VCol cols="6" md="3" v-if="role < 2">
            <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="filter.governorate"
              item-title="name_ar"
              item-value="id"
              hide-details="auto"
              :label="$t('globals.governorate')"
              density="compact"
              clearable
              prepend-inner-icon="mdi-city"
              @update:modelValue="(filter.directorate = null), getItems()"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="3" v-if="role <= 2">
            <VAutocomplete
              :items="filterDirectorates(filter.governorate)"
              v-model="filter.directorate"
              item-title="name_ar"
              item-value="id"
              hide-details="auto"
              clearable
              :label="$t('globals.directorate')"
              density="compact"
              prepend-inner-icon="mdi-map-outline"
              @update:modelValue="getItems()"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="3">
             <VAutocomplete
                :items="levels"
                v-model="filter.fk_level"
                item-title="name_ar"
                item-value="id"
                :label="$t('summer.level.level')"
                persistent-hint
                density="compact"
                clearable
                prepend-inner-icon="mdi-stairs"
                hide-details="auto"
                @update:modelValue="getItems()"
              ></VAutocomplete>
          </VCol>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <DataTable
    v-if="
      $checkPermissions(
        'opensummercenter.view_opensummerdirectorateapproachstatistic'
      )
    "
    :items="items"
    :method="getItems"
    :pagination="pagination"
    :headers="headers"
    :editItem="editItem"
    :delItem="deleteItem"
    :deleteMultiple="deleteItem"
    :create="
      () => {
        addDialog = true;
      }
    "
    :del_perm="
      $checkPermissions(
        'opensummercenter.delete_opensummerdirectorateapproachstatistic',
        ['sys_admin', 'gen_admin', 'gov_admin']
      )
    "
    :edit_perm="
      $checkPermissions(
        'opensummercenter.change_opensummerdirectorateapproachstatistic',
        ['sys_admin', 'gen_admin', 'gov_admin']
      )
    "
    :add_perm="
      $checkPermissions(
        'opensummercenter.add_opensummerdirectorateapproachstatistic',
        ['sys_admin', 'gen_admin', 'gov_admin']
      )
    "
  >
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          class=""
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol cols="6" v-if="role == 0 || role == 1">
              <VAutocomplete
                :items="filterGovernorates(1)"
                autofocus
                v-model="item.fk_governorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact"
                clearable
                :no-data-text="$t('governorate.not-found')"
                prepend-inner-icon="mdi-city"
                @update:modelValue="item.fk_directorate = null"
                :error-messages="
                  v$.item.fk_governorate.$errors.map((e) => e.$message)
                "
                @input="v$.item.fk_governorate.$touch"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6" v-if="role >= 0 && role <= 2">
              <VAutocomplete
                :items="filterDirectorates(item.fk_governorate)"
                v-model="item.fk_directorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.directorate')"
                persistent-hint
                density="compact"
                clearable
                :no-data-text="$t('directorate.not-found')"
                prepend-inner-icon="mdi-map-outline"
                :error-messages="
                  v$.item.fk_directorate.$errors.map((e) => e.$message)
                "
                @input="v$.item.fk_directorate.$touch"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6">
              <VAutocomplete
                :items="levels"
                v-model="item.fk_level"
                item-title="name_ar"
                item-value="id"
                :label="$t('summer.level.level')"
                persistent-hint
                density="compact"
                clearable
                prepend-inner-icon="mdi-stairs"
                :error-messages="
                  v$.item.fk_level.$errors.map((e) => e.$message)
                "
                @input="v$.item.fk_level.$touch"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6">
              <v-text-field
                v-model="item.book_count"
                type="number"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.quantity')"
                persistent-hint
                density="compact"
                clearable
                prepend-inner-icon="mdi-bookshelf"
                :error-messages="
                  v$.item.book_count.$errors.map((e) => e.$message)
                "
                @input="v$.item.book_count.$touch"
              ></v-text-field>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="item.note"
                clearable
                :label="$t('globals.note')"
                no-resize
                counter="250"
                density="compact"
                rows="2"
                :error-messages="v$.item.note.$errors.map((e) => e.$message)"
                @input="v$.item.note.$touch"
                prepend-inner-icon="mdi-note-outline"
              ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, maxValue } from "@vuelidate/validators";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  async created() {
    try {
      this.$store.commit("updateLoadingValue", true);
      if (this.role == 2) {
        this.fk_governorate = this.user_governorate;
      }
      await this.getGovernorates();
      await this.getLevels();
      await this.getDirectorate();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      gifts: [],
      addDialog: false,
      filter: {
        governorate: null,
        directorate: null,
        fk_level:null,
      },
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      items: [],
      v$: useValidate(),
      pagination: {
        num_pages: 0,
        count: 0,
        current_page: 1,
      },
      headers: [
        {
          title: this.$t("globals.governorate"),
          key: "governorate_name",
        },
        {
          title: this.$t("globals.directorate"),
          key: "directorate_name",
        },
        { title: this.$t("year.name"), key: "year_name" },
        {
          title: this.$t("summer.level.level"),
          key: "level_name",
          sortable: false,
        },
        {
          title: this.$t("globals.quantity"),
          key: "book_count",
        },
        {
          title: this.$t("globals.note"),
          key: "note",
          sortable: false,
          show: false,
        },
        {
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        },
      ],
      item: {
        fk_governorate: null,
        fk_directorate: null,
        fk_level: null,
        count: null,
      },
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
      levels: (state) => state.summer.levels,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getLevels: "summer/getLevels",
    }),
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      if (ordering == "governorate_name")
        ordering = "fk_directorate__fk_governorate__name_ar";
      else if (ordering == "-governorate_name")
        ordering = "-fk_directorate__fk_governorate__name_ar";
      else if (ordering == "directorate_name")
        ordering = "fk_directorate__name_ar";
      else if (ordering == "-directorate_name")
        ordering = "-fk_directorate__name_ar";
      else if (ordering == "year_name") ordering = "fk_year__year";
      else if (ordering == "-year_name") ordering = "-fk_year__year";
      await this.axios
        .get(`${this.base_url}api/summer/directorate-approaches/`, {
          params: {
            page: page,
            page_size: per_page,
            search: search,
            ordering: ordering,
            fk_governorate:this.filter.governorate,
            fk_directorate:this.filter.directorate,
            fk_level:this.filter.fk_level
          },
        })
        .then((response) => {
          this.items = response.data?.results;
          this.pagination = response.data?.pagination;
        });
    },
    async getGiftsTypes() {
      await this.axios
        .get(`${this.base_url}gifts-types/list/`)
        .then((response) => {
          this.gifts = response.data;
        });
    },
    editItem(item) {
      this.item = Object.assign({}, item);
      this.addDialog = true;
      this.is_update = true;
    },
    async saveItem() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.item.data_entry = this.user;
        this.saveDataLoading = true;
        let result = await this.axios.post(this.base_url + "api/summer/directorate-approaches/", this.item)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data?.non_field_errors)
              this.$emit(
                "warningAlert",
                this.$t("globals.error_in_repeted_data")
              );
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async updateItem() {
      this.v$.$validate();
      if (!this.v$.$error && this.item?.id) {
        this.item.updated_by = this.user;
        this.editDataLoading = true;
        let result = await this.axios
          .put(`${this.base_url}api/summer/directorate-approaches/${this.item?.id}/`,this.item)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.editDataLoading = false;
            if (error.response.data.title)
              this.$emit(
                "warningAlert",
                this.$t("globals.error_in_repeted_data")
              );
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(
            `${this.base_url}api/summer/directorate-approaches/${delete_id}/`,
            {
              data: {
                delete_ids: delete_id?.length > 0 ? delete_id : null,
              },
            }
          )
          .then((response) => (status = true))
          .catch((error) => (status = error));
        return status;
      }
    },
    resetForm() {
      this.v$.$reset();
      this.$refs.form.reset();
      this.is_update = false;
      this.addDialog = false;
      this.updated_id = undefined;
    },
  },
  validations() {
    return {
      item: {
        fk_governorate: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_directorate: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        book_count: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxValue: helpers.withMessage(
            `${this.$t("globals.max_numbers_value")}: 999999999`,
            maxValue(999999999)
          ),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(250)
          ),
        },
      },
    };
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>
<template>
<v-dialog
    v-model="value"
    transition="dialog-top-transition"
    scrollable
    width="350"
    elevation="12"
  >
    <v-card
        max-width="600"
        elevation="10"
        rounded="lg"
        color="white"
        width="100%"
        class="pa-4 text-center mx-auto"
    >
        <div class="text-center">
            <v-icon size="50" >mdi-database-lock-outline</v-icon>
            <h2 class="d-block">409</h2>
            <h2> {{$t('globals.read-only-database')}} </h2>
        </div>
        <span class="text-medium-emphasis">
            {{$t('alert.attention.read-only')}}
        </span>
        <v-btn density="compact" @click="cancelRequest"  class="mt-2" rounded="lg" color="indigo" >{{$t('globals.cancel')}}</v-btn>
    </v-card>
  </v-dialog>

</template>

<script>
import store from '@/store'

export default{
    props:{
        customFunction:{
            type:Function,
            required:true
        },
        modelValue:{
            type:Boolean,
            default: false,
        },
    },
    computed:{
        value: {
            get(){
                return this.modelValue
            },
            set(value){
                this.$emit('update:modelValue',value)
            }
        },
    },
    methods:{
        cancelRequest(){
            store.commit('setReadOnlyAlert',false)
        }
    },
    watch:{
        value(val){
            if(val==false){
                this.cancelRequest()
            }
        }
    }
    
}
</script>
<template>
<v-dialog
    v-model="value"
    transition="dialog-top-transition"
    scrollable
    width="350"
    elevation="12"
  >
    <v-card
        max-width="600"
        elevation="10"
        rounded="lg"
        color="white"
        width="100%"
        class="pa-4 text-center mx-auto"
    >
        Session will expire soon !

        <v-btn density="compact" @click="cancelRequest"  class="mt-2" rounded="lg" color="indigo" >{{$t('globals.cancel')}}</v-btn>
    </v-card>
  </v-dialog>

</template>

<script>
export default{
    props:{
        modelValue:{
            type:Boolean,
            default: false,
        },
    },
    computed:{
        value: {
            get(){
                return this.modelValue
            },
            set(value){
                this.$emit('update:modelValue',value);
            }
        },
    },
    methods:{
        cancelRequest(){
            this.$store.dispatch('setLogoutAlert',false);
        }
    },
    watch:{
        value(val){
            if(val==false){
                this.cancelRequest();
            }
        }
    }
    
}


</script>
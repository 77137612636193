<template>
  <v-card class="mb-3 sort-hiden" v-if="!isExists">
    <v-form @submit.prevent>
      <v-expansion-panels model-value="1">
        <v-expansion-panel  value="1">
          <v-expansion-panel-title class="px-4">
            <v-icon icon="mdi-sort-calendar-ascending"></v-icon>
            <span class="mx-2">{{ $t("globals.select-others-criteria") }}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <VRow style="align-items: center" class="mb-1">
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="type"
                  density="compact"
                  item-title="type"
                  item-value="id"
                  hide-details="auto"
                  prepend-inner-icon="mdi-calendar-week"
                  :items="typeList"
                  :label="$t('summer.summer_type')"
                  :rules="rules"
                />
              </v-col>
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="from_date"
                  :items="calendar"
                  density="compact"
                  item-title="date"
                  item-value="date"
                  prepend-inner-icon="mdi-calendar-week"
                  clearable
                  hide-details="auto"
                  :label="$t('globals.from')"
                  :rules="rules"
                />
              </v-col>
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="to_date"
                  :items="calendar"
                  density="compact"
                  item-title="date"
                  item-value="date"
                  hide-details="auto"
                  prepend-inner-icon="mdi-calendar-week"
                  clearable
                  :label="$t('globals.to')"
                  :rules="rules"
                />
              </v-col>
              <v-col cols="6" md="2">
                <VBtn
                  class="bg-indigo"
                  type="submit"
                  @click="type ? fetchData() : ''"
                  size="small"
                  :loading="loading"
                >
                  <span class="text-white">
                    {{ $t("globals.show") }}
                  </span>
                  <VIcon icon="mdi-presentation" color="white" end></VIcon>
                </VBtn>
              </v-col>
            </VRow>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-progress-linear
        color="indigo"
        v-if="loading"
        indeterminate
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear>
    </v-form>
  </v-card>
  <v-card id="report" v-if="isExists">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.TotalNumberOfActivitiesCarriedOut") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable()"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
          <v-btn density="compact" class="mx-1" @click="isExists = !isExists">
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalNumberOfActivitiesCarriedOut") }}
              {{ $t("reportpublic.InDirectorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? data.dir_name_ar
                  : dir_name_en
              }}
            </v-sheet>
          </v-col>

          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <!-- <h4>{{ " هـ " + data.total_activities }}</h4>  -->
            </v-sheet>
          </v-col>

          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item style="overflow:auto"  v-if="data.totals>0" >
      <table id="stable" class="table" style="width: 100%;border-collapse: collapse;">
        <thead>
          <tr>
            <th  rowspan="2">{{ $t("reportpublic.CenterName") }}</th>
          </tr>
          <tr>
            <th v-for="head in data.header" :key="head">
              {{ head }}
            </th>
            <th rowspan="2">{{ $t("reportpublic.Total") }}</th>
          </tr>
        </thead>
        <tbody   class="text-center">
          <tr v-for="(d, index) in data.data" :key="index">
            <td>
              {{
                $i18n.locale == "ar"
                  ? d?.summer_name_ar ?? ""
                  : d?.summer_name_en ?? ""
              }}
            </td>
            <td v-for="(val, index) in d.value" :key="index">
              {{ val }}
            </td>
            <td>{{ d.totalAllActivity }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>{{ $t("reportpublic.GrandTotal") }}</th>
            <th v-for="(data, index) in data.footer" :key="index">
              {{ data }}
            </th>
            <th>{{ data.totals }}</th>
          </tr>
        </tfoot>
      </table>
    
    </v-card-item>
      <v-card-item v-else>
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card-item>
  </v-card>
</template>

<script>
;;import { mapState} from "vuex";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getCalendar();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      if (this.typeList && this.to_date && this.from_date) {
        this.loading = true;
        await  this.axios
          .get(
            `${this.base_url}public-reports/directorate-reports/total-number-of-activities-carried-out`,
            {
              params: {
                directorate: this.user_directorate,
                type: this.type,
                from_date: this.from_date,
                to_date: this.to_date,
              }
            }
          )
          .then((response) => {
            this.isExists = true;
            this.data = response.data.results;
             


            this.loading = false;
          })

          .catch((error) => {
            this.loading = false;
          });
      }
    },
    async getCalendar() {
      await  this.axios
        .get(`${this.base_url}api/summer/calendar`)
        .then((response) => {
          this.calendar = response.data.results;
        })
        .catch((error) => {});
    },
    async printTable() {
      window.print();
    },
  },
  computed: {
    ...mapState({
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      id: 5,
      results: [],
      data: [],
      loading: false,
      isExists: false,
      from_date: null,
      to_date: null,
      calendar: [],
      type: 2,
      typeList: [
        { id: 1, type: this.$t("reportpublic.Open") },
        { id: 2, type: this.$t("reportpublic.Typical") },
      ],
      header: [],
      body: [],
      directorate_name_ar: null,
      directorate_name_en: null,
      rules: [(value) => (value ? true : this.$t("globals.required_field"))],
    };
  },
  

  components: {
    ReportHeader,
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
  overflow-x: scroll !important;

}
.v-card-item .v-card-item__content{
  overflow: auto;
}
 
#stable th {
  padding: 10px;
  border: 1px solid #649add !important;
  background-color: #cfd8dc;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}
 
#stable td:first-child{

 text-align: right;
}
</style>

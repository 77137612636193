<template>
    <v-dialog
      v-model="value"
      transition="dialog-top-transition"
      dir="rtl"
      scrollable
      persistent
      width="350"
      :close-on-content-click="false"
      :close-on-back="false"
      elevation="12"
  >
      <v-sheet class="pa-2"> 
          <div class="text-center pa-2 mb-2">
            <VIcon
                class="mb-1"
                color="warning"
                icon="mdi-alert"
                size="55"
            ></VIcon>
            <h2 class="  mb-2 text-blue-grey-darken-4 " >{{$t('alert.attention.title')}}</h2>
            <h4 class=" mb-6 text-blue-grey-darken-5 " >{{title}} </h4>
            <v-divider/>
            <v-expansion-panels  >
              <v-expansion-panel >
                <v-expansion-panel-title >{{$t('globals.details')}}</v-expansion-panel-title>
                <v-expansion-panel-text class="scroll">
                  <v-list-item v-for="data in data_message" :key="data" class="text-start" prepend-icon="mdi-circle-small">
                    <span style="font-size:0.8rem">{{data}}</span>
                  </v-list-item>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <v-btn size="small" @click="confirmDelete()" :loading="loading" variant="text"  class="bg-error">{{$t('globals.delete')}}</v-btn>
          <v-btn size="small" @click="value=false" :disabled="loading" variant="text" color="indigo">{{$t('globals.cancel')}}</v-btn>
      </v-sheet>
  </v-dialog>
</template>

<script>

export default{
    props:{
        data_message:{
            type:Array,
            required:true
        },
        title:String,
        modelValue:{
            type:Boolean,
            default: false,
        },
        loading:Boolean
                
    },
    methods:{
        confirmDelete(){
            this.$emit('confirm-delete');
        }
    },
    computed:{
        value: {
            get(){
                return this.modelValue
            },
            set(value){
                this.$emit('update:modelValue',value)
            }
        },
    },
}

</script>
<style >
.scroll{
    max-height: 200px;
    overflow: auto;
}
</style>
<template>
 <v-expansion-panels  class="mb-2" v-if="role>=0&&role<=3">
    <v-expansion-panel   >
      <v-expansion-panel-title class="px-4">
          <span>{{$t('globals.select-criteria')}}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text >
        <v-row class="py-2" >
            <VCol class="mt-1" cols="6" md="3" sm="4"  v-if="role==0||role==1">
              <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="fk_governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              hide-details
              clearable
              :no-data-text="$t('governorate.not-found')"
              prepend-inner-icon="mdi-city"
              @update:modelValue="getItems(),getSummerSchools(),fk_directorate=null,fk_summer=null"
              ></VAutocomplete>
            </VCol>
            <VCol class="mt-1" cols="6" md="3" sm="4" v-if="role>=0&&role<=2">
                <VAutocomplete
                :items="filterDirectorates(fk_governorate)"
                v-model="fk_directorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.directorate')"
                persistent-hint
                hide-details
                density="compact"
                clearable
                :no-data-text="$t('directorate.not-found')"
                prepend-inner-icon="mdi-map-outline"
                @update:modelValue="getItems(),getSummerSchools(),fk_summer=null"
                ></VAutocomplete>
            </VCol> 
            <VCol class="mt-1" cols="6" md="3" sm="4" v-if="role>=0&&role<=3">
                <VAutocomplete
                    v-model="fk_summer"
                    item-value="id"  
                    item-title="name_ar"
                    prepend-inner-icon="mdi-domain"    
                    :items="summer_schools"
                    :label="$t('globals.summer-centre')"
                    persistent-hint
                    hide-details
                    density="compact"
                    :no-data-text="$t('summer.not-found')"
                    @update:modelValue="getItems(),getSummerSchools()"
                    clearable
                ></VAutocomplete>
            </VCol>
            <v-col class="mt-1" cols="6" md="3" >
              <VAutocomplete
                v-model="fk_role"
                clearable
                :items="roles"
                item-title="name"
                item-value="id"
                :label="$t('summer.work-type')"
                persistent-hint
                density="compact"
                hide-details="auto"
                prepend-inner-icon="mdi-format-list-bulleted"
                @update:modelValue="getItems(page=1)"
              ></VAutocomplete>
            </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
 </v-expansion-panels>
<DataTable
    v-if="$checkPermissions('summer_center.view_employee')"
    :items="employees"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        $router.push({ name: 'add-employee' })
      }
    "
    :exports="exportToExcel"
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="$checkPermissions('summer_center.delete_employee', ['sys_admin', 'summer_admin','gen_admin','gov_admin','dir_admin','user'])"
    :edit_perm="$checkPermissions('summer_center.change_employee', ['sys_admin', 'summer_admin','gen_admin','gov_admin','dir_admin','user'])"
    :add_perm="$checkPermissions('summer_center.add_employee', ['sys_admin', 'summer_admin','gen_admin','gov_admin','dir_admin','user'])"
  >
  <template v-slot:item.state="{ item }">
    {{item.raw.state?'مستمر':'منقطع'}}
  </template>
</DataTable>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{  
    DataTable
  },
  data() {
    return {
      DataLoading: false,
      exportProgress:false,
      protected_records: false,
      fk_governorate:null,
      fk_directorate:null,
      fk_summer:null,
      fk_role:null,
      summer_schools:[],
      data_message: [],
      tab: null,
      groups_list: [],
      employee: {},
      employees: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      dialogshow: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      user_delete_id: undefined,
      excel_fields:[
        { label: this.$t("summer.name-employees"), key: "name_ar" },
        { label: this.$t("globals.birth_date"), key: "birthdate" },
        { label: this.$t("globals.gender"), key: "gender_type" },
        { label: this.$t("globals.marital-status"), key: "marital_status_name"},
      ],
      headers: [
        { title: this.$t("summer.name-employees"), key: "name_ar",show:true, },
        { title: this.$t("globals.birth_date"), key: "birthdate",show:false, },
        { title: this.$t("globals.gender"), key: "gender_type",show:false, },
        { title: this.$t("globals.marital-status"), key: "marital_status_name",show:false, },
        { title: this.$t("summer.employee.children-numbers"), key: "children",show:false, },
        { title: this.$t("summer.employee.place-of-work"), key: "entity_type",show:true, },
        { title: this.$t("user.role"), key: "role_name",show:true, },
        { title: this.$t("globals.job-id"), key: "job_id",show:false, },
        { title: this.$t("summer.shift-time"), key: "work_period_name",show:false, },
        {
          title: this.$t("summer.employee.qualifications"),
          key: "qualifications_name",show:false,
        },
        { title: this.$t("globals.address"), key: "address",show:false, },
        { title: this.$t("globals.country"), key: "country_name",show:false, },
        { title: this.$t("globals.governorate"), key: "governorate_name",show:false, },
        { title: this.$t("globals.directorate"), key: "directorate_name",show:false, },
        { title: this.$t("summer.accommodation-type"), key: "accommodation_type_name",show:false, },
        {
          title: this.$t("summer.employee.mobile-number"),
          key: "phone_number",show:false,
        },
        { title: this.$t("user.fname"), key: "user_first_name",show:false, },
        { title: this.$t("user.lname"), key: "user_last_name",show:false, },
        { title: this.$t("user.username"), key: "user_username",show:false, },
        { title: this.$t("user.email"), key: "user_email",show:false, },
        { title: this.$t("summer.card-number"), key: "identity_id",show:false, },
        { title: this.$t("summer.employee.state"), key: "state",show:true, },
        { title: this.$t("summer.employee.other-work"), key: "other_work",show:false, },
        { title: this.$t("summer.employee.birth-place"), key: "birth_place",show:false, },
        { title: this.$t("user.group"), key: "group_user_name",show:true, },
        { title: this.$t("globals.note"), key: "note",show:false, sortable: false },
        { title: this.$t("globals.actions"), key: "actions",show:true,sortable: false }
      ],
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      subjects: (state) => state.summer.subjects,
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      employeeStates: (state) => state.summer.employeeStates,
      nationalities: (state) => state.school.nationalities,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      roles:(state)=>state.summer.roles,
      user: (state) => state.User,
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,

    }),
    fileName(){
      const title = " بيانات موظفي الدورات المدرسية النموذجية"
      var str = ''
      if(this.fk_governorate){
        str = ` ${this.$t("globals.for-governorate")} ( ${this.getGovernorateName(this.fk_governorate)}  )`
      }else{
        str = ` ( ${this.$t("globals.for-all-governorates") })`
      }
      if(this.fk_directorate){
        str+=` ${this.$t("globals.for-directorate") } (   ${this.getDirectorateName(this.fk_directorate) } )`
      }else{
        str+=` (  ${this.$t("globals.for-all-directorates") } )`
      }
      return `${title} ${str}`

    },
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      getGovernorateName: "school/getGovernorateName",
      getDirectorateName: "school/getDirectorateName",
    }),
    
  },
  async created() {

    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getRoles();
      await this.getEmployeeStates();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getItems()
      if(this.role==2){
        this.fk_governorate=this.user_governorate;
      }
      if(this.role==3){
        this.fk_directorate=this.user_directorate;
        this.getSummerSchools()
      }
      if(this.role>=0&&this.role<=3){
        this.headers.splice(2,0,
          { title: this.$t('summer.address-summer'), key: "summer_address" ,sortable: false}
        )
        this.headers.splice(2,0,
          { title: this.$t("summer.summer-camp"), key: "school_summer" }
        )
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async exportToExcel() {
      this.exportProgress=true
      var employees = []
      await this.axios(`${this.base_url}/api/summer/employee`, {
          params:{
            role:this.fk_role,
            governorate:this.fk_governorate,
            directorate:this.fk_directorate,
            school_summer:this.fk_summer
          }
        }).then(response=>{
          employees=JSON.parse(JSON.stringify(response.data,(key,value)=>value==null?'':value));
          employees.forEach(obj=>{
            obj.user_first_name=obj?.user_first_name??''
            obj.user_last_name=obj?.user_last_name??''
            obj.user_username=obj?.user_username??''
            obj.user_email=obj?.user_email??''
            obj.state=obj.state==true?this.$t('globals.continus'):this.$t('globals.drop-out')
          if(typeof(obj.group_user_name)=='object'){
            obj.group_user_name=obj.group_user_name?.join(' - ')
          }
        })
      })
      const headers=this.headers.filter(el=>!['actions'].includes(el.key)).map(item=>{
        return{ header:item.title,key:item.key }
      })
        
      await this.$ExportToExcel(headers,employees,this.fileName).then(res=>{
        this.exportProgress=false
      }).catch(err=>{
        this.exportProgress=false
      })
    },
    async getSummerSchools(){
      await this.axios.get(`${this.base_url}api/summer/school-select-list`, {
      params:{
        directorate:this.fk_directorate,
        governorate:this.fk_governorate
      }
      }).then(response=>{
          this.summer_schools=response.data
      })
    },
    ...mapActions({
      getEmployeeStates: "summer/getEmployeeStates",
      getRoles:"summer/getRoles",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate"
    }),
    resetForm() {
      this.$refs.form.resetValidation();
      this.employee = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    editItem(employee) {
      this.$router.push({ name: "edit-employee", params: { id: employee.id } });
      // this.tab = this.items[0].key;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        this.DataLoading = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/employee/" + delete_id, {
            data: { user_delete_id: this.user_delete_id }})
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.DataLoading = false;
          })
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          });
        return status
      }
    },
    async getItems(page = 1,per_page = 10,ordering = null,search = null) {
      this.DataLoading = true;
      await this.axios(`${this.base_url}/api/summer/employee`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
          role:this.fk_role,
          governorate:this.fk_governorate,
          directorate:this.fk_directorate,
          school_summer:this.fk_summer
        },
      }).then((response) => {
        this.employees = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.DataLoading = false;
      }).catch(error=>{
        this.DataLoading=false
      });
    },
  },
};
</script>

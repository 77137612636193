<template>
  <v-card v-if="!exist && role <= 3">
    <v-card-text>
      <h3 class="text-grey-darken-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <v-col
          cols="6"
          md="3"
          sm="4"
          class="mb-1"
          v-if="role >= 0 && role <= 1"
        >
          <VAutocomplete
            :items="filterGovernorates(1)"
            v-model="fk_governorate"
            item-title="name_ar"
            item-value="id"
            :label="$t('globals.governorate')"
            persistent-hint
            density="compact"
            hide-details
            clearable
            :no-data-text="$t('governorate.not-found')"
            prepend-inner-icon="mdi-city"
          >
          </VAutocomplete>
        </v-col>
        <VCol cols="6" md="3" sm="4" v-if="role >= 0 && role <= 2">
          <VAutocomplete
            :items="filterDirectorates(fk_governorate)"
            v-model="fk_directorate"
            item-title="name_ar"
            item-value="id"
            :label="$t('globals.directorate')"
            persistent-hint
            density="compact"
            hide-details
            clearable
            :no-data-text="$t('directorate.not-found')"
            prepend-inner-icon="mdi-map-outline"
          ></VAutocomplete>
        </VCol>
        <VCol cols="6" md="3" sm="4" >
          <DatetimePicker
            v-model="date"
            :calendar="'gregorg'"
            :placeholder="$t('globals.year-month-day')"
            :label="$t('globals.date')"
          ></DatetimePicker>
        </VCol>
        <v-col cols="6" md="2" sm="4" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="indigo"
            :loading="loading"
            @click="getData"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card flat v-if="exist" id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center pt-4">
          <h1 class="text-h6">
            {{ $t("report.user-input-data") }}
          </h1>
        </div>
      </template>
    </ReportHeader>
    <v-card-text class="mx-0 px-0 mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <!-- <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="exportToExcel"
          >
            <v-icon color="green-darken-2">mdi-file-excel</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top" >
              <small>{{ $t("export.excel") }}</small>
            </v-tooltip>
          </v-btn> -->
          <v-btn
            density="compact"
            class="pa-0"
            elevation="1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            elevation="1"
            class="ms-1"
            density="compact"
            @click="exist = false"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <div id="myTable">
        <v-card
          class="pa-3 border rounded-lg"
          flat
          elevation="0"
          v-if="items?.data?.length < 1"
        >
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <table
          class="report-t text-center"
          ref="table"
          v-if="items?.data?.length > 0"
        >
          <thead>
            <tr>
              <th>{{ $t("user.username") }}</th>
              <th>{{ $t("user.full-name") }}</th>
              <th>{{ $t("user.role") }}</th>
              <th>{{ $t("globals.governorate") }}</th>
              <th>{{ $t("globals.directorate") }}</th>
              <th>{{ $t("summer.registered-school") }}</th>
              <th>{{ $t("employee.count") }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(user,index) in items.data" :key="index">
              <tr>
                <td :rowspan="user.dirs_count+1">{{ user?.username }}</td>
                <td :rowspan="user.dirs_count+1">
                  {{ user?.fullname.includes("None") ? "---" : user?.fullname }}
                </td>
                <td :rowspan="user.dirs_count+1">{{ user?.role_name }}</td>
              </tr>
              <tr v-for="(dir, index) in user.dir_statistics" :key="index">
                <td >{{ dir?.governorate == "" ? "---" : dir?.governorate }}</td>
                <td >{{ dir?.directorate == "" ? "---" : dir?.directorate }}</td>
                <td >{{ dir?.schools_count }}</td>
                <td >{{ dir?.employees_count }}</td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="5">{{ $t("globals.grand-total") }}</th>
              <th>{{ items?.total?.schools_count ?? 0 }}</th>
              <th>{{ items?.total?.employees_count ?? 0 }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates();
      await this.getDirectorate();

      if (this.role == 2) {
        this.fk_governorate = this.user_governorate;
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      fk_governorate: null,
      fk_directorate: null,
      student_type: null,
      date:null,
      exist: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directroates,
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getStudentType: "summer_globals/getStudentType",
    }),

    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/users-added-open-school`, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            governorate: this.fk_governorate,
            directorate: this.fk_directorate,
            date:this.date
          },
        })
        .then((response) => {
          this.loading = false;
          this.exist = true;
          this.items = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.exist = false;
        });
    },
  },
};
</script>


<template>
  <v-expansion-panels>
    <v-expansion-panel >
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row>
          <VCol cols="12" md="6">
            <VAutocomplete
              color="indigo"
              v-model="statistic.school.fk_week"
              density="compact"
              item-title="week_name"
              item-value="id"
              prepend-inner-icon="mdi-calendar-week"
              clearable
              :items="weeks"
              :label="$t('summer.calendar.week-name')"
              @update:model-value="
                getDateList(), (statistic.school.today = undefined)
              "
              :rules="rules"
            />
          </VCol>
          <VCol cols="12" md="6">
            <VAutocomplete
              color="indigo"
              v-model="statistic.school.today"
              density="compact"
              item-title="week_name"
              item-value="week_name"
              prepend-inner-icon="mdi-calendar-today"
              :items="listWeeks"
              :label="$t('summer.calendar.hijri-date')"
              :rules="rules"
              @update:model-value="getStatistics()"
            />
          </VCol>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <VCard class="my-2">
    <v-card-text>
      <v-form ref="form" class="">
        <v-locale-provider :locale="$i18n.locale">
          <v-tabs
            v-model="tab"
            slider-color="indigo"
            center-active
            show-arrows
            density="compact"
            color="blue"
          >
            <v-tab v-for="item in items" :key="item.key" :value="item.key" tabindex="8">
              {{ item.value }}
            </v-tab>
          </v-tabs>
        </v-locale-provider>

        <v-window v-model="tab">
          <v-window-item :value="items[0].key">
            <v-row class="my-4 pt-2" ref="first">
              <v-col cols="12" md="10">
                <v-row>
                  <VCol
                    v-if="role < 3"
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                  >
                    <VCardItem class="pa-0">
                      <v-autocomplete
                        v-model="statistic.school.fk_directorate"
                        item-value="id"
                        item-title="name_ar"
                        prepend-inner-icon="mdi-map-outline"
                        :items="getDirectorateByGovernorates(fk_governorate)"
                        :label="$t('globals.directorate')"
                        persistent-hint
                        density="compact"
                        clearable
                        @update:modelValue="
                          getStatistics(),
                            getAllSummerCenters(),
                            (statistic.school.fk_summer = undefined)
                        "
                      ></v-autocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol
                    cols="12"
                    md="6"
                    v-if="
                      (role == 3 ||
                        !open_school_statistics_only) &&
                      role < 4
                    "
                  >
                    <VRadioGroup
                      inline
                      :label="$t('summer.summer_type')"
                      v-model="statistic.school.school_type"
                      @update:modelValue="
                        resetForm(),
                          getStatistics(),
                          (statistic.school.period = 1)
                      "
                    >
                      <VRadio
                        v-for="item in shoolTypes"
                        :key="item"
                        :label="item.name"
                        :value="item.id"
                      ></VRadio>
                    </VRadioGroup>
                  </VCol>
                  <VCol
                    cols="12"
                    md="6"
                    v-if="
                      role < 4 &&
                      statistic.school.school_type == 1
                    "
                  >
                    <VRadioGroup
                      inline
                      :label="$t('summer.period')"
                      v-model="statistic.school.period"
                      @update:modelValue="getStatistics()"
                    >
                      <VRadio
                        :label="$t('globals.one-period')"
                        :value="1"
                      ></VRadio>
                      <VRadio
                        :label="$t('globals.two-periods')"
                        :value="2"
                      ></VRadio>
                    </VRadioGroup>
                  </VCol>
                  <VCol
                    v-if="
                      role < 4 &&
                      statistic.school.school_type == 2
                    "
                    class="pt-0 px-3"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                  >
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        v-model="statistic.school.fk_summer"
                        item-value="id"
                        item-title="name_ar"
                        prepend-inner-icon="mdi-domain"
                        :items="getClosedSchools"
                        :label="$t('globals.summer-centre')"
                        persistent-hint
                        density="compact"
                        clearable
                        :rules="require_rule"
                        @update:model-value="
                          getStatistics(), getSchoolSummerInfo()
                        "
                      ></VAutocomplete>
                      <!-- @update:modelValue="getStatistics(),statistic.school.fk_summer ? '':resetForm()" -->
                    </VCardItem>
                  </VCol>
                </v-row>
                <v-row>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    md="6"
                    v-if="statistic.school.school_type == 1 && role <= 3"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.male_summer_count"
                        :label="$t('statistics.male-summer')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    md="6"
                    v-if="statistic.school.school_type == 1 && role <= 3"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.female_summer_count"
                        :label="$t('statistics.female-summer')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </v-row>
                <v-row>
                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.male_employees_count"
                        :label="$t('statistics.male-employee')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.female_employees_count"
                        :label="$t('statistics.female-employee')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </v-row>
                <v-row>
                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.committee_count"
                        :label="$t('statistics.committee-count')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.recive_approch_count"
                        :label="$t('statistics.recive-approch-count')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </v-row>
                <!-- <v-row>
                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.trainers_count"
                        :label="$t('statistics.trainers')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" md="4">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.managers_count"
                        :label="$t('statistics.managers')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol> 
                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.activity_responsible_count"
                        :label="$t('statistics.activity-responsibles')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </v-row>-->
                <v-row>
                  <VCol class="pt-0 px-3" cols="12" md="4">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.official_visits"
                        :label="$t('statistics.official-visits')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" md="4">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.supervision_visits"
                        :label="$t('statistics.supervision-visits')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" md="4">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.community_visits"
                        :label="$t('statistics.community-visits')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </v-row>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="items[1].key">
            <v-row class="my-4 pt-2" ref="second">
              <v-col cols="12" md="10">
                <v-row>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    md="6"
                    v-if="students_type != 2"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.martyrs_sons"
                        :label="$t('statistics.martyrs-sons')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    md="6"
                    v-if="students_type != 1"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.martyrs_daughters"
                        :label="$t('statistics.martyrs-daughters')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </v-row>
                <v-row v-for="item in statistic.level" :key="item">
                  <VCol class="pt-0 px-3" cols="12" md="4">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        :items="levels"
                        v-model="item.fk_level"
                        item-title="level_with_phase_name"
                        item-value="id"
                        :label="$t('summer.level.name_ar')"
                        prepend-inner-icon="mdi-stairs-box"
                        persistent-hint
                        density="compact"
                        :disabled="true"
                        :role="require_rule"
                      >
                      </VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    md="4"
                    v-if="students_type != 2"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="item.male_students_count"
                        :label="$t('statistics.male-students')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol
                    class="pt-0 px-3"
                    cols="12"
                    md="4"
                    v-if="students_type != 1"
                  >
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="item.female_students_count"
                        :label="$t('statistics.female-students')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </v-row>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="items[2].key">
            <v-row class="my-4 pt-2" ref="third">
              <v-col cols="12" md="10">
                <v-row>
                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.activaty_group_count"
                        :label="$t('statistics.activaty-group-count')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="statistic.school.group_activaty_count"
                        :label="$t('statistics.group-activaty-count')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </v-row>
                <v-row v-for="item in statistic.activity" :key="item">
                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VAutocomplete
                        :items="activityType"
                        v-model="item.fk_activity_type"
                        item-title="name"
                        item-value="id"
                        :label="$t('summer.activity.type')"
                        persistent-hint
                        prepend-inner-icon="mdi-soccer"
                        density="compact"
                        :disabled="true"
                        :rules="require_rule"
                      ></VAutocomplete>
                    </VCardItem>
                  </VCol>
                  <VCol class="pt-0 px-3" cols="12" md="6">
                    <VCardItem class="pa-0">
                      <VTextField
                        v-model="item.activity_count"
                        :label="$t('statistics.activity-count')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-numeric"
                        :rules="number_rule"
                      ></VTextField>
                    </VCardItem>
                  </VCol>
                </v-row>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-form>
    </v-card-text>
    <VCardActions class="px-3">
      <VBtn
        v-if="
          !is_update &&
          (statistic.school.school_type == 2 || role > 3)
        "
        class="bg-indigo"
        @click="asyncStatistics()"
        :loading="data_sync"
        size="small"
      >
        <span class="text-white">
          {{ $t("statistics.sync") }}
        </span>
        <VIcon icon="mdi-sync" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="!is_update"
        class="bg-indigo"
        @click="saveStatistic()"
        :loading="DataLoading"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update"
        class="bg-success"
        @click="updateStatistic()"
        :loading="DataLoading"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment-hijri";
export default {
  props: {
    id: Number,
  },
  data() {
    return {
      fk_directorate: undefined,
      fk_governorate: undefined,
      
      open_school_statistics_only: true,
      statistic: {
        school: {
          school_type: 1,
          period: 1,
        },
        level: [],
        activity: [],
      },
      students_type: null,
      listWeeks: [],
      all_summer_centers: [],
      DataLoading: false,
      data_sync: false,
      tab: null,
      number_rule: [
        (value) => !!value || value == 0 || this.$t("globals.required_field"),
        (value) =>
          parseInt(value) < 10000 ||
          `${this.$t("globals.biggest_number_of_character")} 9999`,
        (value) => /^[0-9]+$/.test(value) || this.$t("globals.must_be_numbers"),
      ],
      require_rule: [(value) => !!value || this.$t("globals.required_field")],
      dialogVisible: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      user_delete_id: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      governorate: (state) => state.school.governorates,
      activityType: (state) => state.summer.activityType,
      shoolTypes: (state) => state.summer.shoolTypes,
      levels: (state) => state.summer.levels,
      weeks: (state) => state.summer.weeks,
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
      current_year: (state) => state.current_year,
    }),
    ...mapGetters({
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
    getClosedSchools() {
      let list = [];
      this.all_summer_centers.forEach((element) => {
        if (element.school_type == 2) list.push(element);
      });
      return list;
    },
    items() {
      return [
        { key: 1, value: this.$t("statistics.center-statistic") },
        { key: 2, value: this.$t("statistics.students-statistic") },
        { key: 3, value: this.$t("statistics.activities-statistic") },
      ];
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getLevels();
      await this.getSchoolSummerInfo();
      await this.getActivityType();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.fetchWeek();
      await this.getShoolTypes();
      await this.getWeeks();
      await this.getDateList();
      if (this.role >= 2)
        this.fk_governorate = this.user_governorate;
      if (this.role == 3) {
        this.fk_directorate = this.user_directorate;
        this.getAllSummerCenters();
      }
      if (this.role > 3) {
        this.fk_directorate = this.user_directorate;
        await this.getStatistics();
      } else {
        this.resetFormLists();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getShoolTypes: "summer/getShoolTypes",
      getLevels: "summer/getLevels",
      getActivityType: "summer/getActivityType",
      getWeeks: "summer/getWeeks",
    }),
    async getSchoolSummerInfo() {
      await this.axios
        .get(`${this.base_url}api/summer/school/info`, {
          params: {
            fk_summer: this.statistic.school.fk_summer,
          }
        })
        .then((response) => {
          this.students_type = response.data.students_type;
        });
    },
    async getAllSummerCenters() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          params: {
            directorate: this.statistic.school.fk_directorate,
            // governorate:this.fk_governorate
          }
        })
        .then((response) => {
          if (response.data != "no summer school found")
            this.all_summer_centers = response.data;
        });
    },
    getCurrentDate() {
      let current_date = moment().format("iYYYY/iM/iD");
      return current_date;
    },
    resetForm() {
      if (this.$refs.form) this.$refs.form.resetValidation();
      let week_temp = this.statistic.school.fk_week;
      let date_temp = this.statistic.school.today;
      let type_temp = this.statistic.school.school_type;
      let period_temp = this.statistic.school.period;
      this.statistic.school = {};
      this.statistic.school.fk_week = week_temp;
      this.statistic.school.today = date_temp;
      this.statistic.school.school_type = type_temp;
      this.statistic.school.period = period_temp;
      this.statistic.level = [];
      this.statistic.activity = [];
      this.is_update = false;
      this.updated_id = undefined;
      this.resetFormLists();
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    resetFormLists() {
      if (!this.statistic.level.length)
        this.levels.forEach((element) => {
          this.statistic.level.push({
            fk_level: element.id,
            male_students_count: undefined,
            female_students_count: undefined,
          });
        });
      if (!this.statistic.activity.length)
        this.activityType.forEach((element) => {
          this.statistic.activity.push({
            fk_activity_type: element.id,
            activity_count: undefined,
          });
        });
    },
    openAllTabs() {
      setTimeout(() => {
        this.tab = 2;
      }, 1000);
      setTimeout(() => {
        this.tab = 3;
      }, 1500);
      setTimeout(() => {
        this.tab = 1;
      }, 2000);
    },
    async fetchWeek() {
      await this.axios.get(`${this.base_url}statistics/get-current-date`)
        .then((response) => {
          this.statistic.school.fk_week = response.data.week_id;
          this.statistic.school.today = response.data.current_date;
          // this.currnet_week_id = response.data.week_id;
          // this.currnet_date_id = response.data.current_date;
          this.getStatistics();
        })
        .catch((error) => {});
    },
    async getDateList() {
      if (this.statistic.school.fk_week) {
        await this.axios
          .get(this.base_url + "api/summer/get-work-days", {
            params: { week_id: this.statistic.school.fk_week },
          })
          .then((response) => {
            this.listWeeks = response.data.date;
          })
          .catch((error) => {});
      } else {
        this.resetForm();
      }
    },
    async checkValidation() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        return valid;
      }
      return false;
    },
    async saveStatistic() {
      if (await this.checkValidation()) {
        this.DataLoading = true;
        this.statistic.school.data_entry = this.user;
        this.statistic.school.fk_year = this.current_year;
        await this.axios
          .post(this.base_url + "public/school-statistic", this.statistic)
          .then((response) => {
            if (response.status == 204)
              this.$emit("infoAlert", this.$t("statistics.no-need"));
            else this.$emit("successAlert", this.$t("globals.data_added"));
            // this.resetForm();
            this.DataLoading = false;
            this.getStatistics();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.DataLoading = false;
          });
      }
    },
    async updateStatistic() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.DataLoading = true;
        this.statistic.school.updated_by = this.user;
        await this.axios
          .put(this.base_url +"public/school-statistic/" +this.statistic.school.id, this.statistic )
          .then((response) => {
            this.DataLoading = false;
            this.$emit("successAlert", this.$t("globals.data_updated"));
          })
          .catch((error) => {
            this.DataLoading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async getStatistics() {
      this.is_update = false;
      if (this.statistic.school.school_type && this.statistic.school.today) {
        if (
          this.statistic.school.school_type == 2 &&
          this.statistic.school.fk_summer == undefined
        )
          return 0;
        if (!this.statistic.school.fk_directorate && this.role < 3) return 0;
        await this.axios(`${this.base_url}/public/school-statistic`, {
          params: {
            fk_directorate: this.statistic.school.fk_directorate,
            period: this.statistic.school.period,
            schoolsummer: this.statistic.school.fk_summer,
            school_type: this.statistic.school.school_type,
            today: this.statistic.school.today,
          }
        }).then((response) => {
          let temp = this.statistic.school.fk_summer;
          let week_temp = this.statistic.school.fk_week;
          let date_temp = this.statistic.school.today;
          let type_temp = this.statistic.school.school_type;
          let directorate = this.statistic.school.fk_directorate;
          if (response.data) {
            this.statistic = response.data;
            if (response.data.update && response.data.update == true) {
              this.is_update = true;
            }
          } else {
            this.resetForm();
          }
          if (!this.statistic.school.fk_directorate)
            this.statistic.school.fk_directorate = directorate;
          this.statistic.school.fk_summer = temp;
          this.statistic.school.fk_week = week_temp;
          this.statistic.school.today = date_temp;
          this.statistic.school.school_type = type_temp;
        });
      }
    },
    async asyncStatistics() {
      this.data_sync = true;
      await this.axios(`${this.base_url}/public/statistic-async/`, {
        params: {
          // date:this.statistic.school.today,
          fk_summer: this.statistic.school.fk_summer,
          fk_directorate: this.fk_directorate,
          fk_governorate: this.fk_governorate,
        }
      })
        .then((response) => {
          let temp = this.statistic.school.fk_summer;
          let week_temp = this.statistic.school.fk_week;
          let date_temp = this.statistic.school.today;
          let type_temp = this.statistic.school.school_type;
          if (response.status == 204)
            this.$emit("infoAlert", this.$t("statistics.no-need"));
          else if (response.data) {
            if (
              response.data == "dir_saved" ||
              response.data == "gov_saved" ||
              response.data == "all_saved"
            )
              this.$emit(
                "successAlert",
                this.$t("statistics." + response.data)
              );
            if (response.data.school != undefined) {
              this.statistic = response.data;
              this.openAllTabs();
            }
          } else {
            this.resetForm();
          }
          this.statistic.school.fk_summer = temp;
          this.statistic.school.fk_week = week_temp;
          this.statistic.school.today = date_temp;
          this.statistic.school.school_type = type_temp;
        })
        .catch((error) => {
          this.data_sync = false;
        });
      this.data_sync = false;
    },
  },
};
</script>

<style>

</style>

import axios from "axios";
import { api_url } from "@/main";
import store from "@/store";

let tokenInterval = null;
let refresh_time = 1 * 10 * 100;

export default {
  async fetchData() {
    await axios.post(api_url + "api/token/refresh")
  },
  scheduleFetch() {
    this.fetchData();
    tokenInterval = setInterval(() => {
      this.fetchData();
    }, refresh_time);
  },
  checkLastFetchTime() {
    if (localStorage.getItem("refresh")) {
      const now = Date.now();
      const lastFetchTime = localStorage.getItem("lastFetchTime");

      if (!lastFetchTime || now - lastFetchTime > refresh_time) {
        this.scheduleFetch();
      } else {
        const remainingTime = refresh_time - (now - lastFetchTime);
        setTimeout(() => {
          this.scheduleFetch();
        }, remainingTime);
      }
    }
  },
  stopTokenRefresh() {
    if (tokenInterval) {
      clearInterval(tokenInterval);
      tokenInterval = null;
    }
  }
};
<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.StatisticsOfAllSummerCentersInTheDistricts") }}
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>

      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{
                $t(
                  "reportpublic.StatisticsOfAllSummerCentersInTheDistrictsLabel"
                )
              }}
              {{ $t("reportpublic.InDirectorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results.directorate_name_ar
                  : results.directorate_name_en
              }}
            </v-sheet>
          </v-col>

          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ results.summer_count }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="px-2 pt-1 my-4" style="height: 400px">
            <chart :option="totalOfStudentsMF" autoresize />
          </v-card>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
import * as echarts from "echarts";
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async mounted() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }

    this.totalOfStudentsMF = {
      title: {
        text: this.$t("reportpublic.NumberOfStudentsAndWorkers"),
        right: "center",
        textStyle: {
          fontFamily: "Almarai",
          fontSize: 12,
        },
        subtextStyle: {
          fontFamily: "Almarai",
          fontSize: 10,
        },
      },
      dataset: [
        {
          dimensions: [
            "name",
            this.$t("reportpublic.NumberOfWorkers2"),
            this.$t("reportpublic.NumberOfStudents"),
          ],
          source: this.results.summers.map((item) => [
            this.$i18n.locale == "ar"
              ? item.school_name_ar
              : item.school_name_en,
            item.worker_counts,
            item.student_count,
          ]),
        },
        {
          transform: {
            type: "sort",
            config: { dimension: "constant", order: "desc" },
          },
        },
      ],
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0,
          rotate: 40,
        },
      },
      yAxis: {
        type: "value",
        position: this.$i18n.locale == "ar" ? "right" : "",
        boundaryGap: [0, 0.3],
      },
      series: [
        {
          type: "bar",
          itemStyle: { borderRadius: [5, 5, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [3, 7],
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [5, 5, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [3, 7],
          },
        },
      ],
      legend: {
        bottom: "bottom",
        top: "85%",
        left: "center",
        itemHeight: 10,
        itemWidth: 13,
        borderWidth: 1,
        borderColor: "black",
      },
      transform: {
        type: "sort",
        config: { dimension: "constant", order: "desc" },
      },

      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params) {
          let tooltipText = params[0].axisValue + "<br/>";
          params.forEach((item, index) => {
            tooltipText +=
              item.value[index + 1] +
              "&nbsp;&nbsp;&nbsp;" +
              item.marker +
              " " +
              item.seriesName +
              "<br/>";
          });

          return tooltipText;
        },
      },
      grid: {
        bottom: "12%",
        containLabel: true,
        top: "10%",
      },
    };
  },
  methods: {
    async fetchData() {
      await  this.axios
        .get(`${this.base_url}public-reports/directorate-reports/summer-center-statistics?directorate_id=${this.id}`)
        .then((response) => {
          this.results = response.data.results;
        });
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },

  data() {
    return {
      id: 4,
      results: {},
      results2: [],
      statistics: [],
      option_2: {},
      totalOfStudentsMF: {},
      size: null,
    };
  },
  
  components: {
    ReportHeader,
  },
};
</script>

import axios from "axios";
import store from "@/store/index.js";
import {decryptString, encryptString} from '@/utils/index.js'
import host from '@/main'
import jwtDecode from "jwt-decode";
import routes from "@/router";

const isTokenNearExpiry=(token,bufferMinutes=5)=>{
  const decoded = jwtDecode(token)
  const expiryTime = decoded.exp * 1000;
  const bufferTime = bufferMinutes * 60 * 1000;
  return Date.now() >= expiryTime - bufferTime;
}

const refreshAccessToken = async () =>{
  try {
    const refreshToken = localStorage.getItem('refresh');
    if(!refreshToken) throw new Error('Refresh token not found.');
    const response =  await custom_axios.post(`${host()}/api/token/refresh`,{refresh:refreshToken});
    const access = response.data.access;
    localStorage.setItem('token',access);
    localStorage.setItem('refresh',response.data.refresh);
    return access;
  } catch (error) {
    throw error;
  }
}

const custom_axios = axios.create({
  withCredentials:true,
  timeout: 1800000,
  strict:true
  
    
});

custom_axios.interceptors.response.use(
  (response)=>  response,
  async (error)=> {
    if(error.response?.status==401){
      store.dispatch('logout')
    }
    if(error.message=="Network Error"){
      store.commit('setErrorConnection',true)
    }
    else if(error.message.includes("timeout")) {
      store.commit('setTimeoutAlert',true)
    }
    else if(error?.response?.status==409){
      store.commit('setReadOnlyAlert',true)
    }
    else if (error?.response?.data?.code=='bad_authorization_header'){
      localStorage.clear()
    }
    return Promise.reject(error);
  }
  );
export default custom_axios;
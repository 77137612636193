<template>
  <v-card
    v-if="$checkPermissions('summer_center.add_lecturesummer', ['sys_admin', 'gen_admin'])">
    <v-card-text class="pb-0">
      <v-form ref="form" @submit.prevent="saveLectureAttendance">
        <v-row class="mt-2">
          <v-col cols="12" md="10" xl="10">
            <v-text-field
              color="indigo"
              ref="lecture_name"
              v-model="lecture_attendance.name"
              counter="100"
              autofocus
              prepend-inner-icon="mdi-format-text"
              :label="$t('lecture.name')"
              :error-messages="v$.lecture_attendance.name.$errors.map((e) => e.$message)"
              
            />
          </v-col>
          <v-col cols="12" md="5" xl="5">
            <v-autocomplete
              color="indigo"
              v-model="lecture_attendance.fk_level"
              :item-title="classesName"
              item-value="id"
              prepend-inner-icon="mdi-stairs-box"
              :items="classes"
              :label="$t('summer.level.name_ar')"
              :error-messages="
                v$.lecture_attendance.fk_level.$errors.map((e) => e.$message)
              "
              @update:modelValue="(lecture_attendance.fk_subject = null), getSubjects()"
              
            />
          </v-col>
          <v-col cols="12" md="5">
            <v-autocomplete
              color="indigo"
              v-model="lecture_attendance.fk_subject"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-bookshelf"
              :items="subjects"
              :label="$t('subject.select')"
              :error-messages="
                v$.lecture_attendance.fk_subject.$errors.map((e) => e.$message)
              "
            />
          </v-col>
          <v-col cols="10">
            <v-textarea
              color="indigo"
              v-model="lecture_attendance.note"
              hide-details="auto"
              counter="250"
              rows="3"
              prepend-inner-icon="mdi-note-outline"
              :label="$t('globals.note')"
              :error-messages="v$.lecture_attendance.note.$errors.map((e) => e.$message)"
              
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-3">
      <v-btn
        v-if="
          !update &&
          $checkPermissions('summer_center.add_lecturesummer', [
            'sys_admin',
            'gen_admin',
          ])
        "
        @click="saveLectureAttendance"
        class="bg-indigo"
        size="small"
        :loading="saveDataLoading"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
        v-if="
          update &&
          $checkPermissions('summer_center.change_lecturesummer', [
            'sys_admin',
            'gen_admin',
          ])
        "
        @click="updateLectureAttendance(selected_lecture_attendance)"
        class="bg-success"
        size="small"
        :loading="editDataLoading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn @click="cleanLectureAttendanceForm" class="mx-3 bg-ccc" size="small">
        <span>
          {{ $t("globals.clear") }}
        </span>
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
export default {
  name: "LectureAttendance",
  props: {
    id: String,
  },
  async mounted() {
    if (this.id) {
      await this.axios
        .get(`${this.base_url}api/summer/lecture-summer/${this.id}`)
        .then((response) => {
          this.lecture_attendance = response.data.results;
          this.getSubjects();
          this.update = true;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("alert.not-found.message"));
        });
    }
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      await this.getClasses();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      v$: useValidate(),
      teachers: [],
      subjects: [],
      txt_search: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      alert: false,
      saveDataLoading: false,
      editDataLoading: false,
      user: null,
      alert: {
        color: undefined,
        icon: undefined,
        title: undefined,
        message: undefined,
      },
      selected_lecture_attendance: null,
      lecture_attendance: {
        name: "",
        note: "",
        fk_level: undefined,
        fk_subject: undefined,

        data_entry: undefined,
        updated_by: undefined,
        fk_year: this.current_year,
      },

      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.summer.levels,
      current_year: (state) => state.current_year, 
    }),
    ...mapGetters({
      getDivisionName: "summer/getDivisionName",
    }),
    classesName() {
      return this.$i18n.locale == "ar" ? "level_with_phase_name" : "level_with_phase_name";
    },
  },

  validations() {
    return {
      lecture_attendance: {
        name: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(100)),
        },
        note: {
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(250)),
        },
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_subject: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },

        data_entry: {},
        updated_by: {},
      },
    };
  },
  methods: {
    ...mapActions({
      getClasses: "summer/getLevels",
    }),
    async getSubjects() {
      if (this.lecture_attendance.fk_level != null) {
        let subjects = await this.axios.get(
          this.base_url + "api/summer/subjects-by-level",
          {
            params: {
              level: this.lecture_attendance.fk_level,
            },
          }
        );
        this.subjects = subjects.data;
      }
    },

    submitLectureAttendance() {
      this.v$.$validate();
    },
    async saveLectureAttendance() {
      this.submitLectureAttendance();
      if (!this.v$.$error) {
        this.saveDataLoading = true;
        this.lecture_attendance.data_entry = this.user;
        this.axios
          .post(`${this.base_url}api/summer/lecture-summer`, this.lecture_attendance)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.cleanLectureAttendanceForm();
            this.saveDataLoading = false;
            this.$refs.lecture_name.focus();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
            this.saveDataLoading = false;
          });
      }
      setTimeout(() => {}, 1500);
    },
    cleanLectureAttendanceForm() {
      if (this.update) {
        this.update = false;
        this.$router.push({ name: "lecture_add" });
      }
      this.v$.lecture_attendance.$reset();
      this.$refs.form.reset();
    },
    async updateLectureAttendance() {
      this.submitLectureAttendance();
      if (!this.v$.$error) {
        this.editDataLoading = true;
        this.lecture_attendance.updated_by = this.user;
        await this.axios
          .put(`${this.base_url}api/summer/lecture-summer/${this.id}`,this.lecture_attendance)
          .then((response) => {
            this.$emit("successAlert", this.$t("alert.success.update"));
            this.editDataLoading = false;
            setTimeout(() => {
              this.cleanLectureAttendanceForm();
              this.$router.push({ name: "lecture_list" });
            }, 1500);
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.update"));
            this.editDataLoading = false;
          });
      }
      setTimeout(() => {}, 1500);
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>

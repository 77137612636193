<template>
  <DataTable
    v-if="
      $checkPermissions('summer_center.view_divisionsummer')
    "
    :create="
      () => {
        addDialog = true;
      }
    "
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      $checkPermissions('summer_center.delete_divisionsummer',['sys_admin','gen_admin'])
    "
    :edit_perm="
      $checkPermissions('summer_center.change_divisionsummer',['sys_admin','gen_admin'])
    "
    :add_perm="
      $checkPermissions('summer_center.add_divisionsummer',['sys_admin','gen_admin'])
    "
  >
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <v-form
          ref="form"
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="division.name"
                counter="30"
                autofocus
                :rules="uniqueDivision"
                prepend-inner-icon="mdi-home-outline"
                :label="$t('division.name')"
                :error-messages="v$.division.name.$errors.map((e) => e.$message)"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="division.symbol"
                counter="3"
                :rules="uniqueSymbol"
                prepend-inner-icon="mdi-shape-outline"
                :label="$t('division.symbol')"
                :error-messages="v$.division.symbol.$errors.map((e) => e.$message)"
              />
            </v-col>
            <v-col cols="12" >
              <v-textarea
                v-model="division.note"
                hide-details="auto"
                counter="250"
                rows="2"
                prepend-inner-icon="mdi-note-outline"
                :label="$t('globals.note')"
                :error-messages="v$.division.note.$errors.map((e) => e.$message)"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

import {required,helpers, maxLength} from "@vuelidate/validators";

const dateValidation = function (value) {
  const date = new Date().toJSON().slice(0, 10);
  return value && value > date ? true : false;
};

export default {
  components:{
    DataTable
  },
  name: "Adddivision",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      addDialog:false,
      divisions: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      v$: useValidate(),
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      name: "",
      symbol: "",
      addDialog: false,
      overlay: false,
      is_update: false,
      selectedDivision: null,
      division: {
        name: "",
        symbol: "",
        note: "",
        data_entry: null,
        updated_by: null,
      },
      defaultdivision: {
        name: "",
        symbol: "",
        note: "",
        data_entry: null,
        updated_by: null,
      },
      uniqueDivision: [
        (value) => {
          if (!this.update || this.name != this.division.name.trim())
            return !this.divisionNames.includes(value.trim())
              ? true
              : this.$t("errors.unique");
          return true;
        },
      ],
      uniqueSymbol: [
        (value) => {
          const list = this.symbols.map((item) => item.toLowerCase());
          if (!this.update || this.symbol != this.division.symbol.trim().toLowerCase())
            return !list.includes(value.trim().toLowerCase())
              ? true
              : this.$t("errors.unique");
          return true;
        },
      ],
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("division.name"), key: "name" },
        { title: this.$t("division.symbol"), key: "symbol" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ],
    };
  },
  computed: {
    divisionNames() {
      return this.divisions.map((item) => item.name);
    },
    symbols() {
      return this.divisions.map((item) => item.symbol);
    },
    ...mapState({
      // divisions: (state) => state.summer.divisions,
    }),
    tableList() {
      if (this.divisions) {
        return JSON.parse(JSON.stringify(this.divisions));
      }
    },
  },

  validations() {
    return {
      division: {
        name: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(30)),
        },
        symbol: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(3)),
        },
        note: {
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(250)),
        },
      },
    };
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      // getItems: "summer/getItems",
    }),

    editItem(item) {
      this.selectedDivision = item.id;
      this.name = item.name.trim();
      this.addDialog = true;
      this.symbol = item.symbol.trim().toLowerCase();
      this.division = Object.assign({}, item);
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    submitDivisionForm() {
      this.v$.$validate();
    },
    async saveItem() {
      this.submitDivisionForm();
      if (!this.v$.$error) {
        this.division.data_entry = this.user;
        this.saveDataLoading = true;
        await this.axios
          .post(`${this.base_url}api/summer/division-summer`, this.division)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
            this.getItems();
            this.saveDataLoading = false;
          })
          .catch((error) => {
            if (error.response.data.name)
              this.$emit("warningAlert", this.$t("summer.already-division"));
            else if (error.response.data.symbol)
              this.$emit("warningAlert", this.$t("summer.already-division"));
            else this.$emit("errorAlert", this.$t("alert.failure.insert"));
            this.saveDataLoading = false;
          });
      }
    },
    resetForm() {
      this.is_update = false;
      this.v$.division.$reset();
      this.$nextTick(() => {
        this.division = Object.assign({}, this.defaultdivision);
      });
      this.addDialog = false;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    async updateItem() {
      this.submitDivisionForm();
      if (!this.v$.$error&&this.division?.id) {
        this.editDataLoading = true;
        await this.axios
          .put(`${this.base_url}api/summer/division-summer/${this.division.id}`, this.division)
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("alert.success.update"));
            this.getItems();
            this.resetForm();
            this.editDataLoading = false;
          })
          .catch((error) => {
            if (error.response.data.name)
              this.$emit("warningAlert", this.$t("summer.already-division"));
            else if (error.response.data.symbol)
              this.$emit("warningAlert", this.$t("summer.already-division"));
            else this.$emit("errorAlert", this.$t("alert.failure.update"));
            this.editDataLoading = false;
          });
      }
    },
    async deleteItem(delete_id) {
      if(delete_id){
        var status;
        await this.axios
        .delete(`${this.base_url}api/summer/division-summer/${delete_id}`)
        .then(() => status = true)
        .catch((error) => status = error)
        return status
      }
    },
    async getItems(page = 1,per_page=10,ordering='id',search=null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/division-summer`, {
        params: {
          search:search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.divisions = response.data.results;
        this.pagination = response.data.pagination;
      });
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>

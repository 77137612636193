<template>
  <DataTable
    v-if="$checkPermissions('globaltable.view_yearofstudy')"
    :items="academicYears"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      $checkPermissions('globaltable.delete_yearofstudy', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :edit_perm="false"
    :add_perm="
      $checkPermissions('globaltable.add_yearofstudy', [
        'sys_admin',
        'gen_admin',
      ])
    "
  >
    <template v-slot:item.curentyear="{ item }">
      <v-icon v-if="item.raw.curentyear" color="green-darken-2"
        >mdi-check-circle</v-icon
      >
      <v-icon v-else color="red-darken-2">mdi-close-circle</v-icon>
    </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    persistent
    class="text-main-color"
  >
    <v-card class="mb-2 pa-2" :disabled="saveDataLoading" >
      <v-locale-provider :locale="$i18n.locale" >
        <v-tabs
          v-model="step"
          class="bg-white text-main-color"
          slider-color="indigo"
          center-active
          show-arrows
          density="compact"
          color="blue"
        >
          <v-tab  v-for="(tab, index) in tabs"  :class="{'text-error':index==0&&!form_valid}" :slider-color="index==0&&!form_valid?'error':'indigo'"  :prepend-icon="tab.icon" :key="index" :value="index">
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </v-locale-provider>
      <v-window v-model="step" class="my-2" >
        <v-window-item :value="0">
          <v-card flat elevation="0" class="pt-2">
            <v-alert variant="tonal" border="start" border-color="indigo" class="text-main-color ">اسم السنة الهجري هو المفتاح الأساسي لادارة بيانات العام الدراسي الجديد, لذا تأكد من إدخاله بشكل صحيح</v-alert>
            <v-form
              ref="form"
              @submit.prevent="is_update ? updateItem() : saveItem()"
            >
              <v-row class="my-2 mt-4">
                <v-col cols="12" md="6">
                  <v-text-field
                    color="indigo"
                    v-model="academicYear.year"
                    :placeholder="$t('summer.year.placeholder.year-h')"
                    counter="4"
                    autofocus
                    prepend-inner-icon="mdi-calendar-range"
                    :label="$t('year.name-h')"
                    :rules="rules.year"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    color="indigo"
                    v-model="academicYear.year_m"
                    clearable
                    counter="4"
                    :placeholder="$t('summer.year.placeholder.year-m')"
                    density="compact"
                    prepend-inner-icon="mdi-calendar-range"
                    :label="$t('year.name-m')"
                    :rules="rules.year_m"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    color="indigo"
                    v-model="academicYear.note"
                    hide-details="auto"
                    density="compact"
                    counter="250"
                    rows="2"
                    clearable
                    prepend-inner-icon="mdi-note-outline"
                    :label="$t('globals.note')"
                    :rules="rules.note"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-window-item>
        <v-window-item :value="1">
          <v-card flat elevation="0" class="mb-2">
            <v-alert  variant="tonal" border="start" border-color="indigo" class="text-main-color ">
              لإنشاء السنة الدراسية الجديدة, نحتاج إلى نسخ بيانات من سنة دراسية
              سابقة, لذلك سيتم استخدام قاعدة البيانات الخاصة بسنة
              <span class="text-h6"> {{ current_year_name }} </span> كمصدر للبيانات
            </v-alert>
            <v-expansion-panels class="mt-2">
              <v-expansion-panel  class="border">
                <v-expansion-panel-title class="text-main-color">
                  سيتم نسخ معلومات مثل أسماء الطلاب, تفاصيل الصفوف والموظفين,
                  دون أي تعديل على قاعدة البيانات الأصلية.
                </v-expansion-panel-title>
                <v-expansion-panel-text class="scroll text-main-color">
                  <v-list-item  prepend-icon="mdi-minus" v-for="(model,index) in models_to_copy" :key="index">{{$t(`models.${model}`)}}</v-list-item>
                </v-expansion-panel-text>
              </v-expansion-panel>
              <v-expansion-panel  class="border mt-1">
                <v-expansion-panel-title class="text-main-color">
                  بيانات مثل الحضور, الغياب, ودرجات الطلاب لن يتم نسخها.
                </v-expansion-panel-title>
                <v-expansion-panel-text class="scroll text-main-color">
                  <v-list-item prepend-icon="mdi-minus-" v-for="(model,index) in models_to_clear" :key="index">{{$t(`models.${model}`)}}</v-list-item>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-list-item  class="border mt-1 rounded">قاعدة البيانات للسنة الدراسية السابقة ستكون خاصة بالقراءة فقط </v-list-item>
          </v-card>
        </v-window-item>
        <v-window-item :value="2">
          <v-card flat elevation="0" class="mb-2"  v-if="!saveDataLoading">
            <v-alert variant="tonal" border="start" border-color="indigo" class="text-main-color ">
             قبل متابعة العملية يرجى مراجعة التفاصيل التالية  : 
            </v-alert>
            <v-list-item prepend-icon="mdi-minus">اسم السنة الدراسية الجديدة : {{academicYear.year}}</v-list-item>
            <v-list-item prepend-icon="mdi-minus">اسم السنة الدراسية السابقة : {{current_year_name}}</v-list-item>
            <v-alert variant="tonal" type="warning"  title="تنبيه" text="هذه العملية قد تستغرق عدة دقائق حسب حجم البيانات. تأكد من موافقتك على جميع الخطوات قبل المتابعة.">
            </v-alert>
            <v-btn block color="indigo" class="mt-2" @click="saveItem()">متابعة</v-btn>
          </v-card>
          <v-card flat elevation="0" class="mb-2" v-else >
            <v-progress-linear height="20" indeterminate striped class="my-2"></v-progress-linear>
            <v-alert variant="tonal" border="start" border-color="indigo" class="text-main-color ">جاري تنفيذ العملية... يرجى الانتضار قليلا.</v-alert>
            <v-list-item prepend-icon="mdi-minus" class="border mt-1 rounded">اضافة السنة الدراسية</v-list-item>
            <v-list-item prepend-icon="mdi-minus" class="border mt-1 rounded">نسخ البيانات من السنة الدراسية السابقة</v-list-item>
            <v-list-item prepend-icon="mdi-minus" class="border mt-1 rounded">إعداد قاعدة البيانات الجديدة وإزالة البيانات غير الضرورية</v-list-item>
            <v-list-item prepend-icon="mdi-minus" class="border mt-1 rounded">احالة السنة الدراسية السابقة للقراءة فقط </v-list-item>
          </v-card>
        </v-window-item>
      </v-window>
      <v-card-action class="d-flex justify-space-between">
        <div>
          <v-btn
            color="indigo"
            size="small"
            :disabled="!isNext"
            @click="nextStep()"
            >{{ $t("globals.next") }}</v-btn
          >
          <v-btn
            size="small"
            class="ms-2"
            :disabled="!isPrev"
            @click="prevStep()"
            >{{ $t("globals.prev") }}</v-btn
          >
        </div>
        <v-btn size="small" color="ccc" @click="resetForm">{{
          $t("globals.cancel")
        }}</v-btn>
      </v-card-action>
    </v-card>
  </v-dialog>
</template>

<script>
import Datetime from "vue3-datetime-js";
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  name: "AcademicYear",
  components: {
    Datetime,
    DataTable,
  },
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      current_year_name: this.current_year_name,
      form_valid:true,
      models_to_clear:[
        "student_approach",
        "marksummer",
        "studentattendancesummer",
        "employeeattendance",
        "visitor",
        "studentactivity",
        "levelstudentyear_initiative",
        "vacation",
        "supplies",
        "employeelevel",
        "commiteemember",
        "activitydifficulty",
        "approach",
        "recommendation",
        "calendar",
        "week",
        "activityoperation",
        "lecturelog",
        "directoratecertficatestatistic",
        "directorategiftstatistic",
        "opensummerdirectorateapproachstatistic",
        "opensummerdirectoratecardstatistic",
        "permitanddocumentstatistic",
        "sportaccessoriestatistic",
        "summeropentaskmangement",
        "summeropenactivitystatistic",
        "summeropenvisits",
        "summeropendropout",
        "summeropenlevelstatistics",
        "erroroffiledwithopensummer",
        "opensummerdirectorateapproachstatistic",
      ],
      models_to_copy:[
        "supervisor",
        "schoolsummer",
        "subjectsummer",
        "talent",
        "skill",
        "governorate",
        "directorate",
        "country",
        "yearofstudy",
        "daysummer",
        "level",
        "divisionsummer",
        "studentsummer",
        "parentsummer",
        "activitysummer",
        "activitytype",
        "initiative",
        "commitee",
        "schedulesummer",
        "schoolweeklyschedulesummer",
        "employee",
        "teachersubjectsummer",
        "auth_data",
        "mobileversion",
        "app",
      ],
      step: 0,
      tabs: [
        {title:this.$t("globals.create-academic-year"),icon:"mdi-calendar"},
        {title:this.$t("globals.copy-old-database"),icon:"mdi-database-sync-outline"},
        {title:this.$t("globals.confirm-proccess"),icon:"mdi-database-settings-outline"},
      ],
      rules: {
        year_m: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value?.length <= 4) ||
            `${this.$t("globals.biggest_number_of_character")} 9999`,
          (value) =>
            (value && /^[0-9]+$/.test(value)) ||
            this.$t("globals.must_be_numbers"),
        ],
        year: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value?.length <= 4) ||
            `${this.$t("globals.biggest_number_of_character")} 9999`,
          (value) =>
            (value && /^[0-9]+$/.test(value)) ||
            this.$t("globals.must_be_numbers"),
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      is_update: false,
      academicYears: [],
      saveDataLoading: false,
      editDataLoading: false,
      addDialog: false,
      user: null,
      current_year: null,
      selectedHead: [
        "year",
        "year_m",
        "curentyear",
        this.checkrole(["sys_admin", "gen_admin"]) ? "actions" : null,
      ],
      selectedAcademicYear: null,
      academicYear: {},
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("year.name-h"), key: "year" },
        { title: this.$t("year.name-m"), key: "year_m" },
        { title: this.$t("year.current-year"), key: "curentyear" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
    };
  },
  computed: {
    isNext() {
      return this.step < this.tabs?.length - 1;
    },
    isPrev() {
      return this.step > 0;
    },
    years() {
      return this.academicYears.map((item) => item.year.split("/")[0]);
    },
    years_m() {
      return this.academicYears.map((item) => item.year_m.split("/")[0]);
    },

    ...mapState({
      // academicYears: (state) => state.school.academic_years,
      current_year_name: (state) => state.current_year_name,
    }),
    
  },

  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    nextStep() {
      if (this.step < this.tabs?.length - 1) this.step++;
    },
    prevStep() {
      if (this.step > 0) this.step--;
    },
    ...mapActions({
      // getItems: "school/getItems",
    }),
    async getCurrentYear() {
      await this.axios
        .get(`${this.base_url}/academic-years/current-year/`)
        .then((res) => {
          localStorage.setItem("current_year", res.data.id);
        });
    },

    editItem(item) {
      this.academicYear = Object.assign({}, item);
      this.is_update = true;
      this.addDialog = true;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveItem() {
      const {valid} = await this.$refs.form.validate();
      this.form_valid = valid
      if (valid) {
        this.saveDataLoading = true;
        this.academicYear.data_entry = this.user;
        this.axios
          .post(`${this.base_url}academic-years/`, this.academicYear)
          .then(() => {
            this.$emit("successAlert", this.$t("alert.success.insert"));
            this.resetForm();
            this.getItems();
            this.getCurrentYear();
            this.saveDataLoading = false;
          })
          .catch((error) => {
            if (error.message.includes("Network Error")) {
              this.$emit("errorAlert", this.$t("alert.failure.connect"));
            } 
            else if(error.response?.data?.includes("it is not exist")) {
              this.$emit("errorAlert", "لا يوجد قاعدة بيانات لهذه السنة. الرجاء التواصل مع مسؤول النظام لاضافة قاعدة البيانات");
            }else {
              this.$emit("errorAlert", this.$t("alert.failure.insert"));
            }
            if (error.response.data.year_m) {
              this.$emit("warningAlert", this.$t("summer.already-year"));
            }
            if (error.response.data.year) {
              this.$emit("warningAlert", this.$t("summer.already-year-m"));
            }
            this.saveDataLoading = false;
          });
      }
    },
    resetForm() {
      this.is_update = false;
      this.academicYear.curentyear = false;
      this.$refs.form.reset();
      this.addDialog = false;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.academicYear.id) {
        this.editDataLoading = true;
        this.academicYear.updated_by = this.user;
        await this.axios
          .put(
            `${this.base_url}academic-years/${this.academicYear.id}/`,
            this.academicYear
          )
          .then((response) => {
            this.editDataLoading = false;
            this.update = false;
            this.$emit("successAlert", this.$t("alert.success.update"));
            this.getItems();
            this.resetForm();
            this.getCurrentYear();
          })
          .catch((error) => {
            this.editDataLoading = false;
            if (error.response?.data?.year) {
              this.$emit("warningAlert", this.$t("summer.already-year"));
            } else if (error.response?.data?.year_m) {
              this.$emit("warningAlert", this.$t("summer.already-year-m"));
            } else if (error.response?.data?.includes("you cannot change")) {
              this.$emit("errorAlert", this.$t("errors.close-open-year"));
            } else this.$emit("errorAlert", this.$t("alert.failure.update"));
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(`${this.base_url}academic-years/${delete_id}/`)
          .then((response) => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}academic-years-for-data-table/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.academicYears = response.data.results;
        this.pagination = response.data.pagination;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
        this.step = 0;
      }
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>

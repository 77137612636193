<template>
    <v-expansion-panels
      model-value="1"
      class="mb-6"
      v-if="(role>=0 && role<=2) &&!isexit"
    >
      <v-expansion-panel  value="1">
        <v-expansion-panel-title class="px-4">
          <div class="d-flex justify-space-between w-75">
            <div>
              <v-icon>mdi-tune</v-icon>
              <span class="mx-1">{{ $t("globals.select-criteria") }}</span>
            </div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row class="py-2">
            <VCol
              class="mt-1"
              cols="12"
              md="3"
              sm="6"
              v-if="role == 0 || role == 1"
            >
              <VAutocomplete
                :items="filterGovernorates(1)"
                v-model="fk_governorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact"
                hide-details
                clearable
                :no-data-text="$t('governorate.not-found')"
                prepend-inner-icon="mdi-city"
                @update:modelValue="fk_directorate=null"
              ></VAutocomplete>
            </VCol>
            <VCol
              class="mt-1"
              cols="12"
              md="3"
              sm="6"
            >
              <VAutocomplete
                :items="filterDirectorates(fk_governorate)"
                v-model="fk_directorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.directorate')"
                persistent-hint
                hide-details
                density="compact"
                clearable
                :no-data-text="$t('directorate.not-found')"
                prepend-inner-icon="mdi-map-outline"
              ></VAutocomplete>
            </VCol>
            <v-col cols="6" sm="2" class="d-flex align-center">
              <v-btn
                density="comfortable"
                append-icon="mdi-presentation"
                class="w-100"
                color="indigo"
                :loading="loading"
                @click="fetchData()"
              >
                <span>{{ $t("globals.show") }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  <v-card id="report" v-if="isexit">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2 ">
          <div class="text-h5">
            {{ $t("summer.visits-total") }}
            <template v-if="fk_governorate" > {{$t('globals.for-governorate')}} ( {{getGovernorateName(fk_governorate)}} ) </template>
            <template v-if="!fk_governorate"  > ( {{$t('globals.for-all-governorates')}} ) </template>
            <template v-if="fk_directorate"  >  {{$t('globals.for-directorate')}} ( {{getDirectorateName(fk_directorate)}} ) </template>
            <template v-if="!fk_directorate"  > ( {{$t('globals.for-all-directorates')}} ) </template>
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = false))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
        </div>
        <v-btn density="compact" class="mx-1" @click="isexit = !isexit" v-if="role>=0&&role<=2">
          <span class="text-indigo">{{ $t("globals.back") }}</span>
        </v-btn>
      </div>
      
    </v-card-title>
      <table class="report-t text-center" v-if="visits?.entities?.length >= 1">
            <thead>
              <tr>
                <th rowspan="2" class="text-h6">{{$t('globals.governorate')}}</th>
                <th rowspan="2" class="text-h6">{{$t('globals.directorate')}}</th>
                <th :colspan="visits?.entities?.length" class="text-h6">{{$t('globals.visiting-entity')}}</th>
              </tr>
              <tr>
                <th
                  v-for="(entity, index) in visits?.entities"
                  :key="index"
                  class="right-header"
                >
                  {{ entity }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(entity, index) in visits?.visits_data"
                :key="index"
              >
                <td>{{entity?.governorate}}</td>
                <td>{{entity?.directorate}}</td>
                <td v-for="(entity_visits,index) in entity.entities_visits" :key="index">
                  {{ entity_visits?.visits_count }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">{{$t('summer.visits-total')}}</th>
                <th v-for="(total,index) in visits?.total_visits" :key="index">
                  {{total}}
                </th>
              </tr>
            </tfoot>
            
      </table>
    <v-row v-else justify="center" align-content="center" class="pa-4">
      <v-card class="w-100 pa-3 text-center">
        {{$t('globals.not-found')}}
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions ,mapGetters} from "vuex";

export default {
  
  async created(){
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates();
      await this.getDirectorate();
      if(this.role==2){
        this.fk_governorate = this.user_governorate;
      }else if(this.role==3){
        this.fk_governorate = this.user_governorate;
        this.fk_directorate = this.user_directorate;
        this.isexit = true
      }
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getDirectorate: "school/getDirectorate",
      getGovernorates: "school/getGovernorates",

    }),
    getGovernorateName(gov_id){
      return this.governorates?.find(gov=>gov.id==gov_id)?.name_ar
    },
    getDirectorateName(dir_id){
      return this.directorates?.find(dir=>dir.id==dir_id)?.name_ar
    },
    async fetchData() {
      this.loading = true
      await  this.axios
        .get(`${this.base_url}api/summer/visits-carried-out-per-directorate`,
          {
            params:{
              fk_governorate:this.fk_governorate,
              fk_directorate:this.fk_directorate
            }
          }
        )
        .then((response) => {
          this.loading=false
          this.visits = response.data.results;
          this.isexit=true
        })
        .catch((error) => {
          this.loading=false
        });
        
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  data() {
    return {
      loading:false,
      fk_governorate:null,
      fk_directorate:null,
      isexit: false,
      visits:[]
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      governorates:(state)=>state.school.governorates,
      directorates:(state)=>state.school.directorate,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
    }),
    ...mapGetters({
      filterDirectorates: "school/getDirectorateByGovernorates",
      filterGovernorates: "school/getGovernoratesByCountry",
    }),
  },
  components: {
    ReportHeader,
  },
};
</script>

export default {
  "committees": {
    "student_number_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the maximum number of students for this class"])},
    "add_students_to_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add students to committee"])},
    "add_committee_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit committee's students"])}
  },
  "elevation": {
    "student_elevate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student elevate"])},
    "current_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current grade"])},
    "next_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next grade"])},
    "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimate"])},
    "registeration_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registeration status"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student status"])},
    "study_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study status"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total grades"])},
    "elevate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elevate"])}
  },
  "user": {
    "full-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "profile-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data related to the user's account with the role that has to deal with the system"])},
    "logout-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are sure you want to logout from the system ?"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "view-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Users"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active account with the given credintials "])},
    "adduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
    "edituser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])},
    "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name "])},
    "mname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Middle Name"])},
    "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name  "])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "superuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superuser"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Type"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ppassword"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password "])},
    "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password "])},
    "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Confirm Password "])},
    "date_joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of joined"])},
    "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "is_deactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password "])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "not-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password doesn't match "])},
    "username-unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This username is already exist"])},
    "group-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access grant to"])},
    "confirm-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password will be reset to the default password ?"])}
  },
  "group": {
    "add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manage groups"])}
  },
  "mobile": {
    "version-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version Name"])}
  },
  "permission": {
    "assigntask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Assign"])},
    "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
    "tasktype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks Types"])},
    "summeropenemployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Summer School Employees"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groups"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activity"])},
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branches"])},
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buildings"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classes"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "mobileversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile application's versions"])},
    "classdivisionhall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classes plan"])},
    "classdocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["class documents"])},
    "classstudentyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["students elevate"])},
    "classsubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam committees"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countries"])},
    "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["directorates"])},
    "discountcategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount"])},
    "distributionofseatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seat numbers settings"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisions"])},
    "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document"])},
    "examschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Schedule"])},
    "examsperiod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam Periods"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee plan"])},
    "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total fee"])},
    "feetype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee types"])},
    "paymentsreciept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catch receipt"])},
    "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governorates"])},
    "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halls"])},
    "homework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["homework"])},
    "installmentspayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installments"])},
    "lectureattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lesson preparation"])},
    "marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly grades"])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parents"])},
    "parentmessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent messages"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study preiods"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school data"])},
    "schoolweeklyschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weekly schedule"])},
    "seatnumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seat numbers"])},
    "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semester"])},
    "settingsstudentcommittees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["committees plan"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student"])},
    "studentactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activity enrollment"])},
    "studentattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student attendance"])},
    "studentdocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student document"])},
    "studentfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assing fees"])},
    "studenthomework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check homeworks"])},
    "studentsubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semester grades"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subjects"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teachers"])},
    "teachersubjectclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teachers plan"])},
    "yearofstudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study years"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])},
    "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drivers"])},
    "studentbus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bus subscription"])},
    "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buses"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study months"])},
    "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimations"])},
    "is_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "is_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission name"])},
    "activitydifficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficulties and solutions"])},
    "activityoperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute activity"])},
    "activitysummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add activities"])},
    "activitytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity types"])},
    "parentsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
    "studentsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "schoolsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer center Data"])},
    "contactsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "divisionsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisions"])},
    "daysummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "schedulesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study schedule"])},
    "subjectsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
    "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker's skills"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers"])},
    "employee-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers"])},
    "employeelevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver curriculum"])},
    "levelstudentYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study weeks"])},
    "marksummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marks"])},
    "leveldivision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign divisions to levels"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity plans"])},
    "talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talents"])},
    "studenttalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student's talents"])},
    "initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiatives"])},
    "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors"])},
    "commitee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees"])},
    "commiteemember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee members"])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations"])},
    "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive curriculums"])},
    "lecturesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture"])},
    "studentattendancesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students attendance"])},
    "employeeattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers attendance"])},
    "schoolweeklyschedulesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly schedule"])},
    "schoolweeklyactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity weekly schedule"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendars"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacations"])},
    "teachersubjectsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign teachers"])},
    "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervision Entity"])},
    "managmenttask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management Tasks"])},
    "lecturelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Logs"])},
    "supplies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplies"])},
    "levelstudentyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Students to Levels"])},
    "levelstatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["level statistics"])},
    "activitystatistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" activity statistic"])},
    "summerschoolstatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["summer school statistics"])},
    "generalizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generalizations"])},
    "applicationsfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Files"])}
  },
  "financial": {
    "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome you in Financial system"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "account_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting Guide"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name"])},
    "account_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Account Name"])},
    "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
    "account_type_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Type"])},
    "parent_serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Account"])},
    "account_nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Nature"])},
    "fk_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "fk_analyst_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyst Account"])},
    "number_of_digits_for_children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Number of branches"])},
    "account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Description"])},
    "account_is_sub_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not use sub-account as main Account"])},
    "financial_period_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Period"])},
    "financial_periods_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Periods"])},
    "financial_year_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting Date"])},
    "financial_year_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ending Date"])},
    "periodicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periods Types"])},
    "annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annually"])},
    "semi-annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semi-Annually"])},
    "every_4_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every 4 Months"])},
    "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarterly"])},
    "every_2_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every 2 Months"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "period_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period Arabic Name"])},
    "period_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period English Name"])},
    "add_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Period"])},
    "add_financial_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Financial Period"])},
    "edit_financial_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Financial Period"])},
    "period_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Period is Added Successfully"])},
    "financial_period_not_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Period was not added, Can not overlapping periods !"])},
    "not_deleted_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is used, can not be deleted!"])},
    "not_updated_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is used, can not be updated!"])},
    "account_not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must choose account to delete"])},
    "account_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is Added Successfully"])},
    "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is Deleted Successfully"])},
    "account_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is updated Successfully"])},
    "box_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setting up accounting funds"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic name"])},
    "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English name"])},
    "box_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funds type"])},
    "catch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catch"])},
    "spend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spend"])},
    "main_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["main account"])},
    "box_data_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funds details "])},
    "add_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add funds"])},
    "box_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funds details"])},
    "bank_data_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["banks details "])},
    "add_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add bank "])},
    "bank_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bank type"])},
    "intermedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intermedate"])},
    "real": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["real"])},
    "currencies_data_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencies details"])},
    "add_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add currency"])},
    "currency_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency symbol"])},
    "currency_exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency exchange name"])},
    "currency_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency type"])},
    "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["local"])},
    "foreign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foreign"])},
    "curr_exchange_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exchange price"])},
    "curr_highest_exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["highest exchange price"])},
    "curr_lowest_exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lowest exchange price"])},
    "must_be_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be lower than highest exchange price"])},
    "must_be_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be higher than lowest exchange price"])},
    "local_already_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is already a local currency"])},
    "messing_exchange_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter exchange data"])},
    "currency_is_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency is used in the system"])},
    "currency_is_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency is deleted"])},
    "currency_is_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency is added"])},
    "exchange_data_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exchange data is updated"])},
    "student_data_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["students accounts details"])},
    "add_student_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add student account"])},
    "daily_operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["journal entry"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receipts"])},
    "target_operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["target operation"])},
    "doctype_data_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documents type details"])},
    "add_doctype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add document type"])},
    "doctype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document type"])},
    "doctype_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document type details"])},
    "bank_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bank details"])},
    "student_account_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student account details"])},
    "added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["added successfully"])},
    "updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updated successfully"])},
    "deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleted successfully"])},
    "not_updated_doctype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document type is used and can't be updated"])},
    "not_deleted_doctype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document type is used and can't be deleted"])},
    "receipt_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "catch_receipt_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catch Receipt"])},
    "payment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment type"])},
    "box_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funds account"])},
    "bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bank account"])},
    "receiver_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receiver account"])},
    "analyst_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analyst account"])},
    "payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment amount"])},
    "written_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["written payment"])},
    "high_exchange_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warning, you have exceeded the highest exchange limit"])},
    "low_exchange_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warning, you have exceeded the lowest exchange limit"])},
    "date_limit_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collect date must be greater than today's date"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cash"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check"])},
    "check_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check number"])},
    "collect_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collect date"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time"])},
    "journal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["journal entry"])},
    "journal_total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["journal total amount"])},
    "journal_reference_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["journal reference number"])},
    "add_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add record"])},
    "remove_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove record"])},
    "statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statement"])},
    "debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["debit"])},
    "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credit"])},
    "f_debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foreign debit"])},
    "f_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foreign credit"])},
    "debit_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["debit total"])},
    "credit_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credit total"])},
    "journal_data_messing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["journal data is messing"])},
    "invalid_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalid currency"])},
    "foreign_debit_local_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can't use foreign debit with local currency"])},
    "exchange_price_messing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exchange price is messing"])},
    "foreign_debit_not_like_debit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foreign debit is not like debit when exchange"])},
    "foreign_credit_local_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can't use foreign credit with local currency"])},
    "foreign_credit_not_like_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foreign credit is not like credit when exchange"])},
    "debit_and_credit_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["debit and credit can't be empty"])},
    "foreign_debit_and_credit_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foreign debit and credit can't be empty"])},
    "invalid_analyst_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalid analyst account"])},
    "analyst_account_messing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analyst account is messing"])},
    "debit_not_like_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total debit is not equal to total credit"])}
  },
  "report": {
    "visits-carried-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits Carried Out per Directorate"])},
    "user-input-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of schools and staffs inputs by users"])},
    "other-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Report Statistic"])},
    "employee_avaliable_by_responsibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker Avaliable By Responsibility"])},
    "employee-attendance-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Employee Attendance "])},
    "employee-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Attendance "])},
    "study-clander": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   Study Clander    "])},
    "employee-by-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker By Role"])},
    "material-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material Statistics "])},
    "droupout_resons_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droupout Reasons Report"])},
    "approch-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Approch  Statistic"])},
    "students-statistic-by-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Statistic By Level  "])},
    "students-statistic-all-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Statistic All Level"])},
    "attendance_day_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Attendance Report"])},
    "attendance_month_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance Report for a Month"])},
    "student-name-cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate"])},
    "parent-cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent"])},
    "student-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name:"])},
    "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "visit_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Count"])},
    "count-of-actual-lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lectures"])},
    "count-of-attendence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["present"])},
    "count-of-absentce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["absent"])},
    "count-of-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])},
    "count-of-not-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no permission"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "total-degrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Total"])},
    "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Grade"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average:"])},
    "writing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Written"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "collector-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Collector"])},
    "collector-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Collector"])},
    "final-exam-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Half Year"])},
    "final-exam-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Year"])},
    "final-collector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "final-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Final Total"])},
    "subject-estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Written"])},
    "oral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oral"])},
    "assigments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigments"])},
    "attendance_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendnace"])},
    "optional-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Optional Subjects For The Semester"])},
    "correct_by_secretnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Correct By Secret Number"])},
    "student_with_seatnumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Student With Seat Numbers"])},
    "commission-distr-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Committee Distribution Plan"])},
    "committee_with_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Report Committee With Student"])},
    "committee_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The Report Committee Members "])},
    "statement_grades_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score sheet of the month "])},
    "detailed_statement_of_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed Statement Of Fees"])},
    "grade-semester-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Student Grades In"])},
    "student_with_secretnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Student With Secret Number"])},
    "schedule_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule Week"])},
    "teacher_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule Teacher"])},
    "student_with_secretnumber_and_seatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Student With Secret Number And Seat Number"])},
    "student_without_secretnumber_and_seatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Student Without Secret Number And Seat Number"])},
    "student_by_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Student Mark "])},
    "student_mark_by_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Student "])},
    "activity_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Activity Schedule"])},
    "talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Talent by Level"])},
    "initiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Initiatives"])},
    "initiatives_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Initiatives Subscribe"])},
    "all_activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics for activities "])},
    "statistic_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community statistics and supporting agencies"])},
    "positives_negatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistics Positives and Negatives "])},
    "all_activities_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities in Summer"])},
    "all_activities_by_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Students who Subscribe in Activitie"])},
    "all_activities_by_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report All Activities by "])},
    "and_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And Activities "])},
    "staff-meeting-minutes-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Staff Meeting Minutes"])},
    "staff-meeting-minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff Meeting Minutes"])},
    "count_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count Activities "])},
    "committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report exams committees"])},
    "usernames_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report for usernames for parents"])},
    "month_deficiencies_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Deficiencies grade for"])},
    "month_grade_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly exam result"])},
    "student_has_spicific_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["students has an estimate"])},
    "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Sheet of the subject"])},
    "students": {
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Reports"])},
      "by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by year - class  "])},
      "by-governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" by Governorate"])},
      "by-nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by Nationality"])},
      "by-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by Parent"])},
      "by-age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" by Age"])},
      "by-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by Username "])},
      "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam"])},
      "oral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oral"])},
      "assigments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigments"])},
      "attendance_mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendnace"])}
    },
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System users report"])},
    "report_header": {
      "Show_Halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Halls"])},
      "Monthly_Marks_statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Subject Monthly"])},
      "Students_by_Year_Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students by Class"])},
      "Students_by_Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students by Governorate"])},
      "Students_by_Nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students by Nationality"])},
      "Students_by_Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students by Parent"])},
      "Students_Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Age"])},
      "Students_Users_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students User's Name"])},
      "Students_Images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Images"])},
      "New_Enrolled_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Enrolled Students"])},
      "Walk_out_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Walk out Students"])},
      "Students_Parents_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Parents Information"])},
      "Personal_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
      "Usernames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usernames"])},
      "Parents_Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents' Contacts"])},
      "Students_without_Monthly_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students without Monthly Marks"])},
      "Monthly_Deficiencies_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Deficiencies Marks"])},
      "Monthly_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Marks"])},
      "Specific_Month_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Month Marks"])},
      "Month_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month Certificate"])},
      "Month_Certificate_En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month Certificate English"])},
      "First_Class_Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Class Students"])},
      "Students_Estimation_by_Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Estimation by Month"])},
      "Subjects_not_Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects not Assign"])},
      "Marks_not_Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marks not Assign"])},
      "Specific_Years_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Years' Certificate"])},
      "Specific_Years_English_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Years' English Certificate"])},
      "Years_Firsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years' First's"])},
      "Year_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year Marks' Transcript"])},
      "Subjects_Review_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects Review Transcript"])},
      "Semester_Optional_Subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Optional Subjects"])},
      "Specific_Semester_Certificate_En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Semester English Certificate"])},
      "Semester_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Marks Transcript"])},
      "Specific_Semester_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Semester Certificate"])},
      "Final_Exams_Marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Exams' Marks"])},
      "Total_Marks_Transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Marks Transcript"])},
      "Subjects_Firsts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects' First's"])},
      "Students_Subscribed_Buses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Subscribed to Buses"])},
      "Buses_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buses Information"])},
      "Rest_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining amount"])},
      "Student_Account_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Account Statement"])},
      "Student_Installments_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Installments Statement"])},
      "Students_without_Study_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students without Study Fees"])},
      "Students_without_Buses_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students without Buses' Fees"])},
      "Students_Subscribed_Buses_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Subscribed to Buses Statement"])},
      "Student_Statement_Fee_Clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Statement by Fee Clause"])},
      "Student_Statement_Clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Statement by Clause"])},
      "Detailed_Statement_study_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed Statement by study Fees"])},
      "Statement_Paid_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statement by Paid"])},
      "Teachers_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers' Information"])},
      "Subscribers_Activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribers in Activities"])},
      "Student_Statement_Seat_Secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat and Secret Numbers"])},
      "Student_Committees_by_Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees by Class"])},
      "Student_Without_Seat_secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Without Seat-Secret Number"])},
      "Committees_Distribution_Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees Distribution Plan"])},
      "Secret_No_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret Numbers"])},
      "Study_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Schedule"])},
      "Exams_Schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams Schedule"])},
      "Specific_Group_Members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Group Members"])},
      "Specific_Group_Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Group Permissions"])},
      "System_Users_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Users"])},
      "Student_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Absence Statement"])},
      "Parent_by_Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent by Student"])},
      "Month_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month Absence Statement"])},
      "Daily_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Attendance"])},
      "Weekly_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence Statement for a Period"])},
      "Buses_Info_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buses Information Statement"])},
      "Study_Schedule_by_Teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Schedule by Teacher"])},
      "Student_Seat_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Seat Numbers"])},
      "Correction_by_secret_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction by secret Number"])},
      "Committees_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees Statement"])},
      "Exams_Review_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams Review Statement"])},
      "Rest_by_Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rest Fees by Parent"])}
    },
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Remaining Report Based on the Guardian "])},
    "parent_remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The guardian "])},
    "new_enrole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report New Enrolled Student "])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Partent With Student"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Subject Review"])},
    "student-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of Students by Class"])},
    "student-ages-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of Students Ages by Year and Class"])},
    "student-governorates-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of Students by Governorate  "])},
    "StudentWalkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of Students Walkout"])},
    "student-nationality-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of Students by Nationality"])},
    "student-images-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of Students Images by class"])},
    "student-accounts-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of Students Accounts by Class"])},
    "student-all-data-by-class-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report of students data by year and Class"])},
    "deatils_fees_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deatils fees report"])},
    "fees_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees by Clause  Report"])},
    "student_fees_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student fees report"])},
    "bus_info_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus Info Report"])},
    "teacherreportbyteacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  teacher classes byteacher report    "])},
    "mark_by_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark By Year Report"])},
    "collectormarkreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collector Mark Report"])},
    "finalmarkreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Mark Report"])},
    "highest_mark_by_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Report On The Performers According To The Year"])},
    "attendens_by_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attendens by month"])},
    "teacher_info_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Info Report"])},
    "first_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Firsred For Month "])},
    "first_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Firsred For Subject "])},
    "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report New Students In "])},
    "without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Students without Fees "])},
    "without_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Students in Bus without Fees "])},
    "student_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Students Fees Bus "])},
    "student_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Study Fees report "])},
    "students_in_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students participating in bus "])},
    "student_fees_Installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Students Fees Installment"])},
    "student_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Students Activity"])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report All Partents"])},
    "Rest_by_Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rest Fees by Parent"])},
    "Monthly_Absence_Statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Absence Statement"])},
    "Monthly_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Reports"])},
    "Final_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Reports"])},
    "Semester_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Reports"])},
    "Exams_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams Reports"])},
    "Schedule_Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedules Reports"])},
    "clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clause"])},
    "study-start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study start time"])},
    "study-end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["study end time"])},
    "summer-center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Reports"])},
    "general-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Management Reports"])},
    "financial-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Management Reports"])},
    "governorate-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geovernorate Reports"])},
    "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorate Reports "])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Reports "])}
  },
  "exam": {
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Result "])},
    "seat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Number"])},
    "secret-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret Number"])},
    "seat-numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Seat Numbers"])},
    "secret-numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Seat Numbers"])},
    "dist-seat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute Seat Numbers"])},
    "dist-secret-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute Secret Numbers"])}
  },
  "branch": {
    "nam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch name"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select branch"])}
  },
  "nationality": {
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nationality"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nationality name"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select nationality"])}
  },
  "amount": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" enter the amount"])}
  },
  "backup": {
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BackUp"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create BackUp"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore BackUp"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings BackUp"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select format"])},
    "file-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file name"])},
    "ask-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Do you want to create a backup ? "])}
  },
  "activity": {
    "gendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation Category"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start at"])},
    "statrt_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the time whene start activity"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end at"])},
    "end_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the time whene activity finsh"])},
    "employee_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name of the worker "])},
    "employee_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter name of the worker"])},
    "location_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter name of location"])},
    "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "evaluation_act": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation Activity"])},
    "evaluation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the evaluation"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants Count"])},
    "difficultie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficultie"])},
    "difficulties_and_solutions_assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Difficulties And Solutions Table  "])},
    "show_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show activity"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiy Goal"])},
    "school-recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations for the school"])},
    "media-recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations for the media"])},
    "participating-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participating groups"])},
    "entity-to-coordinate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entities to coordinate with"])},
    "execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution Date"])},
    "iteration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity iteration"])},
    "activity_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Name "])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
    "difficulties_and_solutions_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Difficulties and Solutions Show   "])},
    "activity_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Activity Info "])},
    "activtity_assigin_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activtity AssignDate"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Fee"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Name "])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Activity "])},
    "add_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Activity"])},
    "difficulties_and_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficulties And Solutions"])},
    "difficultie_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Difficultie  "])},
    "solutions_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Solutions"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Select Activity"])},
    "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Responsible"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Type"])},
    "desc-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Desc Type"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Location"])},
    "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport Activity"])},
    "cultural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cultural Activity"])},
    "entertaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertaining Activity"])},
    "placeholder": {
      "student-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" participating in the activity "])}
    }
  },
  "employee": {
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees Data"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees count"])},
    "add-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add employee"])},
    "shared-in-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has he joined in summer center before"])},
    "no-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't added employees ."])}
  },
  "circular": {
    "circular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circular"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circular Title"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Circular Date "])},
    "show-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Show to"])},
    "circulars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circulars"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no circulars "])}
  },
  "globals": {
    "coaches-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaches Data"])},
    "task-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Data"])},
    "trainee-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainee Data"])},
    "cancel-from-task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel from the Task"])},
    "add-to-task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to the Task"])},
    "captcha-no-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input characters don't match the image"])},
    "captcha-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the letters on the image"])},
    "confirm-proccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Proccess"])},
    "create-academic-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year Data"])},
    "create-database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a database"])},
    "copy-old-database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the previous database"])},
    "init-database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialize the database"])},
    "read-only-database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to a read-only database."])},
    "schools-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schools Count"])},
    "employees-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees"])},
    "schools-statstics-per-gov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schools Statistics per Governorate"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "select-excel-sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the appropriate worksheet."])},
    "there-are-many-sheets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple worksheets have been discovered in the file you selected. To proceed, please select one of the following worksheets:"])},
    "for-governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for Governorate "])},
    "for-directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for Directorate"])},
    "for-summer-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for Summer School "])},
    "for-all-governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for all Governorates"])},
    "for-all-directorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for all Directorates"])},
    "for-all-summer-schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for all Summer Schools"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
    "review-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Data"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "is-reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is reported"])},
    "errors-of-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors on this record"])},
    "certificate-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate Type"])},
    "gift-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift Type "])},
    "license-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License type"])},
    "sport-accessory-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport Accessory Type"])},
    "sport-accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport Accessories"])},
    "license-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License - Record"])},
    "gift-prize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift - Prize"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
    "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
    "females": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Females"])},
    "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["record"])},
    "sub-district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-District"])},
    "village": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Village"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percent"])},
    "grand-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grand Total"])},
    "content-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Type"])},
    "upload-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload data"])},
    "only-uploaded-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only uploaded data"])},
    "app-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Link"])},
    "file-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size should be less than "])},
    "extension-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file extension must be within "])},
    "upload-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Date "])},
    "app-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Features"])},
    "downloadable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloadable"])},
    "application-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application File"])},
    "data-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
    "updated-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated by"])},
    "updated-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated date"])},
    "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created date"])},
    "delete-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Confirmation"])},
    "school-levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School levels"])},
    "page-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opps! Page Not Found"])},
    "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
    "page-not-found-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry, but the page you were looking for doesn't exist."])},
    "new-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Students"])},
    "studied-last-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studied last year"])},
    "talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talents"])},
    "talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent"])},
    "visiting-entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visiting Entity"])},
    "visiting-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visiting Count"])},
    "executed-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed Count"])},
    "executed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed"])},
    "task-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Name"])},
    "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
    "identity-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity ID"])},
    "specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
    "qualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
    "employee-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Name"])},
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees"])},
    "login-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to your account"])},
    "login-account-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to your account in the summer centers system"])},
    "remeber-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remeber me ?"])},
    "num-of-visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of visits"])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "async-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Async the statistics"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "indoor-accomodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor Accomodation"])},
    "outdoor-accomodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor Accomodation"])},
    "days-absent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days absent"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
    "present-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Present"])},
    "present-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees Present"])},
    "year-month-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year-Month-Day"])},
    "male_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Males School"])},
    "fmale_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Females School"])},
    "total_schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Schools"])},
    "total_workers_male_female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of male/female workers"])},
    "totla_male_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Male Student"])},
    "total_female_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total female students"])},
    "total_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total students"])},
    "school-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "school-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School/University Name"])},
    "activaty_group_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activaty group"])},
    "group_activaty_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group activaty"])},
    "committee_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee count"])},
    "recive_approch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recive approch count"])},
    "choices-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choice today"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skills"])},
    "not_all_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Do Not Save All Records"])},
    "#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "system-summer-center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center"])},
    "system-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School System "])},
    "males": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["males"])},
    "fmales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fmales"])},
    "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mixed"])},
    "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning"])},
    "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afternoon"])},
    "morning$afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning & Afternoon"])},
    "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive"])},
    "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative"])},
    "adjective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adjective"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["done"])},
    "assigin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assigin"])},
    "in_the_name_of_allah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In The Name Of Allah"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
    "summer-centre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Centre"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
    "log-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "default-choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Choices"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
    "select-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select file path"])},
    "subjcect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "print-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["print a file"])},
    "rep-of-yemen-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمهورية اليمنية"])},
    "rep-of-yemen-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic of Yemen"])},
    "ministry-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وزارة التربية والتعليم"])},
    "ministry-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry of Education"])},
    "no-periods-pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Evening Periods "])},
    "no-periods-am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Morning Periods "])},
    "mark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark"])},
    "seatnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Number"])},
    "secretnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret Number"])},
    "parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Name"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "student_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student status"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["check"])},
    "will-be-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the following data will be deleted : "])},
    "dist-dynamically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distribute dynamically"])},
    "public-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["public number"])},
    "am-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ante meridiem"])},
    "pm-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["post meridiem"])},
    "select-criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Filter Criteria"])},
    "filter-students-by-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter students by place of birth"])},
    "select-others-criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select other date"])},
    "all-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Records "])},
    "shown-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Shown Records"])},
    "not-found-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found records "])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" loading ..."])},
    "user_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this email. already used"])},
    "student-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student not found with this name "])},
    "student-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" search with student name "])},
    "employee-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search with employee name "])},
    "daily-follow-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Follow Up"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "current_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current file"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone number"])},
    "home_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home number"])},
    "printy-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["print file "])},
    "installment_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installmentable"])},
    "subscribe_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe type"])},
    "reducible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reducible"])},
    "portability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portability"])},
    "percentage_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage must not exceed 100"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage"])},
    "start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
    "end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "student-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Count"])},
    "num-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of months"])},
    "elementary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ُElementary"])},
    "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary"])},
    "preparatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparatory"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Optionally"])},
    "obligatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatory"])},
    "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
    "committee_excest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this committee and class are already used"])},
    "errpr_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you can't delete the current month"])},
    "error_in_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, review inserted Data"])},
    "group_for_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't change the access grant for a group that assigned to a user account"])},
    "e_in_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, select governorate"])},
    "date_out_of_ring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date is out of the calendar ring"])},
    "future-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attendance can't be in the future"])},
    "error_student_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Can Not Delete Student Link With Records "])},
    "error_in_repeted_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Data Is Uniq Can Not Be Repeated"])},
    "parent-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this parent has students"])},
    "data_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data is Added Successfully"])},
    "data_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data is Saved Successfully"])},
    "data_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data is deleted Successfully"])},
    "deleted_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleting failed"])},
    "data_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data is updated Successfully"])},
    "updated_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updating failed "])},
    "messing_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter the empty fields"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the maximum number of characters"])},
    "min_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the minimum number of characters"])},
    "max_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the maximum number"])},
    "max_numbers_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have exceeded the maximum value"])},
    "min_numbers_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum value is"])},
    "min_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum number of digits is"])},
    "view_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view filters"])},
    "max_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the maximum date allowed is "])},
    "max_date_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the maximum date allowed is bellow "])},
    "min_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the minimum date allowed is "])},
    "min_date_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the minimum date allowed is above "])},
    "wrong_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data dose not fit for the chosen type"])},
    "required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Field"])},
    "hall_excist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this hall is already used"])},
    "division_excist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this division is already assigned"])},
    "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Birth Date"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone. Once deleted, the record will be permanently removed and cannot be recovered. This includes all associated data."])},
    "confirm_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure of editing this record ?"])},
    "complete_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted Successfully !"])},
    "sequence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sequence"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
    "email_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email is not valid"])},
    "lowest_number_of_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum number of characters is "])},
    "must_be_capatal_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be capatal letters"])},
    "must_be_small_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be small letters"])},
    "must_contan_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must contan symbol"])},
    "biggest_number_of_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum number of characters is "])},
    "must_be_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be numbers only !"])},
    "must_be_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be Arabic letters only !"])},
    "must_be_telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be a telegram account"])},
    "must_be_full_letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be full letter"])},
    "must_be_letters_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be English letters only !"])},
    "must_be_letters_and_numbers_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be English letters and numbers only !"])},
    "must_be_between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be between "])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comments"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show"])},
    "view_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view as report"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" password"])},
    "placeholder_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email  "])},
    "placeholder_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password "])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])},
    "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate"])},
    "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorate"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic Name"])},
    "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" English Name"])},
    "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopped"])},
    "continus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continus"])},
    "drop-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop Out"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natioanality"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Active"])},
    "unactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unActive "])},
    "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Children"])},
    "service-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Servie Status"])},
    "basic-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Teacher"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of Birth"])},
    "num-of-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Numbers of Jobs"])},
    "date-of-employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Date of Employment "])},
    "job-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Job Title "])},
    "work-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Work Period"])},
    "marital-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marital Status "])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Note"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Single"])},
    "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Married "])},
    "pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM"])},
    "am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AM"])},
    "employment-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment Data "])},
    "personal-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Personal Data "])},
    "contact-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Contact Data "])},
    "user-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Data"])},
    "globals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globals"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome you in Globals system"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthdate"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warning"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "student_count_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have exceeded the number of student allowed in this division"])},
    "documents_per_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for this class you need these documents"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error !"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success ! "])},
    "dlete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "confirm-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this record ?"])},
    "complete-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated Successfully !"])},
    "error-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Error updated !  "])},
    "error-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Error Added !  "])},
    "error-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Error Delete !  "])},
    "complete-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted Successfully !"])},
    "complete-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ِAdded Successfully ! "])},
    "required-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this field is required "])},
    "addmember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Member "])},
    "addauthor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Author "])},
    "per-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items per page "])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "box-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box Mail"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website "])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "addpublisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Publisher"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "search-here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Search Here  "])},
    "system-adminstration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" System Administration"])},
    "users-adminstration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users Administration"])},
    "system-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Initialize"])},
    "general-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Initialize"])},
    "school-cal-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Initialize"])},
    "school-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Calender"])},
    "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
    "halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halls"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division"])},
    "classes-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes Documents"])},
    "distribute-subjects-classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute Subject on the Classes"])},
    "grades-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grades Initialize"])},
    "estimates-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimates Initialize"])},
    "student-fee-initialize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Fee Initialize"])},
    "fee-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Categories"])},
    "discount-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Categories"])},
    "student-affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Affairs"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "addmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addmission"])},
    "registration-addmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Admission"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "follow-up-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Up Students"])},
    "fee-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Payment"])},
    "new-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Registration"])},
    "installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installments"])},
    "students-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Attendance"])},
    "homeworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HomeWork"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "student-documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Documents"])},
    "distribute-student-on-division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute Students on the Division"])},
    "control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control"])},
    "bus-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus-system"])},
    "score-recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Recording"])},
    "monthly-scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Scores"])},
    "enter-outcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Outcome"])},
    "semester-grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester Grades"])},
    "homework-grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HomeWork Grades"])},
    "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams"])},
    "seats-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seating Numbers "])},
    "examination-periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination Periods "])},
    "examination-table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examination Tables "])},
    "distribute-students-on-committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute Students"])},
    "sections-exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sections Exams Distribute"])},
    "class-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Schedule"])},
    "elevate-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevate Teatcher"])},
    "substitute-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substitute Teatcher"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons"])},
    "lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture"])},
    "lecture-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Title"])},
    "lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lectures"])},
    "financial-affairs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Affairs"])},
    "journal-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal Entry"])},
    "catch-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catch Reciept"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciept"])},
    "accounts-guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts Guide"])},
    "funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds"])},
    "student-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Students activities "])},
    "currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currencies"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banks"])},
    "students-accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Accounts"])},
    "faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty"])},
    "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teatchers"])},
    "teacher-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Teacher"])},
    "teacher-class-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this teacher has been assigned to this class with this subject"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "school-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Data"])},
    "study-years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Years"])},
    "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
    "study-lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Lecture"])},
    "documents-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents Types "])},
    "nationalities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalities"])},
    "governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorates"])},
    "directorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorates"])},
    "buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buildings"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not found"])},
    "view_halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view halls"])},
    "add_halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add halls"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periods"])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent"])},
    "parent-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent add"])},
    "parent-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent list"])},
    "committeeplan-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee's Plan Add"])},
    "committee-student-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Committee's students "])},
    "committeeplan-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee's Plan list"])},
    "teacher-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker is exist"])},
    "driver-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver is exist"])},
    "group_member_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of group members"])},
    "group_permissions_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of group permissions"])},
    "student_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student_id"])},
    "teacher-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees' Personal Inforamtion "])},
    "teacher-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker name"])},
    "teacher-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker phone"])},
    "teacher-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker status"])},
    "teacher-specail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker specail"])},
    "teacher-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker location"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "republic_of_yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Republic Of Yemen"])},
    "one-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One period"])},
    "two-periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two periods"])}
  },
  "student-attendance": {
    "permission-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Permission Data"])},
    "daily-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Daily attendace of students according to the lecture  "])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "is-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present ?"])},
    "is_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Present"])},
    "absent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absent ?"])},
    "is-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Permission ?"])},
    "permission-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Permission Applicant"])},
    "permission-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Mobile"])},
    "permission-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Permission Reason "])},
    "permission-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission Date"])},
    "permission-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Permission Type"])}
  },
  "directorate": {
    "code-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Number"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Directorate"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no directorate"])}
  },
  "governorate": {
    "code-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Number"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select governorate"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no governorate"])}
  },
  "lecture": {
    "lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Date"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Lesson"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Lesson"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Name"])},
    "preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Preparing"])},
    "prepare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Preparing"])},
    "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons Count"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Lessons"])},
    "adds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Lessons"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no lesson with this data ! "])},
    "studied-lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studied Lectures"])}
  },
  "year": {
    "for-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for Study Year"])},
    "current-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Year"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year"])},
    "name-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hijri Year"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Year"])},
    "select-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Hijri Year"])},
    "select-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Gregorian Year"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Academic Year "])},
    "name-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gregorian Year"])},
    "placeholder": {
      "year-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example 1444/45  "])},
      "year-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example 2023/24  "])}
    },
    "teacher-subject-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teachers' subjects paln for this year"])},
    "class-student-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student result for this year"])},
    "class-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["class activities for this year "])},
    "homework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student homeworks for this year "])},
    "student-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student fees for this year "])}
  },
  "student-activity": {
    "is-subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribed ?"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity the students participants in activity "])},
    "max-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participant students number is max than required number for this activity "])},
    "student-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Activities"])}
  },
  "class-marks": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Enter Semester Marks"])},
    "collector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collector"])},
    "final-exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Exam"])},
    "mark_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark Total"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["class"])},
    "for-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the class "])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Name"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Select Class"])},
    "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Arabic Class Name"])},
    "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Class Name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Type "])},
    "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Phase"])},
    "ministerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is this class ministerial ?"])},
    "dedicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedicated"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])}
  },
  "class": {
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["class"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["class Name"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add New Class "])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" select class"])},
    "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Arabic Class Name"])},
    "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Class Name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Type "])},
    "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Phase"])},
    "ministerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is this class ministerial ?"])},
    "dedicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedicated"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])}
  },
  "semester": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add New Semester "])},
    "semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester "])},
    "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arabic semester name"])},
    "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" english semester name  "])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" select semester  "])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" semester months "])}
  },
  "division": {
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["division"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Add New Division"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division Name"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division Symbol"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Division "])},
    "select-multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Divisions"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisions not found"])}
  },
  "skill": {
    "select-multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Skills"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Skill"])}
  },
  "school": {
    "student_ar_placehoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the student name"])},
    "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start_time"])},
    "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end_time"])},
    "is_continued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continued ?"])},
    "is_regestered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["regestered ?"])},
    "change_student_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change student status"])},
    "student-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" student name"])},
    "status_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Study"])},
    "edit_exam_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit exam schedule"])},
    "add_exam_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add exam schedule"])},
    "exam_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam day"])},
    "exam_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam date"])},
    "exam_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Schedule"])},
    "fee_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee type"])},
    "fee_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee types"])},
    "fee_types_bus_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't add more than one bus fee type"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee"])},
    "fees_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fees items"])},
    "add_assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add hall to the plan"])},
    "edit_assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change halls plan"])},
    "assign_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halls plan"])},
    "add_assign_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add teacher to the plan"])},
    "edit_assign_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change teachers plan"])},
    "assign_teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teach Plan"])},
    "discount_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount amount"])},
    "discount_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount type"])},
    "placeholder_discount_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter a discount category"])},
    "discount_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount category"])},
    "add_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add discount categories"])},
    "schedule_timing_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start time can't be bigger than end time"])},
    "schedule_timing_error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periods time should not be interfering"])},
    "schedule_timing_error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["periods time shouldnot pass one and helf in Morning Period"])},
    "schedule_timing_error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["periods time shouldnot be less eight in Morning Period"])},
    "schedule_end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period End Time"])},
    "schedule_start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period Start Time"])},
    "evening_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evening Period"])},
    "morning_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning Period"])},
    "schedule_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "placeholder_schedule_quotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter study Period name"])},
    "schedule_quotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "add_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Period"])},
    "placeholder_hall_seats_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the number of seats"])},
    "hall_seats_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seats number"])},
    "placeholder_hall_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the floor number"])},
    "hall_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["floor number"])},
    "placeholder_hall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the hall name"])},
    "hall_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hall name"])},
    "edit_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit hall"])},
    "add_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add hall"])},
    "placeholder_building_caftira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the number of caftiras"])},
    "building_caftira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of caftiras"])},
    "placeholder_building_ws_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the number of WS"])},
    "building_ws_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of WS"])},
    "placeholder_building_rooms_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the number of rooms"])},
    "building_rooms_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of rooms"])},
    "placeholder_building_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the building place"])},
    "building_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["building place"])},
    "placeholder_building_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the number of floors"])},
    "building_floor_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of floors"])},
    "placeholder_building_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter building name"])},
    "building_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["building name"])},
    "add_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add building"])},
    "main_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["main branch"])},
    "main_branch_must_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["it must be one main branch"])},
    "placeholder_branch_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the branch coordinates"])},
    "branch_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch coordinates"])},
    "placeholder_branch_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the branch place"])},
    "branch_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch place"])},
    "is_main_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["it is the main branch"])},
    "placeholder_branch_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the branch english name"])},
    "branch_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch english name"])},
    "placeholder_branch_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the branch arabic name"])},
    "branch_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch arabic name"])},
    "branch_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch image"])},
    "branch_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch data"])},
    "add_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add branch"])},
    "placeholder_branch_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the branch headmaster name"])},
    "branch_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch headmaster"])},
    "main_branch_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["main branch data"])},
    "ministry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ministry data"])},
    "school_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school number in the ministry"])},
    "countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Countries"])},
    "add_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Country"])},
    "country_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Name"])},
    "country_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country English Name"])},
    "nationality_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality Name"])},
    "nationality_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality English Name"])},
    "placeholder_country_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Country Arabic Name"])},
    "placeholder_country_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Country English Name"])},
    "placeholder_nationality_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Nationality Name"])},
    "placeholder_nationality_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Nationality English Name"])},
    "placeholder_country_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Country Description"])},
    "governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Governorates"])},
    "add_governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Governorate"])},
    "governorate_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate Name"])},
    "governorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate English Name"])},
    "placeholder_governorate_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Governorate Arabic Name"])},
    "placeholder_governorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Governorate English Name"])},
    "placeholder_governorate_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Governorate Description"])},
    "add_directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Directorate"])},
    "directorate_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorate Name"])},
    "directorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorate English Name"])},
    "placeholder_directorate_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Directorate Arabic Name"])},
    "placeholder_directorate_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Directorate English Name"])},
    "placeholder_directorate_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Directorate Description"])},
    "school_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school data"])},
    "school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school name"])},
    "placeholder_school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter school name"])},
    "school_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school english name"])},
    "placeholder_school_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter school english name"])},
    "establish_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establish Date"])},
    "ministry_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministry Name"])},
    "placeholder_ministry_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter ministry name"])},
    "education_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["education office"])},
    "placeholder_education_office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter education office"])},
    "license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Number"])},
    "placeholder_license_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter License Number"])},
    "license_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["license image"])},
    "school_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school type"])},
    "typical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typical"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "civil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["civil"])},
    "governmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governmental"])},
    "headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["headmaster"])},
    "placeholder_headmaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the headmaster name"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school location"])},
    "placeholder_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the school location"])},
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school coordinates"])},
    "placeholder_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter school coordinates"])},
    "coordinates_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location image"])},
    "students_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Type"])},
    "school_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school logo"])},
    "boys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["boys"])},
    "girls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["girls"])},
    "main_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["main data"])},
    "location_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location data"])},
    "ministry_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ministry data"])},
    "contact_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact data"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "contact_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Type"])},
    "buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buildings"])},
    "job-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job ID "])},
    "parent_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent phone number"])},
    "student_academic_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["academic info"])},
    "academic_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["academic id"])},
    "card_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card id"])},
    "add_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Parent"])},
    "edit_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Parent"])},
    "show_registered_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registered students"])},
    "add_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Student"])},
    "student_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
    "student_ar_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student arabic name"])},
    "student_en_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student English Name"])},
    "student_nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student nationality"])},
    "parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent name"])},
    "student_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "parent_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent relationship"])},
    "student_birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student birthdate"])},
    "student_birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student birth place"])},
    "student_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "student_registration_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration form"])},
    "student_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Image"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "students_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students information"])},
    "student_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student information"])},
    "parent_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent information"])},
    "student_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student documents"])},
    "student_financial_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student financial information"])},
    "student_ar_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student Arabic name"])},
    "student_en_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student English name"])},
    "student_birth_place_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ُEnter student birth place"])},
    "parent_relation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student parent relationship"])},
    "student_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student address"])},
    "student_registration_form_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter registration form image"])},
    "student_image_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student image"])},
    "notes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add some notes"])},
    "select_parent_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select parent"])},
    "student_parent_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent name"])},
    "student_parent_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student parent name"])},
    "parent_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent id"])},
    "parent_id_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent id no"])},
    "parentList_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ParentList information"])},
    "parent_id_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter id no"])},
    "parent_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent job"])},
    "parent_job_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter parent job"])},
    "parent_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "parent_phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter phone number"])},
    "parent_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "parent_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter address"])},
    "parent_landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home number"])},
    "parent_landline_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter landline number"])},
    "parent_id_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Identity"])},
    "add_examPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add exam Period"])},
    "examPeriod_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Period"])},
    "examPeriod_start_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam start at"])},
    "examPeriod_end_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam end at"])},
    "examPeriod_period_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Period of exam"])},
    "examPeriod_start_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time of exam"])},
    "examPeriod_end_at_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time of exam"])},
    "title_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Committees"])},
    "add_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add committee"])},
    "committee_hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee hall"])},
    "committee_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee name"])},
    "edit_committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit committee"])},
    "committee_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter committee name"])},
    "committeesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees List"])},
    "title_committeePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees plan"])},
    "add_committeePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add committee plan"])},
    "edit_committeePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Edit committee plan"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class room"])},
    "committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee"])},
    "student_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Student number"])},
    "student_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student number"])},
    "committeesPlanList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees  plan information"])}
  },
  "homework": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Homework"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" select homework"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Homeworks "])},
    "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery time "])},
    "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked ?"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["homework name"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no homework with this name ! "])},
    "correction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correcting Homeworks "])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing Homeworks  "])}
  },
  "driver": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drivers"])},
    "add_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add driver"])},
    "edit_driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit_driver"])},
    "driversList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver information"])},
    "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver name"])},
    "driver_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver name"])},
    "driver_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Driver phone"])},
    "driver_phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver phone"])},
    "driver_home_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home number"])},
    "driver_home_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter home number"])},
    "card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card type"])},
    "card_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
    "card_no_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter card number"])},
    "card_issuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Card issuance"])},
    "driver_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Age"])},
    "driver_age_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver age"])},
    "driver_martial_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martial status"])},
    "driver_martial_status_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter martial age"])},
    "driver_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "driver_email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
    "card_issuance_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter card issuance place"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card expiration"])},
    "card_issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card issuer"])}
  },
  "bus": {
    "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buses"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select bus"])},
    "add_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add bus"])},
    "edit_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit bus"])},
    "bus_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus information"])},
    "teacher_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
    "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
    "driver_road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Road lin"])},
    "driver_road_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver road"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus type"])},
    "type_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter bus type"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus number"])},
    "number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter bus number"])},
    "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat number"])},
    "seat_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter seat number"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "color_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter color"])},
    "car_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car card"])},
    "car_card_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter car card number"])}
  },
  "student-bus": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students with Buses"])},
    "add_student_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add student to bus"])},
    "chose_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chose student"])},
    "chose_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chose bus"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
    "driver_road": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Road lin"])},
    "driver_road_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter driver road"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus type"])},
    "type_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter bus type"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus number"])},
    "number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter bus number"])},
    "seat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat number"])},
    "seat_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter seat number"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "color_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter color"])},
    "car_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car card"])},
    "car_card_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter car card number"])},
    "add_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add bus"])},
    "edit_bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit bus"])},
    "bus_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus information"])},
    "bus_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus fee"])},
    "teacher_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
    "driver_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])}
  },
  "month": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Months Guide"])},
    "add_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add month"])},
    "edit_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit month"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month name"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter month name"])},
    "cureent_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current month"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" select month "])},
    "cureent_month_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there must be one current month"])}
  },
  "day": {
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "gaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days gaid"])},
    "day_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Off"])},
    "add_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add day"])},
    "day_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the day name"])},
    "day_overload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days count can't exceed seven days"])}
  },
  "estimate": {
    "estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate "])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimations"])},
    "add_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add estimate"])},
    "edit_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit estimate"])},
    "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate AR name"])},
    "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate EN name"])},
    "degree_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAx score"])},
    "degree_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min score"])},
    "name_ar_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter estimate"])},
    "name_en_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter estimate "])},
    "degree_max_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter max score"])},
    "degree_min_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter min score"])},
    "degree_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum degree can't be more than minimum degree"])},
    "degree_error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum degree can't be more than minimum degree"])}
  },
  "document": {
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Document "])},
    "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Initialize"])},
    "add-to-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Class Documents  "])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Name"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter document Name"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no document with this name ! "])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The document is optional for the class ? "])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Optional"])}
  },
  "teacher": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Teacher"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher name"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Teachers Information"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Teacher"])}
  },
  "errors": {
    "greater-than-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be greater than zero"])},
    "maximum-attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum login attempts exceeded, Please try again later."])},
    "close-open-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An open academic year cannot be closed until another academic year is opened."])},
    "delete-open-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An open academic year cannot be deleted until another academic year is opened."])},
    "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry !"])},
    "error-connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are look like that you are not conntected with the database"])},
    "must-be-full-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be full name "])},
    "expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the expiration date must not less than the issuer date "])},
    "issuer_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the issuer date must not exceed the expiration date "])},
    "period_is_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this Period is used before"])},
    "must-be-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this message must be accepted"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this field is required"])},
    "max-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure that the field does not exceed the maximum characters limit ."])},
    "min-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure that the field is not below the minimum characters limit ."])},
    "expire-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ُExpire date must be less than release date . "])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value is not a valid email address ."])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is not a valid URL address"])},
    "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is not alphabetical"])},
    "arabic-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only Arabic letter must br entered"])},
    "english-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only English letter must br entered"])},
    "numbers_and_letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this field must contains letters"])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be only numeric entered "])},
    "int": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" only numbers"])},
    "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be integer number "])},
    "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value is already exists"])},
    "end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start time greater than the end time ."])},
    "start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The end time less than the start time ."])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start date greater than the end date ."])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The end date less than the start date ."])},
    "start-equal-end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start date can't be equal to the end date ."])},
    "must-be-insert-seat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must enter a seating numbers first"])},
    "user-logged-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! user is already logged in "])},
    "deadline_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the deadline date must be more than todays date"])},
    "file-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file name is not accepted : just letters and numbers _ -"])},
    "invalid-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalid date"])}
  },
  "subject": {
    "for-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the subject "])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subject"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Name"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add School Subjects"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Subject "])},
    "name-ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic Name"])},
    "subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects  "])},
    "subjects-study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Subjects "])},
    "name-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" English Name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject Type"])},
    "min-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Min Score "])},
    "max-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Max Score "])},
    "add-to-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Subject to the Total ?"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects not found "])},
    "not-found-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity not found "])},
    "add-weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Weekly Schedule"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Study Schedules "])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Update the weekly schedule "])},
    "schedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Schedules "])}
  },
  "weekly-schedule": {
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Weekly Schedule"])},
    "add-weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Weekly Schedule"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Study Schedule"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Study Schedules "])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Update the weekly schedule "])},
    "schedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Schedules "])}
  },
  "estimate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimations"])},
    "add_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add estimate"])},
    "edit_estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit estimate"])},
    "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate AR name"])},
    "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate EN name"])},
    "degree_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAx score"])},
    "degree_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min score"])},
    "name_ar_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter estimate"])},
    "name_en_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter estimate "])},
    "degree_max_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter max score"])},
    "degree_min_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter min score"])},
    "degree_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum degree can't be more than minimum degree"])}
  },
  "document": {
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Document "])},
    "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Initialize"])},
    "add-to-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Class Documents  "])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Name"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter document Name"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no document with this name ! "])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The document is optional for the class ? "])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Optional"])}
  },
  "teacher": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Teacher"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher name"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Teachers Information"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Teacher"])}
  },
  "alert": {
    "failure": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure ! "])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry Failed"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Faield "])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion Faield "])},
      "exist-but-not-movable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student exist but not Mavable"])},
      "exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student not exist"])},
      "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This records is already exists"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saving data is faield "])},
      "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no database connection "])},
      "create-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure in create backup "])},
      "restore-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure in restore backup "])},
      "cannot-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This record cannot be deleted because there are others record associated with it "])},
      "entry-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry date is not correct"])},
      "changed-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure in change the password"])},
      "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure in reset the password"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success "])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserted Data Successfully "])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated Data Successfully"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted Data Successfully"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Data Successfully"])},
      "create-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created BackUp Successfully"])},
      "restore-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restored BackUp Successfully"])},
      "changed-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password has changed successfully"])},
      "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password has reset successfully"])}
    },
    "attention": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attention"])},
      "current-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must be there is one current year "])},
      "restore-backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" the last created backup will be restored "])},
      "delete-talent-with-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This talent will be deleted from students records "])},
      "delete-supervisor-with-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This supervisor will be deleted from summer courses records"])},
      "delete-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of the next records that related with this record will be deleted :"])},
      "more-regulars-than-registrants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More regular students than registrants"])},
      "read-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current database is read-only. No modification or addition operations can be performed."])}
    },
    "not-found": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Found 404 "])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is not found record with this data"])}
    },
    "warnning": {
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As this is your first time signing in or you are still using the default password, we kindly ask you to change your password to ensure yout account's security."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnning"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this record will be modified !"])}
    }
  },
  "export": {
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["export pdf file"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["export excel file"])},
    "cvs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["export cvs file"])},
    "error-loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure to download data."])}
  },
  "import": {
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["import"])},
    "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["import pdf file"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["import excel file"])},
    "cvs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["import cvs file"])},
    "import-records-to-db": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the records to the database"])}
  },
  "dashboard": {
    "summer-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Courses Count"])},
    "gender-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics Males - Females"])},
    "teachers-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["studetns - teachers"])},
    "save-as-png": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save as png"])},
    "students-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Count"])},
    "teachers-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers Count"])},
    "lectures-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leactures Count "])},
    "total-income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Expenses "])},
    "subjects-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects Count "])},
    "teachers-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers Statistics"])},
    "students-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Statistics"])},
    "walkout-constant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Walkouts - Constants"])},
    "presents-absents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presents - Absents"])},
    "presents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presents"])},
    "absents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absents"])}
  },
  "son": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select son "])}
  },
  "pay-type": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" select pay type"])}
  },
  "student": {
    "enter-academic-num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student academic number"])},
    "walkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Walkout"])},
    "constant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["constant"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select student"])}
  },
  "libraries": {
    "libraries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libraries"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome you in Libraries system"])},
    "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name "])},
    "mname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Middle Name"])},
    "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name  "])},
    "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members "])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authors "])},
    "cs-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Chose an Image ... "])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
    "instructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
    "external-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Member "])},
    "type-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Type "])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Active"])},
    "release-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   Release place "])},
    "release-place-placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  enter release place "])},
    "release-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   Release Date "])},
    "expire-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Expire Date "])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Dashboard"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "mng-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Books"])},
    "mng-fines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Fines"])},
    "issue-book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue Book"])},
    "issue-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue Books"])},
    "return-book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Book"])},
    "all-issued-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Issued Books"])},
    "all-archived-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all Archived Books"])},
    "mng-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Users"])},
    "all-request-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Request Books"])},
    "institution-setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution Setup"])},
    "other-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Settings"])},
    "report-bugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Bugs"])},
    "about-software": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Software"])},
    "change-passowrd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "update-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Details"])},
    "add-book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Book"])},
    "all-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Books"])},
    "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
    "all-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Users"])},
    "book": {
      "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the books"])},
      "book-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books Detales"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["book name"])},
      "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["book number"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["book description"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["book image"])},
      "page-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["page number"])},
      "publication-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publication date"])},
      "total-copys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copys number"])}
    },
    "language": {
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the language"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose language"])}
    },
    "country": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Country Name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" select country"])}
    },
    "city": {
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" City Name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" City Chose"])}
    },
    "category": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Category"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Category"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the category"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["category name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose category"])}
    },
    "department": {
      "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the department"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["department name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose department"])}
    },
    "authors": {
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the author"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["author name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose author"])}
    },
    "publisher": {
      "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publisher"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publisher name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose publisher"])}
    },
    "shelf": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shelf name"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose shelf"])}
    },
    "form": {
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])}
    }
  },
  "purchases": {
    "purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchases"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome you in Purchases system"])}
  },
  "sales": {
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome you in Sales system"])}
  },
  "storages": {
    "storages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storages"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome you in Storages system"])}
  },
  "monthly_marks": {
    "marks-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add the marks of the month"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
    "oral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oral"])},
    "assigments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assigments"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attendance"])},
    "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam"])}
  },
  "fees": {
    "discount_is_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the discount in not correct"])},
    "add_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add student fee"])},
    "edit_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit student fee"])},
    "add_student_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add fees to the student"])},
    "update_student_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit the student fees"])},
    "installments_number_placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter installments number"])},
    "installments_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installments number"])},
    "days_between_installments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of days between installments "])},
    "days_between_installments_placehloder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter number of days"])},
    "installment_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pay student's installment"])},
    "installment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installment date"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installments"])},
    "installment_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid amount"])},
    "installment_remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remain amount"])},
    "payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment date"])},
    "add_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add payment"])},
    "show_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show payments"])},
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["make payment"])},
    "who_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid by"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required amount"])},
    "total_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total paid"])}
  },
  "summer": {
    "task-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Number"])},
    "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])},
    "task-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Type "])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "mission-start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Task start date"])},
    "mission-end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Task end date "])},
    "place-of-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Place"])},
    "implementing-authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authority"])},
    "course-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Number"])},
    "target-audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Audience"])},
    "batch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "positives-of-the-workshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positives of the workshop"])},
    "obstacles-in-the-workshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obstacles of the workshop"])},
    "coach-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach Name"])},
    "participants-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants Count"])},
    "yard-is-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is yard available"])},
    "lecture-room-is-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is Lecture hall available"])},
    "accoustics-is-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is audio available"])},
    "electricity-is-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is electricity / power available"])},
    "activity-tools-is-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is sport activities tools available"])},
    "shortage-of-worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the shortage of worker"])},
    "distribute-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute Gifts and Prizes Plan"])},
    "supply-dispensing-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supply Dispensing Plan"])},
    "open-schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Schools"])},
    "typical-schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typicaly Schools"])},
    "eighth-quran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quran eighth"])},
    "quarter-quran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quran quarter"])},
    "half-quran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quran half"])},
    "seals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seals"])},
    "sacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sacks"])},
    "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flags"])},
    "big-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big flags"])},
    "medium-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium flags"])},
    "scarfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarfs"])},
    "gifts-prizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gifts and prizes"])},
    "teachers-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers certificates"])},
    "male-students-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male students certificates"])},
    "female-students-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female students certificates"])},
    "students-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students certificates"])},
    "collaborators-for-schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborators for schools"])},
    "male-teachers-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male teachers certificates"])},
    "female-teachers-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female teachers certificates"])},
    "football-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Football count"])},
    "football-net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Football NETs"])},
    "volleyball-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volleyball count"])},
    "volleyball-net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volleyball NETs"])},
    "headbands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headbands"])},
    "sports-supplies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports supplies"])},
    "activity-groups-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity groups records"])},
    "records-licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records and licenses"])},
    "curriculums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculums"])},
    "open-school-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open school records"])},
    "open-school-licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open school licenses"])},
    "open-school-supplement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open school supplement"])},
    "typical-school-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typical school records"])},
    "typical-school-licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typical school licenses"])},
    "typical-school-supplement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typical school supplement"])},
    "collaborator-certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborator certificates"])},
    "male-collaborator-certificates-directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male collaborator certificates for directorates"])},
    "female-collaborator-certificates-directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female collaborator certificates for directorates"])},
    "male-collaborator-certificates-governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male collaborator certificates for governorates"])},
    "female-collaborator-certificates-governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female collaborator certificates for governorates"])},
    "dropout-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropout Percent"])},
    "registered-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered Total "])},
    "regular-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Total "])},
    "dropout-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Dropout Total"])},
    "registered-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered School "])},
    "serial-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Nubmer"])},
    "overbooks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overbooks"])},
    "issued-for-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued for students"])},
    "issued-for-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued for employees"])},
    "received-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received Count"])},
    "lectures-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count of Lectures"])},
    "summer-open-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Open Summer Schools"])},
    "summer-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Summer Schools"])},
    "summer-open-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Summer Schools Data"])},
    "students-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Students"])},
    "employees-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Employees"])},
    "activities-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Activities"])},
    "visits-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Visits"])},
    "tasks-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Tasks Management"])},
    "address-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address of Summer Center"])},
    "is-funded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the school funded ?"])},
    "is-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Active"])},
    "is-emmployee-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Employee Active"])},
    "is-student-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Student Active"])},
    "supplies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplies"])},
    "school-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Courses Statistics"])},
    "school-statistics-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Courses Statistics Report"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no summer course"])},
    "expelled-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expelled students due to absence"])},
    "students-drop-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Dropping Out"])},
    "droped-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droped out"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Manager"])},
    "Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info"])},
    "another-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another Notes"])},
    "extended-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Student Will Be Excluded after"])},
    "lesson-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Record"])},
    "lesson-record-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Name"])},
    "lesson-record-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson was Taked"])},
    "work-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Type"])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Personal Number"])},
    "not-found-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not Found Image"])},
    "add-supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Supervising Authorit"])},
    "edit-supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Supervising Authorit"])},
    "summer-camp-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Summer Camps"])},
    "add-sup-auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Supervising Authority  "])},
    "remove-sup-auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Supervising Authority"])},
    "initialization-supervising-authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Supervising Authority"])},
    "supervising-authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervising Authority Data"])},
    "supervising-authority-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervising Authority Select"])},
    "supervising-authority-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervising Authority Name"])},
    "supervision-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervision Type"])},
    "solitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solitude/Village"])},
    "not-found-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Found Data"])},
    "out-of-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today Is Out of calender range"])},
    "is-holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today Is Holiday"])},
    "already-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor with this name already exists."])},
    "already-calender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vacation with this date already exists."])},
    "already-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country arabic name is already exists."])},
    "already-country-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country english name is already exists."])},
    "already-governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governorate arabic name is already exists."])},
    "already-governorate-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governorate english name is already exists."])},
    "already-directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["directorate arabic name is already exists."])},
    "already-directorate-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["directorate english name is already exists."])},
    "already-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase with this level already exists."])},
    "already-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year of study with this year already exists."])},
    "already-year-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year of study with this year m already exists."])},
    "already-division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["division with this name is already exists"])},
    "already-symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["division with this symbol is already exists"])},
    "assign-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned skills"])},
    "un-assign-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned skills"])},
    "imageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Image Size Should be Is"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "school_summer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name of school summer"])},
    "school_summer_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type of school summer"])},
    "employee_avaliable_by_responsibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker Avaliable By Responsibility"])},
    "plan_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activity plan status"])},
    "recived-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recived Books Number "])},
    "member_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Name "])},
    "work_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shift"])},
    "employee_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["count of Worker"])},
    "member_adject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Job"])},
    "member_entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity that he work for"])},
    "member_phonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Phonenumber"])},
    "enter_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Note "])},
    "edit-talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Skills"])},
    "add-commit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Committee"])},
    "purpose-of-commit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose Of Commit"])},
    "type-of-commit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Of Commit"])},
    "bignner-perform-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Perform Plan"])},
    "end-perform-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Perform Plan"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "plan_describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Plan"])},
    "plan_describe_write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Activity Plan"])},
    "plan_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan Name"])},
    "date-activity-opreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Activity Opreation"])},
    "date-activity-opreation_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date End Of Activity "])},
    "is_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Is Done "])},
    "is_run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Is Run "])},
    "name-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker Name "])},
    "edit-assgin-employee-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit assgin Worker skills"])},
    "class_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["class name"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["count of student"])},
    "add-skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Skill "])},
    "skill-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Skill Name  "])},
    "summer-camp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Camp"])},
    "summer-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer School Data"])},
    "add-closed-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Closed School"])},
    "directorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Directorates"])},
    "add-directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Directorate"])},
    "initiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anitiative"])},
    "add-an-initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an Anitiative"])},
    "edit-an-initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit an Anitiative"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
    "assigning-division-to-levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigning Divistion Levels"])},
    "edit-assigning-division-to-levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Assigning Divistion Levels"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "add-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Days"])},
    "name-talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Talent"])},
    "weekly-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks"])},
    "academic-calendar-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Calendar"])},
    "add-holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacations and Occasions"])},
    "is-holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Holiday?"])},
    "add-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Activity"])},
    "view-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view Activities"])},
    "visitors": {
      "name-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor Name"])},
      "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors"])},
      "adjective-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor Adjective"])},
      "date-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor Date"])},
      "entity-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor Side"])},
      "evalu-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor Evaluation"])},
      "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit"])},
      "dir-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporting body"])},
      "impression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impression"])},
      "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations"])},
      "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Evaluation to Visit"])},
      "type-visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitor Type"])},
      "occasion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occasion"])}
    },
    "name-administrative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative Tasks Name"])},
    "add-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Statistic"])},
    "edit-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Statistic"])},
    "type-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistic Type"])},
    "is-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it exist"])},
    "is-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it done"])},
    "initialize-types-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Types Activities"])},
    "add-types-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Types Activities"])},
    "initialize-activity-processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute Activity"])},
    "add-activity-processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute Activity"])},
    "add-activity-plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Activity Plans"])},
    "show-activity-plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Activity  Plans"])},
    "set-weekly-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Activities Schedule"])},
    "assign-student-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Student Activities"])},
    "assign-commitiesmember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Commitis Member  "])},
    "showcommiteemember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Show Commitee Member "])},
    "curricula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curricula"])},
    "receiving-curricula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving Curricula"])},
    "curriculum-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curriculum Statistics"])},
    "add-curriculum-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Curriculum Statistics"])},
    "dropout-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage State Student"])},
    "move-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move Students"])},
    "dropout-reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropout Reasons"])},
    "dropout-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropout Reason"])},
    "dropout-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropout Date"])},
    "assigning-students-initiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Student Initiatives"])},
    "edit-assigning-students-initiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Edit Assign Student Initiatives "])},
    "manage-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Workers"])},
    "add-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Worker"])},
    "manage-state-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage State Employee  "])},
    "state-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" State Employee  "])},
    "save-state-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save State Employee"])},
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Workers"])},
    "place-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["place name"])},
    "class-count-study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of study classes"])},
    "yard_founded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is a yard for activities"])},
    "overall-assessment-for-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overell rating of the place (suitable-unsuitable)"])},
    "obtain-permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obtain a permit(true-false)"])},
    "initialization-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Workers"])},
    "add-employee-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Workers Skills"])},
    "edit-employee-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit Workers Skills"])},
    "assign-employee-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Workers Skills"])},
    "student-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Skills"])},
    "add-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Skills"])},
    "assign-student-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Student Skills"])},
    "edit-assign-student-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Assign Student Skills"])},
    "add-teachers-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Teachers Plan"])},
    "view-teachers-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Teachers Plan"])},
    "teacher-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Attendance"])},
    "initialize-committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Committees"])},
    "add-committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Committees"])},
    "assign-committees-to-teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Committees Teachers"])},
    "study-lecture-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Lecture Period"])},
    "assign-study-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Study Schedule"])},
    "assign-weekly-grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Weekly Grades"])},
    "study-schedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Schedules"])},
    "add-lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Lectures"])},
    "show-lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Lectures"])},
    "name-lectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Lectures"])},
    "add-visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Visitors and Support Destinations"])},
    "all-visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Visitors and Support Destinations"])},
    "edit-visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Visitors and Support Destinations"])},
    "weekly-follow-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Follow Up"])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistic"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "weekly-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Recommendations"])},
    "class-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seasons Numbers"])},
    "add-class-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Seasons Numbers"])},
    "shift-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "place-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Type"])},
    "place-name-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter place name"])},
    "school-has-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Has License"])},
    "school-has-garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Has Garden"])},
    "overall-assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall Assessment"])},
    "add-overall-assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Overall Assessment"])},
    "quotas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotas"])},
    "center_name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center Name Arabic"])},
    "center_name_ar_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the center name in arabic"])},
    "center_name_en_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the center name in english"])},
    "center_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center Name English"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start at"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end at"])},
    "head_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Of The Center Head"])},
    "head_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the name of the center head"])},
    "summer_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Of Summer Center"])},
    "summer_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Logo"])},
    "summer_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Of Summer Center"])},
    "summer_place_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the place of summer center"])},
    "summer_placeholder_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter che cummer center coordinates"])},
    "summer_coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Coordinates"])},
    "initiative": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiative Name"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiative Description"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed date"])},
      "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor Name"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])}
    },
    "add-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Subject"])},
    "edit-subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Subject"])},
    "add-type-activiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Type Activity"])},
    "edit-activiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Activity"])},
    "level": {
      "phase-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Phase"])},
      "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
      "name_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Name"])},
      "name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level English Name"])},
      "edit-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Level"])},
      "add-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Level"])},
      "please-select-division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select Divisions"])},
      "the-division-assigned-to-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Division Assigned to Level"])},
      "the-division-is-not-assigned-to-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Division isn't Assigned to Level"])}
    },
    "marks": {
      "quran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qura'an"])},
      "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approach"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "ehsan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehsan"])},
      "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])}
    },
    "calendar": {
      "difficulties_and_solutions_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Difficulties And Solutions Show   "])},
      "semesters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semesters Guide"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years Guide "])},
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Days Guide"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months Guide "])},
      "add-academic-weeks-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Academic Weeks Data"])},
      "edit-academic-weeks-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Academic Weeks Data"])},
      "start-date-of-the-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date of The Week"])},
      "end-date-of-the-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date of The Week"])},
      "is-it-the-current-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it The Current Week"])},
      "week-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of weeks"])},
      "week-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week Name"])},
      "academic-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Academic Year"])},
      "add-vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Data Vacations and Occasions"])},
      "edit-vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Data Vacations and Occasions"])},
      "study-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Day"])},
      "day-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Name"])},
      "duration-of-one-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Enter the Duration of One Week"])},
      "select-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Week"])},
      "add-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a study calendar"])},
      "hijri-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hijri calendar"])},
      "gregorian-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gregorian calendar"])},
      "saturday-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The calendar dose not start with saturday therefore the first week will be less than seven days"])},
      "calendar-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar will be reset and all inserted vacations will be deleted"])},
      "calendar-update-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar can't be updated because there are some data connected to it"])},
      "day-init": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days must be initialized"])},
      "year-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study years must be initialized or You need to log in again"])}
    },
    "activity": {
      "excuted-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excuted Activities"])},
      "activity_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Name "])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Type"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Name"])},
      "excuted-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excuted Date"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Activity"])},
      "gendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation Category"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start at"])},
      "statrt_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the time whene start activity"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end at"])},
      "end_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter the time whene activity finsh"])},
      "employee_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name of the worker "])},
      "employee_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter name of the worker"])},
      "location_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter name of location"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
      "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
      "is_run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done "])},
      "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Start Date "])},
      "evaluation_act": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation Activity"])},
      "evaluation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the evaluation"])},
      "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants Count"])},
      "difficultie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficultie"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["place"])},
      "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
      "activtity_assigin_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activtity AssignDate"])},
      "activity_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Activity Info "])},
      "difficulties_and_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficulties And Solutions"])},
      "difficulties_and_solutions_assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Difficulties And Solutions Table  "])},
      "difficulties_and_solutions_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Difficulties And Solutions Show   "])},
      "show_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show activity"])},
      "add-dif-sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add difficultiies and solutions"])},
      "del-dif-sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add difficultiies and solutions"])}
    },
    "address": {
      "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
      "add-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Address"])},
      "edit-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Address"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Name"])}
    },
    "student": {
      "enter-academic-num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter student academic number"])},
      "recording-student-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording Student Attendance"])}
    },
    "lecture": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Name"])}
    },
    "employee": {
      "unique-job-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The job id is already exist "])},
      "place-of-work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of Work"])},
      "mobile-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
      "children-numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children Numbers"])},
      "add-employees-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Worker Data"])},
      "edit-employees-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Worker Data"])},
      "qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Qualifications"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee State"])},
      "other-work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Work"])},
      "birth-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Place"])},
      "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers Attendance"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker Name"])},
      "date-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Attendance"])},
      "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee name is already exist"])}
    },
    "recommendations": {
      "add-weekly-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positives/Negatives"])},
      "title-weekly-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title Positives/Negatives"])},
      "edit-weekly-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Weekly Recommendations"])},
      "is-optimastic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Optimastic"])},
      "optimastic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimastic"])},
      "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title of Positives"])},
      "address-negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title of Negatives"])},
      "implementation-officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation Officer"])},
      "name-implementation-officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Implementation Officer"])}
    },
    "year": {
      "placeholder": {
        "year-h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example 1444"])},
        "year-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example 2023"])}
      }
    },
    "curriculum": {
      "reciever_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reciever type"])},
      "reciever_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reciever name"])},
      "recieved?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recieved?"])},
      "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teachers"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["students"])}
    },
    "user-with-this-email-already-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User With This Email Already Exists"])},
    "prime-minister-office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prime Minister Office"])},
    "supreme-committee-for-activities-and-summer-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Supreme Committee for Activities and Summer Courses"])},
    "general-administration-for-summer-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Administration for Summer Courses"])},
    "report": {
      "open-school-employees-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open School employees State"])},
      "closed-school-employees-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed School employees State"])},
      "closed-school-students-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open School Students State"])},
      "closed-school-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed School State"])},
      "open-school-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open School State"])},
      "students-dropout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a Report of dropout students at the directorate level"])},
      "school-summer-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring school summer operations"])},
      "talented-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of talented students"])},
      "feeding-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute the feeding for Students and Employee"])},
      "events-and-occasions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holidays of oThe Events and Occasions"])},
      "event-or-occasion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event or Occasion"])},
      "fullname-with-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FullName With Surname"])},
      "qualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
      "work-on-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work on School Summer"])},
      "skills-he-is-good-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills He is Good at"])},
      "total-attendance-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Attendance Days"])},
      "total-absence-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Absence Days"])},
      "school-visit-impact-assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management Visit Impact Assessment"])},
      "visitors-with-evaluation-and-recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors With Evaluation and Recommendations"])},
      "impression-about-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impression about School"])},
      "administrative-tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative Tasks"])},
      "write-yes-or-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes or No"])},
      "do-or-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is Do it"])},
      "task-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Name"])},
      "positives-negatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positives and Negatives"])},
      "schedule-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistic on the Number of Lessons"])}
    },
    "accommodation-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation types at the school"])}
  },
  "settings": {
    "system-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System settings"])},
    "school-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School system settings"])},
    "summer-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer center settings"])}
  },
  "summer-report": {
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "school-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Data"])},
    "general-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Reports"])},
    "approach-statistics-by-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curreculam Statistics by Level"])},
    "material-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplies Statistics "])},
    "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplies"])},
    "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "employee-available-by-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker Availabliy "])},
    "school-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Calendar"])},
    "occasion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holidays Events and Occuasion "])},
    "students-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Students Reports "])},
    "personal-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])},
    "student-personal-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students' Personal Data"])},
    "teacher-personal-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers' Personal Data"])},
    "students-talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Talents"])},
    "walkout-students-by-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Dropout "])},
    "students-statistics-by-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Statistics "])},
    "students-marks-sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Students Marks Sheet "])},
    "lectures-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lecture Timetable "])},
    "weekly-lectures-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Timetable "])},
    "teachers-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers reports "])},
    "employee-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["worker attendance"])},
    "visits-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits reports"])},
    "initiatives-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiatives reports"])},
    "initiatives-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiatives participant"])},
    "activity-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity report"])},
    "summer-center-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer center activities"])},
    "weekly-activity-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly activities schedule"])},
    "weeekly-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeekly reports"])},
    "workers-displine-level-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final workers' discipline level"])},
    "workers-displine-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workers discipline level"])},
    "students-count-statistic-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Students' count statistic"])},
    "students-count-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students count statistic"])},
    "student-statistics-by-leaking-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Student statistics based on dropping out"])},
    "student-statistics-by-leaking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student statistics based on dropping out"])},
    "curreculam-statistics-by-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curreculam statistics based on level"])},
    "lectures-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final lectures statistics"])},
    "lectures-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lectures statistics"])},
    "actiivty-excution-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final activities execution statistics"])},
    "actiivty-excution-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities execution statistics"])},
    "supporting-entity-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final supporting entities statistics"])},
    "supporting-entity-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporting entities statistics"])},
    "management-tasks-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final management tasks statistics"])},
    "management-tasks-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management tasks statistics"])},
    "supplies-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplies Statistics"])},
    "positives-negatives-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positives and negatives "])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "final-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final reports"])},
    "summer-center-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer center data"])},
    "teachers-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final teachers statistics"])},
    "teachers-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers statistics"])},
    "curreculam-statistics-final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Curreculam statistics"])},
    "curreculam-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curreculam statistics"])},
    "supplies-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplies statistic"])},
    "activity-participated-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity participated student"])},
    "activity-full-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity full data"])},
    "daily-check-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily check-ups"])},
    "students-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students attendance"])},
    "teachers-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers attendance"])},
    "committee-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees members"])},
    "committee-members-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees members reports"])}
  },
  "summer-sidebar": {
    "assign-task-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Trainee List"])},
    "assign-task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Trainee"])},
    "task-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks Type"])},
    "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Task"])},
    "visits-carried-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report of Visits Carrried out"])},
    "roles-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Roles Status"])},
    "review-summer-schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Summer Schools"])},
    "review-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Employees Records"])},
    "user-input-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Input Data"])},
    "licenses-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenses & Records"])},
    "gifts-prizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gifts & Prizes"])},
    "phases-cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards for Levels"])},
    "levels-books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books for Levels"])},
    "sport-accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport Accessories"])},
    "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
    "add-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Statistics"])},
    "distribute-supplies-gifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute supplies and gifts"])},
    "dropedout-froms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropedout from the entered forms"])},
    "mobile-apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Applications"])},
    "upload-apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Applications"])},
    "employees-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees List"])},
    "circulars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circulars"])},
    "mobile-versions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mobile app versions"])},
    "dropout-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropout Students"])},
    "dropout-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropout Statistics"])},
    "statistics-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Reports "])},
    "talented-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talented Students"])},
    "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations log "])},
    "user-session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Session"])},
    "login-attempt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Attempt"])},
    "feeding-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate financial report "])},
    "feeding-report-periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate financial report based on Periods"])},
    "attendnace-protoflio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendnace Protoflio"])},
    "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors"])},
    "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons"])},
    "activities-intiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities and Intiatives"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "intiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intiatives"])},
    "community-committees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community Committees"])},
    "study-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Timetables"])},
    "init-periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialize Periods"])},
    "add-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Timetable"])},
    "edit-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Timetable"])},
    "personnel-authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnel Authority"])},
    "init-student-talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Talents"])},
    "init-employee-skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers Skills "])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "supplies-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add Supplies "])},
    "supplies-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Edit Supplies "])},
    "supplies-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Supplies "])},
    "supplies-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Supplies"])},
    "managament-task-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Managament Task"])},
    "managament-task-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Managament Task "])},
    "managament-task-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Managament Task "])},
    "managament-task-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Managament Task "])},
    "marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Marks"])},
    "weekly-marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Marks"])}
  },
  "request": {
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response"])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Request Timeout 408"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])}
  },
  "models": {
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "LectureLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lecture Log"])},
    "ManagmentTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managment Task "])},
    "EmployeeAttendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Attendance "])},
    "UserAuthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Authority "])},
    "StudentAttendanceSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Attendance   "])},
    "StudentSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Records"])},
    "SchoolSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Center Data"])},
    "Employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["worker Records "])},
    "Calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Records "])},
    "LectureSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lectures Records "])},
    "SchoolWeeklyScheduleSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Weekly Timetable Records  "])},
    "ActivitySummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Records "])},
    "EmployeeLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workers with Levels Recodrds "])},
    "StudentActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Activities Records  "])},
    "Week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Weeks Records "])},
    "MarkSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Marks Records "])},
    "LevelDivision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Divisions Records "])},
    "Approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approaches Records "])},
    "ParentSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents Records  "])},
    "ContactSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts Records "])},
    "DivisionSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisions Records "])},
    "DaySummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Days Records"])},
    "ScheduleSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedules Records"])},
    "ActivityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Types Records "])},
    "SubjectSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects Records"])},
    "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels Records "])},
    "Skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills Records "])},
    "LevelStudentYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels Students Years Records "])},
    "Week,": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks Records "])},
    "ActivityOperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity execution Records "])},
    "Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans Records "])},
    "Talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talents Records  "])},
    "StudentTalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Talents Records  "])},
    "Initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiatives Records  "])},
    "Visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors Records "])},
    "Commitee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee Records "])},
    "CommiteeMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee Members Records  "])},
    "Statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics Records "])},
    "Recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations Records "])},
    "SchoolWeeklyActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Weekly Activities Records  "])},
    "ActivityDifficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Difficulties Records "])},
    "Vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacations Records  "])},
    "TeacherSubjectSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Subjects Records  "])},
    "Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate "])},
    "Directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorate  "])},
    "lecturelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lecture Log"])},
    "managmenttask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managment Task "])},
    "employeeattendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Attendance "])},
    "userauthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Authority "])},
    "studentattendancesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Attendance   "])},
    "studentsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Records"])},
    "schoolsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Center Data"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["worker Records "])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Records "])},
    "lecturesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lectures Records "])},
    "schoolweeklyschedulesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Weekly Timetable Records  "])},
    "activitysummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Records "])},
    "employeelevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workers with Levels Recodrds "])},
    "studentactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Activities Records  "])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Weeks Records "])},
    "marksummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Marks Records "])},
    "leveldivision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Divisions Records "])},
    "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approaches Records "])},
    "parentsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents Records  "])},
    "contactsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts Records "])},
    "divisionsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisions Records "])},
    "daysummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study Days Records"])},
    "schedulesummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedules Records"])},
    "activitytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Types Records "])},
    "subjectsummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects Records"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels Records "])},
    "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills Records "])},
    "levelstudentyear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels Students Years Records "])},
    "week,": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks Records "])},
    "activityoperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity execution Records "])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans Records "])},
    "talent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talents Records  "])},
    "studenttalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Talents Records  "])},
    "initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiatives Records  "])},
    "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors Records "])},
    "commitee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee Records "])},
    "commiteemember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committee Members Records  "])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics Records "])},
    "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations Records "])},
    "schoolweeklyactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Weekly Activities Records  "])},
    "activitydifficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Difficulties Records "])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacations Records  "])},
    "teacherSubjectSummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Subjects Records  "])},
    "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate "])},
    "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorate  "])},
    "LevelStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Statistics"])},
    "levelstatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Statistics"])}
  },
  "reportpublic": {
    "daily_summer_operations_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Summer School Operations Report"])},
    "the_total_number_student_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Total Number Of Student And Employees in Summer Centers"])},
    "total_number_student_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Total Number Of Student And Employees"])},
    "summer_center_student_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Student Data"])},
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employees"])},
    "data-student-employee-present-actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data of student and staff present and actual"])},
    "summer_course_statistics_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer course statistics report"])},
    "TotalWorkersStudentsCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Workers Students Centers"])},
    "CentersOWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centers Open-Workers-Students"])},
    "NoInternet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Internet"])},
    "SummerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Type"])},
    "SummerTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total of Centers"])},
    "students_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Type"])},
    "summer_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Type"])},
    "TotalSummation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summation"])},
    "statistics-types-tenters-students-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics for The Types of (Centers - Students - Employee) foreach Type"])},
    "Total-types-tenters-students-type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total of Summer Centers"])},
    "CenterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center Name"])},
    "number-positions-each-TOC-typical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Positions For Each Type Of Open Closed And Typical"])},
    "parent_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Phone"])},
    "job-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Number"])},
    "centers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centers"])},
    "name-worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker Name"])},
    "WorkerStatisticsForEachCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker Statistics For Each Center"])},
    "WorkerStatisticsForEachCenter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker Total For Each Center"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
    "student-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
    "student_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Id"])},
    "StudentStatisticsForAllCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Statistics For All Centers"])},
    "StudentStatisticsForAllCenters2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Total For All Centers"])},
    "StatisticsOfAllSummerCentersInTheDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics Of All Summer Centers"])},
    "StatisticsOfAllSummerCentersInTheDistrictsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total of All Summer Centers"])},
    "StatisticsOfAllCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics Of All Centers"])},
    "TotalVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Visits"])},
    "governorate1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate"])},
    "VisitsCarriedOutDuringTheDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits Carried Out During The Day"])},
    "visits-carried-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits Carrried out"])},
    "VisitsCarriedOutDuringTheDay2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visits  Carried Out During The Day"])},
    "StatisticsTypeMaleFemaleSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics Type Centers (Male - Female - General)"])},
    "SummaryTotalOpenPositionMFDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of The Total Open Centers (Males - Females)"])},
    "SummaryTotalOpenPositionMFDistrict2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total of Open Centers (Males - Females)"])},
    "SummaryTotalOpenPositionMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary Total of Open Centers (Males - Females)"])},
    "SummaryTotalClosePositionMFDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary Total of Close Centers (Males - Females)"])},
    "SummaryTotalClosePositionMFDistrict2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total of Close Centers (Males - Females)"])},
    "SummaryTotalClosePositionMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary Total of Close Centers (Males - Females)s"])},
    "SummaryTotalTypicalPositionMFDistrict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary Total of Typical Centers (Males - Females)"])},
    "SummaryTotalTypicalPositionMFDistrict2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total of Typical Centers (Males - Females)"])},
    "SummaryTotalTypicalPositionMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary Total of Typical Centers (Males - Females)"])},
    "LevelsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels Name"])},
    "NumberOfStudentsByLevels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Students By Levels"])},
    "TotalMFWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Male Fmemales Workers"])},
    "NumberOfOpenCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Open Centers"])},
    "WorkingStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Staff Statistics"])},
    "directorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directorate"])},
    "GenerlSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generl Centers"])},
    "MalesSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Males Centers"])},
    "FemalesSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Females Centers"])},
    "TotalSchoolsStaffStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Of Centers - Staff - Students"])},
    "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "Cloes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloes"])},
    "Typical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typical"])},
    "Workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers"])},
    "Students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "TotalSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Centers"])},
    "TotalWorkrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Workrs"])},
    "Totalstudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Students"])},
    "TotalStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total of Students"])},
    "StudentsRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Ratio"])},
    "WorkersRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers Ratio"])},
    "SchoolsRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schools Ratio"])},
    "TotalTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Teachers"])},
    "TeacherRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Ratio"])},
    "Totalmartyrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Martyrs"])},
    "martyrsRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martyrs Ratio"])},
    "ReportOnNumbersOfOpenSchoolsStaffAndStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report on Numbers of Open Centers Staff and Students"])},
    "NumberOfDirectorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Directorates"])},
    "SummerSchoolsForTheYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Centers For The Year"])},
    "TheAverageNumberOfTeachersPerSchoolIs4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Average Number of Teachers Per Center Is"])},
    "Teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
    "NumberOfActivitiesOfficialsInTheGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Activities of ficials In The Governorate"])},
    "ActivitiesInTheGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities In The Governorate"])},
    "ActivitiesInTheDirectorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities In The Directorate"])},
    "ActivitiesInTheDirectorate2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities  In The Directorate"])},
    "NumberOfPrincipalsOfOpenSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Principals of Open Centers"])},
    "MalesWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Males Workers"])},
    "FemalesWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Females Workers"])},
    "Males": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Males"])},
    "Females": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Females"])},
    "Generl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generl"])},
    "MaleStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male Students"])},
    "FemaleStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female Students"])},
    "TotalOpenCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Open Centers"])},
    "TotalCloseCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Close Centers"])},
    "TotalOpenAndClosedCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Open - Closed - Typical Centers"])},
    "TotalNumberOfStudentsInGovernoratesByLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Number Of Students In Governorates By Level"])},
    "Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate"])},
    "FirstQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Qualification"])},
    "SecondQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Qualification"])},
    "FirstBasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Basic"])},
    "SecondBasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Basic"])},
    "FirstAverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Average"])},
    "SecondAverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Average"])},
    "FirstHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First High"])},
    "SecondHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second High"])},
    "SummerTotalActivitiesCarriedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities Carried Out (From The Beginning To Today)"])},
    "SummerTotalActivitiesCarriedOutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Total Activities Carried Out"])},
    "SummerTotalActivitiesCarriedOutDuringDayHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Total Activities Carried Out During Day"])},
    "SummerTotalActivitiesCarriedOutDuringDayHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Total  Activities Carried Out During Day"])},
    "Values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values"])},
    "SummerWorkProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Work Progress"])},
    "TotalNumberOfActivitiesCarriedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Number Of Activities Carried Out to Today"])},
    "ActiivtyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actiivty Type"])},
    "SummerStatisticsBySchoolType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Statistics By Center Type"])},
    "NumberOfGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Governorate"])},
    "TheTotalAreaOfTheGovernorateIsKm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the Total Area of the Governorate in Km"])},
    "SummerCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Centers"])},
    "NumberOfStudentsAndWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Students And Workers"])},
    "NumberOfWorkers2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Workers"])},
    "NumberOfFWorkers2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Females Workers"])},
    "NumberOfWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Workers"])},
    "TotalForStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total For Students"])},
    "NumberOfFStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female Students"])},
    "NumberOfStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Students"])},
    "TotalNumberOfStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Males Students"])},
    "TotalNumberOfFStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Females Students"])},
    "TotalTypicalCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Typical Centers"])},
    "VariousActivitiesDuringTheSummerCentersPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various Activities During The Summer Centers Period "])},
    "VariousActivitiesDuringTheSummerCentersPeriod2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various Activities During The Summer Centers Period"])},
    "VariousActivitiesDuringTheSummerCentersPeriodInGovernorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various Activities During The Summer Centers Period In Governorates"])},
    "SummerCouresStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Statistics"])},
    "ThePositionsForEachTypeAreTypicalOpenAndClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Centers For Each Type Typical Open And Closed"])},
    "StatisticsOfSummerCerntersInTheCapitalsSecretariatDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics Of Summer Centers In The Capitals Secretariat Districts"])},
    "StatisticsOfCerntersInDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics Of Cernters In Districts"])},
    "CentersForTheGovernorateAndItsAffiliates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate Centers"])},
    "CentersForTheGovernorateAndItsAffiliatesTatel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centers For The Directorate in"])},
    "TotalSummaryOpenPositionsMFGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summary Open Centers Males Females"])},
    "TotalSummaryOpenPositionsMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summary Open Centers Males Females"])},
    "TotalSummaryClosePositionsMFGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summary Close Centers Males Females"])},
    "TotalSummaryClosePositionsMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summary Close Centers Males Females"])},
    "TotalStudentsByLevelForCenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TotalStudentsByLevelForCenters"])},
    "TotalStudentsByLevelForCenters2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Students By Level For Centers"])},
    "TotalStudentsByLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Students By Level"])},
    "TotalSummaryTypicalPositionsMFGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summary Typical Centers Males Females"])},
    "TotalSummaryTypicalPositionsMF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summary Typical Centers Males Females"])},
    "SchoolStatisticsMFFirstReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center Statistics Males Females"])},
    "SchoolStatisticsMFSecondtReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Statistics Males Females"])},
    "SchoolStatisticsTypeSchoolMFFirstReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center Statistics by Center Type Males Females In The Governorate"])},
    "SchoolStatisticsTypeSchoolMFSecondReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center Statistics by Students Type Males Females In The Governorate"])},
    "printCurriculum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["print The Curriculum"])},
    "TotalActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الأنشطة"])},
    "TotalActivitiesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total of Activities"])},
    "TotalSchoolsWorkersStudentsByGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summary Of (Centers - Workers - Students) by Governorate"])},
    "TotalOpenSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Centers"])},
    "TotalClosedSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Centers"])},
    "TotalTypicalSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typical Centers"])},
    "TotalClosedWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers (Males - Females) Closed"])},
    "TotalOpenWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers (Males - Females) Open"])},
    "TotalSchoolsMalesFemalesByGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summary Of Centers (Males - Females) By Governorate"])},
    "Schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schools"])},
    "OpenFemales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Females"])},
    "OpenMales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Males"])},
    "OpenGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open General"])},
    "ClosedGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed General"])},
    "ClosedFemales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Females"])},
    "ClosedMales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Males"])},
    "TypicalFemales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typical Females"])},
    "TypicalMales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typical Males"])},
    "TypicalGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typical General"])},
    "TotalWorkersMalesFemalesByGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summary Of Workers (Males - Females) By Governorate"])},
    "TotalOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Open"])},
    "TotalClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Closed"])},
    "TotalTypical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Typical"])},
    "TotalStudentsMalesFemalesByGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Summary Of Students (Males - Females) By Governorate"])},
    "TotalMalesStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Students (Males)"])},
    "TotalFemalesStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Students (Females)"])},
    "NumberOfSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Of Centers"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "WorkersMalesFemales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workers (Males - Females)"])},
    "StudentsMalesFemales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students (Males - Females)"])},
    "GrandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grand Total"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "PrintedCurricula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curricula Delivered to Summer Center"])},
    "ClassesNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes Names"])},
    "StatisticBySchoolType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistic By School Type"])},
    "OverallSummaryForNumberOfSchoolsre2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall Summary For Number Of Centers (report 2)"])},
    "SummerAllDetailedSummaryre2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer All Detailed Summary report 2"])},
    "OverallSummaryForNumberOfSchools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall Summary For Summer Centers"])},
    "StatisticsSchoolsAndStudentByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics Centers And Student By Type"])},
    "SummerAllDetailedSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer All Detailed Summary"])},
    "SummerSchoolDetailedSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Detailed Summary"])},
    "SummerWorkerDetailedSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Worker Detailed Summary"])},
    "SummerStudentDetailedSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Student Detailed Summary"])},
    "SummerActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Activities"])},
    "InDirectorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Directorate"])},
    "InGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Governorate"])},
    "InTheGovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In The Governorate"])},
    "InGovernorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Governorates"])},
    "ATotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Of"])},
    "Authorities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorities"])},
    "the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the"])},
    "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "ChooseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Choose Item"])},
    "UsersReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users Report"])},
    "SchoolReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Report"])}
  },
  "statistics": {
    "statistics-pushed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics have been uploaded"])},
    "check-up-statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check up summer center statistics "])},
    "summer-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer Center Statistics"])},
    "center-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center Statistic"])},
    "levels-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels Statistic"])},
    "students-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Statistic"])},
    "activities-statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities Statistic"])},
    "male-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male students count"])},
    "female-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female students count"])},
    "male-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male summer school count"])},
    "female-summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female summer school count"])},
    "male-teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male teachers count"])},
    "female-teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female teachers count"])},
    "male-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attended male workers count"])},
    "female-employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attended female workers count"])},
    "trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainers count"])},
    "managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managers count"])},
    "activity-responsibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity responsibles count"])},
    "martyrs-sons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martyrs' sons count"])},
    "martyrs-daughters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martyrs' daughters count"])},
    "official-visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Official visits count"])},
    "supervision-visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervision visits count"])},
    "community-visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community visits count"])},
    "committee-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committees members count"])},
    "recive-approch-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recived curreculam count"])},
    "activaty-group-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activaty groups count"])},
    "group-activaty-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group activaties count"])},
    "activity-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities count"])},
    "dir_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all summer schools statistics of this directorate have been saved"])},
    "gov_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all summer schools statistics of this governorate have been saved"])},
    "all_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all summer schools statistics have been saved"])},
    "sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync"])},
    "no-need": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this summer school dosen't need to insert its statistics"])}
  },
  "log-entries": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "action-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action Date"])},
    "content-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Type"])},
    "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "ip-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Address"])},
    "browser-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser Info"])},
    "os-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["os Info"])},
    "login-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Time"])},
    "logout-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout Time"])},
    "attempt-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempt Time"])},
    "failures-since-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failures Since Start"])},
    "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["browser"])},
    "os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["os"])}
  }
}
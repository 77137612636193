<template>
  <!-- filter data  -->
  <v-card flat class="justify-center" id="lectures-report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("report.material-statistics") }}</div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1"></div>
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0"
            style="min-width: 25px"
            @click="printTable"          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0" rounded="0" flat>
          <table  class="report-t" >
            <thead >
              <th style="width:50px">{{ $t("globals.#") }}</th>
              <th >{{ $t("summer.type-statistic") }}</th>
              <span  >
                <th style="width: 200px">
                  {{ $t("summer-sidebar.supplies-name") }}
                </th>
                <th  style="width: 20%">
                  {{ $t("report.quantity") }}
                </th>
                <th  style="width: 20%">
                  {{ $t("report.company") }}
                </th>
                <th  style="width: 20%">
                  {{ $t("summer.provider") }}
                </th>
                <th  style="width: 20%">
                  {{ $t("financial.date") }}
                </th>
              </span>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td>{{index+1}}</td>
                <td >{{ item.type }}</td>
                <template v-if="item.data.length">
                  <tr v-for="(val, key) in item.data" :key="key" style="width: 800px">
                    <td class="text-center " style="width: 200px">
                      {{ val.name }}
                    </td>
                    <td class="text-center " style="width: 20%">
                      {{ val.amount ? val.amount : "-" }}
                    </td>
                    <td class="text-center " style="width: 20%">
                      {{ val.company ? val.company : "-" }}
                    </td>
                    <td class="text-center " style="width: 20%">
                      {{ val.provider }}
                    </td>
                    <td class="text-center " style="width: 20%">
                      {{ val.date ? val.date : "-" }}
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center " style="width: 200px">-</td>
                    <td class="text-center " style="width: 20%">-</td>
                    <td class="text-center " style="width: 20%">-</td>
                    <td class="text-center " style="width: 20%">-</td>
                    <td class="text-center " style="width: 20%">-</td>
                  </tr>
                </template>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getData();
      this.current_year = this.current_year_name;
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },

      current_year: "",
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "write_yes_or_no"],
      headers: [
        { title: "#", key: "id" },
        { title: "#", key: "id" },
        { title: "#", key: "id" },
        { title: "#", key: "id" },
        { title: "#", key: "id" },
        { title: this.$t("summer.report.task-name"), key: "name" },
        { title: this.$t("summer.report.write-yes-or-no"), key: "write_yes_or_no" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      current_year_name: (state) => state.current_year_name,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({}),
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/supplies`, {
          params: {
            search: this.txt_search,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>


<template>
  <v-expansion-panels class="mb-2" >
    <v-expansion-panel   >
      <v-expansion-panel-title class="px-4">
          <span>{{$t('globals.select-criteria')}}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text >
        <v-row class="py-2">
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="visitor.visitortype"
              density="compact"
              item-title="name"
              item-value="key"
              prepend-inner-icon="mdi-account-outline"
              clearable
              hide-details
              :items="type_statistics"
              :label="$t('summer.visitors.type-visitor')"
            />
          </v-col>
          <v-col cols="12" md="2" class="mt-2">
            <v-btn :loading="loading" @click="getvisitor((page = 1)),loading=true" class="bg-indigo" size="small">
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
              <!-- <v-icon icon="mdi-content-save" color="white" end></v-icon> -->
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <VCard class="mb-2 pa-0" v-if="$checkPermissions('summer_center.view_visitor',['sys_admin','summer_admin','user'])">
    <v-card-text>
    <!-- ######## Header Of Data Table  ####### -->
      <div class="d-flex justify-space-between mb-2">
        <div class="d-flex flex-fill">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <div style="width: 100%">
            <v-text-field
              color="indigo"
              v-model="txt_search"
              @input="getvisitor((page = 1))"
              style="
                border-radius: 30px;
                border: 1px solid #ccc;
                color: black;
                height: 30px;
                width: 100%;
              "
              class="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              :placeholder="$t('globals.search-here')"
              density="compact"
              variant="text"
            >
            </v-text-field>
          </div>
          <div class="d-flex justify-end" style="flex: 1">
            <VBtn
              v-if="$checkPermissions('summer_center.add_visitor',['sys_admin','summer_admin','user'])"
              class="bg-success"
              @click="addvisitor"
              size="small"
            >
              <span class="text-white">{{ $t("globals.create") }}</span>
              <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
            </VBtn>
          </div>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table-server
        :loading="loading"
        :headers="selectedHeaders"
        :items="tableList"
        v-model:sort-by="sortBy"
        item-value="title"
        item-key="id"
        id="vDataTable"
        fixed-footer
        density="compact"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <span v-if="key === 'visitortype'"> 
                  <v-span v-if="val === true">جهة مساندة</v-span>
                  <v-span v-if="val === false">زيارة</v-span>
              </span>
              <span v-else-if="key === 'actions'">
                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="editvisitor(item.raw)"
                  v-if="$checkPermissions('summer_center.change_visitor',['sys_admin','summer_admin','user'])"
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  
                  variant="text"
                  size="small"
                  class="v-btn-icon"
                  @click="(dialogVisible = true), (this.delete_id = item.raw.id)"
                  v-if="$checkPermissions('summer_center.delete_visitor',['sys_admin','summer_admin','user'])"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
              <span v-else> {{ val }}</span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="pagination.num_pages"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ pagination.count }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </template>
      </v-data-table-server>
    </v-card-text>
  </VCard>
  <delete-dialog
    v-model="dialogVisible"
    :loading="loading_delete"
    @confirm-delete="deletevisitor()" 
  ></delete-dialog>
  
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
export default {
  data() {
    return {
      loading:false,
      loading_delete:false,
      homeworks: [],
      visitors: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      visitor: {
        name_ar: undefined,
        adjective: "",
        impression: "",
        recommendation: "",
        evaluation: "",
        occasion: undefined,
        entity: undefined,
        date: undefined,
        fk_week: undefined,
        data_entry: undefined,
        updated_by: undefined,
      },
      type_statistics: [
        { key: 0, name: "زيارة", is_visior: false },
        { key: 1, name: "جهة مساندة", is_visior: true },
        // { key: 3, name: "الكل" },
      ],
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      is_visior: true,
      rules: {
        name_ar: [(value) => !!value || this.$t("globals.required_field")],
        week: [(value) => !!value || this.$t("globals.required_field")],
        occasion: [(value) => !!value || this.$t("globals.required_field")],
        date: [(value) => !!value || this.$t("globals.required_field")],
        is_visior: [(value) => !!value || this.$t("globals.required_field")],

        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,
      visitType: 3,
      v$: useValidate(),
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      adj_entity: "adjective",
      selectedHead: [
        "name_ar",
        "entity_name",
        "visitortype",
        "date",
        "evaluation",
        "actions",
      ],
      headers: [
        { title: this.$t("summer.visitors.name-visitor"), key: "name_ar" },
        {
          title: this.$t("summer.visitors.entity-visitor"), key: "entity_name", sortable: false,
        },
        { title: this.$t("summer.visitors.type-visitor"), key: "visitortype" },
        { title: this.$t("summer.visitors.date-visitor"), key: "date" },
        { title: this.$t("summer.visitors.occasion"), key: "occasion" },
        { title: this.$t("summer.visitors.adjective-visitor"), key: "adjective" },
        { title: this.$t("summer.visitors.impression"), key: "impression" },
        { title: this.$t("summer.visitors.recommendations"), key: "recommendation" },
        { title: this.$t("summer.visitors.evaluation"), key: "evaluation" },
        { title: this.$t("globals.note"), key: "note",sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      txt_search: undefined,
    };
  },
  components: { Datetime },
  computed: {
    statisticdata() {
      if (this.visitType == 1 || this.visitType == 2) {
        return this.statistics.filter((el) => el.type == this.visitType);
      } else {
        return this.statistics;
      }
    },
    ...mapState({
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
    }),
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.visitors) {
        return JSON.parse(JSON.stringify(this.visitors));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getWeeks();
      await this.getvisitor()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
    }),
    getStartWeek(e) {
      let date = e;
      date = this.toEnglishString(e);
      this.visitor.date = date;
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.visitor = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async deletevisitor() {
      if (this.delete_id) {
        this.loading_delete = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/visitor/" + this.delete_id)
          .then((response) => {
            this.dialogVisible = false;
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.loading_delete = false;
          })
          .catch((error) => {
            this.dialogVisible = false;
            this.loading_delete = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.dialogVisible = false;
        this.delete_id = undefined;
        this.getvisitor();
      }
    },
    async getvisitor(page = 1) {
      this.DataLoading = true;
      await this.axios(`${this.base_url}/api/summer/visitor`, {
        params: {
          search: this.txt_search,
          page: page,
          fk_week: this.visitor.fk_week,
          visitortype: this.visitor.visitortype,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.visitors = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      }).catch((error)=>{
            this.loading=false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          setTimeout(()=>{
              this.showAlert=false
          },1500)
      });

    },
    editvisitor(item) {
      this.$router.push(`${item.id}/edit/`);
    },
    addvisitor() {
      this.$router.push({ name: "add-visitor" });
    },
  },
  watch: {
    perPage() {
      this.getvisitor();
    },
    page() {
      this.getvisitor(this.page);
    },
    order_data(){
      this.getvisitor(this.page);
    }
  },
};
</script>

<style scoped>

</style>

<template>
  <v-card class="pb-3 px-2 mb-2">
    <v-card-title>{{ $t("globals.select-criteria") }}</v-card-title>
    <v-row class="py-2">
      <v-col cols="12" md="3">
        <DatetimePicker
          v-model="date"
          :calendar="'hijri'"
          :placeholder="$t('globals.year-month-day')"
          :label="$t('globals.date')"
          @update:modelValue="date?.length > 9 ? getData() : null"
        ></DatetimePicker>
      </v-col>
    </v-row>
  </v-card>
  <v-card :loading=" date?loading:null" :disabled=" date?loading:null">
    <DataTable
      fixed-header
      :height="user_sessions?.length > 10 ? 400 : null"
      :items="user_sessions"
      :headers="headers"
      density="compact"
      :method="getData"
      :pagination="pagination"
    >
      <template #item.ip_address="{ item }">
        <v-chip  color="indigo">
          {{item.value.ip_address}}
          <template v-slot:append>
            <v-icon color="indigo">mdi-circle-medium</v-icon>
          </template>
        </v-chip>
      </template>
      <template #item.browser_info="{ item }">
        <v-chip  color="warning">
          {{item.value.browser_info}}
          <template v-slot:append>
            <v-icon color="warning">mdi-circle-medium</v-icon>
          </template>
        </v-chip>
      </template>
      <template #item.os_info="{ item }">
        <v-chip  color="success">
          {{item.value.os_info}}
          <template v-slot:append>
            <v-icon color="success">mdi-circle-medium</v-icon>
          </template>
        </v-chip>
      </template>
    </DataTable>
  </v-card>

</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getData();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      loading: false,
      user_sessions: [],
      headers: [
        { title: this.$t("log-entries.user"), key: "user" },
        { title: this.$t("log-entries.ip-address"), key: "ip_address" },
        { title: this.$t("log-entries.browser-info"), key: "browser_info" },
        { title: this.$t("log-entries.os-info"), key: "os_info" },
        { title: this.$t("log-entries.login-time"), key: "login_time" },
        { title: this.$t("log-entries.logout-time"), key: "logout_time" },
      ],
      search: null,
      date: null,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
   
  },
  methods: {
    ...mapActions({}),
    async getData(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/user-sessions`, {
        params: {
          // page: page,
          // page_size:per_page,
          txt_search: search,
          order_by: ordering,
          date: this.date,
        },
      })
        .then((response) => {
          this.loading = false;
          this.user_sessions = response.data.results;
          this.pagination = response.data.pagination;
          this.itemsPerPage[4].value = this.pagination.count;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
  
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span {
  font-size: 11px;
}
tbody td {
  font-size: 11px;
}
</style>

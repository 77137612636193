<template>
  <v-container>
    <v-row>
      <h1>المراكز المفتوحة</h1>
    </v-row>
    <v-row class="mt-8">
      <table class="table text-center">
        <thead>
          <tr>
            <th class="right-header">المحافظة</th>
            <th class="">إجمالي المراكز حسب الخطة</th>
            <th class="right-header">عدد المراكز المفتوحة</th>
            <th class="">الفارق</th>
            <th class="right-header">عدد الطلاب حسب الخطة</th>
            <th class="">إجمالي الطلاب والطالبات</th>
            <th class="right-header">الفارق</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>أب</td>
            <td>3</td>
            <td>4</td>
            <td>1</td>
            <td>600</td>
            <td>254</td>
            <td>26</td>
          </tr>
          <tr>
            <td>صنعاء</td>
            <td>3</td>
            <td>4</td>
            <td>1</td>
            <td>600</td>
            <td>254</td>
            <td>26</td>
          </tr>
          <tr>
            <td>ذمار</td>
            <td>3</td>
            <td>4</td>
            <td>1</td>
            <td>600</td>
            <td>254</td>
            <td>26</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="right-footer rounded-be">المجموع الكلي</th>
            <th class="rounded-bs">3543</th>
            <th class="rounded-bs">3543</th>
            <th class="rounded-bs">3543</th>
            <th class="rounded-bs">3543</th>
            <th class="rounded-bs">3543</th>
            <th class="rounded-bs">3543</th>
          </tr>
        </tfoot>
      </table>
    </v-row>
    <v-row class="mt-16">
      <v-col cols="12" md="12" outlined>
        <v-card class="pt-3 px-1" id="card" style="height: 400px">
          <!-- :style="{height:$vuetify.display.xs?'600px':'300px'}" -->
          <!-- :option="$vuetify.display.xs?option_3:option_10" -->
          <div id="schoolTotalMF"></div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as echarts from "echarts";
;


export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  async mounted() {
    // await this.fetchData();
    this.option_10 = {
      color: ["#445069", "#ff5252", "#536dfe", "#FFD95A"],
      title: {
        right: "center",
        textStyle: {
          fontFamily: "Almarai",
          fontSize: 18,
        },
        subtextStyle: {
          fontFamily: "Almarai",
          fontSize: 10,
        },
      },
      dataset: [
        {
          dimensions: [
            "name",
            "عدد المدارس النموذجية",
            "عدد المدارس  المفتوحة حاليا",
          ],
          source: [
            ["ttt ", "12", "52"],
            ["أب", "12", "52"],
            ["البيضاء", "123", "52"],
          ],
        },
        {
          transform: {
            type: "sort",
            config: { dimension: "constant", order: "desc" },
          },
        },
      ],
      xAxis: {
        type: "category",
        // data: this.results.map((item) => item.governorat.name_ar),
        axisLabel: {
          interval: 0,
          rotate: 60,
        },
      },
      yAxis: {
        type: "value",
        position: this.$i18n.locale == "ar" ? "right" : "",
      },
      series: [
        {
          type: "bar",
          barWidth: 8,
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            textStyle: {
              fontSize: 8,
            },
            offset: [5, 5],
          },
          // data: this.results.map((item) => {
          //   return { value: item.dataLevel.count };
          // }),
        },
        {
          type: "bar",
          barWidth: 8,
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            textStyle: {
              fontSize: 8,
            },
            offset: [5, 5],
          },
          // data: this.results.map((item) => {
          //   return { value: item.dataLevel.count };
          // }),
        },
      ],
      legend: {
        itemHeight: 12,
        itemWidth: 19,
        borderWidth: 1,
        borderColor: "black",
        bottom: 20,
        // data: ["صنعاء", "مارب", "عدن", "صعدة"],
      },
      grid: {
        bottom: "20%",
        containLabel: true,
      },

      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
    };

    this.printChart();
    const appIconMenu = document.getElementById("appIconMenu");
    appIconMenu.addEventListener("click", () => {
      // this.printChart()
      const chartElement = document.getElementById("schoolTotalMF");
      const chart = echarts.getInstanceByDom(chartElement);
      setTimeout(() => {
        const element = document.getElementById("card");
        chart.resize({
          width: element.offsetWidth,
          height: element.offsetHeight,
        });
      }, 10);
    });
    window.addEventListener("resize", this.printChart);
  },
  methods: {
    async fetchData() {
      await  this.axios
        .get("http://127.0.0.1:8070/public-reports/summer-level-students")
        .then((response) => {
          this.results = response.data.results;
        })

        .catch((error) => {
        });
    },
    printChart() {
      const chartElement = document.getElementById("schoolTotalMF");
      const chart = echarts.init(chartElement);
      chart.setOption(this.option_10);
      const element = document.getElementById("card");
      chart.resize({
        width: element.offsetWidth - 12,
        height: element.offsetHeight,
      });
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      statistics: [],
      option_10: {},
      size: null,
      results: [],
    };
  },
  computed: {
    
  },
};
</script>

<style scoped>
.table td {
  border: 0.5px solid #bebebe;
}
.table th {
  background-color: #2a7379;
  /* background:linear-gradient(30deg, #78D6C6, #2a7379, #01243b) ; */
  color: white;
}
</style>

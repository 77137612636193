<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.statistics-types-tenters-students-type") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
         <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>

      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pl-0 pr-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
            {{ $t("reportpublic.statistics-types-tenters-students-type") }}
              {{ $t("reportpublic.InDirectorate") + " " }}
              {{
                this.$i18n.locale == "ar"
                  ? results.directorate_name_ar
                  : results.directorate_name_en
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ results.total_summers }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
      <v-card-item v-if="hasResults">
          <table class="report-t">
            <thead>
              <tr>
                <th style="width:50px">{{$t('globals.#')}}</th>
                <th>{{ $t("reportpublic.CenterName") }}</th>
                <th>{{ $t("reportpublic.summer_type") }}</th>
                <th>{{ $t("reportpublic.students_type") }}</th>
                <th>{{ $t("reportpublic.MaleStudents") }}</th>
                <th>{{ $t("reportpublic.FemaleStudents") }}</th>
                <th>{{ $t("reportpublic.MalesWorkers") }}</th>
                <th>{{ $t("reportpublic.FemalesWorkers") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(val, key) in results.data" :key="key">
                <td>{{key+1}}</td>
                <td>
                  {{
                    this.$i18n.locale == "ar"
                      ? val.summer_name_ar
                      : val.summer_name_en
                  }}
                </td>
                <td>{{ val.school_type }}</td>
                <td >{{ val.students_type }}</td>
                <td class="text-center">{{ val.total_student_male }}</td>
                <td class="text-center">{{ val.total_student_fmale }}</td>
                <td class="text-center">{{ val.total_employee_male }}</td>
                <td class="text-center">{{ val.total_employee_fmale }}</td>
              </tr>
            </tbody>
            <tfoot>
             <tr>
                <th  colspan="4" >{{ $t("globals.total") }}</th>
                <th>{{totals?.total_s_male }}</th>
                <th>{{ totals?.total_s_fmale }}</th>
                <th>{{ totals?.total_e_male }}</th>
                <th>{{ totals?.total_e_fmale }}</th>
              </tr>
              <tr>
                <th  colspan="4" >{{ $t("reportpublic.GrandTotal") }}</th>
                <th colspan="2">{{totals?.total_s }}</th>
                <th colspan="2">{{totals?.total_e }}</th>

              </tr>
            </tfoot>
          </table>
 
     
    </v-card-item>
     <v-card-item   v-else>
 <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
     </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
   async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async printTable(all) {
      if (all) {
         await this.fetchData();
      }
      window.print();
    },
    async fetchData() {
      await  this.axios
        .get(`${this.base_url}public-reports/directorate-reports/statistics-by-schools-type-student-type`)
        .then((response) => {
          this.results = response.data.results;
          this.totals = response.data.results.alltotal;
          if(this.results.data.length >0)
           this.hasResults = true
          else  this.hasResults = false 
        })
    },
  },
  data() {
    return {
      results: [],
      hasResults :false
    };
  },
  
  components: {
    ReportHeader,
  },
};
</script>
<style scoped>
.report-table thead th {
  padding-inline: 2px;
}
</style>

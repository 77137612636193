<template>
  <v-card class="mb-3 sort-hiden" v-show="!isExists">
      <v-form @submit.prevent>
        <v-expansion-panels model-value="1">
          <v-expansion-panel  value="1">
            <v-expansion-panel-title class="px-4">
              <v-icon icon="mdi-sort-calendar-ascending"></v-icon>
              <span class="mx-2">{{ $t("globals.select-others-criteria") }}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <VRow style="align-items: center" class="mb-1">
                <v-col cols="6" md="3" sm="4">
                  <v-autocomplete
                    color="indigo"
                    v-model="type"
                    density="compact"
                    item-title="type"
                    item-value="id"
                    hide-details="auto"
                    prepend-inner-icon="mdi-calendar-week"
                    :items="typeList"
                    :label="$t('summer.summer_type')"
                  />
                </v-col>
                <v-col cols="6" md="3" sm="4">
                  <v-autocomplete
                    color="indigo"
                    v-model="from_date"
                    :items="calendar"
                    density="compact"
                    item-title="date"
                    item-value="date"
                    prepend-inner-icon="mdi-calendar-week"
                    clearable
                    hide-details="auto"
                    :label="$t('globals.from')"
                  />
                </v-col>
                <v-col cols="6" md="3" sm="4">
                  <v-autocomplete
                    color="indigo"
                    v-model="to_date"
                    :items="calendar"
                    density="compact"
                    item-title="date"
                    item-value="date"
                    hide-details="auto"
                    prepend-inner-icon="mdi-calendar-week"
                    clearable
                    :label="$t('globals.to')"
                  />
                </v-col>
                <v-col cols="6" md="2">
                  <VBtn
                    class="bg-indigo"
                    type="submit"
                    @click="fetchData()"
                    size="small"
                    :loading="loading"
                  >
                    <span class="text-white">
                      {{ $t("globals.show") }}
                    </span>
                    <VIcon icon="mdi-presentation" color="white" end></VIcon>
                  </VBtn>
                </v-col>
              </VRow>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-progress-linear
          color="indigo"
          v-if="loading"
          indeterminate
          height="2px"
          :reverse="$i18n.locale == 'ar'"
        >
        </v-progress-linear>
      </v-form>
  </v-card>
  <v-card id="report" v-show="isExists">
      <ReportHeader class="pb-0">
        <template v-slot:header-title>
          <div class="d-flex justify-center py-2">
            <div class="text-h5">
              {{
                $t("reportpublic.VariousActivitiesDuringTheSummerCentersPeriod2")
              }}
            </div>
          </div>
        </template>
      </ReportHeader>
      <v-card-title>
        <div class="d-flex justify-space-between mb-2" id="tableHead">
          <div class="d-flex justify-end" style="flex: 1">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  density="compact"
                  class="pa-0 mx-1"
                  style="min-width: 25px"
                  @click="printTable()"
                >
                  <v-icon color="icon-color">mdi-printer</v-icon>
                  <v-tooltip class="tooltip" activator="parent" location="top">
                    <small>{{ $t("globals.print-file") }}</small>
                  </v-tooltip>
                </v-btn>
              </template>
            </v-menu>
            <v-btn density="compact" class="mx-1" @click="isExists = !isExists">
              <span class="text-indigo">{{ $t("globals.back") }}</span>
            </v-btn>
            
          </div>
        </div>

        <v-container>
          <v-row align="center">
            <v-col cols="auto" class="px-0">
              <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
                {{ $t("reportpublic.TotalActivitiesLabel") }}
                {{ $t("reportpublic.InDirectorate") }}
                {{
                  this.$i18n.locale == "ar"
                    ? directorate_name_ar
                    : directorate_name_ar
                }}
              </v-sheet>
            </v-col>
            <v-col class="pa-0" cols="auto">
              <v-sheet
                :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
                height="25"
              >
                <h4>{{ }}</h4>
              </v-sheet>
            </v-col>
            <v-col cols="max" align-self="center" class="pa-0">
              <v-divider
                :thickness="1"
                class="border-opacity-100"
                color="black"
              ></v-divider>
            </v-col>
            <v-sheet
              color="black"
              height="6"
              width="6"
              style="border-radius: 50%"
            >
            </v-sheet>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-item>
        <activites-chart :data="data" :title="'احصائيات تنفيذ الانشطة'" :subtitle="'انواع الانشطة المنفذة'"></activites-chart>
      </v-card-item>
  </v-card>
</template>
<script>
;import { mapState} from "vuex";
import ActivitesChart from "@/components/reports/ActivitiesOperationsStatistics.vue";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  components: {
    ActivitesChart,
    ReportHeader,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getCalendar();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
 
      directorate_name_ar:null,
      directorate_name_en:null,
      data:[],
      type:null,
      loading:false,
      isExists:false,
      from_date:null,
      to_date:null,
      calendar:[],
      
    };
  },  
  methods: {
    async fetchData() {
      this.loading=true
      await  this.axios
        .get(`${this.base_url}public-reports/directorate-reports/activities`, {
          params:{        
            directorate_id: this.user_directorate,
            type:this.type,
            from_date:this.from_date,
            to_date:this.to_date
          }})
        .then((response) => {
          
          this.directorate_name_ar=response.data?.results?.directorate_name_ar??''
          this.directorate_name_en=response.data?.results?.directorate_name_en??''
          this.data=response.data?.results?.details?.map(item=>{
            return{
              name:item.activity_name,
              value:item.activity_count
            }
          })??[]
          this.loading=false
          this.isExists=true
        })
        .catch((error) => {
          this.loading=false
          this.isExists=true
        });
    },
    async getCalendar() {
      await  this.axios
        .get(`${this.base_url}api/summer/calendar`)
        .then((response) => {
          this.calendar=response.data.results
        })
        .catch((error) => {
        });
    },
    async printTable() {
      window.print();
    },
  },
  computed: {
    ...mapState({
      user_directorate: (state) => state.user_directorate,
    }),
  }
};
</script>

<template>
  <VListGroup :value="$t('report.summer-center')" :fluid="false" v-if="checkRole(['summer_admin', 'user'])" >
    <template v-slot:activator="{ props }">
      <VListItem
        v-bind="props"
        min-height="40"
        
      >
        <template v-slot:title>
          <span class="v-select-title">{{ $t("report.summer-center") }}</span>
        </template>
        <template v-slot:prepend>
          <v-tooltip :text="$t('report.summer-center')">
            <template v-slot:activator="{ props }">
              <v-icon v-show="expand == true" v-bind="props">
                mdi-chart-pie
              </v-icon>
              <v-icon v-show="expand == false">mdi-chart-pie </v-icon>
            </template>
          </v-tooltip>
        </template>
      </VListItem>
    </template>
    <VListGroup :value="$t('summer-report.general-report')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.general-report") }}</span>
          </template>
        </VListItem>
      </template>
    
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'summer-center-data' }"
        :value="$t('summer-report.summer-center-data')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.summer-center-data")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'approach-statistics' }"
        :value="$t('summer-report.approach-statistics-by-level')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.approach-statistics-by-level")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'supplies-statistic' }"
        :value="$t('summer-report.material-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.material-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employees-by-role' }"
        :value="$t('summer-report.employee-available-by-level')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.employee-available-by-level")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'clender-report' }"
        :value="$t('summer-report.school-calendar')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.school-calendar")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'vacations' }"
        :value="$t('summer-report.occasion')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.occasion")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'support-Visitors-Entity-final' }"
        :value="$t('summer-report.supporting-entity-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.supporting-entity-statistics")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup :value="$t('summer-report.students-reports')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.students-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'students-info' }"
        :value="$t('summer-report.student-personal-data')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.personal-data")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'talents' }"
        v-if="
          $checkPermissions('summer_center.change_talent') ||
          $checkPermissions('summer_center.add_talent') ||
          $checkPermissions('summer_center.view_talent')
        "
        :value="$t('summer-report.students-talents')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.students-talents")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'talented_students' }"
        v-if="$checkPermissions('summer_center.view_studentsummer')"
        :value="$t('summer-sidebar.talented-students')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-sidebar.talented-students")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'dropout_students' }"
        v-if="$checkPermissions('summer_center.view_studentsummer')"
        :value="$t('summer-sidebar.dropout-students')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-sidebar.dropout-students")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'droupout-report' }"
        :value="$t('summer-report.walkout-students-by-reason')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.walkout-students-by-reason")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'students-statistics' }"
        :value="$t('summer-report.students-statistics-by-level')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.students-statistics-by-level")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'student-marks-reports' }"
        :value="$t('summer-report.students-marks-sheet')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.students-marks-sheet")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-initiative-suscriber' }"
        :value="$t('summer-report.initiatives-participant')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.initiatives-participant")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup :value="$t('summer-report.lectures-timetable')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.lectures-timetable") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        v-if="$checkPermissions('summer_center.view_marksummer')"
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'schedule-summer' }"
        :value="$t('summer-report.weekly-lectures-timetable')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.weekly-lectures-timetable")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup :value="$t('summer-report.teachers-reports')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.teachers-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'teachers-data' }"
        :value="$t('summer-report.teacher-personal-data')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.personal-data")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-attendance-report' }"
        :value="$t('summer-report.employee-attendance')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.employee-attendance")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'visitors-report' }"
        :value="$t('summer-report.visits-reports')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.visits-reports")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-initiative' }"
        :value="$t('summer-report.initiatives-reports')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.initiatives-reports")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup :value="$t('summer-report.activity-reports')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.activity-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-activities-by-type-activity' }"
        :value="$t('summer-report.summer-center-activities')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.summer-center-activities")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'weekly-activities' }"
        :value="$t('summer-report.weekly-activity-schedule')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.weekly-activity-schedule")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'students-by-Type-activity' }"
        :value="$t('summer-report.activity-participated-student')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.activity-participated-student")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-details-activities' }"
        :value="$t('summer-report.activity-full-data')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.activity-full-data")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup :value="$t('summer-report.weeekly-reports')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.weeekly-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-discipline-week' }"
        :value="$t('summer-report.workers-displine-level')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.workers-displine-level")
          }}</span>
        </template>
      </VListItem>
      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-discipline-portfolio-by-week' }"
        :value="$t('report.staff-meeting-minutes')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("report.staff-meeting-minutes")
          }}</span>
        </template>
      </VListItem> -->
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'students-statistics-week' }"
        :value="$t('summer-report.students-count-statistic')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.students-count-statistic")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'droupout-report-week' }"
        :value="$t('summer-report.student-statistics-by-leaking')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.student-statistics-by-leaking")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'approach-statistics-week' }"
        :value="$t('summer-report.curreculam-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.curreculam-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'schedule-statistic-by-level' }"
        :value="$t('summer-report.lectures-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.lectures-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-activities-by-week-type' }"
        :value="$t('summer-report.actiivty-excution-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.actiivty-excution-statistics")
          }}</span>
        </template>
      </VListItem>
      
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'management-statistic-by-week' }"
        :value="$t('summer-report.management-tasks-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.management-tasks-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'positives-negatives' }"
        :value="$t('summer-report.positives-negatives')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.positives-negatives")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'student-attendance-report' }"
        :value="$t('summer-report.attendance')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.attendance")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup :value="$t('summer-report.final-reports')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.final-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-attendance-report-final' }"
        :value="$t('summer.report.employee-attendance-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer.report.employee-attendance")
          }}</span>
        </template>
      </VListItem>
      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-discipline-final' }"
        :value='$t("summer-report.teachers-statistics-final")'
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.teachers-statistics")
          }}</span>
        </template>
      </VListItem> -->
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-discipline-portfolio-final' }"
        :value="$t('report.staff-meeting-minutes-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("report.staff-meeting-minutes")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'students-statistics-final' }"
        :value="$t('summer-report.students-count-statistic-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.students-count-statistic")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'droupout-report-final' }"
        :value="$t('summer-report.student-statistics-by-leaking-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.student-statistics-by-leaking")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'approach-statistics-final' }"
        :value="$t('summer-report.curreculam-statistics-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.curreculam-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'schedule-statistic' }"
        :value="$t('summer-report.lectures-statistics-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.lectures-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-activities' }"
        :value="$t('summer-report.actiivty-excution-statistics-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.actiivty-excution-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'management-statistic' }"
        :value="$t('summer-report.management-tasks-statistics-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.management-tasks-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'positives-negatives-final' }"
        :value="$t('summer-report.positives-negatives-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.positives-negatives")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-discipline-final' }"
        :value="$t('summer-report.workers-displine-level-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.workers-displine-level")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup
      :value="$t('summer-report.committee-members-reports')"
      :fluid="false"
    >
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.committee-members-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'socail-committees' }"
        :value="$t('summer-report.committee-members')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.committee-members")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
  </VListGroup>
</template>

<script>
export default {
  props: {
    show: Boolean,
    expand: Boolean,
    direction: String,
    
  },
  computed: {
    checkRole() {
      return (role) => {
          return this.$store.getters.checkrole(role);
      };
    },
  },
};
</script>
<style scoped>
.prtl-5 {
  padding-right: 5px !important;
}
.prtl-10 {
  padding-right: 10px !important;
}
.prtl-15 {
  padding-right: 15px !important;
}
.prtl-20 {
  padding-right: 20px !important;
}
.prtl-25 {
  padding-right: 25px !important;
}
.prtl-30 {
  padding-right: 30px !important;
}
.prtl-40 {
  padding-right: 40px !important;
}
.prtl-45 {
  padding-right: 45px !important;
}
.prtl-50 {
  padding-right: 52px !important;
}
.prtl-60 {
  padding-right: 62px !important;
}
.pltr-5 {
  padding-left: 5px !important;
}
.pltr-10 {
  padding-left: 10px !important;
}
.pltr-15 {
  padding-left: 15px !important;
}
.pltr-20 {
  padding-left: 20px !important;
}
.pltr-25 {
  padding-left: 25px !important;
}
.pltr-30 {
  padding-left: 30px !important;
}
.pltr-40 {
  padding-left: 40px !important;
}
.pltr-45 {
  padding-left: 45px !important;
}
.pltr-50 {
  padding-left: 52px !important;
}
.pltr-60 {
  padding-left: 62px !important;
}
</style>

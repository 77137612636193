<template>
  <VListGroup
    :value="$t('report.governorate-report')"
    :fluid="false"
    v-if="checkRole(['gov_admin','gov_financial'])"
    class="border-bottom"
  >
    <template v-slot:activator="{ props }">
      <VListItem v-bind="props" min-height="40">
        <template v-slot:title>
          <span class="v-select-title">{{
            $t("report.governorate-report")
          }}</span>
        </template>

        <template v-slot:prepend>
          <v-tooltip :text="$t('report.governorate-report')">
            <template v-slot:activator="{ props }">
              <v-icon v-show="expand == true" v-bind="props">
                mdi-chart-pie
              </v-icon>
              <v-icon v-show="expand == false"> mdi-chart-pie </v-icon>
            </template>
          </v-tooltip>
        </template>
      </VListItem>
    </template>
    <VListItem
      :to="{ name: 'school-report-gov' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SchoolReport')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SchoolReport")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'users-report-gov' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.UsersReport')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.UsersReport")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'summer-center-number-student-employees' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.the_total_number_student_employees')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.the_total_number_student_employees")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'talented_students' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('summer-sidebar.talented-students')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("summer-sidebar.talented-students")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'dropout_students' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('summer-sidebar.dropout-students')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("summer-sidebar.dropout-students")
        }}</span>
      </template>
    </VListItem>
     <VListItem
      :to="{ name: 'summer-center-student-data-report' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.summer_center_student_data')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.summer_center_student_data")
        }}</span>
      </template>
    </VListItem>
    
     <VListItem
      :to="{ name: 'summer-center-employees-data-report' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.summer_center_employee_data')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.summer_center_employee_data")
        }}</span>
      </template>
    </VListItem>
    
    <VListItem
      :to="{ name: 'centersGovernorateAffiliatedDirectorates' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.CentersForTheGovernorateAndItsAffiliates')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.CentersForTheGovernorateAndItsAffiliates")
        }}</span>
      </template>
    </VListItem>
      <VListItem
      :to="{ name: 'student-employee-present-actual' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      v-if="checkRole(['gov_admin','gov_financial'])"  
      :value="this.$t('reportpublic.data-student-employee-present-actual')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.data-student-employee-present-actual")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'feeding_report' }"
      v-if="checkRole(['gov_admin','gov_financial'])"
      :value="this.$t('summer-sidebar.feeding-report')"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t('summer-sidebar.feeding-report')
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'feeding_report_periods' }"
      v-if="checkRole(['gov_admin','gov_financial'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('summer-sidebar.feeding-report-periods')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t('summer-sidebar.feeding-report-periods')
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'centersTypeOpenClosedTypical' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="
        this.$t('reportpublic.ThePositionsForEachTypeAreTypicalOpenAndClosed')
      "
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.ThePositionsForEachTypeAreTypicalOpenAndClosed")
        }}</span>
      </template>
    </VListItem>
 
    <VListItem
      :to="{ name: 'totalSummaryOpenPositionsMFGovernorate' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalSummaryOpenPositionsMF')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalSummaryOpenPositionsMF")
        }}</span>
      </template>
    </VListItem>
    <!-- <VListItem
      :to="{ name: 'totalSummaryClosedPositionsMFGovernorate' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalSummaryClosePositionsMF')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalSummaryClosePositionsMF")
        }}</span>
      </template>
    </VListItem> -->
    <VListItem
      :to="{ name: 'totalSummaryTypicalPositionsMFGovernorate' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalSummaryTypicalPositionsMF')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{ this.$t("reportpublic.TotalSummaryTypicalPositionsMF") }}
        </span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'governorateWorkingStaff' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="
        this.$t('reportpublic.WorkingStaff') +
        ' ' +
        this.$t('reportpublic.InTheGovernorate')
      "
      v-if="checkRole(['gov_admin'])"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.WorkingStaff") +
          " " +
          this.$t("reportpublic.InTheGovernorate")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'schoolStatisticsTypeSchoolMFFirstReport' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SchoolStatisticsMFFirstReport')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SchoolStatisticsMFFirstReport")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'schoolStatisticsTypeSchoolMFSecondReport' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SchoolStatisticsMFSecondtReport')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{ this.$t("reportpublic.SchoolStatisticsMFSecondtReport") }}
        </span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'numberStudentsAccordingLevelsGovernorates' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.NumberOfStudentsByLevels')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.NumberOfStudentsByLevels")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'reportVisitsCarriedDuringDayGovernorates' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.VisitsCarriedOutDuringTheDay')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{ this.$t("reportpublic.VisitsCarriedOutDuringTheDay") }}
        </span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'overall-summary-for-number-of-schools' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.OverallSummaryForNumberOfSchools')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{ this.$t("reportpublic.OverallSummaryForNumberOfSchools") }}
        </span>
      </template>
    </VListItem>
    <!-- <VListItem
      :to="{ name: 'overall-summary-for-number-of-scholls-table' }" v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{ this.$t("reportpublic.OverallSummaryForNumberOfSchoolsre2") }}
        </span>
      </template>
    </VListItem> -->
    <VListItem
      :to="{ name: 'statistics-schools-and-student-by-type' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.StatisticsSchoolsAndStudentByType')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{ this.$t("reportpublic.StatisticsSchoolsAndStudentByType") }}
        </span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'summer-statistics-by-school-type' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerStatisticsBySchoolType')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{ this.$t("reportpublic.SummerStatisticsBySchoolType") }}
        </span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'workflow-in-summer' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerWorkProgress')"
      v-if="checkRole(['gov_admin'])"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{ this.$t("reportpublic.SummerWorkProgress") }}
        </span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'total-number-of-activities-carried-out' }"
      v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerTotalActivitiesCarriedOutHeader')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{ this.$t("reportpublic.SummerTotalActivitiesCarriedOutHeader") }}
        </span>
      </template>
    </VListItem>

    <!-- <VListItem
      :to="{ name: 'summer-center-statistics-governorate' }" v-if="checkRole(['gov_admin'])"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.StatisticsOfSummerCerntersInTheCapitalsSecretariatDistricts')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle"
          >{{$t(
                "reportpublic.StatisticsOfSummerCerntersInTheCapitalsSecretariatDistricts"
              )}}
        </span>
      </template>



    </VListItem> -->
  </VListGroup>
  <VListGroup
    :value="$t('report.daily')"
    v-if="checkRole(['gov_admin'])"
    :fluid="false"
  >
    <template v-slot:activator="{ props }">
      <VListItem v-bind="props" min-height="40">
        <template v-slot:title>
          <span class="v-select-title">{{ $t("report.daily") }}</span>
        </template>

        <template v-slot:prepend>
          <v-tooltip :text="$t('report.daily')">
            <template v-slot:activator="{ props }">
              <v-icon v-show="expand == true" v-bind="props">
                mdi-chart-pie
              </v-icon>
              <v-icon v-show="expand == false"> mdi-chart-pie </v-icon>
            </template>
          </v-tooltip>
        </template>
      </VListItem>
    </template>
    <VListItem
        :to="{ name: 'school_summer_attendance' }"
        :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
        :value="this.$t('summer.report.school-summer-attendance')"
      >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("summer.report.school-summer-attendance")
        }}</span>
      </template>
    </VListItem>
      <!-- <VListItem
      :to="{ name: 'dialy-summer-operations-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.daily_summer_operations_report')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.daily_summer_operations_report")
        }}</span>
      </template>
    </VListItem> -->

      <VListItem
      :to="{ name: 'student-employee-present-actual' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      
      :value="this.$t('reportpublic.data-student-employee-present-actual')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.data-student-employee-present-actual")
        }}</span>
      </template>
    </VListItem>
       <VListItem
      :to="{ name: 'activitiesInGovernorate' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.ActivitiesInTheGovernorate')"
      v-if="checkRole(['gov_admin'])"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.ActivitiesInTheGovernorate")
        }}</span>
      </template>
    </VListItem>
    <!-- <VListItem
      :to="{ name: 'CoursesForEachTypePublicStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.number-positions-each-TOC-typical')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.number-positions-each-TOC-typical")
        }}</span>
      </template>
    </VListItem> -->
    <!-- <VListItem
      :to="{ name: 'ActivitiesStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.ActivitiesInTheDirectorate2')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.ActivitiesInTheDirectorate2")
        }}</span>
      </template>
    </VListItem> -->
    <!-- <VListItem
      :to="{ name: 'StudentByLevelsGovStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalStudentsByLevelForCenters2')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalStudentsByLevelForCenters2")
        }}</span>
      </template>
    </VListItem>  -->
     <!-- <VListItem
        :to="{ name: 'ActivityGovStatistics' }"
        :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
        :value="
          this.$t('reportpublic.SummerTotalActivitiesCarriedOutDuringDayHeader2')
        "
      >
        <template v-slot:title>
          <v-icon size="medium" class="me-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            this.$t(
              "reportpublic.SummerTotalActivitiesCarriedOutDuringDayHeader2"
            )
          }}</span>
        </template>
      </VListItem>   -->
    <VListItem
      :to="{ name: 'VisitorsGovStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.VisitsCarriedOutDuringTheDay2')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.VisitsCarriedOutDuringTheDay2")
        }}</span>
      </template>
    </VListItem>  
  
  </VListGroup>
</template>

<script>
export default {
  props: {
    show: Boolean,
    expand: Boolean,
    direction: String,
    
  },
  computed: {
    checkRole() {
      return (role) => {
          return this.$store.getters.checkrole(role);
      };
    },
  },
};
</script>
<style scoped>
.prtl-5 {
  padding-right: 5px !important;
}
.prtl-10 {
  padding-right: 10px !important;
}
.prtl-15 {
  padding-right: 15px !important;
}
.prtl-20 {
  padding-right: 20px !important;
}
.prtl-25 {
  padding-right: 25px !important;
}
.prtl-30 {
  padding-right: 30px !important;
}
.prtl-40 {
  padding-right: 40px !important;
}
.prtl-45 {
  padding-right: 45px !important;
}
.prtl-50 {
  padding-right: 52px !important;
}
.prtl-60 {
  padding-right: 62px !important;
}
.pltr-5 {
  padding-left: 5px !important;
}
.pltr-10 {
  padding-left: 10px !important;
}
.pltr-15 {
  padding-left: 15px !important;
}
.pltr-20 {
  padding-left: 20px !important;
}
.pltr-25 {
  padding-left: 25px !important;
}
.pltr-30 {
  padding-left: 30px !important;
}
.pltr-40 {
  padding-left: 40px !important;
}
.pltr-45 {
  padding-left: 45px !important;
}
.pltr-50 {
  padding-left: 52px !important;
}
.pltr-60 {
  padding-left: 62px !important;
}
</style>

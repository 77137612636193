<template>
  <v-card class="pb-3 px-2 mb-2">
    <v-card-title>{{ $t("globals.select-criteria") }}</v-card-title>
    <v-row class="py-2">
      <v-col cols="12" md="3">
        <VAutocomplete
          :label="$t('log-entries.action')"
          item-title="val"
          item-value="id"
          :items="[
            { id: 0, val: $t('permission.add') },
            { id: 1, val: $t('permission.change') },
            { id: 2, val: $t('permission.delete') },
          ]"
          type="data"
          v-model="action"
          persistent-hint
          density="compact"
          clearable
          hide-details="auto"
          @update:modelValue="getData()"
        ></VAutocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <VAutocomplete
          :label="$t('globals.content-type')"
          item-title="name"
          item-value="id"
          :items="contentTypes"
          v-model="contentTypeId"
          persistent-hint
          density="compact"
          clearable
          hide-details="auto"
          @update:modelValue="getData()"
        ></VAutocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <DatetimePicker
          v-model="date"
          :calendar="'hijri'"
          :placeholder="$t('globals.year-month-day')"
          :label="$t('globals.date')"
          @update:modelValue="date?.length > 9 ? getData() : null"
        ></DatetimePicker>
      </v-col>
    </v-row>
  </v-card>
  <v-card
    :loading="action || content_type || date ? loading : null"
    :disabled="action || content_type || date ? loading : null"
  >
    <DataTable
      fixed-header
      :height="log_entries?.length > 10 ? 400 : null"
      :items="log_entries"
      :headers="headers"
      density="compact"
      :expand="true"
      :method="getData"
      :pagination="pagination"
    >
      <template #item.object_repr="{ item }">
        {{ substring(item.value.object_repr, 40) }}
      </template>
      <template #item.action="{ item }">
        <v-chip v-if="item.value.action == 0" class="bg-indigo">
          {{ $t("permission.add") }}
        </v-chip>
        <v-chip v-else-if="item.value.action == 1" class="bg-success ">
          <span class="text-white">
            {{ $t("permission.change") }}
          </span>
        </v-chip>
        <v-chip v-else-if="item.value.action == 2" class="bg-error">
          {{ $t("permission.delete") }}
          
        </v-chip>
      </template>
      <template v-slot:expanded-row="{ columns, item }">
        <td :colspan="columns?.length" v-if="item.value.action==1">
          <v-card class="mx-4 my-1 border border-dashed"  elevation="0">
            <v-table density="compact">
              <thead>
                <tr>
                  <th>الحقل</th>
                  <th>قبل التعديل</th>
                  <th>بعد التعديل</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="change in Object.keys(item.value.changes)"
                  :key="change"
                >
                  <td>{{ change }}</td>
                  <td>{{ item.value.changes[change][0] }}</td>
                  <td>{{ item.value.changes[change][1] }}</td>
                </tr>
              </tbody>
            </v-table>
          </v-card>
          <v-divider></v-divider>
        </td>
      </template>
    </DataTable>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getData();
      await this.getUsersList();
      await this.getContentTypes();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      loading: false,
      log_entries: [],
      contentTypes: [],
      headers: [
        { title: this.$t("log-entries.user"), key: "user" },
        { title: this.$t("log-entries.content-type"), key: "content_type" },
        { title: this.$t("log-entries.record"), key: "object_repr" },
        { title: this.$t("log-entries.action-time"), key: "action_time" },
        { title: this.$t("log-entries.action"), key: "action" },
      ],
      action: null,
      search: null,
      date: null,
      contentTypeId: null,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions({}),
    async getData(page = 1, per_page = 10, ordering = null, search = null) {
      if(ordering=='action_time') ordering='timestamp';
      if(ordering=='-action_time') ordering='-timestamp';
      if(ordering=='id') ordering=null;
      this.loading = true;
      await this.axios(`${this.base_url}api/auditlog`, {
        params: {
          page: page,
          page_size: per_page,
          txt_search: search,
          order_by: ordering,
          action: this.action,
          content_type: this.contentTypeId,
          date: this.date,
        },
      })
        .then((response) => {
          this.loading = false;
          this.log_entries = response.data.results;
          this.pagination = response.data.pagination;
          this.itemsPerPage[4].value = this.pagination.count;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async getUsersList() {
      await this.axios(`${this.base_url}userdata/user-data/`).then(
        (response) => {
          this.users_list = response.data;
        }
      );
    },
    async getContentTypes() {
      await this.axios(`${this.base_url}api/content-types`).then((response) => {
        this.contentTypes = response.data;
      });
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span {
  font-size: 11px;
}
tbody td {
  font-size: 11px;
}
</style>

<template>
  <div>
    <div class="d-flex align-center">
      <v-img
        :src="captchaImage"
        class="border rounded "
        alt="CAPTCHA"
      ></v-img>
      <dir title="صورة اخرى">
        <v-icon
          @click="refreshCaptcha"
          size="30"
          color="grey"
          class="border rounded ms-1"
          >mdi-refresh</v-icon
        >
      </dir>
    </div>

    <v-text-field
      ref="captchaResponse"
      class="mt-2"
      :error-messages="errorMessage"
      v-model="captchaResponse"
      @input="
        () => {
          (captchaResponse = captchaResponse.toUpperCase()),
            (errorMessage = '');
        }
      "
      :placeholder="$t('globals.captcha-placeholder')"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      captchaImage: "",
      captchaKey: "",
      captchaResponse: "",
      errorMessage: "",
    };
  },
  created() {
    this.getCaptcha();
  },
  methods: {
    async getCaptcha() {
      this.captchaResponse = null;
      if (this.$refs.captchaResponse) {
        this.$refs.captchaResponse.focus();
      }
      await this.axios(`${this.base_url}/captcha/generate`).then((res) => {
        this.captchaImage = `${this.base_url}/${res.data?.captcha_image_url}`;
        this.captchaKey = res.data?.captcha_key;
      })
    },
    refreshCaptcha() {
      this.getCaptcha();
    },
    async validateCaptcha() {
      try {
        const csrfToken = document.cookie
          .split("; ")
          .find((row) => row.startsWith("csrftoken="))
          ?.split("=")[1];
        const response = await this.axios.post(
          `${this.base_url}/captcha/validate`,
          {
            captcha_key: this.captchaKey,
            captcha_response: this.captchaResponse,
          },
          {
            headers: {
              "X-CSRFToken": csrfToken,
            },
          }
        );
        if (response.data.valid) {
          return true;
        } else {
          this.errorMessage = this.$t("globals.captcha-no-match");
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    clearCaptchaImage(){
        this.captchaImage=''
    }
  },
};
</script>
<template>
  <VCard class="mb-2 pa-0">
    <v-card-text class="pb-0">
      <VForm ref="form" class="" @submit.prevent="savevisitor">
      
      <VRow class="mt-2">
        <VCol class="pt-0 px-3" cols="12" xs="10" md="10" lg="10">
          <VCardItem class="pa-0">
            <VTextField
              v-model="visitor.name_ar"
              :label="$t('summer.visitors.name-visitor')"
              persistent-hint
              clearable
              density="compact"
              prepend-inner-icon="mdi-account"
              :rules="rules.name_ar"
            ></VTextField>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" xs="10" md="5" lg="5">
          <VCardItem class="pa-0">
            <v-select
              v-model="visitor.entity"
              :label="$t('summer.visitors.entity-visitor')"
              persistent-hint
              item-title="name"
              item-value="id"
              clearable
              density="compact"
              :items="dir_visitors"
              prepend-inner-icon="mdi-account"
              :rules="rules.entity"
            ></v-select>
          </VCardItem>
        </VCol>
        <VCol  cols="12" xs="12" sm="12" md="6" lg="5">
          <DatetimePicker  
            v-model="visitor.date"
            :placeholder="$t('globals.year-month-day')"
            :label="$t('summer.visitors.date-visitor')"
            :rules="rules.date"
          ></DatetimePicker>
        </VCol>  
         <VCol class="pt-0 px-3" cols="12" xs="12" md="5" lg="5">
          <VCardItem class="pa-0">
            <VRadioGroup inline v-model="visitor.visitortype"
            @update:modelValue="this.visitor.adjective = null,this.visitor.occasion = null,this.visitor.impression = null,this.visitor.recommendation = null"
            >
              <VRadio :label="$t('summer.visitors.visit')" :value="false"></VRadio>
              <VRadio :label="$t('summer.visitors.dir-support')" :value="true"></VRadio>
            </VRadioGroup>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" xs="10" md="5" lg="5" v-if="!visitor.visitortype">
          <VCardItem class="pa-0">
            <VTextField
              v-model="visitor.adjective"
              :label="$t('summer.visitors.adjective-visitor')"
              persistent-hint
              clearable
              density="compact"
              prepend-inner-icon="mdi-account-group"
            ></VTextField>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" xs="10" md="5" lg="5" v-if="visitor.visitortype">
          <VCardItem class="pa-0">
            <VTextField
              v-model="visitor.occasion"
              :label="$t('summer.visitors.occasion')"
              persistent-hint
              clearable
              density="compact"
              prepend-inner-icon="mdi-calendar-star"
            ></VTextField>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" xs="10" md="6" lg="10">
          <VCardItem class="pa-0">
            <VTextarea
              v-model="visitor.evaluation"
              clearable
              :label="$t('summer.visitors.evaluation')"
              counter="250"
              rows="2"
              persistent-hint
              density="compact"
              color="indigo"
              prepend-inner-icon="mdi-star"
              :rules="rules.note"
            ></VTextarea>
          </VCardItem>
        </VCol>
      </VRow>
      <VRow class="pt-0  pb-0">
        <VCol class="pt-0 px-3" cols="12" xs="10" md="5" lg="5" v-if="!visitor.visitortype">
          <VCardItem class="pa-0">
            <VTextField
              v-model="visitor.impression"
              :label="$t('summer.visitors.impression')"
              persistent-hint
              clearable
              density="compact"
              prepend-inner-icon="mdi-emoticon"
            ></VTextField>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" xs="10" md="5" lg="5" v-if="!visitor.visitortype">
          <VCardItem class="pa-0">
            <VTextarea
              v-model="visitor.recommendation"
              :label="$t('summer.visitors.recommendations')"
              persistent-hint
              clearable
              counter="250"
              rows="1"
              density="compact"
              prepend-inner-icon="mdi-lightbulb-on"
              :rules="rules.note"
            ></VTextarea>
          </VCardItem>
        </VCol>
      </VRow>
      <VRow class="pt-0  pb-0">
        <VCol cols="12" class="pt-0 px-3" xs="10" md="10" lg="10">
          <VCardItem class="pa-0">
            <VTextarea
              v-model="visitor.note"
              color="indigo"
              clearable
              :label="$t('summer.initiative.note')"
              counter="250"
              density="compact"
              rows="2"
              prepend-inner-icon="mdi-note-outline"
              :rules="rules.note"
            ></VTextarea>
          </VCardItem>
        </VCol>
      </VRow>
    </VForm>
    </v-card-text>
    <VCardActions
      class="mx-1"
      v-if="
        $checkPermissions('summer_center.add_visitor',['sys_admin','summer_admin','user']) ||
        $checkPermissions('summer_center.change_visitor',['sys_admin','summer_admin','user'])
      "
    >
      <VBtn
        v-if="!is_update && $checkPermissions('summer_center.add_visitor',['sys_admin','summer_admin','user'])"
        class="bg-indigo"
        @click="savevisitor()"
        size="small"
        :loading="loading"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update && $checkPermissions('summer_center.change_visitor',['sys_admin','summer_admin','user'])"
        class="bg-success"
        @click="updatevisitor()"
        size="small"
       :loading="loading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import DatetimePicker from '@/components/Globals/DatetimePicker.vue'

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      id_click:null,
      loading: false,
      homeworks: [],
      visitor: {
        name_ar: undefined,
        adjective: "",
        impression: "",
        recommendation: "",
        evaluation: "",
        visitortype: false,
        occasion: undefined,
        entity: undefined,
        date: undefined,
        fk_week: undefined,
        data_entry: undefined,
        updated_by: undefined,
      },
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      dir_visitors: [],
      rules: {
        name_ar: [(value) => !!value || this.$t("globals.required_field")],
        entity: [(value) => !!value || this.$t("globals.required_field")],
        occasion: [(value) => !!value || this.$t("globals.required_field")],
        date: [(value) => !!value || this.$t("globals.required_field")],

        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,
      listWeeks: [],
      v$: useValidate(),
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      adj_entity: "adjective",
      txt_search: undefined,
    };
  },
  components: { DatetimePicker },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    ...mapGetters({}),
    tableList() {
      if (this.visitors) {
        return JSON.parse(JSON.stringify(this.visitors));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getDirVisitorsr();
      if (this.id != undefined) {
        await this.axios
          .get(`${this.base_url}/api/summer/visitor/${this.id}`)
          .then((response) => {
            this.visitor = response.data.results;
          }).catch(err=>{
          })
        this.is_update = true;
      }
      this.$store.commit("updateLoadingValue", false);
      } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    resetForm() {
      this.$refs.form.resetValidation();
      this.visitor = {};
      this.visitor.visitortype = false;
      this.is_update = false;
      this.updated_id = undefined;
    },
    editvisitor(visitor) {
      this.visitor = visitor;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async savevisitor() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.visitor.data_entry = this.user;
        if (!this.visitor.visitortype) this.visitor.occasion = "لايوجد";
        let result = await this.axios
          .post(this.base_url + "api/summer/visitor", this.visitor)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.visitor = {};
            this.resetForm();
            this.loading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.failure"));
            if (error.response.data.title)
              this.alert_message = error.response.data.title[0];
            else this.alert_message = this.$t("globals.error_in_data");
            this.loading = false;
          });
      }
    },
    async updatevisitor() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.visitor.updated_by = this.user;
        let result = await this.axios
          .put(this.base_url + "api/summer/visitor/" + this.visitor.id,this.visitor)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.loading = false;
            setTimeout(() => {
              this.resetForm();
              this.$router.push({ name: "visitor" });
            }, 1500);
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.title"));
            this.loading = false;
          });     
      }
    },
    async getDirVisitorsr() {
      await this.axios(`${this.base_url}/api/summer/choices/support-committees`).then((response) => {
        this.dir_visitors = response.data;
      });
    },
  },
  watch: {
    
  },
};
</script>

<style scoped>

</style>

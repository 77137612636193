<template>
  <v-card  v-if="!exist">
    <v-card-text>
      <h3 class="text-grey-darken-2 ">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="mt-4">
        <v-col cols="12" md="4" sm="5" >
            <v-select
              color="indigo"
              v-model="filter_data.type"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-soccer"
              :items="activityType"
              :label="$t('summer.activity.type')"
              clearable
              @update:modelValue="getActivitiesByType(),filter_data.activity=null"
              :error-messages="v$.filter_data.type.$errors.map(e=>e.$message)" 
              :rules="rules.requird_filed"
            />
        </v-col>
              <v-col cols="12" md="4" sm="5" >
            <v-select
              color="indigo"
              v-model="filter_data.activity"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-soccer"
              :items="allAvtivitiesByType"
              :label="$t('summer.activity.name')"
              clearable
              :error-messages="v$.filter_data.activity.$errors.map(e=>e.$message)" 
              :rules="rules.requird_filed"
            />
        </v-col>
    
        
        <v-col cols="6" md="2" sm="2" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="indigo"
            :loading="loading"
            @click="checkFilterData"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("summer-report.actiivty-excution-statistics") }} ( {{getActivityTypeName(this.filter_data.activity)}} )
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="my-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
          <v-btn
            density="compact"
            @click="(exist = false),(exist_table=false) , v$.filter_data.$reset()"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" rounded="0" flat v-if="exist_table">
          <table id="stable" class="table" style="width: 100%; border-collapse: collapse">
            <thead class="table-light">
              <tr class="text-center">
                <th class="text-center border bg-blue-grey-lighten-4 pa-2">{{$t("globals.#")}}</th>
                <th class="text-center border bg-blue-grey-lighten-4 pa-2">
                  {{ $t("summer.activity.name") }}
                </th>
                <th class="text-center border bg-blue-grey-lighten-4 pa-2">
                  {{ $t("report.count_activity") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-for="(item, key) in items" :key="item">
                <td>
                  {{ key + 1 }}
                </td>
                <td>
                  {{ item.activity_name }}
                </td>
                <td>
                  {{ item.count_of_activity }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);

      await this.getActivityType();
      await this.getYears();
      this.current_year = this.current_year_name;
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },

      theaders: [],
      allAvtivitiesByType:[],
      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      exist_table: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "adjective", "entity", "phone_number"],
      headers: [
        { title: "#", key: "id" },
        { title: this.$t("globals.name"), key: "name" },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      activityType:(state)=>state.summer.activityType,
      current_year: (state) => state.current_year,
      current_year_name: (state) => state.current_year_name,
    }),

    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getActivityType:"summer/getActivityType",
      getClassAssignHall: "summer/getClassAssignHall",
    }),
    getYears() {
      this.year_data = this.current_year_name;
      this.filter_data.year = this.current_year;
      return this.year_data;
    },
    getActivityTypeName(driver_id) {
      if (driver_id) {
        const committees = this.allAvtivitiesByType.find((committees) => committees.id === driver_id);
        return committees.name;
      }
      return "===========";
    },
    async checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      // const { valid } = await this.$refs.form.validate();
      // if (valid) {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/all-activities`, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            type: this.filter_data.type,
            activity: this.filter_data.activity,
          },
        })
        .then((response) => {
          this.exist = true;
          this.exist_table = true;
          if (response.data) {
            this.items = response.data;
            if(this.items.length<1){
              this.exist_table = false;
            }
              this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });

      // }
    },
    async getActivitiesByType() {
      await this.axios
        .get(`${this.base_url}api/summer/get-activity-by-type`, {
          params:{
            activity_type: this.filter_data.type,
          }
        })
        .then((response) => {
          if (response.data) {
            this.allAvtivitiesByType = response.data         
          }
        })
        .catch((error) => {
        });
    },
  },
  validations() {
    return {
      filter_data: {
        type: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        activity: {
            required: helpers.withMessage(
                this.$t("errors.required"),
                required
            ),
        },
      },  
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

.pp2 {
  padding: 2px !important;
}
#stable th {
  padding:10px;                                                                                              
  border: 1px solid #649add !important;
  background-color: #fff;
  color:black
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}

/* #stable tr:nth-child(even){
  background-color: skyblue;

} */

/* #stable tr:hover{background-color: skyblue;} */

/* #stable th {
border: 2px solid skyblue;
padding-top: 12px;
padding-bottom: 12px;
text-align: center;
background-color: blue;
color: black;

} */

/* td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
} */

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
#stable td:first-child {

background-color: #cfd8dc;

}
</style>

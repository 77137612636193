import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Globals/HomeView.vue";
import Login from "../views/Globals/Login.vue";
import MainPage from "../views/Globals/MainPage.vue";
import SummerUsers from "./SummerUsers";
import Globals from "./GlobalsRoutes";
import example from "../views/Examples/Dashboard";

import NotFoundView from "../views/Globals/NotFoundView.vue";
import TestDatetimePicker from '../views/Examples/TestDatetimePicker.vue'
import i18n from "@/i18n";
import store from "@/store";
import Summer from "./Summer";
import SummerReport from "./SummerReport";
import PublicReport from "./PublicReport";
import {decryptString} from '@/utils/index.js'

import { api_url } from "@/main";
import jwt_decode from "jwt-decode";
i18n.global.locale = localStorage.getItem("user-locale");
// const sys_type=localStorage.getItem('sys_type')

const routes = [
  {
    path: "/main-page",
    name: "main_page",
    component: MainPage,
  },
  {
    path: "/secure-login",
    name: "login",
    component: Login,
  },
  
  {
    path: "/summer",
    name: "summer",
    component: Home,
    children: [
      ...Summer,
      {
        path: "user",
        children: [...SummerUsers],
      },
      {
        path: "report",
        children: [...SummerReport],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFoundView,
  },  
  
  {
    path: "/public",
    name: "public",
    component: Home,
    children: [...PublicReport],
  },
  {
    path: "/test",
    component: TestDatetimePicker,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.isAuthenticated;
  if (!isAuthenticated) {
    if (to.path === "/") {
      next({name:"main_page"});
    } else {
      next();
    }
  } else if (isAuthenticated && to.path === "/") {
    next({name:'summer_dashboard'});
  } else {
    const mustChangePassword = localStorage.getItem('must_change_password')
    if(mustChangePassword&&to.name!='user_profile'){
      next({name:'user_profile'})
    }else{
      next();
    }
  }
});

export default router;

<template>
  <v-card
    v-if="
      $checkPermissions('globaltable.view_assigntask', [
        'sys_admin',
        'gen_admin',
        'gov_admin',
        'dir_admin',
      ])
    "
  >
    <v-card-text class="pb-0">
      <VForm ref="form" @submit.prevent="is_update ? updateItem() : saveItem()">
        <fieldset class="mb-2 pt-1 pb-3 border rounded px-2">
          <legend class="mx-2 px-2">{{ $t("globals.task-data") }}</legend>
          <VRow class="mt-2">
            <VCol cols="12" xs="12" sm="6" md="3" lg="2">
              <VAutocomplete
                :items="tasks_type"
                v-model="item.fk_task_type"
                item-title="title"
                item-value="id"
                :label="$t('summer.task-type')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-clipboard-file-outline"
                :errorMessages="
                  v$.item.fk_task_type.$errors.map((e) => e.$message)
                "
                @input="v$.item.fk_task_type.$touch"
              ></VAutocomplete>
            </VCol>
            <VCol cols="12" xs="12" sm="6" md="3" lg="2">
              <VAutocomplete
                :items="tasks"
                v-model="item.fk_task"
                item-title="name"
                item-value="id"
                :label="$t('summer.task')"
                persistent-hint
                density="compact"
                prepend-inner-icon="mdi-clipboard-check-outline"
                :errorMessages="v$.item.fk_task.$errors.map((e) => e.$message)"
                @input="v$.item.fk_task.$touch"
              ></VAutocomplete>
            </VCol>
            <VCol cols="12" xs="12" sm="6" md="3" lg="2">
              <VTextField
                v-model="item.period"
                :label="$t('summer.duration')"
                placeholder="3 ايام"
                density="compact"
                counter="100"
                prepend-inner-icon="mdi-clipboard-clock-outline"
                :errorMessages="v$.item.period.$errors.map((e) => e.$message)"
                @input="v$.item.period.$touch"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" xs="12" sm="6" md="3" lg="2">
              <DatetimePicker
                v-model="item.task_start"
                :placeholder="$t('globals.year-month-day')"
                :label="$t('summer.mission-start-date')"
                :maxDate="item.task_end"
                :errorMessages="
                  v$.item.task_start.$errors.map((e) => e.$message)
                "
                @input="v$.item.task_start.$touch"
              ></DatetimePicker>
            </VCol>
            <VCol cols="12" xs="12" sm="6" md="3" lg="2">
              <DatetimePicker
                v-model="item.task_end"
                :placeholder="$t('globals.year-month-day')"
                :label="$t('summer.mission-end-date')"
                :minDate="item.task_start"
                :errorMessages="v$.item.task_end.$errors.map((e) => e.$message)"
                @input="v$.item.task_end.$touch"
              ></DatetimePicker>
            </VCol>
            <VCol cols="12" xs="12" sm="6" md="3" lg="2">
              <VTextField
                v-model="item.place"
                :label="$t('summer.place-of-residence')"
                density="compact"
                prepend-inner-icon="mdi-map-marker-outline"
                counter="100"
                :errorMessages="v$.item.place.$errors.map((e) => e.$message)"
                @input="v$.item.place.$touch"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" xs="12" sm="6" md="3" lg="2">
              <VTextField
                v-model="item.target"
                :label="$t('summer.target-audience')"
                density="compact"
                :rules="rules.item_name"
                prepend-inner-icon="mdi-account-group"
                counter="100"
                :errorMessages="v$.item.target.$errors.map((e) => e.$message)"
                @input="v$.item.target.$touch"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" xs="12" sm="6" md="3" lg="2">
              <VTextField
                v-model="item.participants_count"
                type="number"
                :label="$t('summer.participants-count')"
                density="compact"
                :rules="rules.item_name"
                prepend-inner-icon="mdi-clipboard-account-outline"
                counter="4"
                :errorMessages="
                  v$.item.participants_count.$errors.map((e) => e.$message)
                "
                @input="v$.item.participants_count.$touch"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" xs="12" sm="6" md="6" lg="4">
              <VTextField
                v-model="item.pros"
                :label="$t('summer.positives-of-the-workshop')"
                density="compact"
                :rules="rules.item_name"
                prepend-inner-icon="mdi-clipboard-plus-outline"
                counter="250"
                :errorMessages="v$.item.pros.$errors.map((e) => e.$message)"
                @input="v$.item.pros.$touch"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" xs="12" sm="6" md="6" lg="4">
              <VTextField
                v-model="item.cons"
                :label="$t('summer.obstacles-in-the-workshop')"
                density="compact"
                :rules="rules.item_name"
                prepend-inner-icon="mdi-clipboard-remove-outline"
                counter="250"
                :errorMessages="v$.item.cons.$errors.map((e) => e.$message)"
                @input="v$.item.cons.$touch"
              >
              </VTextField>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="item.note"
                color="indigo"
                clearable
                :label="$t('globals.note')"
                no-resize
                counter="250"
                density="compact"
                rows="2"
                prepend-inner-icon="mdi-note-outline"
                :errorMessages="v$.item.note.$errors.map((e) => e.$message)"
                @input="v$.item.note.$touch"
              ></VTextarea>
            </VCol>
          </VRow>
        </fieldset>
        <fieldset class="mb-2 pt-1 pb-3 border rounded px-2">
          <legend class="mx-2 px-2">{{ $t("globals.coaches-data") }}</legend>
          <v-row class="mt-2">
            <VCol
              cols="12"
              lg="3"
              md="4"
              v-for="(coach, index) in item.coachs_names"
              :key="index"
            >
              <VTextField
                v-model="coach.name"
                :label="`${$t('summer.coach-name')} ${index + 1}`"
                density="compact"
                prepend-inner-icon="mdi-clipboard-account-outline"
              >
                <template v-slot:append>
                  <dir
                    v-if="index === item.coachs_names?.length - 1"
                    title=" اضافة مدرب"
                  >
                    <v-btn @click="addCoach()" icon size="small">
                      <v-icon color="success"> mdi-plus-thick</v-icon>
                    </v-btn>
                  </dir>
                  <dir v-else title="حذف المدرب">
                    <v-btn @click="removeCoach()" icon size="small">
                      <v-icon color="error" itemtype="button">
                        mdi-close-thick</v-icon
                      >
                    </v-btn>
                  </dir>
                </template>
              </VTextField>
            </VCol>
          </v-row>
        </fieldset>
        <fieldset class="mb-2 py-1 border rounded px-2">
          <legend class="mx-2 px-2">{{ $t("globals.trainee-data") }}</legend>
          <v-row class="mt-1 mb-1 hide-item">
            <v-col cols="8">
              <v-btn
                variant="tonal"
                prepend-icon="mdi-account-multiple-plus"
                class="mb-1"
                size="small"
                @click="addDialog = true"
                >اختيار موظفين
              </v-btn>
              <v-btn
                variant="tonal"
                prepend-icon="mdi-clipboard-account-outline"
                class="mb-1 ms-1"
                size="small"
                @click="addTraineeDialog = true"
                >{{ $t("employee.add-trainee") }}
              </v-btn>
            </v-col>
            <v-col cols="4" class="text-end">
              <v-btn-group class="ms-2">
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props">
                      <v-icon class="me-2">mdi-eye-outline</v-icon>
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-checkbox
                        v-for="header in employees_header.filter(
                          (h) => h.key != 'add_to_task'
                        )"
                        :key="header.key"
                        :label="header.title"
                        :value="header.key"
                        color="indigo"
                        v-model="selectedHead"
                        density="compact"
                        hide-details
                      >
                      </v-checkbox>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-btn-group>
              <slot name="action"></slot>
            </v-col>
          </v-row>
          <v-card class="mb-1">
            <v-data-table
              items-per-page="0"
              density="compact"
              :headers="employees_header.filter((h) => h.show)"
              :items="selected_employees"
            >
              <template v-slot:bottom>
                <p
                  v-if="selected_employees?.length == 0"
                  class="text-center pa-2"
                >
                  لم يتم اختيار المتدربين .
                </p>
              </template>
              <template v-slot:item.add_to_task="{ item }">
                <dir :title="$t('globals.cancel-from-task')">
                  <v-btn
                    density="compact"
                    icon
                    @click="deleteEmployee(item.raw)"
                  >
                    <v-icon color="error">mdi-trash-can</v-icon>
                  </v-btn>
                </dir>
              </template>
            </v-data-table>
          </v-card>
        </fieldset>
      </VForm>
    </v-card-text>
    <VCardActions class="px-3">
      <VBtnSave
        v-if="!is_update"
        @click="saveItem()"
        :loading="saveDataLoading"
      >
        {{ $t("globals.add") }}
      </VBtnSave>
      <VBtnUpdate
        v-if="is_update"
        @click="updateItem()"
        :loading="saveDataLoading"
      >
        {{ $t("globals.edit") }}
      </VBtnUpdate>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </v-card>

  <v-dialog
    v-model="addDialog"
    scrollable
    width="auto"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    @keydown.esc="addDialog = false"
  >
    <v-card flat>
      <div
        class="mt-1 text-main-color mx-2 border rounded"
        rounded="0"
        v-if="role >= 0 && role <= 2"
      >
        <v-expansion-panels >
          <v-expansion-panel>
            <v-expansion-panel-title class="px-3">
              <span>{{ $t("globals.select-criteria") }}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="text-main-color">
              <v-row>
                <VCol class="mt-1" cols="6" v-if="role == 0 || role == 1">
                  <VAutocomplete
                    :items="filterGovernorates(1)"
                    v-model="fk_governorate"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('globals.governorate')"
                    persistent-hint
                    density="compact"
                    hide-details
                    clearable
                    :no-data-text="$t('governorate.not-found')"
                    prepend-inner-icon="mdi-city"
                    @update:modelValue="
                      getAllEmployees(), (fk_directorate = null)
                    "
                  ></VAutocomplete>
                </VCol>
                <VCol class="mt-1" cols="6" v-if="role >= 0 && role <= 2">
                  <VAutocomplete
                    :items="filterDirectorates(fk_governorate)"
                    v-model="fk_directorate"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('globals.directorate')"
                    persistent-hint
                    hide-details
                    density="compact"
                    clearable
                    :no-data-text="$t('directorate.not-found')"
                    prepend-inner-icon="mdi-map-outline"
                    @update:modelValue="getAllEmployees()"
                  ></VAutocomplete>
                </VCol>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <DataTable
        :items="all_employees"
        :headers="employees_header"
        density="compact"
        :method="getAllEmployees"
        :pagination="pagination"
      >
        <template v-slot:item.add_to_task="{ item }">
          <dir :title="$t('globals.add-to-task')">
            <v-btn
              density="compact"
              icon
              @click="addEmployee(item.raw)"
              :disabled="isSelected(item.raw)"
            >
              <v-icon color="success">mdi-plus-thick</v-icon>
            </v-btn>
          </dir>
        </template>
      </DataTable>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="addTraineeDialog"
    scrollable
    max-width="800"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    @keydown.esc="addTraineeDialog = false"
  >
    <v-card
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      class="text-main-color overflow-auto"
      max-height="700 "
    >
      <v-card-title
        class="py-2 bg-indigo mb-4 d-flex justify-space-between align-center"
      >
        <span>
          <v-icon size="30" class="me-2">mdi-clipboard-account-outline</v-icon>
          <span class="text-h6">{{ $t("employee.add-trainee") }}</span>
        </span>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="8">
            <VTextField
              v-model="employee.name_ar"
              autofocus
              counter="100"
              :label="$t('globals.employee-name')"
              persistent-hint
              ref="employee_name"
              clearable
              density="compact"
              prepend-inner-icon="mdi-account-tie"
              :errorMessages="
                v$.employee.name_ar.$errors.map((e) => e.$message)
              "
              @input="v$.employee.name_ar.$touch"
            ></VTextField>
          </v-col>
          <v-col cols="6" md="4">
            <AutoSelect
              :items="genders"
              v-model="employee.gender"
              item-title="name"
              item-value="id"
              :label="$t('globals.gender')"
              persistent-hint
              density="compact"
              prepend-inner-icon="mdi-gender-male-female"
              :errorMessages="
                v$.employee.gender.$errors.map((e) => e.$message)
              "
              @input="v$.employee.gender.$touch"
            ></AutoSelect>
          </v-col>

          <v-col cols="6" md="4">
            <AutoSelect
              :items="roles"
              v-model="employee.role"
              item-title="name"
              item-value="id"
              :label="$t('summer.work-type')"
              persistent-hint
              prepend-inner-icon="mdi-list-status"
              density="compact"
              :errorMessages="
                v$.employee.role.$errors.map((e) => e.$message)
              "
              @input="v$.employee.role.$touch"
            ></AutoSelect>
          </v-col>
          <VCol cols="6" md="4" v-if="role == 0 || role == 1">
            <AutoSelect
              :items="filterGovernorates(1)"
              v-model="school_filter.governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              clearable
              :no-data-text="$t('governorate.not-found')"
              prepend-inner-icon="mdi-city"
              @update:modelValue="school_filter.directorate = null"
              
            ></AutoSelect>
          </VCol>
          <VCol cols="6" md="4" v-if="role >= 0 && role <= 2">
            <AutoSelect
              :items="filterDirectorates(school_filter.governorate)"
              v-model="school_filter.directorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.directorate')"
              persistent-hint
              density="compact"
              clearable
              :no-data-text="$t('directorate.not-found')"
              prepend-inner-icon="mdi-map-outline"
            ></AutoSelect>
          </VCol>
          <v-col cols="6" md="4">
            <AutoSelect
              item-title="name"
              item-value="id"
              v-model="school_filter.type"
              :items="school_types"
              :label="$t('school.school_type')"
              persistent-hint
              density="compact"
              prepend-inner-icon="mdi-city"
              @update:modelValue="getSchools(), (employee.fk_summer = null)"
            ></AutoSelect>
          </v-col>
          <v-col cols="6" md="4">
            <AutoSelect
              item-title="name_ar"
              item-value="id"
              v-model="employee.fk_summer"
              :items="summer_schools"
              :label="$t('summer.school_summer_name')"
              persistent-hint
              density="compact"
              prepend-inner-icon="mdi-domain"
              :errorMessages="
                v$.employee.fk_summer.$errors.map((e) => e.$message)
              "
              @input="v$.employee.fk_summer.$touch"
            ></AutoSelect>
          </v-col>
          <v-col cols="6" md="4">
            <VTextField
              :label="$t('summer.employee.mobile-number')"
              v-model="employee.phone_number"
              persistent-hint
              clearable
              density="compact"
              type="number"
              prepend-inner-icon="mdi-cellphone-text"
              :errorMessages="
                v$.employee.phone_number.$errors.map((e) => e.$message)
              "
              @input="v$.employee.phone_number.$touch"
            ></VTextField>
          </v-col>
          <v-col cols="6" md="4">
            <VTextField
              :label="$t('globals.identity-id')"
              v-model="employee.identity_id"
              persistent-hint
              clearable
              counter="15"
              density="compact"
              type="number"
              prepend-inner-icon="mdi-numeric"
              :errorMessages="
                v$.employee.identity_id.$errors.map((e) => e.$message)
              "
              @input="v$.employee.identity_id.$touch"
            ></VTextField>
          </v-col>

          <v-col cols="6" md="4">
            <AutoSelect
              :items="filterGovernorates(1)"
              v-model="employee.fk_governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              prepend-inner-icon="mdi-city"
              @update:modelValue="employee.fk_directorate=null"
            ></AutoSelect>
          </v-col>
          <v-col cols="6" md="4">
            <AutoSelect
              v-model="employee.fk_directorate"
              :items="filterDirectorates(employee.fk_governorate)"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.directorate')"
              persistent-hint
              density="compact"
              prepend-inner-icon="mdi-map-outline"
              :errorMessages="
                v$.employee.fk_directorate.$errors.map((e) => e.$message)
              "
              @input="v$.employee.fk_directorate.$touch"
            ></AutoSelect>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="employee.address"
              counter="50"
              type="input"
              density="compact"
              clearable
              prepend-inner-icon="mdi-map-marker"
              :label="$t('summer.solitude')"
              :errorMessages="
                v$.employee.address.$errors.map((e) => e.$message)
              "
              @input="v$.employee.address.$touch"
            />
          </v-col>
          <VCol cols="12">
            <VTextarea
              v-model="employee.note"
              clearable
              :label="$t('globals.note')"
              color="indigo"
              density="compact"
              hide-details="auto"
              rows="3"
              prepend-inner-icon="mdi-note-outline"
              :errorMessages="
                v$.employee.note.$errors.map((e) => e.$message)
              "
              @input="v$.employee.note.$touch"
            ></VTextarea>
          </VCol>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-2 py-0">
        <v-btn class="bg-indigo" size="small" @click="saveEmployee">
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </v-btn>
        <v-btn size="small" @click="resetEmployeeForm(),addTraineeDialog = false">{{
          $t("globals.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AutoSelect from "@/components/Globals/AutoSelect";

import useValidate from "@vuelidate/core";
import DataTable from "@/components/Globals/DataTable.vue";
import {
  required,
  helpers,
  maxLength,
  alphaNum,
  minLength ,
  numeric,
} from "@vuelidate/validators";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  components: {
    DataTable,
    AutoSelect,
  },
  data() {
    return {
      addDialog: false,
      addTraineeDialog: false,
      school_types: [
        {
          id: 1,
          name: "مغلقة",
        },
        {
          id: 2,
          name: "مفتوحة",
        },
      ],
      employee: {
        user:{}
      },
      selectedHead: [],
      saveDataLoading: false,
      deleteDataLoading: false,
      fk_governorate: null,
      fk_directorate: null,
      school_filter: {
        directorate: null,
        governorate: null,
        type: null,
      },
      summer_schools: [],
      selected_employees: [],
      all_employees: [],
      tasks_type: [],
      tasks: [],
      items: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      item: {
        coachs_names: [
          {
            name: null,
          },
        ],
      },
      sortBy: [
        {
          key: "title",
          order: "asc",
        },
      ],
      rules: {
        title: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      v$: useValidate(),
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.task-number"), key: "name0" },
        { title: this.$t("summer.task"), key: "name1" },
        { title: this.$t("summer.task-type"), key: "name2" },
        { title: this.$t("summer.duration"), key: "name3" },
        { title: this.$t("summer.mission-start-date"), key: "name4" },
        { title: this.$t("summer.mission-end-date"), key: "name5" },
        { title: this.$t("summer.place-of-residence"), key: "name6" },
        { title: this.$t("summer.implementing-authority"), key: "name7" },
        { title: this.$t("summer.course-no"), key: "name8" },
        { title: this.$t("summer.target-audience"), key: "name9" },
        { title: this.$t("summer.batch"), key: "name10" },
        { title: this.$t("summer.category"), key: "name11" },
        {
          title: this.$t("summer.positives-of-the-workshop"),
          key: "name12",
          show: false,
        },
        {
          title: this.$t("summer.obstacles-in-the-workshop"),
          key: "name13",
          show: false,
        },
        {
          title: this.$t("summer.workshop-recommendations"),
          key: "name14",
          show: false,
        },
        {
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      role: (state) => state.role,
      roles: (state) => state.summer.roles,
      genders: (state) => state.summer.genders,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    selected_headers() {
      return this.employees_header.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    employees_header() {
      return [
        { title: "#", key: "add_to_task", show: true, sortable: false },
        { title: this.$t("summer.name-employees"), key: "name_ar", show: true },
        {
          title: this.$t("globals.school"),
          key: "school_name",
          show: !this.$vuetify.display.xs,
          sortable: false,
        },
        {
          title: this.$t("school.school_type"),
          key: "school_type",
          show: !this.$vuetify.display.xs,
          sortable: false,
        },
        {
          title: this.$t("globals.job-title"),
          key: "role",
          show: !this.$vuetify.display.xs,
          sortable: false,
        },
        {
          title: this.$t("globals.address"),
          key: "address",
          show: !this.$vuetify.display.xs,
          sortable: false,
        },
        {
          title: this.$t("summer.employee.mobile-number"),
          key: "phone_number",
          show: !this.$vuetify.display.xs,
          sortable: false,
        },
        {
          title: this.$t("summer.card-number"),
          key: "identity_id",
          show: !this.$vuetify.display.xs,
          sortable: false,
        },
      ];
    },
    
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getTasksType();
      await this.getTasks();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getAllEmployees();
      await this.getRoles();
      await this.getGenders();
      this.selectedHead = this.employees_header
        ?.filter((h) => h.show === true)
        ?.map((h) => h.key);
      if (this.id) {
        this.getTaskAssignData(this.id);
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    getSchools() {
      if (this.school_filter.type == 1) {
        this.getClosedSummerSchools();
      }
      if (this.school_filter.type == 2) {
        this.getOpenSummerSchools();
      }
    },
    async getClosedSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          params: {
            directorate: this.school_filter.directorate,
            governorate: this.school_filter.governorate,
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    async getOpenSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-open-list`, {
          params: {
            directorate: this.school_filter.directorate,
            governorate: this.school_filter.governorate,
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getRoles: "summer/getRoles",
      getGenders: "summer/getGenders",
    }),
    addCoach() {
      this.item.coachs_names.push({
        name: null,
      });
    },
    removeCoach(index) {
      this.item.coachs_names.splice(index, 1);
    },
    addEmployee(emp) {
      if (!this.isSelected(emp)) {
        this.selected_employees.push(emp);
      }
    },
    deleteEmployee(emp) {
      this.selected_employees = this.selected_employees.filter(
        (item) => item != emp
      );
    },
    isSelected(emp) {
      return this.selected_employees.some(
        (item) => item.id === emp.id && item.name_ar === emp.name_ar
      );
    },
    async getAllEmployees(
      page = 1,
      per_page = 10,
      ordering = null,
      search = null
    ) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/all-employees`, {
        params: {
          search: this.txt_search,
          governorate: this.fk_governorate,
          directorate: this.fk_directorate,
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      })
        .then((response) => {
          this.loading = false;
          this.all_employees = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async getTasksType() {
      await this.axios(`${this.base_url}api/task-type/list/`).then(
        (response) => {
          this.tasks_type = response.data;
        }
      );
    },
    async getTasks() {
      await this.axios(`${this.base_url}api/task/list/`).then((response) => {
        this.tasks = response.data;
      });
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.v$.$reset();
      this.selected_employees = [];
      this.addDialog = false;
      // this.is_update = false;
      this.updated_id = undefined;
    },
    resetEmployeeForm(){
      this.employee = {
        user:{}
      }
      this.v$.$reset()
    },
    async saveItem() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveDataLoading = true;
        this.item.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/assign-task/", this.item)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            setTimeout(() => {
              this.resetForm();
              this.$router.push({ name: "task_assign_list" });
            }, 2000);
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data.title)
              this.$emit(
                "warningAlert",
                this.$t("globals.error_in_repeted_data")
              );
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.resetForm();
          });
      }
    },
    async saveEmployee() {
      this.v$.employee.$validate();
      if (!this.v$.employee.$error) {
        this.saveDataLoading = true;
        this.employee.data_entry = this.user;
        try {
          if (this.school_filter.type == 1) {
            await this.axios.post(
              this.base_url + "api/summer/employee",
              this.employee
            );
          } else {
            await this.axios.post(
              this.base_url + "api/summer/school-open-employees",
              this.employee
            );
          }
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetEmployeeForm()
        } catch (error) {
          this.saveDataLoading = false;
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        }
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.item.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}api/assign-task/${this.item.id}/`, this.item)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.saveDataLoading = false;
            setTimeout(() => {
              this.resetForm();
              this.$router.push({ name: "task_assign_list" });
            }, 2000);
          })
          .catch((error) => {
            if (error.response.data.title)
              this.$emit(
                "warningAlert",
                this.$t("globals.error_in_repeted_data")
              );
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
      }
    },

    async getTaskAssignData(id) {
      if (id) {
        this.is_update = true;
        this.loading = true;
        await this.axios(`${this.base_url}api/assign-task/${id}/`)
          .then((res) => {
            this.item = res.data;
            this.selected_employees = [
              ...this.item.open_school_employees,
              ...this.item.closed_school_employees,
            ];
            this.item.open_school_employees = [];
            this.item.closed_school_employees = [];
            this.loading = false;
          })
          .catch((err) => {
            this.false;
          });
      }
    },
  },
  validations() {
    return {
      item: {
        fk_task_type: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        fk_task: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        period: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        participants_count: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(4)
          ),
        },
        task_start: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(10)
          ),
          minValue: helpers.withMessage(
            "وقت البداية يجب يكون اقل من وقت النهاية",
            (value) => {
              if (this.item.task_end && this.item.task_start) {
                return value <= this.item.task_end;
              }
              return true;
            }
          ),
        },
        task_end: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(10)
          ),
        },
        place: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        target: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        pros: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
        cons: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
      },
      employee: {
        name_ar: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(100)
          ),
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_summer:{
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        birthdate: {
          minLength: helpers.withMessage(" تاريخ غير صحيح", minLength(4)),
        },
        gender: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        role: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        phone_number: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(14)
          ),
        },
        identity_id: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(15)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.lowest_number_of_character") + "5",
            minLength(5)
          ),
        },
        fk_directorate: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        address: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(50)
          ),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(250)
          ),
        },
      },
    };
  },
  watch: {
    selected_employees: {
      handler(val) {
        this.item.employees_opensummer = val
          ?.filter((emp) => emp.school === "open")
          ?.map((emp) => emp.id);
        this.item.employees_summer_center = val
          ?.filter((emp) => emp.school === "closed")
          ?.map((emp) => emp.id);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.v-icon {
  opacity: 0.7;
}
.v-icon:hover {
  opacity: 1;
}
</style>
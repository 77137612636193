<template>
    <div v-if="timeLeft>0">
        <p>الوقت المتبقي: {{formattedTime}}</p>
    </div>
    <div v-else>
        <p>انتهت فترة الحظر. </p>
    </div>
</template>
<script>
import moment from "moment-hijri";
export default {
    props:{
        banDuration:{
            type:Number,
            required:true
        }
    },
    data(){
        return{
            timeLeft:this.banDuration,
            timer:null
        }
    },
    computed:{
        formattedTime(){
            let duration = moment.duration(this.timeLeft, "seconds");
            let formatedTime = moment
                .utc(duration.asMilliseconds())
                .format("HH:mm:ss");
            return formatedTime;
        }
    },
    mounted(){
        this.startCountDown()
    },
    methods:{
        startCountDown(){
            if(this.timer){
                clearInterval(this.timer)
            }
            this.timer = setInterval(()=>{
                if(this.timeLeft > 0 ){
                    this.timeLeft -= 1;
                    // this.$emit('count-down',this.timeLeft);  
                }else{
                    clearInterval(this.timer);
                    this.timer = null;
                    // this.$emit('count-down',0);
                }
            },1000);
        }
    },
    beforeUnmount(){
        if(this.timer){
            clearInterval(this.timer)
        }
    },
    watch:{
        timeLeft(val){
            if(!val){
                this.$emit('reset-timer',true)
            }
        }
    }
}
</script>
<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.TotalNumberOfActivitiesCarriedOut") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>

      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pl-0">
            <v-sheet class="px-2 chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalNumberOfActivitiesCarriedOut") }}
              {{ $t("reportpublic.InGovernorate") }}
              {{ this.$i18n.locale == "ar" ? gov_name.ar : gov_name.en }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{total}}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
  
          <table class="report-t">
            <thead>
              <tr>
                <th rowspan="2" style="width:50px">{{ $t("globals.#") }}</th>

                <th>
                  {{ $t("reportpublic.directorate") }}
                </th>
                <th v-for="(item, index) in results.header" :key="index">
                  {{ item }}
                </th>
                <th>{{ $t("report.final-collector") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in results.data" :key="index">
                <td>{{index+1}}</td>
                <td>{{ data.dir_name }}</td>
                <td v-for="(subdata, index) in data.value" :key="index" class="text-center">
                  {{ subdata }}
                </td>
                <td class="text-center">{{ data.total }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">{{ $t("reportpublic.GrandTotal") }}</th>
                <th v-for="(data, index) in results.footer" :key="index">
                  {{ data }}
                </th>
              </tr>
            </tfoot>
          </table>
     
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      await  this.axios
        .get(`${this.base_url}public-reports/governorate-reports/total-number-of-activities-carried-out`)
        .then((response) => {
          this.results = response.data.results;
          this.total = this.results.footer[this.results.footer.length-1];
          this.gov_name = this.results.gover;
        })
        .catch((error) => {});
    },

    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      gov_name: "",
      id: 5,
      results: [],
      header: [],
      total:''
    };
  },
  

  components: {
    ReportHeader,
  },
};
</script>

import CryptoJS from 'crypto-js';
const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ENCRYPTION_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ENCRIPTION_IV);

export function encryptString (txt){
    return CryptoJS.AES.encrypt(txt,process.env.VUE_APP_ENCRYPTION_KEY).toString();
}
export function decryptString (cipher_txt){
    const bytes = CryptoJS.AES.decrypt(cipher_txt,key,{
        iv:iv,
        padding:CryptoJS.pad.Pkcs7,
        mode:CryptoJS.mode.CBC
      })
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8)
    return decryptedData;
}

<template>
  <DataTable
    v-if="$checkPermissions('summer_center.view_supervisor')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getSupervisors"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editSupervisor"
    :delItem="deleteSupervisor"
    :del_perm="$checkPermissions('summer_center.delete_supervisor')"
    :edit_perm="$checkPermissions('summer_center.change_supervisor')"
    :add_perm="$checkPermissions('summer_center.add_supervisor')"
  >
  
    <template v-slot:item.type="{ item }">
      {{ getSuperVisorTypeName(item.raw.type) }}
    </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="saveSupervisor">
          <VRow class="mt-2">
            <VCol cols="12">
              <VTextField
                v-model="supervisor.name"
                ref="supName"
                autofocus
                :label="$t('summer.supervising-authority-name')"
                density="compact"
                :rules="rules.supervisor_name"
                prepend-inner-icon="mdi-office-building"
              >
              </VTextField>
            </VCol>
            <VCol cols="12">
              <VAutocomplete
                :items="SuperVisorType"
                v-model="supervisor.type"
                item-title="name"
                item-value="id"
                :label="$t('summer.supervision-type')"
                persistent-hint
                density="compact"
                :rules="supervisor_type_rule"
                prepend-inner-icon="mdi-text"
              ></VAutocomplete>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-3">
        <VBtnSave
          v-if="!is_update "
          @click="saveSupervisor()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update "
          @click="updateSupervisor()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
  <alert-cascade-records
    v-model="alert_cascade"
    :data_message="data_message"
    :title="alert_cascade_title"
    :loading="deleteDataLoading"
    @confirm-delete="confirmDelete"
  >
  </alert-cascade-records>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      saveDataLoading: false,
      addDialog: false,
      editDataLoading: false,
      deleteDataLoading: false,
      data_message: [],
      alert_cascade_title: "",
      alert_cascade: false,
      force_delete: false,
      supervisors: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      supervisor: {},
      
      rules: {
        supervisor_name: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) ||
            this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
      },

      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.supervising-authority-name"), key: "name" },
        {
          title: this.$t("summer.supervision-type"),
          key: "type",
          sortable: false,
        },
        {
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      SuperVisorType: (state) => state.summer.SuperVisorType,
    }),
    ...mapGetters({}),
    tableList() {
      if (this.supervisors) {
        return JSON.parse(JSON.stringify(this.supervisors));
      }
    },
    
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getSupervisors();
      await this.getSuperVisorType();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async confirmDelete() {
      this.force_delete = true;
      const res = await this.deleteSupervisor(this.delete_id);
      if (res == true) {
        this.$emit("successAlert", this.$t("globals.data_deleted"));
        this.getSupervisors();
      } else {
        this.$emit("errorAlert", this.$t("alert.failure.delete"));
      }
      this.alert_cascade = false;
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getSuperVisorType: "summer/getSuperVisorType",
    }),
    resetForm() {
      this.$refs.form?.resetValidation();
      this.supervisor = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    editSupervisor(supervisor) {
      this.supervisor = supervisor;
      this.is_update = true;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    getSuperVisorTypeName(type) {
      let superVisor = this.SuperVisorType.find(
        (element) => element.id == type
      );
      return superVisor ? superVisor.name : "";
    },
    async saveSupervisor() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.supervisor.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/supervisor/", this.supervisor)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.addDialog = false;
            this.saveDataLoading = false;
            this.$refs.supName.focus();
            this.resetForm();
            this.getSupervisors();
          })
          .catch((error) => {
            if (error.response.data.non_field_errors)
              this.$emit("errorAlert", error.response.data.non_field_errors);
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
      }
    },
    async updateSupervisor() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        this.supervisor.updated_by = this.user;
        let result = await this.axios.put(`${this.base_url}api/summer/supervisor/${this.supervisor.id}/`,this.supervisor)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.getSupervisors();
            setTimeout(() => {
              this.resetForm();
              this.addDialog = false;
            }, 1000);
          })
          .catch((error) => {
            if (error.response.data.non_field_errors)
              this.$emit("errorAlert", error.response.data.non_field_errors);
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
      }
    },
    async deleteSupervisor(delete_id) {
      if (delete_id) {
        this.delete_id = delete_id;
        var status;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}api/summer/supervisor/${delete_id}/`, {
            params: {
              force_delete: this.force_delete,
            }
          })
          .then((response) => {
            status = true;
          })
          .catch((error) => {
            if (error.response?.status == 406) {
              this.deleteDataLoading = false;
              this.alert_cascade = true;
              this.alert_cascade_title = this.$t(
                "alert.attention.delete-supervisor-with-school"
              );
              this.data_message = error.response.data;
            } else {
              this.deleteDataLoading = false;
              status = error;
            }
          });
        return status;
      }
    },
    async getSupervisors(
      page = 1,
      per_page = 10,
      ordering = null,
      search = null
    ) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/supervisor/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.supervisors = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
    alert_cascade(val) {
      if (val == false) {
        this.force_delete = false;
        this.delete_id = undefined;
        this.del_loading = false;
      }
    },
  },
};
</script>
      <!-- <v-card 
        flat
        v-if="
          $checkPermissions('summer_center.add_supervisor', ['sys_admin', 'gen_admin']) ||
          $checkPermissions('summer_center.change_supervisor', ['sys_admin', 'gen_admin'])
        " 
        >
        <v-card-text class="pb-0">
          <VForm ref="form" @submit.prevent="saveSupervisor">
            <VRow class="mt-2">
              
              <VCol  cols="12" md="5">
                  <VTextField
                    v-model="supervisor.name"
                    ref="supName"
                    autofocus
                    :label="$t('summer.supervising-authority-name')"
                    density="compact"
                    :rules="rules.supervisor_name"
                    prepend-inner-icon="mdi-office-building"
                  >
                  </VTextField>
              </VCol>
              <VCol  cols="12" md="5">
                  <VAutocomplete
                    :items="SuperVisorType"
                    v-model="supervisor.type"
                    item-title="name"
                    item-value="id"
                    :label="$t('summer.supervision-type')"
                    persistent-hint
                    density="compact"
                    :rules="supervisor_type_rule"
                    prepend-inner-icon="mdi-text"
                  ></VAutocomplete>
              </VCol>
            </VRow>
          </VForm>
        </v-card-text>
        <VCardActions class=" px-3" >
          <VBtn
            v-if="!is_update && $checkPermissions('summer_center.add_supervisor')"
            class="bg-indigo"
            @click="saveSupervisor()"
            size="small"
            :loading="saveDataLoading"
          >
            <span class="text-white">
              {{ $t("globals.add") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn
            v-if="
              is_update &&
              $checkPermissions('summer_center.change_supervisor', [
                'sys_admin',
                'gen_admin',
              ])
            "
            class="bg-success"
            @click="updateSupervisor()"
            size="small"
            :loading="editDataLoading"
          >
            <span class="text-white">
              {{ $t("globals.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm()">
            {{ $t("globals.clear") }}
            <VIcon icon="mdi-broom" color="golden" end></VIcon>
          </VBtn>
        </VCardActions>
      </v-card> -->
    
    <!-- ######## Header Of Data Table  ####### -->
    
        <!-- <v-row class="mt-1 mb-2">
          <v-col cols="4">
            <VTextSearch
              v-model="txt_search"
              @input="getSupervisors((page = 1))"
              class="search"
            >
            </VTextSearch>
          </v-col>
          <v-col cols="8" class="text-end">
            <v-btn-group density="compact" class="ms-2">
              <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props">
                    <v-icon class="me-2">mdi-eye-outline</v-icon>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-checkbox
                      v-for="header in headers"
                      :key="header.key"
                      :label="header.title"
                      :value="header.key"
                      color="indigo"
                      v-model="selectedHead"
                      density="compact"
                      hide-details
                    >
                    </v-checkbox>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-btn @click="addDialog = true">
                <span>{{ $t("globals.create") }}</span>
                <v-icon icon="mdi-plus-thick" color="success" end></v-icon>
              </v-btn>
            </v-btn-group>
          </v-col>
        </v-row> -->
        <!-- ######## End Header Of Data Table Server ####### -->
        <!-- <v-data-table-server
          :loading="loading"
          :headers="selectedHeaders"
          :items="tableList"
          v-model:sort-by="sortBy"
          item-value="name"
          item-key="id"
          id="vDataTable"
          fixed-footer
          density="compact"
          class=" mb-2 rounded elevation-1"
        >
          <template v-slot:item.type="{ item }">
            {{ item.value }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              variant="text"
              class="me-1 v-btn-icon"
              size="large"
              @click="editSupervisor(item.raw)"
              v-if="
                $checkPermissions('summer_center.change_supervisor', [
                  'sys_admin',
                  'gen_admin',
                ])
              "
            >
              <v-icon color="warning">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn
              variant="text"
              size="small"
              class="v-btn-icon"
              @click="
                (dialogVisible = true), (delete_id = item.raw.id), resetForm()
              "
              v-if="
                $checkPermissions('summer_center.delete_supervisor', [
                  'sys_admin',
                  'gen_admin',
                ])
              "
            >
              <v-icon color="error">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server> -->

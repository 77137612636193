<template>
  <!-- filter data  -->
  <v-card  v-if="!exist">
    <v-card-text>
      <h3 class="text-grey-darken-2 ">{{ $t("globals.select-criteria") }}</h3>
      <v-row class=" mt-4">
        <v-col cols="12" md="4" sm="6" >
          <VAutocomplete
            :items="weeks"
            v-model="filter_data.fk_week"
            item-title="week_name"
            item-value="id"
            :label="$t('summer.calendar.select-week')"
            persistent-hint
            prepend-inner-icon="mdi-calendar-week"
            density="compact"
            clearable
            :error-messages="v$.filter_data.fk_week.$errors.map(e=>e.$message)" 
            :rules="rules.requird_filed"
              ></VAutocomplete>
        </v-col>

        <v-col cols="6" sm="2" class=" mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="indigo"
            :loading="loading"
            @click="checkFilterData"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader>
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("report.positives_negatives") }} ( {{getWeekName(this.filter_data.fk_week) }} )</div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="my-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn density="compact"  @click="exist = false">
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0" rounded="0" flat>
          <h3 class="text-grey-darken-2 mt-3 mb-3">
            {{ $t("globals.positive") }} :
          </h3>
          <table class="report-t">
            <thead >
              <th class="bg-blue-grey-lighten-4 pa-2">
               {{$t('globals.#')}}
              </th>
              <th class="bg-blue-grey-lighten-4 pa-2">
               {{ $t("summer.calendar.week-name") }}
              </th>
              <th class="bg-blue-grey-lighten-4 pa-2">
               {{ $t("globals.address") }} 
              </th>
              <th class="bg-blue-grey-lighten-4 pa-2">
               {{ $t("summer.recommendations.implementation-officer") }} 
              </th>
              <th class="bg-blue-grey-lighten-4 pa-2">
               {{ $t("summer.initiative.note") }} 
              </th>
            </thead>
            <tbody>
              <tr  v-for="(item,key) in items" :key="item">
                <td style="width:50px">
                  {{ key+ 1 }}
                </td>
                <td>
                  {{ item.fk_week }}
                </td>
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ item.responsible }}
                </td>
                <td>
                  {{ item.note }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>

      <div id="myTable">
        <v-card class="pt-2" :loading="loading" v-if="negatives.length > 0" rounded="0" flat>
          <h3 class="text-grey-darken-2 mt-3 mb-3">
            {{ $t("globals.negative") }} :
          </h3>
          <table class="report-t">
            <thead >
              <th>
               {{$t('globals.#')}}
              </th>
              <th>
               {{ $t("summer.calendar.week-name") }}
              </th>
              <th>
               {{ $t("globals.address") }} 
              </th>
              <th>
               {{ $t("summer.recommendations.solutions") }} 
              </th>
              <th>
               {{ $t("summer.recommendations.implementation-officer") }} 
              </th>
              <th>
               {{ $t("summer.initiative.note") }} 
              </th>
            </thead>
            <tbody>
              <tr  v-for="(item, key) in negatives" :key="item">
                <td style="width:50px"
                >
                  {{ key +  1 }}
                </td>
                <td>
                  {{ item.fk_week }}
                </td>
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ item.solution }}
                </td>
                <td>
                  {{ item.responsible }}
                </td>
                <td>
                  {{ item.note }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "../../../components/SummerGlobals/ReportHeader";
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);

      await this.getWeeks();
      await this.getYears();

      this.current_year = this.current_year_name;
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },
      current_year: "",
      v$: useValidate(),
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      exist: false,
      items: [],
      negatives: [],
      loading: false,
      txt_search: "",
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
      current_year: (state) => state.current_year,
      current_year_name: (state) => state.current_year_name,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
    }),
    getYears() {
      this.year_data = this.current_year_name;
      this.filter_data.year = this.current_year;
      return this.year_data;
    },
  async checkFilterData(){
    
      this.v$.filter_data.$validate();
          if (!this.v$.filter_data.$error) {
            this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    getWeekName(driver_id) {
      if (driver_id) {
        const committees = this.weeks.find((committees) => committees.id === driver_id);
        return committees.week_name;
      }
      return "";
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/positives-negatives`, {
          params: {
            week: this.filter_data.fk_week,
          },
        })
        .then((response) => {        
            this.exist = true;
            this.items = response.data.positives;
            this.negatives = response.data.negatives;
         

        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
       this.loading = false;
    },
  },
    validations() {
    return {
      filter_data: {
        fk_week: {
            required: helpers.withMessage(
                this.$t("errors.required"),
                required
            ),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

#stable th {
  border: 1px solid #649add;
  background-color: #fff;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 4px;
}
.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}

.inside-th-cells {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 0px !important;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>

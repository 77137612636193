<template>
  <DataTable
    v-if="
      $checkPermissions('usermanage.view_rolesstatus', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      $checkPermissions('usermanage.delete_rolesstatus', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :edit_perm="
      $checkPermissions('usermanage.change_rolesstatus', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :add_perm="
      $checkPermissions('usermanage.add_rolesstatus', [
        'sys_admin',
        'gen_admin',
      ])
    "
  >
    <template v-slot:item.is_stopped="{ item }">
      <v-chip
        v-if="item.raw.is_stopped"
        color="error"
        size="medium"
        class="px-2 py-1"
      >
        {{ $t("globals.disabled") }}
      </v-chip>
      <v-chip v-else color="success" size="medium" class="px-2 py-1">
        {{ $t("globals.enabled") }}
      </v-chip>
    </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <v-col>
              <v-autocomplete
                v-model="item.role"
                :label="$t('user.role')"
                :items="roles_choices"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-account"
                @input="v$.item.role.$touch"
                :error-messages="v$.item.role.$errors.map((e) => e.$message)"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="item.is_stopped"
                :label="$t('globals.disabled')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="item.note"
                color="indigo"
                clearable
                :label="$t('summer.initiative.note')"
                no-resize
                counter="250"
                density="compact"
                rows="2"
                prepend-inner-icon="mdi-note-outline"
                @input="v$.item.note.$touch"
                :error-messages="v$.item.note.$errors.map((e) => e.$message)"
              ></v-textarea>
            </v-col>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers ,maxLength} from "@vuelidate/validators";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      saveDataLoading: false,
      deleteDataLoading: false,
      items: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      item: {
        role:null,
        is_stopped:false,
        note:null,
      },
      roles_choices:[],
      v$: useValidate(),
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("user.role"), key: "role_name" },
        { title: this.$t("summer.status"), key: "is_stopped" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    tableList() {
      if (this.items) {
        return JSON.parse(JSON.stringify(this.items));
      }
    },
    
  },
  validations() {
    return {
      item: {
        role: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(250)
          ),
        },
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getUserRoles();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    
    async getUserRoles(){
        const exclude_roles = [0,1,5,6,7]
        const roles =(await this.axios.get(`${this.base_url}user-role/`).then()).data
        if(roles){
            this.roles_choices = roles?.filter(role=>!exclude_roles.includes(role.id))
        }
    },
    ...mapActions({}),
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.reset();
      this.v$.$reset();
      this.item.is_stopped=false
      this.addDialog = false;
      this.is_update = false;
      this.updated_id = undefined;
    },
    editItem(talent) {
      this.item = Object.assign({}, talent);
      this.addDialog = true;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveItem() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveDataLoading = true;
        this.item.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "roles-status/", this.item)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error?.response?.data?.role[0].includes('موجود'))
              this.$emit(
                "warningAlert",
                this.$t("globals.error_in_repeted_data")
              );
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async updateItem() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveDataLoading = true;
        this.item.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}roles-status/${this.item.id}/`,this.item)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.saveDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            if (error?.response?.data?.role[0].includes('موجود'))
              this.$emit(
                "warningAlert",
                this.$t("globals.error_in_repeted_data")
              );
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        this.delete_id = delete_id;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}roles-status/${delete_id}/`, {
            params: {
              force_delete: this.force_delete,
            },
          })
          .then((response) => {
            status = true;
            this.deleteDataLoading = false;
            this.force_delete = false;
          })
          .catch((error) => {
            status = error;
            this.force_delete = false;
            if (error.message.includes("406")) {
              this.alert_cascade = true;
              this.alert_cascade_title = this.$t(
                "alert.attention.delete-talent-with-student"
              );
              this.data_message = error.response.data;
            }
            this.deleteDataLoading = false;
          });
        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
        if(ordering=='role_name') ordering='role'
        if(ordering=='-role_name') ordering='-role'
      this.loading = true;
      await this.axios(`${this.base_url}roles-status/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.items = response.data.results;
        this.pagination = response.data.pagination;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.NumberOfStudentsByLevels") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pl-0">
            <v-sheet class="px-2 chart-label-sheet" elevation="0" rounded="0">
              {{
                $t("reportpublic.ATotal") +
                " " +
                $t("reportpublic.NumberOfStudentsByLevels")
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ results.total_student_gov }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-container>
        <v-row>
          <table class="report-t">
            <thead>
              <tr>
                <th rowspan="2" style="width: 50px;">{{$t("globals.#")}}</th>

                <th rowspan="2">
                  {{ $t("reportpublic.Governorate") }}
                </th>
                <th :colspan="levels.length">
                  {{ $t("reportpublic.LevelsName") }}
                </th>
                <th rowspan="2">{{ $t("reportpublic.Total") }}</th>
              </tr>
              <tr>
                <th v-for="(val, key) in levels" :key="key" class="">
                  {{
                    this.$i18n.locale == "ar"
                      ? val.level_name_ar
                      : val.level_name_en
                  }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(val, key) in results.data" :key="key">
              <tr>
                <td>{{key+1}}</td>
                <td>
                  {{
                    this.$i18n.locale == "ar"
                      ? val.gov_name_ar
                      : val.gov_name_en
                  }}
                </td>
                <td class="text-center" v-for="(v, k) in val.dataLevel" :key="k">{{ v.count }}</td>
                <td class="text-center">
                  {{ val.total_student_gov }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2" class="right-footer rounded-be">
                  {{ $t("reportpublic.GrandTotal") }}
                </th>
                <th v-for="(val, key) in levels" :key="key" class="rounded-bs">
                  {{ val.count_student_gov }}
                </th>
                <th class="rounded-bs">{{ results.total_student_gov }}</th>
              </tr>
            </tfoot>
          </table>
        </v-row>
      </v-container>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  data() {
    return {
      results: [],
      levels: [],
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  async mounted() {},
  methods: {
    async fetchData() {
      await  this.axios
        .get(`${this.base_url}public-reports/daily-reports/summer-number-of-student-according-to-level`)
        .then((response) => {
          this.results = response.data.results;
          this.levels = response.data.results.data_level_gov;
        })
        .catch((error) => {
        });
    },
    computed: {
      
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  components: {
    ReportHeader,
  },
};
</script>

import Vuex from "vuex";
import host from "./../main";
import router from "@/router";
import globals from "./Globals/globals.module";
import school from "./School/school.module";
import summer_globals from "./Globals/summer_globals.module";
import users from "./Users/users.module";
import summer from "./Summer/summer.module";
import custom_axios from "@/plugins/axios.js";
import jwt_decode from "jwt-decode";
import {decryptString, encryptString} from '@/utils/index.js'
 

const store= new Vuex.Store({    
    state:{
            Bearer:undefined,
            User:undefined,
            permissions:['school.dashboard'],
            lang:'ar',
            role:undefined,
            direction:'rtl',
            expire:undefined,
            isAuthenticated:false,
            directorate:undefined,
            governorate:undefined,
            schoolsummer:undefined,
            username:undefined,
            academic_year:null,
            login_error:false,
            loading:false,
            drawer:true,
            role_list:['sys_admin','gen_admin','gov_admin','dir_admin','summer_admin','user','student','parent','gov_financial','gen_financial'],
            API_URL:'http://localhost:5000/api/token/',
            breadcrumb:[],
            error_connection:false,
            timeout_alert:false,
            read_only_alert:false,
            circulars:[],
            logout_alert:false,
            user_governorate:null,
            user_directorate:null,
            user_school_summer:null
    },
    mutations:{
        setRole(state,role){
            state.role=role
        },
        setUserData(state,userData){
          try {
            state.User = userData.user_id
            state.role = userData.role
            state.permissions = userData.permissions
            state.username = userData.username
            state.user_governorate= userData.governorate
            state.user_directorate= userData.directorate
            state.user_school_summer= userData.schoolsummer
            state.isAuthenticated = true
          } catch (error) {
          }
        },
        setAcademicYear(state,academic_year){
          state.academic_year = academic_year
        },
        setErrorConnection(state,value){
          state.error_connection=value
        },
        setCirculars(state,value){
          state.circulars=value
        },
        setTimeoutAlert(state,value){
          state.timeout_alert=value
        },
        setReadOnlyAlert(state,value){
          state.read_only_alert=value
        },
        updateBreadcrumbValue(state,value){
            state.breadcrumb=value
        },
        updateLoadingValue(state,value){
            state.loading=value
        },
        updateDrawer(state,value){
            state.drawer=value
        },
        setAuthenticated(state,bearer){
          state.Bearer=bearer 
        },
        setPermission(state,permission ){          
          state.permissions=permission
        
        },
        setUser(state,user ){          
            state.User=user            
        },
        logout(state,value){
          
            state.Bearer=''
            // state.permissions=value 
            state.User='' 
            state.isAuthenticated = false
            
        },
        setlang(state,lang){
            state.lang=lang
        },
        setdir(state,dir){
            state.direction=dir
        },
        setExpire(state,expire){
            state.expire=expire;
        },
        setUsername(state,username){
            state.username=username;
        },
        setLoginError(state){
            state.login_error=true;
        },
        setDirectorate(state,directorate){
          state.directorate=directorate
        },
        setGovernorate(state,governorate){
          state.governorate=governorate
        },
        setSchoolSummer(state,schoolsummer){
          state.schoolsummer=schoolsummer
        }
    },
    getters:{
        getrole:(state)=>state.role,

        getbrebar:(state)=>state.Bearer,      
        getpermission:(state)=>state.permissions,
        getuser:(state)=>state.User, 
        getdrawer:(state)=>state.drawer,    
        checkrole:(state)=>(role=[]) => role.includes(state.role_list[state.role]),
        checkpermission:(state)=>(perm,role=[])=>{
          if((role.includes(state.role_list[state.role]) || role.length == 0) &&
            state.permissions?.includes(perm))
            return true
          else
            return false
        },
        // checkpermission:(state)=>(perm,role=[])=>{
        //   if((role.includes(state.role_list[state.role]) || role.length == 0) &&
        //     localStorage.getItem('userinfo').includes(perm))
        //     return true
        //   else
        //     return false
        // },
       
        getlang:(state)=>state.lang,
        

        getexpire:(state)=>state.expire,
        getdir:(state)=>state.direction,

        header(state){
            return 'Bearer '+state.Bearer
        }


      }, 
  
    actions:{
      
      setLogoutAlert({state},val){
        state.logout_alert = val
      },
      async setAuthenticated({commit,getters,state,dispatch},response ){
          if(response && response.data){
            commit('setUserData',JSON.parse(decryptString(response.data.user_data)))
              if(response.data['must_change_password']){
                router.push({name:'user_profile'})
                localStorage.setItem('must_change_password',true)
              }else{
                router.push('/') 
              }
              
          }
        
      },
      async getUserData({commit},response){
        await custom_axios.get(host()+'api/token/payload').then(res=>{
          const userData =JSON.parse(decryptString(res.data.user_data))
          return commit("setUserData", userData);
        }).catch((err)=>{
          return commit("setUserData", {});
        })
      },
    async updatePermission({ commit }, response) {
      try {
        // commit("setPermission", response);
      } catch (error) {
        commit("setLoginError");
      }
    },
    async updateAuthenticated({ commit }, response) {
      try {
        const decoded = jwt_decode(response);
        commit("setExpire", decoded.exp);
        commit("setAuthenticated", response);
        commit("setUser", decoded.user_id);
      } catch (error) {
        commit("setLoginError");
      }
    },
    setAcademicYear({commit},val){
      console.log(val);
      commit("setAcademicYear",val)
    },
    updatelanguage({ commit }, value) {
      commit("setlang", value);
    },
    updatedirection({ commit, state }, value) {
      commit("setdir", value);
    },
    async logout({ commit,state }) {
      if(state.isAuthenticated){
        await custom_axios.post(`${host()}api/logout`)
      }
      commit("logout");
      router.push({name:'login'})
    },
    async reloadSystem({ commit ,state}){
      await custom_axios.get(`${host()}/api/token/payload`).then(res=>{
        const userData = JSON.parse(decryptString(res.data.user_data))
        commit('setUserData',userData)
      }).catch(err=>{
        commit("setUserData", {});
        state.isAuthenticated = false;
      })


    }
  },
  modules: {
    globals,
    users,
    school,
    summer,
    summer_globals,
  },
});

export default store;

<template>
    <v-form  ref="form"  v-if="$checkPermissions('summer_center.add_marksummer',['sys_admin','summer_admin','user'])">
        <v-expansion-panels model-value="1" >
            <v-expansion-panel   value="1">
                <v-expansion-panel-title class="px-4">
                    <span>{{$t('globals.select-criteria')}}</span>
                </v-expansion-panel-title>
                <v-expansion-panel-text >
                    <v-row class="py-2" >
                        <v-col cols="6" md="3" >
                            <v-autocomplete
                                autofocus
                                color="indigo"
                                v-model="weekly_marks.fk_week" 
                                prepend-inner-icon="mdi-calendar-week"
                                density="compact"
                                item-title="week_name"
                                item-value="id"
                                :label="$t('summer.calendar.week-name')"
                                :items="weeks"
                                hide-details="auto"
                                clearable
                                :error-messages="v$.weekly_marks.fk_week.$errors.map((e) => e.$message)"
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-autocomplete
                                color="indigo"
                                v-model="weekly_marks.fk_level" 
                                density="compact"
                                item-title="level_with_phase_name"
                                item-value="id"
                                prepend-inner-icon="mdi-stairs-box"
                                :items="levels"
                                :label="$t('summer.level.name_ar')"
                                hide-details="auto"
                                clearable
                                :error-messages="v$.weekly_marks.fk_level.$errors.map((e) => e.$message)"
                                @update:modelValue="weekly_marks.fk_division = undefined"
                            />
                        </v-col>
                        <v-col cols="6" md="3" >
                            <v-autocomplete
                                color="indigo"
                                v-model="weekly_marks.fk_division" 
                                density="compact"
                                item-title="name"
                                prepend-inner-icon="mdi-shape-outline"
                                item-value="id"
                                :label="$t('division.name')"
                                :no-data-text="$t('division.not-found')"
                                :items="level_divisions"
                                hide-details="auto"
                                clearable
                                :error-messages="v$.weekly_marks.fk_division.$errors.map((e) => e.$message)"

                            />
                        </v-col>
                        <v-col cols="6" md="3" class="mt-1">
                            <VBtn
                                class="bg-indigo"
                                size="small"
                                @click="getStudents()"
                                :loading="loading"
                            >
                                <span class="text-white">
                                {{ $t("globals.show") }}
                                </span>
                                <VIcon icon="mdi-presentation" color="white" end></VIcon>
                            </VBtn>
                            </v-col>
                    </v-row>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-progress-linear 
            v-if="loading" 
            color="indigo"
            indeterminate
            height="2px"
            :reverse="$i18n.locale=='ar'"
        >
        </v-progress-linear>
        <v-card class="justify-center mt-2" v-if="exist" > 
            <v-card-text class="pb-0">
                <v-row  class="mt-1" >
                    <v-col cols="12" md="6">
                        <v-text-field
                            color="indigo"
                            v-model="search_student" 
                            @input="searchStudentMarks"
                            density="compact"
                            append-inner-icon="mdi-magnify"
                            prepend-inner-icon="mdi-account"
                            :label="$t('globals.student-search')"
                        />
                    </v-col>
                </v-row>
                <v-card>
                    <v-table density="compact">
                        <thead>
                            <tr >
                                <th >{{$t('globals.number')}}</th>
                                <th class="text-center">{{$t('school.student-name')}}</th>
                                <th class="text-center">{{$t('summer.marks.quran')}}</th>
                                <th class="text-center">{{$t('summer.marks.approach')}}</th>
                                <th class="text-center">{{$t('summer.marks.activity')}}</th>
                                <th class="text-center">{{$t('summer.marks.ehsan')}}</th>
                                <th class="text-center">{{$t('summer.marks.attendance')}}</th>
                                <th class="text-center">{{$t('globals.note')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="student_weekly_mark,index in weekly_marks.student_weekly_mark" :key="student_weekly_mark">
                                
                                <td >{{index+1}}</td>
                                <td  style="min-width:200px; max-width:200px;">
                                    <v-lazy>
                                        {{student_weekly_mark.student_name}}
                                    </v-lazy>
                                </td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            class="mt-1 "
                                            v-model="student_weekly_mark.quran"
                                            @input="student_weekly_mark.quran==''?student_weekly_mark.quran=null:''"
                                            density="compact"
                                            type="number"
                                            variant="underlined"
                                            :rules="quran_rule"
                                            hide-details="auto"
                                            @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''"
                                            >
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                                <td  style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            class="mt-1"
                                            v-model="student_weekly_mark.approach"
                                            density="compact"
                                            type="number"
                                            variant="underlined"
                                            style="text-align:center;"
                                            @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''"
                                            :rules="approach_rule"
                                            @input="student_weekly_mark.approach==''?student_weekly_mark.approach=null:''"
                                            hide-details="auto"
                                            >
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            v-model="student_weekly_mark.activity"
                                            density="compact"
                                            hide-details="auto"
                                            variant="underlined"
                                            type="number"
                                            @input="student_weekly_mark.activity==''?student_weekly_mark.activity=null:''"
                                            :rules="activity_rule"
                                            class="my-1"
                                            @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''"
                                        >
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                        v-model="student_weekly_mark.ahsan"
                                        density="compact"
                                        hide-details="auto"
                                        variant="underlined"
                                        @input="student_weekly_mark.ahsan==''?student_weekly_mark.ahsan=null:''"
                                        type="number"
                                        :rules="ahsan_rule"
                                        class="my-1"
                                        @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''"
                                    >
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                                <td style="min-width:100px;max-width:100px; ">
                                    <v-lazy>
                                        <v-text-field
                                            v-model="student_weekly_mark.attendance"
                                            density="compact"
                                            type="number"
                                            hide-details="auto"
                                            variant="underlined"
                                            @input="student_weekly_mark.attendance==''?student_weekly_mark.attendance=null:''"
                                            :rules="attendnace_rule"
                                            class="my-1"
                                            @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''"
                                        >
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                                <td >
                                    <v-lazy>
                                        <v-text-field
                                            v-model="student_weekly_mark.note"
                                            style="min-width:250px;"
                                            density="compact"
                                            variant="underlined"
                                            :placeholder="$t('globals.note')"
                                            hide-details="auto"
                                            :rules="note_max_entry"
                                            class="my-1"
                                            @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''"
                                        >
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-card-text>
            <v-card-actions class="mx-2 pt-0">
                <v-btn v-if="$checkPermissions('summer_center.add_marksummer',['sys_admin','summer_admin','user'])" @click="saveClassMarks" :loading="saveLoading"   class="bg-indigo"  size="small">
                    <span class="text-white">
                        {{$t('globals.save')}}
                    </span>
                    <v-icon icon="mdi-content-save" color="white" end></v-icon>
                </v-btn>
                <v-btn  @click="cleanStudentSubjectMark(),resetFilters()" class="mx-3 bg-ccc"  size="small">
                    <span>
                        {{$t('globals.clear')}}
                    </span>
                    <v-icon icon="mdi-broom" color="golden" end></v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else-if="exist==false" class="pa-2 ma-2" >
        <h3 class="text-center text-grey-darken-2">
          {{ $t("globals.not-found-items") }}
        </h3>
      </v-card>
    </v-form> 

<Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
    >
</Alert>
</template>

<script>
 
import useValidate from '@vuelidate/core'
import {mapState,mapActions,mapGetters} from 'vuex'
import {required,helpers,maxLength,numeric} from '@vuelidate/validators'
export default{
    async created(){
        try {
            await this.$store.commit('updateLoadingValue',true)
            await this.getLevels()
            await this.getDivisions()
            await this.getWeeks()
            this.getClassAssignHall()
            this.$store.commit('updateLoadingValue',false)
        } catch (error) {
            this.$store.commit('updateLoadingValue',false)
            
        }
    },
    data(){
        return{
            v$: useValidate(),
            student_mark_list:[],
            weekly_marks:{
                fk_level:undefined,
                fk_week:undefined,
                fk_division:undefined,
                fk_student:undefined,
                data_entry:undefined,
                student_weekly_mark:[]
            },
            valid:false,
            loading:false,
            student_list:[],
            search_student:'',
            exist:undefined, 
            quran_rule:[
            // (value) => !!value || this.$t('globals.required_field'),
            (value) => (!value || /^[-0-9]+$/.test(value)) || this.$t('errors.int'),
            (value) => (!value || value <= 40) || `${this.$t('globals.biggest_number_of_character')} 40`,
            (value) => (!value || value >= 0) || `${this.$t('globals.lowest_number_of_character')} 0`,
            ],
            approach_rule:[
            // (value) => !!value || this.$t('globals.required_field'),
            (value) => (!value || /^[-0-9]+$/.test(value)) || this.$t('errors.int'),
            (value) => (!value || value <= 20) || `${this.$t('globals.biggest_number_of_character')} 20`,
            (value) => (!value || value >= 0) || `${this.$t('globals.lowest_number_of_character')} 0`,
            ],
            attendnace_rule:[
            // (value) => !!value || this.$t('globals.required_field'),
            (value) => (!value || /^[-0-9]+$/.test(value)) || this.$t('errors.int'),
            (value) => (!value || value <= 10) || `${this.$t('globals.biggest_number_of_character')} 10`,
            (value) => (!value || value >= 0) || `${this.$t('globals.lowest_number_of_character')} 0`,
            ],
            activity_rule:[
            // (value) => !!value || this.$t('globals.required_field'),
            (value) => (!value || /^[-0-9]+$/.test(value)) || this.$t('errors.int'),
            (value) => (!value || value <= 20) || `${this.$t('globals.biggest_number_of_character')} 20`,
            (value) => (!value || value >= 0) || `${this.$t('globals.lowest_number_of_character')} 0`,
            ],
            ahsan_rule:[
            // (value) => !!value || this.$t('globals.required_field'),
            (value) => (!value || /^[-0-9]+$/.test(value)) || this.$t('errors.int'),
            (value) => (!value || value <= 10) || `${this.$t('globals.biggest_number_of_character')} 10`,
            (value) => (!value || value >= 0) || `${this.$t('globals.lowest_number_of_character')} 0`,
            ],
            total_rule:[
                // (value) => !!value || this.$t('globals.required_field'),
                (value) => (!value || /^[-0-9]+$/.test(value)) || this.$t('errors.int'),
                (value) => (!value || value <= 100) || `${this.$t('globals.biggest_number_of_character')} 100`,
                (value) => (!value || value >= 0) || `${this.$t('globals.lowest_number_of_character')} 0`,
                ],
            
            note_max_entry:[
                value =>!value ||value.length<250 || ''
            ],
            process_alert:false,
            icon_color:undefined,
            icon:undefined,
            alert_title:undefined,
            alert_message:undefined,
            saveLoading:false
        }
    },
    computed:{
        
        ...mapState({
            levels:(state)=>state.summer.levels,
            divisions:(state)=>state.summer.divisions,
            weeks:(state)=>state.summer.weeks,
            user: (state) => state.User,
        }),
        
        ...mapGetters({
            getClassDivisions:"summer/getClassDivisions",
        }),
        level_divisions(){
            if(this.weekly_marks.fk_level){
                return this.getClassDivisions(this.weekly_marks.fk_level)
            }
        },
        
        
    },

    validations(){
        return{
            weekly_marks:{
                fk_level: {
                    required:helpers.withMessage(this.$t('errors.required'),required),
                },
                fk_division: {
                    required:helpers.withMessage(this.$t('errors.required'),required),
                },
                fk_week: {
                    required:helpers.withMessage(this.$t('errors.required'),required),
                },
            },
        }
    },
    methods:{
        isRecordChanged(item){
            item['changed']=true
        },
        ...mapActions({
            getLevels:"summer/getLevels",
            getDivisions: "summer/getDivisions",
            getWeeks: "summer/getWeeks",
            getClassAssignHall:"summer/getClassAssignHall"

            

        }),
        checkClassMark(){
            this.v$.weekly_marks.$validate()
            if(!this.v$.weekly_marks.$error){
                this.getStudents()
            }
            
        },
        searchStudentMarks(){
            if(this.search_student!=''){
                this.weekly_marks.student_weekly_mark=this.student_list.filter(student=>{
                return student.student_name.includes(this.search_student)
                })
            }
            else{
                this.weekly_marks.student_weekly_mark=this.student_list
            }
        },
        async getStudents(){
            this.exist=undefined
            this.weekly_marks.student_weekly_mark=[]
            this.v$.$validate()
            if(!this.v$.weekly_marks.$error){
                this.loading=true
                await this.axios.get(`${this.base_url}api/summer/weekly-marks/`,{
                    params:{
                        fk_level:this.weekly_marks.fk_level,
                        fk_division:this.weekly_marks.fk_division,
                        fk_week:this.weekly_marks.fk_week,
                    }
                }).then(response=>{
                    if(response.data.length>0){
                        this.exist=true
                        this.student_list=response.data
                        this.weekly_marks.student_weekly_mark=response.data
                        this.loading=false
                    }
                    else
                        this.exist=false
                        this.loading=false
                }).catch(error=>{
                    this.loading=false
                    this.exist=false
                })
            }
            
        },
        async saveClassMarks(){

            this.v$.$validate()
            const { valid } = await this.$refs.form.validate()
            if(!this.v$.weekly_marks.$error && valid){
                this.saveLoading=true
                this.weekly_marks.data_entry=this.user
                const students_marks=this.weekly_marks.student_weekly_mark.filter(student=>student.changed==true)
                let weekly_students_marks=Object.assign({},this.weekly_marks)
                weekly_students_marks.student_weekly_mark=students_marks
                await this.axios.post(`${this.base_url}api/summer/weekly-marks/`,weekly_students_marks).then(response=>{
                    // this.getStudents()
                    this.icon_color="success"
                    this.icon="check-circle"
                    this.alert_title=this.$t('alert.success.title')
                    this.alert_message=this.$t('alert.success.save')
                    this.saveLoading=false
                    
                }).catch((error)=>{
                    this.icon_color="error"
                    this.icon="cancel"
                    this.alert_title=this.$t('globals.failure')
                    this.alert_message=this.$t('globals.error_in_data')
                    this.saveLoading=false
                })
                this.process_alert=true
                // this.cleanStudentSubjectMark()
                setTimeout(()=>{
                    this.process_alert=false
                },2000)
            }
            else{
                this.icon_color="error"
                this.icon="cancel"
                this.alert_title=this.$t('globals.failure')
                this.alert_message=this.$t('globals.error_in_data')
                this.process_alert=true
                setTimeout(()=>{
                    // this.getStudents()
                    this.process_alert=false
                },2000)
            }
        },
        cleanStudentSubjectMark(){
            this.v$.weekly_marks.$reset()
            this.weekly_marks.student_weekly_mark=[]
            this.weekly_marks.fk_division=null
            this.exist=undefined
        },
        resetFilters(){
            this.weekly_marks.fk_division=null
            this.weekly_marks.fk_week=null
            this.weekly_marks.fk_level=null
        }
    },
}
</script>
<style scoped>

</style>
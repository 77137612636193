<template>
  <v-expansion-panels  >
    <v-expansion-panel   >
      <v-expansion-panel-title class="px-4">
          <span>{{$t('globals.select-criteria')}}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text >
        <v-row class="py-2" >
          <VCol  cols="12" md="4">
            <VAutocomplete
              :items="levels"
              v-model="studenttalent.level"
              prepend-inner-icon="mdi-stairs-box"
              item-title="level_with_phase_name"
              item-value="id"
              :label="$t('summer.level.name_ar')"
              persistent-hint
              density="compact"
              hide-details="auto"
              clearable
              @update:modelValue="
                getDivisionByLevel(),getStudentsbylevel(),
                  (this.studenttalent.division = null),
                  (this.student_levels = [])
              "
            ></VAutocomplete>
          </VCol>
          <VCol  cols="12" md="4">
            <VAutocomplete
              :items="divisions"
              v-model="studenttalent.division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              hide-details="auto"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable
              persistent-hint
              density="compact"
              @update:modelValue="getStudentsbylevel"
            ></VAutocomplete>
          </VCol>
          <VCol  cols="12" class="mt-2">
            <VAutocomplete
              :items="initiatives"
              v-model="initiatives_selected"
              return-object
              hide-details="auto"
              prepend-inner-icon="mdi-handshake"
              item-title="name_ar"
              multiple
              chips
              closable-chips
              clearable
              :item-value="item=>item.id"
              :label="$t('summer.initiative.name')"
              persistent-hint
              density="compact"
              
            >
              <template v-slot:chip="{props , item}">
                <v-chip 
                  class="mx-1"
                  style="font-size:0.9rem"
                  v-bind="props" :text="item.raw.name">
                </v-chip>
              </template>
            </VAutocomplete>
          </VCol>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <VCard class="justify-center mt-2" :loading="loading" >
    <template v-slot:loader>
      <v-progress-linear 
        color="indigo" 
        :reverse="$i18n.locale=='ar'"
        height="3px" 
        indeterminate 
        v-if="loading"
      >
      </v-progress-linear>
    </template>
    <v-card-text class="pb-0">
      <VForm ref="form" >
        <VRow class="mb-1 mt-1" >
          <VCol cols="12" md="4" >
            <v-text-field
              color="indigo"
              v-model="search_student"
              @input="getStudentsbylevel()"
              density="compact"
              hide-details="auto"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('globals.student-search')"
            />
          </VCol>
        </VRow>
        <div v-if="student_levels.length>0" class="mt-2  elevation-1 rounded overflow-x-auto">
          <v-table  
            fixed-header
            :height="student_levels?.length>9?400:null"
            density="compact" 
            class="w-100"  >
            <thead>
              <tr>
                <th class="text-center" >{{ $t("globals.number")}}</th>
                <th style="min-width:200px">{{$t('school.student-name')}}</th>
                <th v-for="initiative in filter_initiatives " :key="initiative">
                  <label class="d-flex align-center ">
                    <v-checkbox 
                      v-model="initiative.checked"
                      :value="initiative.id"
                      hide-details
                      @change="addInitiativeForAllStudents(initiative.id,initiative.checked)"
                      density="compact" 
                      color="indigo"
                      style="max-width:25px" 
                      inline
                      >
                    </v-checkbox>
                    <span  class="v-label flex-fill">{{initiative.name_ar}}</span>
                  </label>

                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(student,index) in student_levels" :key="student" >
                <td class="text-center">{{index+1}}</td>
                <td >
                  <span>{{student.student_name}}</span>
                </td> 
                <td v-for="talent in filter_initiatives" :key="talent" > 
                  <v-lazy>
                    <v-checkbox 
                      v-model="student.initiative"
                      :value="talent.id"
                      hide-details
                      density="compact" 
                      color="indigo" 
                      >
                    </v-checkbox>
                  </v-lazy>
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-divider/>
              <div class="d-flex mt-1 "  id="pagination-bar" >
                <v-pagination  
                    v-model="page" 
                    :length="pagination.num_pages"   
                    density="compact"
                    show-first-last-page
                    >
                </v-pagination>
                <div class="d-flex ">
                  <v-select 
                    v-model="perPage" 
                    class="pa-0" 
                    :items="itemsPerPage"  
                    item-value="value" 
                    item-title="text" 
                    density="compact" 
                    hide-details
                    variant="text"
                    >
                  </v-select>
                  <span class="mt-2 px-2">{{$t('globals.per-page')}} {{$t('globals.from')}} [ {{pagination?.count}} ] {{$t('globals.item')}}</span>
                </div>
              </div>
        </div>
        <v-card  class="pa-2 my-2 text-center" v-else>
          <span >
            {{ $t("globals.not-found-items") }}
          </span>
        </v-card>
      </VForm>
    </v-card-text>
    <VCardActions
      class="px-3"
      v-if="
        student_levels.length>0
      "
    >
       <VBtn
        :loading="loadingSave"
        v-if="
          $checkPermissions('summer_center.add_studentsummer',['sys_admin','summer_admin','user'])||
          $checkPermissions('summer_center.add_studentsummer',['sys_admin','summer_admin','user'])
        "
        class="bg-indigo"
        @click="saveData"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.save") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
    
    </VCardActions>
  </VCard>
</template>
<script>
import { mapState, mapActions,mapGetters } from "vuex";
export default {
  data() {
    return {
      element :[],
      students:[],
      perPage:10,
      itemsPerPage: [
        {value:10,text:"10"},
        {value:25,text:"25"},
        {value:50,text:"50"},
        {value:100,text:"100"},
        {value:null,text:this.$t("globals.all")},
      ],
      pagination:{},
      page:1,
      length:null,
      totalItems:null,
      search_student:'',
      initiatives_selected:[],
      filter_initiatives:[],
      loading:false,
      student_levels: [],
      loadingSave:false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      divisions: [],
      studenttalent: {
        division: undefined,
        level: undefined,
      },
      add_initiative:null
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.fk_year = this.current_year;
      await this.getLevels();
      await this.getInitiatives();
      await this.getInitiativesStudents();
      await this.getStudentsbylevel()
      const  not_assigned_initiative=this.initiatives.filter(initiative=>!this.student_levels.some(student=>student.initiative.includes(initiative.id))).map(el=>{
        return {
          id:el.id,
          name_ar:el.name_ar,
          checked:false

        }
      })
      if(this.initiatives.length>0){
        if(not_assigned_initiative.length==0){
          this.filter_initiatives=[this.initiatives[this.initiatives.length-1]]
        }else{
          this.filter_initiatives=not_assigned_initiative
        }
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      initiatives_students: (state) => state.summer.initiatives_students,
      students: (state) => state.summer.students,
      user: (state) => state.User,
      initiatives:(state)=> state.summer.initiatives,
      current_year: (state) => state.current_year,
      
    }),
    ...mapGetters({
      getClassDivisions: "summer/getClassDivisions",
    }),
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",
      getStudents: "summer/getStudents",
      getInitiativesStudents: "summer/getInitiativesStudents",
      getInitiatives: "summer/getInitiatives",
    }),
    isCheckAll(){
      this.filter_initiatives.forEach(init=>{
        const checked=this.student_levels.every(student=>student.initiative.includes(init.id))
        if(checked){
          init.checked=init.id
        }else{
          init.checked=false
        }
      })
    },
    addInitiativeForAllStudents(id,check){
      if(check){
        this.student_levels.forEach(student=>{
          student.initiative.push(id)
        })
      }else{
        this.student_levels.forEach(student=>{
          student.initiative=student.initiative.filter(initiative=>initiative!=id)
        })
      }
    },
    async getStudentsbylevel() {
      this.loading=true
      let student_level = await this.axios.get(
        this.base_url + "api/summer/get-student-by-level-and-division",
        {
          params: {
            level: this.studenttalent.level,
            division: this.studenttalent.division,
            page: this.page,
            search_txt:this.search_student,
            page_size: this.perPage,
          },
        }
      );
      this.loading=false
      let students = student_level.data.results;
      this.pagination= student_level.data?.pagination
      
      this.student_levels=students
    },
    async getDivisionByLevel() {
      if (this.studenttalent.level) {    
        let levelDivisions = await this.axios.get(this.base_url + `api/summer/get-level-division/${this.studenttalent.level}`);
        this.divisions = levelDivisions.data.division;
    }
   },
    resetForm() {
      this.initiatives=[];
      this.filter_initiatives=[];
      
      this.studenttalent.division=null
      this.studenttalent.level=null
      this.search_student=''
      this.initiatives_selected=[]
      this.studenttalent = {};
      this.studentList = [];
     
    },
    async saveData() {
      this.loadingSave=true
      this.student_levels.data_entry = this.user;
      let result = await this.axios
        .post(this.base_url + `api/summer/student-initiative`, this.student_levels)
        .then((response) => {
          this.loadingSave=false
          this.$emit("successAlert", this.$t("globals.data_added"));
        })
        .catch((error) => {
          this.loadingSave=false
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  watch:{
    page(){
      this.getStudentsbylevel()
    },
    filter_initiatives(){
      this.isCheckAll()
    },
    initiatives_selected(){
      if(this.initiatives_selected.length>0){
        this.filter_initiatives=this.initiatives_selected
      }else{
        this.filter_initiatives=this.initiatives
      }
    },
    student_levels:{
      deep:true,
      handler(){
        this.isCheckAll()
      }
    }
    
  }
};
</script>
<style scoped>
.v-label{
  overflow: visible !important;
}
.v-input--density-compact {
    --v-input-control-height: 35px;
    --v-input-padding-top: 8px;
}
.select-container {
  height: 280px;
  box-shadow: rgb(0, 0, 0) -5px 5px 3px -5px inset;
  overflow-y: auto;
}
td span{
  padding: 0 !important;
  font-size: 12px !important;
}
</style>

<template>
  <v-card class="justify-center">
    <v-card-title>
      <v-icon icon="mdi-timetable" />
      <h3 class="d-inline" style="padding: 0 4px">
        {{ $t("weekly-schedule.update") }}
      </h3>
      <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
    </v-card-title>
    <v-card-text class="mb-4">
      <v-row class="justify-center">
        <v-col cols="12">
          <!-- <v-card class="pa-2 ma-auto" > -->
          <v-form
            ref="form"
            @submitSchoolWeeklySchedule.prevent="saveSchoolWeeklySchedule"
          >
            <v-row class="my-4">
              <v-col cols="6" md="4">
                <v-autocomplete
                  color="indigo"
                  v-model="weekly_schedule.fk_level"
                  density="compact"
                  item-title="level_with_phase_name"
                  item-value="id"
                  prepend-inner-icon="mdi-stairs-box"
                  :items="levels"
                  clearable
                  @update:modelValue="
                    getSubjectsWithTeacher(),
                      (weekly_schedule.fk_division = null),
                      (found = false)
                  "
                  :label="$t('summer.level.name_ar')"
                  hide-details
                />
              </v-col>
              <v-col cols="6" md="4">
                <v-autocomplete
                  color="indigo"
                  v-model="weekly_schedule.fk_division"
                  density="compact"
                  item-title="name"
                  prepend-inner-icon="mdi-home-outline"
                  item-value="id"
                  :label="$t('division.name')"
                  :no-data-text="$t('division.not-found')"
                  @update:modelValue="getSubjectsWithTeacher(), checkAllData()"
                  :items="level_divisions"
                  hide-details
                  clearable
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  color="indigo"
                  v-model="weekly_schedule.period_am"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  prepend-inner-icon="mdi-format-list-bulleted"
                  clearable
                  :items="[
                    { id: true, name: $t('globals.am') },
                    { id: false, name: $t('globals.pm') },
                  ]"
                  :label="$t('school.schedule_period')"
                  @update:modelValue="checkAllData"
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- <small v-if="!checkSchedules&&!update" >shehab alfareh</small> -->
            <v-progress-linear
              color="indigo"
              indeterminate
              v-if="loading"
              height="2px"
              :reverse="$i18n.locale == 'ar'"
            >
            </v-progress-linear>
            <v-card class="mt-4" v-if="found">
              <v-table>
                <thead>
                  <tr>
                    <th>{{ $t("globals.day") }}/{{ $t("globals.lecture") }}</th>
                    <th v-for="schedule in timetable_schedules" :key="schedule">
                      {{ schedule.quotas }}
                      <br />
                      <small>
                        {{ schedule.quotas_start_time }}
                        -
                        {{ schedule.quotas_end_time }}
                      </small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="day in weekly_schedule.days" :key="day">
                    <td>
                      <span>{{ getDayName(day.fk_day) }}</span>
                    </td>
                    <td
                      v-for="subject in day.subjects"
                      :key="subject"
                      class="justify-center"
                    >
                      <v-autocomplete
                        v-model="subject.fk_subject"
                        density="compact"
                        item-title="subject_name"
                        clearable
                        item-value="fk_subject"
                        :no-data-text="$t('subject.not-found')"
                        :items="subjects"
                        hide-details
                        :placeholder="$t('subject.select')"
                        variant="underlined"
                        @update:modelValue="
                          subject.fk_employee = determineTeacher(
                            subject.fk_subject
                          )
                        "
                      >
                      </v-autocomplete>
                      <!-- <div class="d-none" v-if="subject.fk_subject!==null">
                                                {{subject.fk_teacher=determineTeacher(subject.fk_subject)}}
                                            </div> -->
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <v-card-actions>
                <v-btn
                  @click="updateWeeklySchedule"
                  v-if="
                    $checkPermissions(
                      'summer_center.change_schoolweeklyschedule',
                      ['sys_admin', 'gen_admin']
                    )
                  "
                  class="bg-success"
                  size="small"
                >
                  <span class="text-white">
                    {{ $t("globals.edit") }}
                  </span>
                  <v-icon icon="mdi-content-save" color="white" end></v-icon>
                </v-btn>
                <v-btn
                  @click="dialog = true"
                  v-if="
                    $checkPermissions(
                      'summer_center.delete_schoolweeklyschedule',
                      ['sys_admin', 'gen_admin']
                    )
                  "
                  class="bg-red"
                  size="small"
                >
                  <span class="text-white">
                    {{ $t("globals.delete") }}
                  </span>
                  <v-icon icon="mdi-content-save" color="white" end></v-icon>
                </v-btn>
                <v-btn
                  @click="cleanSchoolWeeklySchedule"
                  v-if="
                    $checkPermissions(
                      'summer_center.delete_schoolweeklyschedule',
                      ['sys_admin', 'gen_admin']
                    )
                  "
                  class="mx-3 bg-ccc"
                  size="small"
                >
                  <span>
                    {{ $t("globals.clear") }}
                  </span>
                  <v-icon icon="mdi-broom" color="golden" end></v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
          <!-- </v-card> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-dialog
    color="indigo"
    v-model="dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        <v-icon>mdi</v-icon>
        {{ $t("globals.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("globals.confirm-delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="(dialog = false), (selectedClass = '')">{{
          $t("globals.cancel")
        }}</v-btn>
        <v-btn color="red" @click="deleteWeeklySchedule">{{
          $t("globals.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <div>
    <Alert
      color="indigo"
      v-model="showAlert"
      :iconColor="alert.color"
      :icon="alert.icon"
      :title="alert.title"
      :message="alert.message"
    >
    </Alert>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapGetters, mapState, mapActions } from "vuex";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
export default {
  name: "SchoolWeeklyScheduleUpdate",
  mounted() {},
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      await this.getDays();
      this.getClassAssignHall();
      // this.getSubjectsWithTeacher()
      await this.getEmployees();
      await this.getLevels();
      await this.getDivisions();
      await this.getSchedules();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      schedules_data: [],
      v$: useValidate(),
      showAlert: false,
      update: false,
      user: null,
      dialog: false,
      found: false,
      loading: false,
      subjects: [],
      alert: {
        color: undefined,
        icon: undefined,
        title: undefined,
        message: undefined,
      },
      user: null,
      weekly_schedule: {
        fk_level: null,
        fk_division: null,
        period_am: null,
        fk_year: this.current_year,
        days: [],
        note: "",
        updated_by: undefined,
      },
    };
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      divisions: (state) => state.summer.divisions,
      weeks: (state) => state.summer.weeks,
      employees: (state) => state.summer.employees,
      schedules: (state) => state.summer.schedules,
      days: (state) => state.summer.days,
      current_year: (state) => state.current_year, 
    }),
    ...mapGetters({
      getClassDivisions: "summer/getClassDivisions",
    }),
    level_division_subjects() {
      if (this.weekly_schedule.fk_level && this.weekly_schedule.fk_division) {
        return this.subjects.filter(
          (el) =>
            el.fk_level == this.weekly_schedule.fk_level &&
            el.fk_division == this.weekly_schedule.fk_division
        );
      }
    },
    level_divisions() {
      if (this.weekly_schedule.fk_level) {
        return this.getClassDivisions(this.weekly_schedule.fk_level);
      }
    },
    days_choices() {
      return this.days.filter((el) => el.is_holiday == false);
    },
    timetable_schedules() {
      return this.schedules.filter(
        (el) =>
          el.add_to_table == true && el.period == this.weekly_schedule.period_am
      );
    },
    scheduleData() {
      return [
        this.weekly_schedule.fk_level,
        this.weekly_schedule.fk_division,
        this.weekly_schedule.period_am,
      ];
    },
    class_subjects() {
      if (this.weekly_schedule.fk_level) {
        var class_data = this.classes.find(
          (item) => item.id == this.weekly_schedule.fk_level
        );
        var subjects_data = [];
        class_data.subjects.forEach((element) => {
          subjects_data.push({
            id: element,
            name: this.getSubjectName(element),
          });
        });
      }
      return subjects_data;
    },

    classesName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    subjectsName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    teachersName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    
  },

  validations() {
    return {
      weekly_schedule: {
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_division: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        period_am: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  methods: {
    ...mapActions({
      getEmployees: "summer/getEmployees",
      getLevels: "summer/getLevels",
      getDivisions: "summer/getDivisions",
      getWeeks: "summer/getWeeks",
      getClassAssignHall: "summer/getClassAssignHall",
      getSchedules: "summer/getSchedules",
      getDays: "summer/getDays",
    }),
    determineTeacher(id_subject) {
      const teacher = this.subjects.find((el) => el.fk_subject == id_subject);
      return teacher ? teacher.fk_employee : null;
    },
    async getSubjectsWithTeacher() {
      await this.axios
        .get(`${this.base_url}api/summer/teacher-subject-custom-summer`, {
          params: {
            level: this.weekly_schedule.fk_level,
            division: this.weekly_schedule.fk_division,
            year: this.current_year,
          },
        })
        .then((res) => {
          this.subjects = res.data.results;
        });
    },
    getDayName(id) {
      const day = this.days_choices.find((el) => el.id == id);
      return day ? day.name : "";
    },
    checkAllData() {
      this.v$.$validate();
      this.found = false;
      if (!this.v$.$error) {
        this.searchSchedule();
      }
    },

    async searchSchedule() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/summer-time-table/list/`, {
          params: {
            level: this.scheduleData[0],
            division: this.scheduleData[1],
            period_am: this.scheduleData[2],
          },
        })
        .then((response) => {
          this.weekly_schedule = response.data;
          this.found = true;
          this.loading = false;
        })
        .catch((error) => {
          this.showAlert = true;
          this.alert.color = "error";
          this.alert.message = this.$t("alert.not-found.message");
          this.alert.title = this.$t("alert.not-found.title");
          this.alert.icon = "alert-circle-outline";
          setTimeout(() => {
            this.showAlert = false;
          }, 1500);
          this.loading = false;
        });
    },
    getSubjectName(id) {
      var subject = this.subjects.find((item) => item.id == id);
      return subject.name_ar;
    },

    getClassName(id) {
      var item = this.classes.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },

    getTeacherName(id) {
      var item = this.teachers.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },

    submitSchoolWeeklySchedule() {
      this.v$.$validate();
    },
    cleanSchoolWeeklySchedule() {
      this.v$.weekly_schedule.$reset();
      this.weekly_schedule.days = [];
      this.weekly_schedule = [];
      this.found = false;
    },
    deleteWeeklySchedule() {
      this.axios
        .post(
          `${this.base_url}api/summer/summer-time-table/delete/`,
          this.scheduleData
        )
        .then((response) => {
          this.dialog = false;
          this.showAlert = true;
          this.alert.color = "success";
          this.alert.message = this.$t("alert.success.delete");
          this.alert.title = this.$t("alert.success.title");
          this.alert.icon = "check-circle";
          setTimeout(() => {
            this.showAlert = false;
          }, 1500);
          this.cleanSchoolWeeklySchedule();
        });
    },
    updateWeeklySchedule() {
      this.submitSchoolWeeklySchedule();
      if (!this.v$.$error) {
        var listSchedules = [];
        this.weekly_schedule.days.forEach((day) => {
          day.subjects.forEach((subject) => {
            listSchedules.push({
              id: subject.id,
              fk_subject: subject.fk_subject,
              data_updated: this.user,
              fk_employee: subject.fk_employee,
              fk_year: this.current_year,
            });
          });
        });
        this.axios.put(`${this.base_url}api/summer/summer-time-table/${null}/`,listSchedules)
          .then((response) => {
            this.showAlert = true;
            this.alert.color = "success";
            this.alert.message = this.$t("alert.success.update");
            this.alert.title = this.$t("alert.success.title");
            this.alert.icon = "check-circle";
            setTimeout(() => {
              this.showAlert = false;
            }, 1500);
          })
          .catch((error) => {
            this.showAlert = true;
            this.alert.color = "error";
            this.alert.message = this.$t("alert.error.update");
            this.alert.title = this.$t("alert.failure.title");
            this.alert.icon = "alert-circle-outline";

            setTimeout(() => {
              this.showAlert = false;
            }, 1500);
          });
      }
    },
  },
};
</script>

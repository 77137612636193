<template>
   <v-form @submit.prevent>
    <v-card class="pa-2" v-if="!exist">
      <h3 class="text-grey-darken-2 px-2">
        {{ $t("globals.select-criteria") }}
      </h3>
      <v-container class="pa-0">
        <div
          class="my-2 mt-6 d-flex align-center flex-wrap flex-grow-1"
          style="gap: 1.5rem"
        >
          <v-col
            class="pt-0 px-3"
            cols="12"
            xs="12"
            sm="12"
            md="4"
            lg="4"
            v-if="visiGovernorat"
          >
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="fk_governorate"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-city"
                :items="governorates"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact"
                clearable
                @update:modelValue="
                  (fk_directorate = undefined), (fk_schoolsummer = undefined)
                "
              ></v-autocomplete>
            </v-card-item>
          </v-col>
          <v-col
            v-if="visiDirectorate"
            align-self=""
            class="pt-0 px-3"
            cols="12"
            xs="12"
            sm="12"
            md="4"
            lg="4"
          >
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="fk_directorate"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-map-outline"
                :items="getDirectorateByGov(fk_governorate)"
                :label="$t('globals.directorate')"
                persistent-hint
                density="compact"
                clearable
                @update:modelValue="
                  sendSummerCenterRequest(fk_directorate),
                    (fk_schoolsummer = undefined),
                    (fk_role = undefined)
                "
              ></v-autocomplete>
            </v-card-item>
          </v-col>

          <v-col class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="fk_schoolsummer"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-domain"
                :items="summers"
                :label="$t('globals.summer-centre')"
                persistent-hint
                density="compact"
                clearable
              ></v-autocomplete>
            </v-card-item>
          </v-col>
          <div>
            <v-btn
              :loading="dataLoading"
              max-width="100px"
              density="comfortable"
              append-icon="mdi-presentation"
              class="table"
              style="width: 100%; border-collapse: collapse"
              color="indigo"
              type="submit"
              @click="fetchData((page = 1))"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-form>
  <v-card id="report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.the_total_number_student_employees") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="mb-5 pa-0">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            @click="printTable((all = false))"
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="visiBack"
            density="compact"
            class="mx-1"
            @click="exist = false"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.total_number_student_employees") }}

              {{ title }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
              {{total.totals}}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>


    <v-card-item v-if="data?.length> 0">
    <table class="report-t">
         <thead>
            <tr>
              <template v-for="header in headers" :key="header">
                <th v-if="header.key=='index'" style="width: 50px;">{{header.title}}</th>
                <th v-else>{{header.title}}</th>
              </template>
            </tr>
          </thead>
          <tbody>
         
             <tr v-for="(item, key) in data" :key="key">
                  <td >{{key+1}}</td>
                  <td >{{item[headers[1].key]}}</td>
                  <td >{{item[headers[2].key]}}</td>
                  <td >{{item[headers[3].key]}}</td>
                  <td class="text-center">{{item[headers[4].key]}}</td>
                  <td class="text-center">{{item[headers[5].key]}}</td>
              </tr>

          </tbody>
          <tfoot>
            <tr>
              <th colspan="4">{{ $t("report.final-collector") }}</th>

              <th>{{total.total_student}}</th>
              <th>{{total.total_employee}}</th>
              
            </tr>
            <tr>
              <th colspan="4">{{ $t("reportpublic.GrandTotal") }}</th>
              <th colspan="2">{{total.totals}}</th>
            </tr>
          </tfoot>
      </table>
    
    </v-card-item>    
    <v-card-item v-else>
      <h3 class="text-center text-grey-darken-2">
        {{ $t("globals.not-found-items") }}
      </h3>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    ReportHeader,
  },
  data() {
    return {
      summers:[],
      title: "",
      exist: false,
      visiBack: true,
      visiGovernorat: false,
      visiDirectorate: false,
      governorates: [],
      data: [],
      fk_governorate: null,
      fk_directorate: null,
      fk_schoolsummer: undefined,
      dataLoading: false,
      totalItems: null,
      length: 0,
       page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
        itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      headers: [
            {
          title: this.$t("globals.#"),
          key: "index",
          sortable: false,
          
        },

        {
          title: this.$t("report.governorate"),
          key: "gov_ar",
          sortable: false,
        },
        {
          title: this.$t("reportpublic.directorate"),
          key: "dir_ar",
          sortable: false,
        },
        {
          title: this.$t("globals.summer-centre"),
          key: "name_ar",
          sortable: false,
        },
        {
          title: this.$t("globals.student-count"),
          key: "count_student",
          sortable: false,
        },

        {
          title: this.$t("summer.employee_count"),
          key: "count_employee",
          sortable: false,
        },
      ],
      total:{}
    };
  },
  async created() {
    await this.$store.commit("updateLoadingValue", true);
    try {
      await this.getGovernorates();
      await this.getDirectorate();
      this.visiGovernorat = true;
      this.visiDirectorate = true;
      
      var gov = this.user_governorate;
      if (this.role == 0 || this.role == 1) {
        this.governorates = this.governorate;
      } else if (this.role == 2) {
        // this.governorates = this.governorate.filter((item) => item.id == gov);
        this.fk_governorate = parseInt(gov);
        this.visiGovernorat = false;
      } else if (this.role == 3) {
        this.fk_governorate = parseInt(gov);
        this.fk_directorate = this.user_directorate;
        this.visiGovernorat = false;
        this.visiDirectorate = false;
        this.sendSummerCenterRequest(this.fk_directorate);
      }
    } catch {}
    await this.$store.commit("updateLoadingValue", false);
  },
  computed: {
    ...mapState({
      governorate: (state) => state.school.governorates,
      all_summer_centers: (state) => state.summer.all_summer_centers,
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
    }),
    ...mapGetters({
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getAllSummerCenters: "summer/getAllSummerCenters",
    }),
      getTitle() {
       this.title = "";
      if (this.fk_governorate) {
        this.title +=
          " في محافظة " +
          " ( " +
          this.governorate.find((item) => item.id == this.fk_governorate)
            .name_ar +
          " ) ";
      }else{
         this.title +=" في كل المحافظات ";
      }
      if (this.fk_directorate) {
        const dir = this.getDirectorateByGov(this.fk_governorate);
        this.title +=
          " في مديرية " +
          " ( " +
          dir.find((item) => item.id == this.fk_directorate).name_ar +
          " ) ";
      }else {
         this.title +=" في كل المديريات ";
      }
     
      if (this.fk_schoolsummer) {
        this.title +=
          " الدورة المدرسية " +
          " ( " +
          this.all_summer_centers.find(
            (item) => item.id == this.fk_schoolsummer
          ).name_ar +
          " ) ";
      }else {

        this.title +="  لكل المراكز المدرسية ";
              }
    },
    async sendSummerCenterRequest(dir) {
      if (dir) {
         await  this.getAllSummerCenters(dir);
         this.summers = this.all_summer_centers
        }
      else
       this.summers = []
      
    },
    getDirectorateByGov(gov) {
      if (this.role == 3) {
        var l = this.getDirectorateByGovernorates(gov);

        return l.filter(
          (item) => item.id == this.user_directorate
        );
      }

      return this.getDirectorateByGovernorates(gov);
    },

    async fetchData(page) {      
        this.dataLoading = true;
        await this.axios(
          `${this.base_url}public-reports/summe-center-number-student-employees`,
          {
            params: {
              page: page,
              page_size: 1000000,
              gov_id: this.fk_governorate,
              directorate_id: this.fk_directorate,
              summer_id: this.fk_schoolsummer,
            }}
        )
          .then((response) => {

            this.data = response.data.results;
            this.total =  response.data.total
            this.getTitle()
            this.exist = true;

 
      
          })
          .catch((err) => {});
        this.dataLoading = false;
    
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  watch: {
    perPage() {
      this.fetchData();
    },
    page() {
      this.fetchData(this.page);
    },
  },
};
</script>
<style>
.stable {
  width: 100%;
}

.stable th {
  padding: 10px;
  border: 0.1px solid #649add !important;
  background-color: #cfd8dc !important;
}

.stable td {
  border: 0.1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}
</style>

<template>
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-container class="pa-0">
      <div
        class="my-2 mt-6 d-flex align-center flex-wrap flex-grow-1"
        style="gap: 1.5rem"
      >
     <v-form ref="form">
    <v-row>
        <v-col>
        <div class="mb-3" style="width: clamp(150px, 40vw, 250px)">
          <v-select
            color="indigo"
            v-model="gov_id"
            density="compact"
            item-title="name"
            item-value="id"
            prepend-inner-icon="mdi-city"
            :items="listgov"
            :label="`${$t('reportpublic.Governorate')}`"
             clearable
            :rules="rules"
          />
        </div>
      </v-col>
         
         <v-col>
           <div class="mb-3" style="width: clamp(130px, 40vw, 250px)">
          <v-select
            color="indigo"
            v-model="typeSelected"
            density="compact"
            item-title="type"
                  item-value="id"
            prepend-inner-icon="mdi-format-list-bulleted-type"
            :items="typeList"
            :label="`${$t('reportpublic.summer_type')}`"
             clearable
            :rules="rules"

          />
        </div>
         </v-col>
    </v-row>
       
     </v-form>
        <div>
          <v-btn
           :loading="dataLoading"
            max-width="100px"
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="indigo"
            @click="getGovForReports()"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </div>
      </div>
    </v-container>
  </v-card>
  <v-card id="report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.SummerCouresStatistics") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
          <v-btn density="compact" class="mx-1" @click="exist = false">
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{
                $t("reportpublic.SummerCouresStatistics") + " ( " + typeList.find(e=>e.id==typeSelected).type  + " ) " + "محافظة"+" ( " + results.governorate_name_ar  + " ) "
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet :class="`border chart-label-arrow-${this.$i18n.locale} text-center`">
              <h4>
                {{ results.current_date ? results.current_date.slice(0, 4) + " هـ" : "" }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet color="black" height="6" width="6" style="border-radius: 50%">
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-card
        class="text-white rounded-lg text-center mb-4 pa-3"
        style="background: #bad7e9"
      >
        <v-card-title>
          <h1 class="text-black">
            {{
              this.$i18n.locale == "ar"
                ? results.governorate_name_ar
                : results.governorate_name_en
            }}
          </h1>
        </v-card-title>
        <v-card-item>
          <v-row class="ma-3">
            <v-col>
              <v-sheet class="rounded pa-2 text-white" style="background: #001524">{{
                $t("reportpublic.NumberOfDirectorates")
              }}</v-sheet>
            </v-col>
            <v-col cols="4">
              <v-sheet class="rounded pa-2 text-white" style="background: #001524">{{
                results.directorate_counts ? results.directorate_counts : 0
              }}</v-sheet>
            </v-col>
          </v-row>
          <v-row class="ma-3">
            <v-col>
              <v-sheet
                class="rounded pa-2 text-white text-center text-wrap h-100"
                style="background: #3876bf"
                >{{ $t("reportpublic.TheTotalAreaOfTheGovernorateIsKm") }}</v-sheet
              >
            </v-col>
            <v-col cols="4">
              <v-sheet
                class="rounded pa-2 text-white d-flex justify-center align-center h-100"
                style="background: #3876bf"
                >{{ results.governorate_area ? results.governorate_area : 0 }}</v-sheet
              >
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>

      <v-card style="background: #bad7e9" class="mb-4 rounded-lg">
        <v-card-title>
          <h1 class="text-black text-center mt-4 mb-2">
            {{ $t("reportpublic.SummerCenters") }}
          </h1>
        </v-card-title>
        <v-card-item>
          <v-row class="flex-card mx-3 align-center">
            <v-col>
              <v-card
                height="auto"
                width="100%"
                class="px-5 mt-5"
                style="background: #b7b7b75b"
              >
                <v-card-title
                  class="pc-label text-white text-center pa-1 px-5 position-absolute"
                  :style="`
                  ${this.$i18n.locale == 'ar' ? 'right:0' : 'left:0'};
                  background: linear-gradient(30deg, #3876bf, #192655);
                  width: min-content;
                `"
                  >{{ $t("reportpublic.NumberOfSchools") }}</v-card-title
                >
                <v-card-title
                  class="tab-label text-white text-center pa-1 px-5 position-absolute"
                  :style="`
                  ${this.$i18n.locale == 'ar' ? 'right:0' : 'left:0'};
                  background: linear-gradient(30deg, #3876bf, #192655);
                  width: min-content;
                `"
                  >{{ $t("reportpublic.NumberOfSchools") + "&nbsp;&nbsp;&nbsp;&nbsp;" }}
                  {{
                     results.school_summer_open_counts
                    
                  }}
                </v-card-title>
                <v-row>
                  <v-container class="pc-container" style="width: auto">
                    <h4 class="text-black text-center">
                      {{ $t("reportpublic.Total") }}
                    </h4>
                    <v-card-title
                      class="text-white text-center pa-2 mt-1 rounded"
                      style="
                        background: linear-gradient(30deg, #3876bf, #192655);

                        width: 100px;
                        height: 40px;
                        aspect-ratio: 1;
                      "
                      >{{
                        results.school_summer_open_counts
                       
                      }}</v-card-title
                    >
                  </v-container>
                </v-row>
                <v-container class="d-flex flex-wrap justify-space-evenly">
                  <div class="d-flex">
                    <v-card-title
                      class="text-white text-center pt-4 mt-3"
                      style="
                        background: linear-gradient(30deg, #3876bf, #192655);
                        border-radius: 50%;
                        width: 60px;
                        height: 60px;
                      "
                      >{{ $t("reportpublic.Males") }}</v-card-title
                    >
                    <v-card-title
                      class="text-white text-center pa-2 mt-4 m-hexagonal"
                      style="background: linear-gradient(30deg, #3876bf, #192655)"
                      >{{
                        results.school_summer_open_for_males_counts
                    
                      }}</v-card-title
                    >
                  </div>
                  <div class="d-flex">
                    <v-card-title
                      class="text-white text-center pt-4 mt-3"
                      style="
                        background: linear-gradient(30deg, #3876bf, #192655);
                        border-radius: 50%;
                        width: 60px;
                        height: 60px;
                      "
                      >{{ $t("reportpublic.Females") }}</v-card-title
                    >
                    <v-card-title
                      class="text-white text-center pa-2 mt-4 m-hexagonal"
                      style="background: linear-gradient(30deg, #3876bf, #192655)"
                      >{{
                       results.school_summer_open_for_females_counts
                      
                      }}</v-card-title
                    >
                  </div>
                  <div class="d-flex">
                    <v-card-title
                      class="text-white text-center pt-4 mt-3"
                      style="
                        background: linear-gradient(30deg, #3876bf, #192655);
                        border-radius: 50%;
                        width: 60px;
                        height: 60px;
                      "
                      >{{ $t("reportpublic.Generl") }}</v-card-title
                    >
                    <v-card-title
                      class="text-white text-center pa-2 mt-4 m-hexagonal"
                      style="background: linear-gradient(30deg, #3876bf, #192655)"
                      >{{
                         results.school_summer_open_for_general_counts
                      
                      }}</v-card-title
                    >
                  </div>
                </v-container>
              </v-card>
            </v-col>
            <v-col class="mt-5">
              <v-card
                height="auto"
                width="100%"
                class="px-5 mb-6"
                style="background: #b7b7b75b"
              >
                <v-card-title
                  class="pc-label text-white text-center pa-1 px-3 position-absolute"
                  :style="`
                  ${this.$i18n.locale == 'ar' ? 'right:0' : 'left:0'};
                  background: linear-gradient(30deg, #ffb000, #ff5b22);
                  width: min-content;
                `"
                  >{{ $t("reportpublic.NumberOfWorkers") }}</v-card-title
                >
                <v-card-title
                  class="tab-label text-white text-center pa-1 px-5 position-absolute"
                  :style="`
                  ${this.$i18n.locale == 'ar' ? 'right:0' : 'left:0'};
                  background: linear-gradient(30deg, #ffb000, #ff5b22);
                  width: min-content;
                `"
                  >{{ $t("reportpublic.NumberOfWorkers") + "&nbsp;&nbsp;&nbsp;&nbsp;" }}
                  {{
                    results.workers_summer_open_counts
                   
                  }}</v-card-title
                >
                <v-row>
                  <v-container class="pc-container pb-0" style="width: auto">
                    <h4 class="text-black text-center">
                      {{ $t("reportpublic.Total") }}
                    </h4>
                    <v-card-title
                      class="text-white text-center pa-2 mt-1"
                      style="
                        background: linear-gradient(30deg, #ffb000, #ff5b22);
                        width: 100px;
                        height: 40px;
                        aspect-ratio: 1;
                      "
                      >{{
                        results.workers_summer_open_counts
                     
                      }}</v-card-title
                    >
                  </v-container>
                </v-row>
                <v-container class="d-flex flex-wrap justify-space-evenly gap-3">
                  <div class="d-flex">
                    <v-card-title
                      class="text-white text-center pt-4 mt-3"
                      style="
                        background: linear-gradient(30deg, #ffb000, #ff5b22);
                        border-radius: 50%;
                        width: 60px;
                        height: 60px;
                      "
                      >{{ $t("reportpublic.Males") }}</v-card-title
                    >
                    <v-card-title
                      class="text-white text-center pa-2 mt-4 m-hexagonal"
                      style="background: linear-gradient(30deg, #ffb000, #ff5b22)"
                      >{{
                          results.males_workers_summer_open_counts
                         
                      }}</v-card-title
                    >
                  </div>
                  <div class="d-flex">
                    <v-card-title
                      class="text-white text-center pt-4 mt-3"
                      style="
                        background: linear-gradient(30deg, #ffb000, #ff5b22);
                        border-radius: 50%;
                        width: 60px;
                        height: 60px;
                      "
                      >{{ $t("reportpublic.Females") }}</v-card-title
                    >
                    <v-card-title
                      class="text-white text-center pa-2 mt-4 m-hexagonal"
                      style="background: linear-gradient(30deg, #ffb000, #ff5b22)"
                      >{{
                         results.females_workers_summer_open_counts
                        
                      }}</v-card-title
                    >
                  </div>
                </v-container>
              </v-card>
            </v-col>
            <v-col class="mt-5">
              <v-card
                height="auto"
                width="100%"
                class="px-5 mb-6"
                style="background: #b7b7b75b"
              >
                <v-card-title
                  class="pc-label text-white text-center pa-1 px-3 position-absolute"
                  :style="`
                  ${this.$i18n.locale == 'ar' ? 'right:0' : 'left:0'};
                  background: linear-gradient(30deg, #219c90, #00425a);
                  width: min-content;
                `"
                  >{{ $t("reportpublic.NumberOfStudents") }}</v-card-title
                >
                <v-card-title
                  class="tab-label text-white text-center pa-1 px-5 position-absolute"
                  :style="`
                  ${this.$i18n.locale == 'ar' ? 'right:0' : 'left:0'};
                  background: linear-gradient(30deg, #219c90, #00425a);
                  width: min-content;
                `"
                  >{{ $t("reportpublic.NumberOfStudents") + "&nbsp;&nbsp;&nbsp;&nbsp;" }}
                  {{
                  results.students_summer_open_counts
                    
                  }}</v-card-title
                >
                <v-row>
                  <v-container class="pc-container pb-0" style="width: auto">
                    <h4 class="text-black text-center">
                      {{ $t("reportpublic.Total") }}
                    </h4>
                    <v-card-title
                      class="text-white text-center pa-2 mt-1 rounded"
                      style="
                        background: linear-gradient(30deg, #219c90, #00425a);
                        width: 100px;
                        height: 40px;
                        aspect-ratio: 1;
                      "
                      >{{
                         results.students_summer_open_counts
                        
                      }}</v-card-title
                    >
                  </v-container>
                </v-row>

                <v-container class="d-flex flex-wrap justify-space-evenly gap-3">
                  <div class="d-flex">
                    <v-card-title
                      class="text-white text-center pt-4 mt-3"
                      style="
                        background: linear-gradient(30deg, #219c90, #00425a);
                        border-radius: 50%;
                        width: 60px;
                        height: 60px;
                      "
                      >{{ $t("reportpublic.Males") }}</v-card-title
                    >
                    <v-card-title
                      class="text-white text-center pa-2 mt-4 m-hexagonal"
                      style="background: linear-gradient(30deg, #219c90, #00425a)"
                      >{{
                         results.males_students_summer_open_counts
                      
                      }}</v-card-title
                    >
                  </div>
                  <div class="d-flex">
                    <v-card-title
                      class="text-white text-center pt-4 mt-3"
                      style="
                        background: linear-gradient(30deg, #219c90, #00425a);
                        border-radius: 50%;
                        width: 60px;
                        height: 60px;
                      "
                      >{{ $t("reportpublic.Females") }}</v-card-title
                    >
                    <v-card-title
                      class="text-white text-center pa-2 mt-4 m-hexagonal"
                      style="background: linear-gradient(30deg, #219c90, #00425a)"
                      >{{
                         results.females_students_summer_open_counts
                       
                      }}</v-card-title
                    >
                  </div>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>

      <v-card class="pa-4" style="background: #bad7e9; height: 120%">
        <v-card-title>
          <h1 class="text-black text-center mb-2">
            {{ $t("reportpublic.LevelsName") }}
          </h1>
        </v-card-title>
        <v-container
          style="gap: 0.5em"
          class="d-flex justify-space-evenly flex-wrap flex-shrink-0"
        >
          <div v-for="(item, index) in dataLevels" :key="index">
            <v-card-title
              class="text-white text-center py-2 pt-3 m-hexagonal"
              style="
                background: linear-gradient(30deg, #c9485b, #4c0027);
                width: 150px;
                height: 50px;
                aspect-ratio: 1;
              "
              >{{ item.levelName }}</v-card-title
            >
            <v-card-title
              class="rounded-pill text-center text-white"
              style="background: linear-gradient(30deg, #c9485b, #4c0027); width: 150px"
              >{{ item.count }}
            </v-card-title>
          </div>
        </v-container>
      </v-card>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    try {
      
      await this.$store.commit("updateLoadingValue", true);
    
      await this.getGovernorates();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async getGovernorates() {
      await  this.axios
        .get(this.base_url + "governorate/?country=" + 1)
        .then((response) => {
          this.listgov = response.data.map((item) => {
            return {
              id: item.id,
              name: this.$i18n.locale == "ar" ? item.name_ar : item.name_en,
            };
          });
        })
        .catch((error) => {
            
        });
    },  
    async getGovForReports() {

      const { valid } = await this.$refs.form.validate()
      if(valid)
      {
      this.dataLoading = true
 
        await this.fetchData();
        await this.fetchData2();
        this.exist = true;
        this.typeIndex = this.typeList.indexOf(this.typeSelected);
    
      this.dataLoading = false
      }
    },
    async fetchData() {
   
        await  this.axios
        .get(
          `${this.base_url}public-reports/summer-governorate-detailed-summary`,
          {
            params:{
              gov_id:this.gov_id,
              type:this.typeSelected
            }
          }
        )
        .then((response) => {
          this.results = response.data.results;
        })
        .catch((error) => {          
        });
      
      
    },

    async fetchData2() {
      
      await  this.axios
        .get(`${this.base_url}public-reports/summer-level-students`, {
          params:{
            gov_id:this.gov_id,
            type_id:this.typeSelected
          }
        })
        .then((response) => {
           this.dataLevels = response.data.results.data.dataLevel;        
       
        })

        .catch((error) => {
            
        });
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },

  data() {
    return {
      gov_id: undefined,
      listgov: [],
      results: [],
      dataLevels: [],
      dataLoading:false,
      id: 1,
      exist: false,
      typeSelected: null,
      typeList: [
       { id: 1, type: this.$t("reportpublic.Open") },
        { id: 2, type: this.$t("reportpublic.Typical") },
       
      ],
      typeIndex: null,
          rules: [(value) => (value ? true : this.$t("globals.required_field"))],

    };
  },
  
  components: {
    ReportHeader,
  },
};
</script>

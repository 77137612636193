<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
      <v-col cols="12" md="4" sm="6" class="mb-3">
        <v-select
          color="indigo"
          v-model="filter_data.fk_week"
          density="compact"
          item-title="week_name"
          item-value="id"
          prepend-inner-icon="mdi-calendar-week"
          :items="weeks"
          :label="$t('summer.calendar.week-name')"
          hide-details
          clearable
          :error-messages="v$.filter_data.fk_week.$errors.map((e) => e.$message)"
          :rules="rules.requird_filed"
        />
      </v-col>

      <v-col cols="6" sm="2" class="d-flex align-center">
        <v-btn
          density="comfortable"
          class="w-100"
          color="indigo"
          :loading="loading"
          @click="checkFilterData"
        >
          <span class="text-white">{{ $t("globals.show") }}</span>
          <VIcon icon="mdi-presentation" color="white" end></VIcon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("report.statistic_community") }} (
            {{ getCommitName(this.filter_data.fk_week) }} )
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mb-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1">
          <v-menu :close-on-content-click="false">
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in theaders"
                  :key="header"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable((all = false))"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
          <v-btn
            density="compact"
            class="mx-1"
            @click="(exist = false), (exist_table = false), v$.filter_data.$reset()"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" rounded="0" flat v-if="exist_table">
          <table
            id="stable"
            class="table text-center"
            style="width: 100%; border-collapse: collapse"
          >
            <thead class="table-light">
              <tr>
                <th class="bg-blue-grey-lighten-4 pa-2">{{ $t("report.entity") }}</th>
                <th class="bg-blue-grey-lighten-4 pa-2">{{ $t("report.visit_count") }}</th>
                <th class="bg-blue-grey-lighten-4 pa-2">{{ $t("financial.date") }}</th>
                <th class="bg-blue-grey-lighten-4 pa-2">
                  {{ $t("summer.visitors.occasion") }}
                </th>
                <th class="bg-blue-grey-lighten-4 pa-2">
                  {{ $t("summer.another-notes") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item">
                <td>
                  {{ item.entity? item.entity : "-" }}
                </td>
                <td>
                  {{ item.visitors_count? item.visitors_count : "-" }}
                </td>
                <td>
                  {{ item.date? item.date : "-" }}
                </td>
                <td>
                  {{ item.occasion? item.occasion : "-" }}
                </td>
                <td>
                  {{ item.note? item.note : "-" }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getWeeks();
      await this.getYears();
      this.current_year = this.current_year_name;
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },
      theaders: [],

      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      exist_table: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "adjective", "entity", "phone_number"],
      headers: [
        { title: "#", key: "id" },
        { title: this.$t("globals.name"), key: "name" },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
      current_year: (state) => state.current_year,
      current_year_name: (state) => state.current_year_name,
    }),

    ...mapGetters({
    }),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
    }),
    getYears() {
      this.year_data = this.current_year_name;
      this.filter_data.year = this.current_year;
      return this.year_data;
    },

    getCommitName(driver_id) {
      if (driver_id) {
        const committees = this.weeks.find((committees) => committees.id === driver_id);
        return committees.week_name;
      }
      return "===========";
    },
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.getData();
      }
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/support-visitors-entity`, {
          headers: {
            "Content-Type": "application/json",
          },

          params: {
            week: this.filter_data.fk_week,
          },
        })
        .then((response) => {
          this.exist = true;
          this.exist_table = true;
          if (response.data) {
            this.items = response.data;
            if (this.items.length < 1) {
              this.exist_table = false;
            }

            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        fk_week: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

.pp2 {
  padding: 2px !important;
}
#stable th {
  border: 1px solid #649add !important;
  background-color: #fff;
  color: black;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>

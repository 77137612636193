<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.StatisticBySchoolType") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>

      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pl-0">
            <v-sheet class="px-2 chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.StatisticBySchoolType") }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{}}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-container>
        <v-row>
          <table class="report-t">
            <thead>
              <tr>
               <th rowspan="3" style="width: 50px;">{{$t("globals.#")}}</th>

                <th colspan="13">
                  {{ $t("reportpublic.StatisticBySchoolType") }}
                </th>
              </tr>
              <tr>
                <th rowspan="2">
                  {{ $t("reportpublic.Governorate") }}
                </th>
                <th colspan="3">{{ $t("reportpublic.Open") }}</th>
                <th colspan="3">{{ $t("reportpublic.Typical") }}</th>
                <th rowspan="2">{{ $t("reportpublic.NumberOfSchools") }}</th>
                <th rowspan="2">
                  {{
                    $t("reportpublic.ATotal") + " " + $t("reportpublic.Workers")
                  }}
                </th>
                <th rowspan="2">
                  {{ $t("reportpublic.TotalStudents") }}
                </th>
              </tr>
              <tr>
                <template v-for="index in 2" :key="index">
                
                      <th>{{ $t("reportpublic.NumberOfSchools") }}</th>
                <th>{{ $t("reportpublic.Workers") }}</th>
                <th>{{ $t("reportpublic.TotalStudents") }}</th>
                  
                </template>
              
              
              </tr>

              <tr></tr>
            </thead>
            <tbody>
              <tr v-for="(data, key) in governorate_data" :key="key">
                <td>{{key+1}}</td>
                <td>
                  {{
                    this.$i18n.locale == "ar"
                      ? data.governorate_name_ar
                      : data.governorate_name_en
                  }}
                </td>
               
                <td class="text-center">{{ data.school_summer_opened_counts }}</td>
                <td class="text-center">{{ data.workers_summer_opened_counts }}</td>
                <td class="text-center">{{ data.students_summer_opened_counts }}</td>
                <td class="text-center">{{ data.school_summer_typical_counts }}</td>
                <td class="text-center">{{ data.workers_summer_typical_counts }}</td>
                <td class="text-center">{{ data.students_summer_typical_counts }}</td>
                <td class="text-center">{{ data.school_summer_counts }}</td>
                <td class="text-center">{{ data.workers_summer_counts }}</td>
                <td class="text-center">{{ data.students_summer_counts }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr v-if="totals">
                <th colspan="2">{{ $t("reportpublic.GrandTotal") }}</th>
           
                <th>{{ totals.school_summer_opened }}</th>
                <th>{{ totals.workers_summer_opened }}</th>
                <th>{{ totals.student_summer_opened }}</th>
                <th>{{ totals.school_summer_typical }}</th>
                <th>{{ totals.workers_summer_typical }}</th>
                <th>{{ totals.student_summer_typical }}</th>
                <th>{{ totals.school_summer }}</th>
                <th>{{ totals.workers_summer }}</th>
                <th>{{ totals.student_summer }}</th>
              </tr>
            </tfoot>
          </table>
        </v-row>
      </v-container>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      await  this.axios
        .get(`${this.base_url}public-reports/daily-reports/summer-statistics-by-type-of-school-for-governorate`).then((response) => {
          this.totals = response.data.results.totals;
          this.governorate_data = response.data.results.governorate_data;
        })
        .catch((error) => {});
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  data() {
    return {
      results: [],
      governorate_data: [],
      totals: [],
    };
  },
  computed: {
    
  },
  components: {
    ReportHeader,
  },
};
</script>

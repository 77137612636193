<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{
              $t(
                "reportpublic.StatisticsOfSummerCerntersInTheCapitalsSecretariatDistricts"
              )
            }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <div class="d-flex justify-space-between mb-2" id="tableHead">
      <div class="d-flex justify-end" style="flex: 1">
        <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
      </div>
    </div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="px-0">
          <v-sheet
            style="
              background: linear-gradient(30deg, #2a7379, #01243b);
              color: white;
            "
            height="25"
            class="px-2"
            elevation="0"
            rounded="0"
          >
            {{
              $t(
                "reportpublic.StatisticsOfSummerCerntersInTheCapitalsSecretariatDistricts"
              )
            }}
            {{ gov_name_ar }}
          </v-sheet>
        </v-col>
        <v-col cols="max" align-self="center" class="pa-0">
          <v-divider
            :thickness="1"
            class="border-opacity-100"
            color="black"
          ></v-divider>
        </v-col>
        <v-sheet color="black" height="6" width="6" style="border-radius: 50%">
        </v-sheet>
      </v-row>
    </v-container>
    <v-row class="mt-6">
      <v-col lg="12" md="12" sm="12">
        <v-card class="pt-3 px-1" id="card" style="height: 300px">
          <div id="studentStatistics"></div>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" md="12">
        <v-card class="pt-3 px-1" id="card2" style="height: 320px">
          <div id="totalOfStudentsMF"></div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import * as echarts from "echarts";
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async mounted() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
    this.option_school_number = {
      title: {
        text: this.$t("reportpublic.NumberOfSchools"),
        right: "center",
        textStyle: {
          fontFamily: "Almarai",
          fontSize: 16,
        },
      },
      xAxis: {
        type: "category",
        size: 10,
        axisLabel: {
          fontSize: 14,
          color: "black",
          fontWeight: "bold",
        },
        data: this.results.map((e) => {
          return e.directorate_name_ar;
        }),
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: false,
        },
      },
      series: [
        {
          label: {
            show: true,
            rotate: 90,
          },

          barWidth: "23px",
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          data: this.results.map((item) => {
            return { value: item.school_number };
          }),
        },
      ],
    };
    this.totalOfStudentsMF = {
      title: {
        text: this.$t("reportpublic.NumberOfStudentsAndWorkers"),
        right: "center",
        textStyle: {
          fontFamily: "Almarai",
          fontSize: 16,
        },
      },
      dataset: [
        {
          dimensions: [
            "name",
            this.$t("reportpublic.NumberOfStudents"),
            this.$t("reportpublic.NumberOfWorkers2"),
          ],
          source: this.results.map((item) => [
            item.directorate_name_ar,
            item.student_number,
            item.employee_number,
          ]),
        },
        {
          transform: {
            type: "sort",
            config: { dimension: "constant", order: "desc" },
          },
        },
      ],
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0,
          rotate: 60,
        },
        data: this.results.map((e) => {
          return e.directorate_name_ar;
        }),
      },
      yAxis: {
        type: "value",
        position: this.$i18n.locale == "ar" ? "right" : "",
      },
      legend: {
        bottom: "bottom",
        top: "84%",
        left: "center",
        itemHeight: 10,
        itemWidth: 13,
        borderWidth: 1,
        borderColor: "black",
        bottom: "30%",
      },
      transform: {
        type: "sort",
        config: { dimension: "constant", order: "desc" },
      },

      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        bottom: "30%",
        height: "50%",
        top: "30%",
      },
      series: [
        {
          label: {
            show: true,
            rotate: 90,
          },

          barWidth: "23px",
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
        },
        {
          label: {
            show: true,
            rotate: 90,
          },

          barWidth: "23px",
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
        },
      ],
    };

    this.printChart2();
    const appIconMenu2 = document.getElementById("appIconMenu");
    appIconMenu2.addEventListener("click", () => {
      const chartElement2 = document.getElementById("totalOfStudentsMF");
      const chart2 = echarts.getInstanceByDom(chartElement2);
      setTimeout(() => {
        const element2 = document.getElementById("card2");
        chart2.resize({
          width: element2.offsetWidth,
          height: element2.offsetHeight,
        });
      }, 10);
    });
    window.addEventListener("resize", this.printChart2);

    this.printChart();
    const appIconMenu = document.getElementById("appIconMenu");
    appIconMenu.addEventListener("click", () => {
      const chartElement = document.getElementById("studentStatistics");
      const chart = echarts.getInstanceByDom(chartElement);
      setTimeout(() => {
        const element = document.getElementById("card");
        chart.resize({
          width: element.offsetWidth,
          height: element.offsetHeight,
        });
      }, 10);
    });
    window.addEventListener("resize", this.printChart);
  },
  methods: {
    async fetchData() {
      await  this.axios
        .get(
          `${this.base_url}public-reports/governorate-reports/summer-center-statistics`)
        .then((response) => {
          this.results = response.data.results.data;
          this.gov_name_ar = response.data.results.gov_name_ar;
          this.gov_name_en = response.data.results.gov_name_en;
        });
    },
    printChart2() {
      const chartElement2 = document.getElementById("totalOfStudentsMF");
      const chart2 = echarts.init(chartElement2);
      chart2.setOption(this.totalOfStudentsMF);
      const element2 = document.getElementById("card2");
      chart2.resize({
        width: element2.offsetWidth - 12,
        height: element2.offsetHeight,
      });
    },
    printChart() {
      if (this.$checkPermissions("school.view_student")) {
        const chartElement = document.getElementById("studentStatistics");
        const chart = echarts.init(chartElement);
        chart.setOption(this.option_school_number);
        const element = document.getElementById("card");
        chart.resize({
          width: element.offsetWidth - 12,
          height: element.offsetHeight,
        });
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart2);
    window.removeEventListener("resize", this.printChart);
  },

  data() {
    return {
      gov_name_ar: "",
      gov_name_en: "",
      results: [],
      statistics: [],
      option_school_number: {},
      totalOfStudentsMF: {},
      size: null,
    };
  },
  
  components: {
    ReportHeader,
  },
};
</script>

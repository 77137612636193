<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <VCol class="mt-1" cols="6" md="3" sm="4" v-if="role == 0 || role == 1">
            <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="fk_governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              hide-details
              clearable
              :no-data-text="$t('governorate.not-found')"
              prepend-inner-icon="mdi-city"
              @update:modelValue="
                (page = 1),
                  getSummerSchools(),
                  (fk_directorate = null),
                  (fk_summer = null)
              "
            ></VAutocomplete>
          </VCol>
          <VCol class="mt-1" cols="6" md="3" sm="4" v-if="role >= 0 && role <= 2">
            <VAutocomplete
              :items="filterDirectorates(fk_governorate)"
              v-model="fk_directorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.directorate')"
              persistent-hint
              hide-details
              density="compact"
              clearable
              :no-data-text="$t('directorate.not-found')"
              prepend-inner-icon="mdi-map-outline"
              @update:modelValue="(page = 1), getSummerSchools(), (fk_summer = null)"
            ></VAutocomplete>
          </VCol>
          <VCol class="mt-1" cols="6" md="3" sm="4" v-if="role >= 0 && role <= 3">
            <VAutocomplete
              v-model="fk_summer"
              item-value="id"
              item-title="name_ar"
              prepend-inner-icon="mdi-domain"
              :items="summer_schools"
              :label="$t('globals.summer-centre')"
              persistent-hint
              hide-details
              density="compact"
              :no-data-text="$t('summer.not-found')"
              @update:modelValue="page = 1"
              clearable
            ></VAutocomplete>
          </VCol>

          <VCol cols="6" sm="4" md="3" lg="4" v-if="role == 4">
            <VAutocomplete
              :items="levels"
              v-model="fk_level"
              prepend-inner-icon="mdi-stairs-box"
              item-title="level_with_phase_name"
              item-value="id"
              :label="$t('class.name')"
              persistent-hint
              density="compact"
              clearable
              @update:modelValue="(fk_division = undefined), getStudentList()"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" sm="4" md="3" v-if="role == 4">
            <VAutocomplete
              :items="getClassDivisions(fk_level)"
              v-model="fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="id"
              :label="$t('division.name')"
              persistent-hint
              density="compact"
              clearable
              @update:modelValue="getStudentList()"
            ></VAutocomplete>
          </VCol>
        </v-row>
      </v-expansion-panel-text>

      <v-expansion-panel-text>
        <span>{{ $t("globals.filter-students-by-address") }}</span>
        <v-row class="py-2 mt-2">
          <VCol class="mt-1" cols="6" md="3" sm="4">
            <VAutocomplete
              :items="filterBirthGovernorates(1)"
              v-model="fk_birth_governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              hide-details
              clearable
              :no-data-text="$t('governorate.not-found')"
              prepend-inner-icon="mdi-city"
              @update:modelValue="
                (page = 1), (fk_birth_directorate = null)
              "
            ></VAutocomplete>
          </VCol>
          <VCol class="mt-1" cols="6" md="3" sm="4" >
            <VAutocomplete
              :items="filterBirthDirectorates(fk_birth_governorate)"
              v-model="fk_birth_directorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.directorate')"
              persistent-hint
              hide-details
              density="compact"
              clearable
              :no-data-text="$t('directorate.not-found')"
              prepend-inner-icon="mdi-map-outline"
              @update:modelValue="(page = 1)"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="3" class="mt-2">
            <VBtn
              class="bg-indigo"
              type="submit"
              @click="getStudentList()"
              size="small"
              :loading="loading"
            >
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
              <VIcon icon="mdi-presentation" color="white" end></VIcon>
            </VBtn>
          </VCol>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <VCard class="mt-2">
    <v-card-text>
      <v-row class="mt-1 mb-2">
        <v-col cols="4">
          <v-text-search
            v-model="txt_search"
            @input="getStudentList((page = 1))"
            class="search"
          >
          </v-text-search>
        </v-col>
        <v-col cols="8" class="text-end">
          <v-btn-group divided>
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props">
                  <v-icon class="me-2">mdi-eye-outline</v-icon>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-btn size="small" @click="exportToExcel" :loading="exportProgress">
              <span>{{ $t("export.export") }} </span>
              <v-icon size="large" color="green-darken-2" end>mdi-file-excel</v-icon>
            </v-btn>
            <VBtn
              @click="addStudent"
              size="small"
              v-if="
                $checkPermissions('summer_center.change_studentsummer', [
                  'sys_admin',
                  'summer_admin',
                  'gen_admin',
                  'gov_admin',
                  'dir_admin',
                  'user',
                ])
              "
            >
              <span>{{ $t("globals.create") }}</span>
              <VIcon icon="mdi-plus-thick" color="success" end></VIcon>
            </VBtn>
          </v-btn-group>
        </v-col>
      </v-row>
      <v-card :disabled="loading" :loading="loading">
        <v-data-table-server
          :headers="selectedHeaders"
          :items="student_list"
          :items-per-page="perPage"
          item-value="name"
          item-key="id"
          fixed-footer
          fixed-header
          :height="student_list?.length > 9 ? 400 : null"
          density="compact"
          class="elevation-1 rounded striped-table"
          v-model:sort-by="sortBy"
        >
          <!-- @update:options="this.page=1,getStudentList()" -->
          <template v-slot:column.checkbox="{ column }">
            <v-checkbox hide-details density="compact"> </v-checkbox>
          </template>
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="small"
                    @click="editStudent(item.raw.id)"
                    v-if="
                      $checkPermissions('summer_center.change_studentsummer', [
                        'sys_admin',
                        'summer_admin',
                        'gen_admin',
                        'gov_admin',
                        'dir_admin',
                        'user',
                      ])
                    "
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    @click="(student_del_id = item.raw.id), (del_dialog = true)"
                    v-if="
                      $checkPermissions('summer_center.delete_studentsummer', [
                        'sys_admin',
                        'summer_admin',
                        'gen_admin',
                        'gov_admin',
                        'dir_admin',
                        'user',
                      ])
                    "
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
                <v-lazy v-else-if="key == 'student_image'">
                  <span>
                    <button v-if="val != undefined" @click="showStudentImage(val)">
                      <VAvatar
                        :image="
                          val != null && val.search(base_url) != -1 ? val : base_url + val
                        "
                        class=""
                        size="small"
                      ></VAvatar>
                    </button>
                  </span>
                </v-lazy>
                <v-lazy v-else-if="key == 'registration_form'">
                  <span>
                    <button v-if="val != undefined" @click="showRegestriationImage(val)">
                      <VAvatar
                        :image="
                          val != null && val.search(base_url) != -1 ? val : base_url + val
                        "
                        class=""
                        size="small"
                      ></VAvatar>
                    </button>
                  </span>
                </v-lazy>
                <v-lazy v-else>
                  <span> {{ val }}</span>
                </v-lazy>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="length"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ totalItems }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card>
    </v-card-text>
    <!-- ############### Dialog Delete  ################### -->
  </VCard>
  <delete-dialog v-model="del_dialog" @confirm-delete="deleteStudent()" />
  <VDialog v-model="dialog" max-width="500px" max-height="500px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <!-- <VImg :src="dialog_image" :width="400"></VImg> -->
      <VCardItem>
        <img :src="dialog_image" class="mx-auto w-75" />
      </VCardItem>
      <VCardActions>
        <VBtn class="bg-info" @click="dialog = false">{{ $t("globals.ok") }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
  <!-- <alert-protected-records v-model="alert_cascade" :data_message="data_message"></alert-protected-records> -->
  <alert-cascade-records
    v-model="alert_cascade"
    :loading="del_loading"
    :data_message="data_message"
    :title="alert_cascade_title"
    @confirm-delete="confirmDelete"
  >
  </alert-cascade-records>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
export default {
  data() {
    return {
      summer_schools: [],
      exportProgress: false,
      force_delete: false,
      del_loading: false,
      alert_cascade_title: "",
      alert_cascade: false,
      fk_governorate: null,
      fk_directorate: null,
      fk_birth_governorate: null,
      fk_birth_directorate: null,
      fk_summer: null,
      v$: useValidate(),
      student_list: [],
      student_list_by_address: [],
      fk_level: undefined,
      fk_division: undefined,
      del_dialog: false,
      student_del_id: undefined,
      process_alert: undefined,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      dialog: false,
      dialog_image: undefined,
      dialog_title: undefined,
      dialog_text: undefined,
      perPage: 10,
      page: 1,
      length: 0,
      pagination: {},
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: "1000" },
      ],
      totalItems: null,
      sortBy: [{ key: "id", order: "asc" }],
      loading: false,
      selectedHead: [
        "name",
        "academic_id",
        "gender",
        "student_type",
        "birthdate",
        "student_image",
        "birth_place",
        "actions",
      ],
      txt_search: undefined,
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getStudentList();
      await this.getLevels();
      await this.getGender();
      await this.getDivisions();
      await this.getClassAssignHall();
      await this.getIdentifyType();
      await this.getNationalities();
      await this.getGovernorates();
      await this.getDirectorate();
      if (this.role == 2) {
        this.fk_governorate = this.user_governorate;
      }
      if (this.role == 3) {
        this.fk_directorate = this.user_directorate;
        await this.getSummerSchools();
      }
      if (this.role >= 0 && this.role <= 3) {
        this.headers.splice(2, 0, {
          title: this.$t("summer.address-summer"),
          key: "summer_address",
          sortable: false,
        });
        this.headers.splice(2, 0, {
          title: this.$t("summer.summer-camp"),
          key: "fk_summer",
        });
        this.selectedHead.push("fk_summer");
        this.selectedHead.push("summer_address");
      }

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      divisions: (state) => state.summer.divisions,
      gender: (state) => state.summer_globals.gender,
      identify_type: (state) => state.summer_globals.identify_type,
      nationalities: (state) => state.school.nationalities,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      user: (state) => state.User,
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
      current_year: (state) => state.current_year, 
    }),
    ...mapGetters({
      getGovernoratesByCountry: "school/getGovernoratesByCountry",
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      filterBirthGovernorates: "school/getGovernoratesByCountry",
      filterBirthDirectorates: "school/getDirectorateByGovernorates",
      getClassDivisions: "summer/getClassDivisions",
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    
    headers() {
      let headers = [
        { title: this.$t("school.student_name"), key: "name" },
        { title: this.$t("globals.username"), key: "fk_user", sortable: false },
        { title: this.$t("school.academic_id"), key: "academic_id" },
        { title: this.$t("globals.gender"), key: "gender" },
        { title: this.$t("summer.level.level"), key: "level_name" },
        { title: this.$t("division.name"), key: "fk_division" },
        { title: this.$t("school.school_type"), key: "school_type" },
        { title: this.$t("school.students_type"), key: "student_type" },
        { title: this.$t("globals.birth_date"), key: "birthdate" },
        { title: this.$t("school.student_birth_place"), key: "birth_place" },
        { title: this.$t("globals.address"), key: "address" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
        { title: this.$t("globals.home_number"), key: "home_number" },
        { title: this.$t("globals.school-name"), key: "school_name" },
        { title: this.$t("globals.school-class"), key: "schoollevel" },
        { title: this.$t("globals.country"), key: "fk_country" },
        { title: this.$t("globals.governorate"), key: "fk_governorate" },
        { title: this.$t("globals.directorate"), key: "fk_directorate" },
        {
          title: this.$t("school.parent_name"),
          key: "fk_parent",
          sortable: false,
        },
        {
          title: this.$t("school.parent_phone_number"),
          key: "parent_phone",
          sortable: false,
        },
        {
          title: this.$t("school.parent_relation"),
          key: "parent_relation",
          sortable: false,
        },
        {
          title: this.$t("school.student_image"),
          key: "student_image",
          sortable: false,
        },
        {
          title: this.$t("summer.accommodation-type"),
          key: "accommodation_type",
        },
        {
          title: this.$t("school.student_registration_form"),
          key: "registration_form",
          sortable: false,
        },
        { title: this.$t("globals.note"), key: "note" },
        {
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        }
      ];

      return headers;
    },
  },
  methods: {
    async confirmDelete() {
      this.force_delete = true;
      await this.deleteStudent();
      this.alert_cascade = false;
    },
    async exportToExcel() {
      this.exportProgress = true;
      var modified_student = [];
      await this.axios(`${this.base_url}api/summer/student/`, {
        params: {
          year: this.current_year,
          level: this.fk_level,
          division: this.fk_division,
          governorate: this.fk_governorate,
          directorate: this.fk_directorate,
          school_summer: this.fk_summer,
        },
      })
        .then((response) => {
          modified_student = response.data?.results?.map((obj) => {
            return Object.fromEntries(
              Object.entries(obj).map(([key, value]) => [key, !value ? "-" : value])
            );
          });
        })
        .catch((err) => {
          this.exportProgress = false;
        });
      const headers = this.headers
        .filter(
          (el) =>
            ![
              "actions",
              "fk_user",
              "note",
              "student_image",
              "registration_form",
            ].includes(el.key)
        )
        .map((item) => {
          return { header: item.title, key: item.key };
        });
      await this.$ExportToExcel(headers, modified_student, "بيانات الطلاب")
        .then(() => {
          this.exportProgress = false;
        })
        .catch((err) => {
          this.exportProgress = false;
        });
    },
    async getSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          params: {
            directorate: this.fk_directorate,
            governorate: this.fk_governorate,
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    ...mapActions({
      getDivisions: "summer/getDivisions",
      getLevels: "summer/getLevels",
      getGender: "summer_globals/getGender",
      getIdentifyType: "summer_globals/getIdentifyType",
      getNationalities: "school/getNationalities",
      getClassAssignHall: "summer/getClassAssignHall",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    addStudent() {
      this.$router.push("add");
    },
    editStudent(id) {
      this.$router.push(`${id}/edit/`);
    },
    showStudentImage(value) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1) this.dialog_license = value;
      else this.dialog_image = this.base_url + value;
      this.dialog_title = this.$t("school.student_image");
      this.dialog = true;
    },
    showRegestriationImage(value) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1)
        this.dialog_Coordinates = value;
      else this.dialog_image = this.base_url + value;
      this.dialog_title = this.$t("school.student_registration_form");
      this.dialog = true;
    },
    async deleteStudent() {
      this.del_loading = true;
      if (this.student_del_id != undefined) {
        let result = await this.axios
          .delete(`${this.base_url}api/summer/student/${this.student_del_id}/`, {
            params: {
              force_delete: this.force_delete,
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.getStudentList();
            this.del_loading = false;
          })
          .catch((error) => {
            this.del_loading = false;
            if ((error.code = 418)) {
              this.alert_cascade = true;
              this.alert_cascade_title = this.$t("alert.attention.delete-records");
              let error_messages = [];
              error.response.data.data.forEach((error) => {
                error_messages.push(
                  this.$t(`models.${error.model}`) + ` = ${error.count}`
                );
              });
              this.data_message = error_messages;
            } else if (error.response.data)
              this.$emit("errorAlert", this.$t("globals.error_student_deleted"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.del_dialog = false;
      }
    },
    async getStudentList() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/student/`, {
          params: {
            year: this.current_year,
            level: this.fk_level,
            division: this.fk_division,
            governorate: this.fk_governorate,
            directorate: this.fk_directorate,
            birth_governorate: this.fk_birth_governorate,
            birth_directorate: this.fk_birth_directorate,
            school_summer: this.fk_summer,
            search: this.txt_search,
            page: this.page,
            page_size: this.perPage,
            sort_by: this.order_data,
          },
        })
        .then((response) => {
          this.loading = false;
          this.student_list = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.page = 1;
      this.getStudentList();
    },
    page() {
      this.getStudentList(this.page);
    },
    alert_cascade(val) {
      if (val == false) {
        this.force_delete = false;
        this.student_del_id = undefined;
        this.del_loading = false;
      }
    },
    order_data() {
      this.getStudentList();
    },
  },
}}
</script>

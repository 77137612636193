    <template>
  <div>
    <v-expansion-panels class="mb-2" v-if="role >= 0 && role <= 4">
      <v-expansion-panel >
        <v-expansion-panel-title class="px-4">
          <div class="d-flex justify-space-between w-75">
            <div>
              <v-icon>mdi-tune</v-icon>
              <span class="mx-1">{{ $t("globals.select-criteria") }}</span>
            </div>
            <div class="pt-1">
              <span
                v-for="item in LocationBreadcrumb"
                :key="item"
                class="text-indigo mx-1"
                >{{ item }}</span
              >
            </div>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row class="py-2">
            <VCol
              class="mt-1"
              cols="12"
              md="3"
              sm="6"
              v-if="role == 0 || role == 1"
            >
              <VAutocomplete
                :items="filterGovernorates(1)"
                v-model="fk_governorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact"
                hide-details
                clearable
                :no-data-text="$t('governorate.not-found')"
                prepend-inner-icon="mdi-city"
                @update:modelValue="
                  getSchoolSummerAttendance(),
                    (fk_directorate = null)
                "
              ></VAutocomplete>
            </VCol>
            <VCol
              class="mt-1"
              cols="12"
              md="3"
              sm="6"
              v-if="role >= 0 && role <= 2"
            >
              <VAutocomplete
                :items="filterDirectorates(fk_governorate)"
                v-model="fk_directorate"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.directorate')"
                persistent-hint
                hide-details
                density="compact"
                clearable
                :no-data-text="$t('directorate.not-found')"
                prepend-inner-icon="mdi-map-outline"
                @update:modelValue="
                  getSchoolSummerAttendance()
                "
              ></VAutocomplete>
            </VCol>
            <v-col class="mt-1" cols="6" md="3" sm="6">
              <v-autocomplete
                color="indigo"
                density="compact"
                v-model="date"
                item-title="date"
                item-value="date"
                prepend-inner-icon="mdi-calendar-today"
                clearable
                hide-details
                :items="dates"
                :label="$t('summer.calendar.hijri-date')"
                @update:modelValue="getSchoolSummerAttendance()"
              />
            </v-col>
            <v-col class="mt-1" cols="6" md="3" sm="6">
              <v-autocomplete
                color="indigo"
                v-model="school_type"
                density="compact"
                item-title="type"
                item-value="id"
                :clearable="false"
                prepend-inner-icon="mdi-calendar-week"
                :items="typeList"
                hide-details
                :label="$t('summer.summer_type')"
                @update:model-value="getSchoolSummerAttendance()"
              />
            </v-col>
            <v-col class="mt-1" cols="6" md="3" sm="6">
              <v-autocomplete
                color="indigo"
                v-model="uploaded_data"
                density="compact"
                item-title="type"
                item-value="id"
                :clearable="false"
                prepend-inner-icon="mdi-calendar-week"
                :items="uploaded_choices"
                hide-details
                :label="$t('globals.upload-data')"
                @update:model-value="getSchoolSummerAttendance()"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- data table  -->
    <v-card>
      <v-card-text>
        <div class="d-flex justify-space-between flex-fill mb-2">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" class="border" elevation="0">
                <v-icon class="me-2" color="icon-color">mdi-eye-outline</v-icon>
                <v-icon color="icon-color">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <div class="w-100 px-2">
            <v-text-field
              color="indigo"
              style="border-radius: 30px; border: 1px solid #ccc; color: black"
              class="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              :placeholder="$t('globals.search-here')"
              density="compact"
              variant="text"
              v-model="txt_search"
              @input="getSchoolSummerAttendance((page = 1))"
            >
            </v-text-field>
          </div>
        </div>
        <v-card  :loading="loading" :disabled="loading">
        <v-data-table-server
          :headers="selectedHeaders"
          :items="attendances"
          fixed-footer
          fixed-header
          :height="attendances?.length > 9 ? 410 : null"
          density="compact"
          class="elevation-1 striped-table"
          v-model:sort-by="sortBy"
          
        >
          <template v-slot:item.employees="{ item }">
            <v-icon v-if="item.value.employees" color="success">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </template>
          <template v-slot:item.students="{ item }">
            <v-icon v-if="item.value.students" color="success">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </template>
          <template v-slot:item.employees_attendance="{ item }">
            <v-icon v-if="item.value.employees_attendance" color="success">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </template>
          <template v-slot:item.students_attendance="{ item }">
            <v-icon v-if="item.value.students_attendance" color="success">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </template>
          <template v-slot:item.activities="{ item }">
            <v-icon v-if="item.value.activities" color="success">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </template>

          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination?.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination?.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
        </v-card>
        <!-- <v-table  
                density="compact" 
                class="rounded elevation-1 " 
                v-if="searched_schools?.length>0"
                fixed-header
                :height="searched_schools?.length>10?400:null"
            >
                <thead>
                    <tr>
                        <th>{{$t('globals.number')}}</th>
                        <th >{{$t('summer.summer-camp')}}</th>
                        <th >{{$t('globals.governorate')}}</th>
                        <th >{{$t('globals.directorate')}}</th>
                        <th >{{$t('globals.employees')}}</th>
                        <th >{{$t('globals.students')}}</th>
                        <th >{{$t('summer.employee.attendance')}}</th>
                        <th >{{$t('globals.students-attendance')}}</th>
                        <th >{{$t('summer.add-activity-processes')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(statistic,index) in searched_schools" :key="statistic">
                        <td>{{index+1}}</td>
                        <td >{{statistic.school}}</td>
                        <td >{{statistic.governorate}}</td>
                        <td >{{statistic.directorate}}</td>
                        <td>
                            <v-icon color="success" v-if="statistic.employees==true">mdi-check-circle</v-icon>
                            <v-icon color="error" v-else>mdi-close-circle</v-icon>
                        </td>
                        <td>
                            <v-icon color="success" v-if="statistic.students==true">mdi-check-circle</v-icon>
                            <v-icon color="error" v-else>mdi-close-circle</v-icon>
                        </td>
                        <td>
                            <v-icon color="success" v-if="statistic.employees_attendance==true">mdi-check-circle</v-icon>
                            <v-icon color="error" v-else>mdi-close-circle</v-icon>
                        </td>
                        <td>
                            <v-icon color="success" v-if="statistic.students_attendance==true">mdi-check-circle</v-icon>
                            <v-icon color="error" v-else>mdi-close-circle</v-icon>
                        </td>
                        <td>
                            <v-icon color="success" v-if="statistic.activities==true">mdi-check-circle</v-icon>
                            <v-icon color="error" v-else>mdi-close-circle</v-icon>
                        </td>
                    </tr>
                </tbody>
            </v-table> -->
        <!-- <v-card v-else class="text-center py-2 ">
                <span>{{$t('globals.not-found-items')}}</span>
            </v-card> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (this.role == 2) {
        this.fk_governorate = this.user_governorate;
      }
      await this.getCalendar();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getSchoolSummerAttendance();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      fk_governorate: null,
      fk_directorate: null,
      attendances: [],
      dates: [],
      date: null,
      school_type: 2,
      uploaded_data:1,
      loading:false,
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      typeList: [
        { id: 1, type: this.$t("globals.Open") },
        { id: 2, type: this.$t("reportpublic.Typical") },
      ],
      uploaded_choices: [
        { id: 1, type: this.$t("globals.all") },
        { id: 2, type: this.$t("globals.only-uploaded-data") },
      ],
      selectedHead: [
        "school",
        "governorate",
        "directorate",
        "employees",
        "students",
        "employees_attendance",
        "students_attendance",
        "activities",
      ],
      headers: [
        { title: this.$t("summer.summer-camp"), key: "school" },
        { title: this.$t("globals.governorate"), key: "governorate" },
        { title: this.$t("globals.directorate"), key: "directorate" },
        { title: this.$t("globals.employees"), key: "employees" },
        { title: this.$t("globals.students"), key: "students" },
        {
          title: this.$t("summer.employee.attendance"),
          key: "employees_attendance",
        },
        {
          title: this.$t("globals.students-attendance"),
          key: "students_attendance",
        },
        { title: this.$t("summer.add-activity-processes"), key: "activities" },
      ],
      txt_search: null,
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
  },
  methods: {
    ...mapActions({
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    async getCalendar() {
      await this.axios
        .get(this.base_url + "api/summer/calendar")
        .then((response) => {
          if (response.data) {
            this.dates = response.data.results;
          }
        });
    },
    async getSchoolSummerAttendance() {
        this.loading=true
      await this.axios
        .get(`${this.base_url}api/summer/report/school-summer-attendance`, {
          params: {
            governorate: this.fk_governorate,
            directorate: this.fk_directorate,
            date: this.date,
            school_type: this.school_type,
            uploaded_data:this.uploaded_data,
            page:this.page,
            search_txt:this.txt_search,
            page_size:this.perPage,
          }})
        .then((response) => {
            this.loading=false
          this.attendances = response.data.results;
          if(response.data?.pagination){
            this.pagination = response.data.pagination
          }
        }).catch(error=>{
            this.loading=false
        })
    },
  },
  watch: {
    perPage() {
      this.getSchoolSummerAttendance();
    },
    page() {
      this.getSchoolSummerAttendance(this.page);
    },
    order_data() {
      this.getSchoolSummerAttendance(this.page);
    },
  },
};
</script>


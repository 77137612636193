<template>
  <v-expansion-panels
    model-value="1"
    class="mb-6"
    v-if="role >= 0 && role <= 2 && !isexit"
  >
    <v-expansion-panel value="1">
      <v-expansion-panel-title class="px-4">
        <div class="d-flex justify-space-between w-75">
          <div>
            <v-icon>mdi-tune</v-icon>
            <span class="mx-1">{{ $t("globals.select-criteria") }}</span>
          </div>
        </div>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <VCol cols="12" md="3" sm="6" v-if="role == 0 || role == 1">
            <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="fk_governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              hide-details="auto"
              clearable
              :no-data-text="$t('governorate.not-found')"
              prepend-inner-icon="mdi-city"
            ></VAutocomplete>
          </VCol>
          <v-col cols="6" sm="2" class="align-center mt-2">
            <v-btn
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100"
              color="indigo"
              :loading="loading"
              @click="fetchData()"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-card id="report" v-if="isexit">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{reportTitle}}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="text-end mt-2" id="tableHead">
      <v-btn-group>
        <v-btn density="compact" class="pa-0" @click="exportToExcel">
          <span>{{ $t("export.export") }} </span>
          <v-icon size="large" color="green-darken-2" end
            >mdi-file-excel</v-icon
          >
          <v-tooltip class="tooltip" activator="parent" location="top">
            <small>{{ $t("export.excel") }}</small>
          </v-tooltip>
        </v-btn>
        <v-btn
          v-bind="props"
          density="compact"
          style="min-width: 25px"
          @click="printTable"
        >
          <v-icon color="icon-color">mdi-printer</v-icon>
          <v-tooltip class="tooltip" activator="parent" location="top">
            <small>{{ $t("globals.print-file") }}</small>
          </v-tooltip>
        </v-btn>
        <v-btn
          density="compact"
          @click="(isexit = !isexit), (statistics = []),(header_1 = []),(headers = [])"
          v-if="role >= 0 && role <= 2"
        >
          <span class="text-indigo">{{ $t("globals.back") }}</span>
        </v-btn>
      </v-btn-group>
    </v-card-title>
    
    <div v-if="statistics" class="overflow-y-auto mt-2">
        <span > 
             *  الاحصائيات الخاصة بالدورات المدرسية المفتوحة تشمل فقط الطلاب المنتظمين 
        </span>
      <table
        class="rounded text-center report-t  mt-2"
        density="compact"
        border="1"
        id="data-table"
      >
        <thead>
          <tr class="text-center">
            <th
              v-for="(head, index) in statistics.header"
              :key="index"
            >
              {{ head.header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in statistics.data" :key="index">
            <td v-for="head in statistics.header" :key="head" >
              {{ row[head.key] }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="text-center">
            <th
              v-for="(key, index) in Object.keys(statistics.footer)"
              :key="index"
            >
              {{ statistics.footer[key] }}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <v-row v-else justify="center" align-content="center" class="pa-4">
      <v-card class="w-100 pa-3 text-center">
        لم يتم العثور على سجلات مرتبطة بهذه البيانات
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates();
      await this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
    }),
    async exportToExcel() {
      this.exportProgress = false;
      const data = [...this.statistics.data,this.statistics.footer]
      this.$ExportToExcel(this.statistics.header,data,this.reportTitle);
    },
    getGovernorateName(gov_id) {
      return this.governorates?.find((gov) => gov.id == gov_id)?.name_ar;
    },
    async fetchData() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}api/summer/relative-statistics-students-governorate`, {
            params: {
              fk_governorate: this.fk_governorate,
            },
          })
          .then((response) => {
            this.statistics = response.data
            this.isexit = true;
            this.loading=false
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    async printTable() {
      window.print();
    },
  },
  data() {
    return {
      v$: useValidate(),
      loading: false,
      fk_governorate: null,
      isexit: false,
      statistics: [],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      governorates: (state) => state.school.governorates,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
    }),
    reportTitle(){
      const title = " إحصائية بنسبة الطلاب في كل مديرية إلى إجمالي عدد الطلاب في المحافظة"
      var str = ''
      if(this.fk_governorate){
        str = `${this.$t("globals.for-governorate")} ( ${this.getGovernorateName(this.fk_governorate)}  )`
      }else{
        str = `( ${this.$t("globals.for-all-governorates") })`
      }
      return `${title} ${str}`

    },
  },
  components: {
    ReportHeader,
  },
};
</script>

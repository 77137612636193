<template>
  <DataTable
    v-if="$checkPermissions('summer_center.view_subjectsummer')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="$checkPermissions('summer_center.delete_subjectsummer',['sys_admin','gen_admin'])"
    :edit_perm="$checkPermissions('summer_center.change_subjectsummer',['sys_admin','gen_admin'])"
    :add_perm="$checkPermissions('summer_center.add_subjectsummer',['sys_admin','gen_admin'])"
  >
  </DataTable>
   <v-dialog
    v-model="addDialog"
    max-width="700"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
   <v-card flat>
      <v-card-text class="pb-0">
        <VForm ref="form" class="" @submit.prevent="is_update?updateSubject():saveSubject()">
          <VRow
            class="mt-2"
          >
            <VCol  cols="12">
                <VTextField
                  v-model="subject.name"
                  :label="$t('subject.name')"
                  persistent-hint
                  autofocus="true"
                  clearable
                  density="compact"
                  prepend-inner-icon="mdi-bookshelf"
                  :rules="rules.subjectNameRule"
                ></VTextField>
            </VCol>
            <VCol cols="12">
                <VTextarea
                  v-model="subject.note"
                  clearable
                  :label="$t('summer.initiative.note')"
                  no-resize
                  color="indigo"
                  counter="250"
                  density="compact"
                  rows="2"
                  :rules="rules.note"
                  prepend-inner-icon="mdi-note-outline"
                ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions
        class="mx-4"
      >
        <VBtnSave
          v-if="!is_update"
          @click="saveSubject()"
          :loading="saveDataLoading"
        >
         {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateSubject()"
          :loading="editDataLoading"
        >
            {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{
    DataTable
  },
  data() {
    return {
      addDialog:false,
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      subjects: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      subject: {},
      rules: {
        subjectNameRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("subject.name"), key: "name" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    ...mapGetters({}),
    tableList() {
      if (this.subjects) {
        return JSON.parse(JSON.stringify(this.subjects));
      }
    },
    
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.subject = {};
      this.addDialog=false
      this.is_update = false;
      this.updated_id = undefined;
    },
    editItem(subject) {
      this.subject = subject;
      this.is_update = true;
      this.addDialog = true
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveSubject() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.subject.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/subject", this.subject)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.subject = {};
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data.name) {
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.getItems();
      }
    },
    async updateSubject() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        this.subject.updated_by = this.user;
        let result = await this.axios
          .put(this.base_url + "api/summer/subject/" + this.subject.id, this.subject)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            this.editDataLoading = false;
            if (error.response.data.name) {
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/subject/" +delete_id)
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            status = true
          })
          .catch((error) => {
            status = error
            
          });
        return status
      }
    },
    async getItems(page = 1,per_page = 10,ordering = null,search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/subject`, {
        params: {
          search:search,
          page: page,
          page_size: per_page,
          sort_by:ordering,
        },
      }).then((response) => {
        this.subjects = response.data.results;
        this.pagination = response.data.pagination;
        // this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    },
  },
};
</script>

<style scoped>

</style>

import { createApp } from "vue";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./i18n";
import custom_axios from "@/plugins/axios.js";
import VueAxios from "vue-axios";
import "@/assets/style.css";
import store from "./store/index";
import useValidate from "@vuelidate/core";
import Alert from "./components/Globals/Alert";
import Cliche from "./components/Globals/Cliche";
import AlertCascadeRecords from "./components/Globals/AlertCascadeRecords";
import AlertProtectedRecords from "./components/Globals/AlertProtectedRecords";
import DeleteDialog from "./components/Globals/DeleteDialog";
import DatetimePicker from '@/components/Globals/DatetimePicker.vue'
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";


import { helpers, numeric } from "@vuelidate/validators";
import echarts from "vue-echarts";



let app = createApp(App);
const v$ = useValidate();
app.config.warnHandler = function (msg, vm, trace) {
  return null;
};
 

async function reloadSystem(){
  await custom_axios.get(`${api_url}/api/token/payload`).then(res=>{
    const userData = JSON.parse(decryptString(res.data.user_data))
    store.commit('setUserData',userData)
  }).catch(err=>{
    this.$store.commit("setUserData", {});
    this.$store.state.isAuthenticated = false;
  })
}



// export const api_url = "http://localhost:5000/";
// 
// export const api_url = "https://scb.mesrye.com/";

export const api_url = "https://testb.opensoftye.net/";

// export const api_url = "https://schoolsoft.opensoftye.com/";
// 
// export const api_url = "https://myschool.opensoftye.com/";

// export const api_url = "http://opensoftye.net/";

// export const api_url = "http://schoolmange.opensoftye.com/";

app.config.globalProperties.base_url = api_url;
app.config.globalProperties.protected_records = false;
app.config.globalProperties.data_message = [];
app.config.errorHandler = ()=>{};

function host() {
  return app.config.globalProperties.base_url;
} 

app.config.globalProperties.$v = v$;
app.config.globalProperties.toHijriDate = function (date) {
  let dateVal = date.toLocaleDateString("en-US", { calendar: "islamic" });
  let dateFormat = dateVal.slice(0, 10).split("/").reverse();
  let day = dateFormat[1];
  let month = dateFormat[2];
  dateFormat[1] = month;
  dateFormat[2] = day;
  
  return dateFormat.join("-");
};
// Encryption methods 
import { decryptString } from "./utils";
// End encryption methods
app.config.globalProperties.toEnglishString = function (string) {
  const arabic_numbers = {
    "\u0660": 0,
    "\u0661": 1,
    "\u0662": 2,
    "\u0663": 3,
    "\u0664": 4,
    "\u0665": 5,
    "\u0666": 6,
    "\u0667": 7,
    "\u0668": 8,
    "\u0669": 9,
    "/": "-",
  };

  var english_string = "";
  if (typeof string == "string") {
    for (let index = 0; index < string.length; index++) {
      if (Object.keys(arabic_numbers).includes(string[index])) {
        english_string += arabic_numbers[string[index]];
      } else {
        english_string += string[index];
      }
    }
    return english_string;
  } else {
    return string;
  }
};
app.config.globalProperties.toGregorianDate = function (date) {
  const datetime = new Date(date);
  const gregorianString = datetime.toLocaleString("en-US", {
    calendar: "islamic",
    timeZone: "UTC",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric",
  });
  return gregorianString;
};
app.config.globalProperties.substring = function (string,count) {
  if(typeof(string)=='string'){
    if(string.length>count){
      return string.substring(0,count)+'...'
    }
    return string
  }
  return string
};
function checkPermissions(requiredPermission,requiredRoles=[]){
  const userPermissions = store.state.permissions || [];
  const userRole = store.state.role;
  const rolesList = store.state.role_list;
  return (requiredRoles.includes(rolesList[userRole]) || requiredRoles?.length==0 ) && userPermissions.includes(requiredPermission)
}
app.config.globalProperties.$checkPermissions = checkPermissions;
app.config.globalProperties.$ExportToExcel=function(header,data,name='ملف'){
  return new Promise((resolve,reject)=>{
    try {
      const today = new Date()
      const options ={year:'numeric',month:'2-digit',day:'2-digit'}
      const dateString = today.toLocaleDateString('en-US',options).replace(/\//g,'-')
      const style = {
        font: {
          name:'Tahoma',
          bold: true,
          size:9,
          color: { argb: "42526e" },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "cfd8dc" },
        },
      };
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.columns=header
      worksheet.addRows(data);  
      worksheet.eachRow(row=>{
        row.height = 18
      })
      worksheet.columns.forEach(function(col,i){
        let maxLength = 0;
        col['eachCell']({includeEmpty:true},function(cell){
          cell.alignment = { horizontal: "center", vertical: "middle" };
          cell.border = {
            top: { style: "thin", color: { argb: "649add" } },
            left: { style: "thin", color: { argb: "649add" } },
            right: { style: "thin", color: { argb: "649add" } },
            bottom: { style: "thin", color: { argb: "649add" } },
          };
          cell.font = { name: "Tahoma", color: { argb: "42526e" },size:10};
          var colLength = cell.value? cell.value.toString().length:10;
          if(colLength > maxLength){
            maxLength = colLength
          }
        })
        col.width = maxLength<10?10:maxLength;
      })
      const row=worksheet.getRow(1)
      row.height = 25
      row.eachCell(cell=>{
          cell.style=style
          cell.alignment={horizontal:'center',vertical:'middle'}
          cell.border={
            top:{style:'thin',color:{argb:'649add'}},
            left:{style:'thin',color:{argb:'649add'}},
            right:{style:'thin',color:{argb:'649add'}},
            bottom:{style:'thin',color:{argb:'649add'}}
        }
      })
      const file_name=`${dateString}-${name}.xlsx`
      workbook.xlsx.writeBuffer().then((buffer)=>{
          const data = new Blob([buffer],{ type:'application/octet-stream'});
          saveAs(data,file_name)
      })
      resolve()
    } catch (error) {
      reject(error)
    }
    
  })
}
app.config.globalProperties.$ExportToExcelWithColspan=function(header_1,headers,data,name='ملف'){
  return new Promise((resolve,reject)=>{
    try {
      const today = new Date();
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const dateString = today
        .toLocaleDateString("en-US", options)
        .replace(/\//g, "-");
      const head1_style = {
        font: {
          name:'Tahoma',
          bold: true,
          size:9,
          color: { argb: "42526e" },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "cfd8dc" },
        },
      };
      const head2_style = {
        font: {
          name:'Tahoma',
          bold: true,
          size:8,
          color: { argb: "42526e" },
        },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "cfd8dc" },
        },
      };
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Data");
      // apply colspan for columns
      let currentCol = 1;
      header_1.forEach((head) => {
        const { header, cols } = head;
        const startCol = currentCol;
        const endCol = startCol + (cols || 1) - 1;
        if (cols > 1) {
          worksheet.mergeCells(1, startCol, 1, endCol);
        }
        worksheet.getCell(1, startCol).value = header;
        currentCol = endCol + 1;
      });
      // add data to the rows
      const columns_keys = headers?.map((head) => head?.key);
      worksheet.addRow(Object.values(headers?.map((head) => head.header)));
      data.forEach((row) => {
        const temp_data = [];
        columns_keys?.forEach((col) => {
          temp_data.push(row[col]);
        });
        worksheet.addRow(temp_data);
      });
      worksheet.eachRow(row=>{
        row.height = 18
      })
      worksheet.columns.forEach(function(col,i){
        let maxLength =0;
        col['eachCell']({includeEmpty:true},function(cell){
          cell.alignment = { horizontal: "center", vertical: "middle" };
          cell.border = {
            top: { style: "thin", color: { argb: "649add" } },
            left: { style: "thin", color: { argb: "649add" } },
            right: { style: "thin", color: { argb: "649add" } },
            bottom: { style: "thin", color: { argb: "649add" } },
          };
          cell.font = { name: "Tahoma", color: { argb: "42526e" },size:10};
          var colLength = cell.value? cell.value.toString().length:10;
          if(colLength > maxLength){
            maxLength = colLength
          }
        })
        col.width = maxLength<10?10:maxLength;
      })
      const rows = worksheet.getRows(1,2);
      rows.forEach((row,index)=>{
        row.height = 25;
        if(index==1){
          row.height = 20
        }
        row.eachCell((cell) => {
          cell.style = head1_style;
          if(index==1){
            cell.style = head2_style
          }
          cell.alignment = { horizontal: "center", vertical: "middle" };
          cell.border = {
            top: { style: "thin", color: { argb: "649add" } },
            left: { style: "thin", color: { argb: "649add" } },
            right: { style: "thin", color: { argb: "649add" } },
            bottom: { style: "thin", color: { argb: "649add" } },
          };
        });
      })
      const file_name=`${dateString}-${name}.xlsx`
      workbook.xlsx.writeBuffer().then((buffer)=>{
          const data = new Blob([buffer],{ type:'application/octet-stream'});
          saveAs(data,file_name)
      })
      resolve()
    } catch (error) {
      reject(error)
    }
    
  })
}
app.config.globalProperties.validators = {
  uniqueValidator: (list) => {
    return (value) => {
      return !list.includes(value.trim());
    };
  },
  lessDate: (date) => {
    return (value) => {
      return date < value;
    };
  },
  moreDate: (date) => {
    return (value) => {
      return date > value;
    };
  },
  timeValidator: (startTime) => {
    return (value) => {
      return x < value;
    };
  },
  unique: (list, val) => {
    return !list.includes(val) ? true : i18n.global.t("errors.unique");
  },
  englishOnly: helpers.regex(/^[a-zA-Z\-'\s]+$/),
  arabicOnly: helpers.regex(
    /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
  ),
};
async function loadSystemRole(){
  try {
    await reloadSystem()
    app
    .use(store)
    .use(i18n)
    .use(vuetify)
    .use(router)
    .use(VueAxios, custom_axios)
    .component("Alert", Alert)
    .component("Cliche", Cliche)
    .component("chart", echarts)
    .component("AlertProtectedRecords", AlertProtectedRecords)
    .component("AlertCascadeRecords", AlertCascadeRecords)
    .component("DeleteDialog", DeleteDialog)
    .component("DatetimePicker", DatetimePicker)
    .mount("#app");
  } catch (error) {
    app
      .use(store)
      .use(i18n)
      .use(vuetify)
      .use(router)
      .use(VueAxios, custom_axios)
      .component("Alert", Alert)
      .component("Cliche", Cliche)
      .component("chart", echarts)
      .component("AlertProtectedRecords", AlertProtectedRecords)
      .component("AlertCascadeRecords", AlertCascadeRecords)
      .component("DeleteDialog", DeleteDialog)
      .component("DatetimePicker", DatetimePicker)
      .mount("#app");
  }
}
loadSystemRole()
export default host;


<template>
  <VCard class="justify-center">
    <v-card-text>
      <VForm
        ref="form"
        v-if="
          $checkPermissions('summer_center.change_studentsummer') ||
          $checkPermissions('summer_center.add_studentsummer')
        "
      >
        <v-locale-provider :locale="$i18n.locale">
          <VTabs
            v-model="tab"
            slider-color="indigo"
            center-active
            show-arrows
            density="compact"
            color="blue"
            class="border-b"
          >
            <VTab
              v-for="(value, index) in tabs"
              :key="index"
              :value="index"
              selected-class="border text-indigo"
              class="text-medium-emphasis"
            >
              {{ value }}
            </VTab>
          </VTabs>
        </v-locale-provider>

        <v-window v-model="tab" class="my-1 py-2">
          <v-window-item :value="0">
            <VRow class="mt-1 py-1" align="start">
              <VCol cols="12" xs="12" sm="12" md="9" lg="10">
                <fieldset
                  class="mb-2 pt-1 pb-3 border rounded px-2"
                  v-if="role >= 0 && role <= 3"
                >
                  <legend class="mx-2 px-2">
                    <span class="text-medium-emphasis">بيانات المدرسة</span>
                  </legend>
                  <VRow class="mt-1">
                    <VCol
                      cols="12"
                      sm="6"
                      md="6"
                      lg="4"
                      v-if="role == 0 || role == 1"
                    >
                      <VAutocomplete
                        :items="filterGovernorates(1)"
                        v-model="student.governorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('globals.governorate')"
                        persistent-hint
                        density="compact"
                        :rules="rules.governorate"
                        prepend-inner-icon="mdi-city"
                        @update:modelValue="
                          getSummerSchools(),
                            (student.directorate = undefined),
                            (student.fk_summer = null)
                        "
                      >
                      </VAutocomplete>
                    </VCol>
                    <VCol
                      cols="12"
                      sm="6"
                      md="6"
                      lg="4"
                      v-if="role >= 0 && role <= 2"
                    >
                      <VAutocomplete
                        :items="filterDirectorates(student.governorate)"
                        v-model="student.directorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('globals.directorate')"
                        persistent-hint
                        density="compact"
                        :rules="rules.directorate"
                        prepend-inner-icon="mdi-map-outline"
                        @update:modelValue="
                          getSummerSchools(), (student.fk_summer = null)
                        "
                      ></VAutocomplete>
                    </VCol>
                    <VCol
                      cols="12"
                      sm="6"
                      md="6"
                      lg="4"
                      v-if="role >= 0 && role <= 3"
                    >
                      <VAutocomplete
                        v-model="student.fk_summer"
                        item-value="id"
                        item-title="name_ar"
                        prepend-inner-icon="mdi-domain"
                        :items="summer_schools"
                        :label="$t('globals.summer-centre')"
                        persistent-hint
                        density="compact"
                        :rules="rules.fk_summer"
                        :no-data-text="$t('summer.not-found')"
                        clearable
                        @update:modelValue="
                          getSummerSchools(),
                            getClassAssignHall(),
                            (student.fk_division = null)
                        "
                      ></VAutocomplete>
                    </VCol>
                  </VRow>
                </fieldset>
                <fieldset class="mb-2 pt-1 pb-3 border rounded px-2">
                  <legend class="mx-2 px-2">
                    <span class="text-medium-emphasis"> البيانات الشخصية</span>
                  </legend>
                  <VRow class="mt-1">
                    <VCol cols="12" sm="6" md="6" lg="4">
                      <VTextField
                        v-model="student.name"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('school.student_ar_name')"
                        :placeholder="$t('school.student_ar_placehoder')"
                        density="compact"
                        autofocus
                        ref="studentName"
                        :error-messages="
                          v$.student.name.$errors.map((e) => e.$message)
                        "
                      >
                      </VTextField>
                    </VCol>
                    <VCol cols="12" sm="6" md="6" lg="2">
                      <VAutocomplete
                        :items="genders"
                        v-model="student.gender"
                        item-title="name"
                        item-value="id"
                        :label="$t('globals.gender')"
                        persistent-hint
                        density="compact"
                        prepend-inner-icon="mdi-gender-male-female"
                        :error-messages="
                          v$.student.gender.$errors.map((e) => e.$message)
                        "
                      ></VAutocomplete>
                    </VCol>
                    <VCol cols="12" sm="6" md="6" lg="2">
                      <VTextField
                        v-model="student.id_card"
                        prepend-inner-icon="mdi-numeric"
                        :label="$t('school.card_id')"
                        density="compact"
                        :error-messages="
                          v$.student.id_card.$errors.map((e) => e.$message)
                        "
                        @input="v$.student.id_card.$touch"
                      >
                      </VTextField>
                    </VCol>

                    <VCol cols="12" sm="6" md="6" lg="2">
                      <VTextField
                        v-model="student.phone_number"
                        prepend-inner-icon="mdi-cellphone-text"
                        :label="$t('globals.phone_number')"
                        density="compact"
                        :error-messages="
                          v$.student.phone_number.$errors.map((e) => e.$message)
                        "
                        @input="v$.student.phone_number.$touch"
                      >
                      </VTextField>
                    </VCol>
                    <VCol cols="12" sm="6" md="6" lg="2">
                      <VTextField
                        v-model="student.home_number"
                        prepend-inner-icon="mdi-phone-classic"
                        :label="$t('globals.home_number')"
                        density="compact"
                        :error-messages="
                          v$.student.home_number.$errors.map((e) => e.$message)
                        "
                        @input="v$.student.home_number.$touch"
                      >
                      </VTextField>
                    </VCol>
                    <VCol cols="12" sm="6" md="6" lg="4">
                      <VRow class="pa-0 ma-0">
                        <VAutocomplete
                          v-model="student.fk_parent"
                          prepend-inner-icon="mdi-human-male-boy"
                          item-value="id"
                          item-title="name"
                          :items="parent_list"
                          :label="$t('school.select_parent_name')"
                          density="compact"
                          :disabled="!selected"
                        >
                        </VAutocomplete>
                        <VBtn
                          density="compact"
                          variant="tonal"
                          icon
                          class="my-2 ms-3"
                          @click.prevent="addParentDialog = true"
                        >
                          <dir :title="$t('globals.parent-add')">
                            <VIcon icon="mdi-plus-thick" color="success"></VIcon>
                          </dir>
                        </VBtn>
                      </VRow>
                    </VCol>
                    <VCol cols="12" sm="6" md="6" lg="4">
                      <VTextField
                        v-model="student.parent_relation"
                        prepend-inner-icon="mdi-human-male-boy"
                        :label="$t('school.parent_relation')"
                        :placeholder="$t('school.parent_relation_placeholder')"
                        density="compact"
                        :error-messages="
                          v$.student.parent_relation.$errors.map(
                            (e) => e.$message
                          )
                        "
                      >
                      </VTextField>
                    </VCol>
                    <VCol cols="12" sm="6" md="6" lg="4">
                      <v-checkbox
                        v-model="student.active"
                        density="compact"
                        hide-details="auto"
                        :label="$t('summer.is-student-active')"
                      >
                      </v-checkbox>
                    </VCol>
                    <VCol cols="12">
                      <VTextField
                        v-model="student.address"
                        prepend-inner-icon="mdi-map-marker-outline"
                        :label="$t('school.student_address')"
                        density="compact"
                        :error-messages="
                          v$.student.address.$errors.map((e) => e.$message)
                        "
                      >
                      </VTextField>
                    </VCol>
                  </VRow>
                </fieldset>
                <fieldset class="mb-2 pt-1 pb-3 border rounded px-2">
                  <legend class="mx-2 px-2">
                    <span class="text-medium-emphasis"
                      >مكان وتاريخ الميلاد</span
                    >
                  </legend>
                  <VRow class="mt-1">
                    <VCol cols="12" sm="6" md="4" lg="3">
                      <DatetimePicker
                        v-model="student.birthdate"
                        :errorMessages="
                          v$.student.birthdate.$errors.map((e) => e.$message)
                        "
                        @input="v$.student.birthdate.$touch"
                        :calendar="'gregorg'"
                        :placeholder="$t('globals.year-month-day')"
                        :label="$t('school.student_birthdate')"
                      ></DatetimePicker>
                    </VCol>
                    <VCol cols="12" sm="6" md="4" lg="3">
                      <VAutocomplete
                        v-model="student.fk_governorate"
                        item-value="id"
                        item-title="name_ar"
                        prepend-inner-icon="mdi-city"
                        :items="getGovernoratesByCountry(1)"
                        :label="$t('globals.governorate')"
                        persistent-hint
                        density="compact"
                        clearable
                        :error-messages="
                          v$.student.fk_governorate.$errors.map(
                            (e) => e.$message
                          )
                        "
                      ></VAutocomplete>
                    </VCol>
                    <VCol cols="12" sm="6" md="4" lg="3">
                      <VAutocomplete
                        v-model="student.fk_directorate"
                        item-value="id"
                        item-title="name_ar"
                        counter="100"
                        prepend-inner-icon="mdi-map-outline"
                        :items="
                          getDirectorateByGovernorates(student.fk_governorate)
                        "
                        :label="$t('globals.directorate')"
                        persistent-hint
                        density="compact"
                        clearable
                        :error-messages="
                          v$.student.fk_directorate.$errors.map(
                            (e) => e.$message
                          )
                        "
                      ></VAutocomplete>
                    </VCol>
                    <VCol cols="12" sm="12" md="12" lg="3">
                      <VTextField
                        v-model="student.birth_place"
                        prepend-inner-icon="mdi-map-marker"
                        :label="$t('summer.solitude')"
                        density="compact"
                        :error-messages="
                          v$.student.birth_place.$errors.map((e) => e.$message)
                        "
                        @input="v$.student.birth_place.$touch"
                      >
                      </VTextField>
                    </VCol>
                  </VRow>
                </fieldset>
              </VCol>
              <VCol cols="12" md="3" lg="2" class="border-s">
                <ImageInput
                  :reset="image_reset_student_image"
                  :image="student.student_image"
                  :text="$t('school.student_image')"
                  @ImageSelected="saveStudentImage"
                  @resetRequest="imageResetStudentImage"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          <v-window-item :value="1">
            <VRow class="mt-2 py-1" align="start">
              <VCol cols="12" xs="12" sm="12" md="9" lg="10" class="mt-3">
                <VRow>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VAutocomplete
                      :items="levels"
                      v-model="student.class.fk_level"
                      item-title="level_with_phase_name"
                      item-value="id"
                      :label="$t('summer.level.name_ar')"
                      prepend-inner-icon="mdi-stairs-box"
                      persistent-hint
                      density="compact"
                      @update:modelValue="student.class.fk_division = undefined"
                      :error-messages="
                        v$.student.class.fk_level.$errors.map((e) => e.$message)
                      "
                    >
                    </VAutocomplete>
                  </VCol>
                  <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                    <VAutocomplete
                      :items="getClassDivisions(student.class.fk_level)"
                      v-model="student.class.fk_division"
                      item-title="name"
                      item-value="id"
                      :label="$t('division.name')"
                      prepend-inner-icon="mdi-shape-outline"
                      persistent-hint
                      density="compact"
                      :error-messages="
                        v$.student.class.fk_division.$errors.map(
                          (e) => e.$message
                        )
                      "
                    >
                      <!-- @update:modelValue="checkStudentCountInHall()" -->
                    </VAutocomplete>
                  </VCol>
                </VRow>

                <VRow>
                  <VCol cols="6" sm="6" md="4">
                    <VAutocomplete
                      :items="school_student_type"
                      v-model="student.school_type"
                      item-title="name"
                      item-value="id"
                      :label="$t('school.school_type')"
                      prepend-inner-icon="mdi mdi-school"
                      persistent-hint
                      density="compact"
                      :error-messages="
                        v$.student.school_type.$errors.map((e) => e.$message)
                      "
                    >
                    </VAutocomplete>
                  </VCol>
                  <VCol cols="6" sm="6" md="4">
                    <VAutocomplete
                      v-model="student.student_type"
                      item-value="id"
                      item-title="name"
                      prepend-inner-icon="mdi mdi-account"
                      :items="student_type"
                      :label="$t('school.students_type')"
                      persistent-hint
                      density="compact"
                      clearable
                      :error-messages="
                        v$.student.student_type.$errors.map((e) => e.$message)
                      "
                    ></VAutocomplete>
                  </VCol>
                  <VCol cols="12" sm="12" md="4">
                    <VAutocomplete
                      v-model="student.accommodation_type"
                      item-value="id"
                      item-title="name"
                      prepend-inner-icon="mdi-home"
                      :items="accommodation_types"
                      :label="$t('summer.accommodation-type')"
                      persistent-hint
                      density="compact"
                      clearable
                      :error-messages="
                        v$.student.accommodation_type.$errors.map(
                          (e) => e.$message
                        )
                      "
                    ></VAutocomplete>
                  </VCol>
                  <VCol>
                    <VTextField
                      v-model="student.school_name"
                      prepend-inner-icon="mdi-school"
                      :label="$t('globals.school-name')"
                      :placeholder="$t('globals.school-name')"
                      density="compact"
                      :error-messages="
                        v$.student.school_name.$errors.map((e) => e.$message)
                      "
                      :rules="address"
                      counter="150"
                    >
                    </VTextField>
                  </VCol>
                  <VCol cols="12" sm="12" md="4">
                    <VAutocomplete
                      v-model="student.schoollevel"
                      item-value="id"
                      item-title="name"
                      prepend-inner-icon="mdi-ladder"
                      :items="school_phases"
                      :label="$t('globals.school-class')"
                      persistent-hint
                      density="compact"
                      clearable
                    ></VAutocomplete>
                  </VCol>
                </VRow>

                <VRow>
                  <VCol cols="12" xs="12" sm="12" md="12" lg="12">
                    <VTextarea
                      v-model="student.note"
                      clearable
                      :label="$t('globals.note')"
                      prepend-inner-icon="mdi-note"
                      counter="250"
                      no-resize
                      density="compact"
                      rows="2"
                      :error-messages="
                        v$.student.note.$errors.map((e) => e.$message)
                      "
                      @input="v$.student.birthdate.$touch"
                    ></VTextarea>
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="12" md="3" lg="2">
                <ImageInput
                  :reset="image_reset_registration_form"
                  :image="student.registration_form"
                  :text="$t('school.student_registration_form')"
                  @ImageSelected="saveRegistrationForm"
                  @resetRequest="imageResetRegistrationForm"
                ></ImageInput>
              </VCol>
            </VRow>
          </v-window-item>
          
        </v-window>
      </VForm>
    </v-card-text>
    <VCardActions
      class="px-3"
      v-if="
        $checkPermissions('summer_center.change_studentsummer') ||
        $checkPermissions('summer_center.add_studentsummer')
      "
    >
      <VBtn
        :loading="in_progress"
        v-if="
          !is_update && $checkPermissions('summer_center.add_studentsummer')
        "
        class="bg-indigo"
        @click="saveStudent"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        :loading="in_progress"
        v-if="
          is_update && $checkPermissions('summer_center.change_studentsummer')
        "
        class="bg-success"
        @click="updateStudent"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
  <v-dialog v-model="dialog" transition="dialog-top-transition" width="auto">
    <v-card>
      <v-card-title>
        <h3 class="text-warning">{{ $t("globals.warning") }}</h3>
      </v-card-title>
      <v-card-text>
        {{ $t("globals.student_count_exceeded") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="dialog = false">{{
          $t("globals.ok")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="addParentDialog"
    scrollable
    persistent
    max-width="800"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    @keydown.esc="addParentDialog = false"
  >
    <v-card
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      class="text-main-color overflow-auto"
      max-height="700 "
    >
      <v-card-title
        class="py-2 bg-indigo mb-4 d-flex justify-space-between align-center"
      >
        <span>
          <v-icon size="30" class="me-2">mdi-human-male-child</v-icon>
          <span class="text-h6">{{ $t("globals.parent-add") }}</span>
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="parent_form">
          <v-row>
            <v-col cols="12" md="8" lg="9">
              <v-row dense>
                <VCol cols="12" md="6">
                  <VTextField
                    v-model="parent.name"
                    prepend-inner-icon="mdi-abjad-arabic"
                    :label="$t('school.student_parent_name_ar')"
                    :placeholder="$t('school.student_parent_name_placeholder')"
                    density="compact"
                    clearable
                    @input="v$.parent.name.$touch"
                    :error-messages="
                      v$.parent.name.$errors.map((e) => e.$message)
                    "
                  >
                  </VTextField>
                </VCol>
                <VCol cols="12" md="6">
                  <VAutocomplete
                    v-model="parent.identity_type"
                    prepend-inner-icon="mdi-card-account-details"
                    item-value="id"
                    item-title="name"
                    :items="identify_type"
                    :label="$t('school.parent_id')"
                    persistent-hint
                    density="compact"
                    clearable
                    @input="v$.parent.identity_type.$touch"
                    :error-messages="
                      v$.parent.identity_type.$errors.map((e) => e.$message)
                    "
                  ></VAutocomplete>
                </VCol>
                <VCol cols="12" md="6">
                  <VTextField
                    v-model="parent.identity_number"
                    prepend-inner-icon="mdi-numeric"
                    :label="$t('school.parent_id_no')"
                    :placeholder="$t('school.parent_id_no_placeholder')"
                    persistent-hint
                    density="compact"
                    clearable
                    type="number"
                    @input="v$.parent.identity_number.$touch"
                    :error-messages="
                      v$.parent.identity_number.$errors.map((e) => e.$message)
                    "
                  ></VTextField>
                </VCol>
                <VCol cols="12" md="6">
                  <VTextField
                    v-model="parent.job"
                    prepend-inner-icon="mdi-account-hard-hat"
                    :label="$t('school.parent_job')"
                    :placeholder="$t('school.parent_job_placeholder')"
                    density="compact"
                    clearable
                    @input="v$.parent.job.$touch"
                    :error-messages="
                      v$.parent.job.$errors.map((e) => e.$message)
                    "
                  ></VTextField>
                </VCol>
                <VCol cols="12" md="6">
                  <VTextField
                    v-model="parent.phone_number"
                    prepend-inner-icon="mdi-cellphone-text"
                    :label="$t('school.parent_phone')"
                    :placeholder="$t('school.parent_phone_placeholder')"
                    density="compact"
                    clearable
                    @input="v$.parent.phone_number.$touch"
                    :error-messages="
                      v$.parent.phone_number.$errors.map((e) => e.$message)
                    "
                  ></VTextField>
                </VCol>
                <VCol cols="12" md="6">
                  <VTextField
                    v-model="parent.home_number"
                    prepend-inner-icon="mdi-phone-classic"
                    :label="$t('school.parent_landline')"
                    :placeholder="$t('school.parent_landline_placeholder')"
                    density="compact"
                    clearable
                    type="number"
                    @input="v$.parent.home_number.$touch"
                    :error-messages="
                      v$.parent.home_number.$errors.map((e) => e.$message)
                    "
                  ></VTextField>
                </VCol>
                <VCol cols="12">
                  <VTextField
                    v-model="parent.address"
                    prepend-inner-icon="mdi-map-marker"
                    :label="$t('school.parent_address')"
                    :placeholder="$t('school.parent_address_placeholder')"
                    density="compact"
                    clearable
                    @input="v$.parent.address.$touch"
                    :error-messages="
                      v$.parent.address.$errors.map((e) => e.$message)
                    "
                  ></VTextField>
                </VCol>
                <VCol cols="12">
                  <VTextarea
                    v-model="parent.note"
                    prepend-inner-icon="mdi-note"
                    clearable
                    :label="$t('school.notes')"
                    :placeholder="$t('school.notes_placeholder')"
                    :rules="notes_rule"
                    counter="250"
                    no-resize
                    density="compact"
                    rows="2"
                    @input="v$.parent.note.$touch"
                    :error-messages="
                      v$.parent.note.$errors.map((e) => e.$message)
                    "
                  ></VTextarea>
                </VCol>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <ImageInput
                :reset="image_reset_identity_image"
                :image="parent.identity_image"
                :text="$t('school.parent_id_image')"
                @ImageSelected="saveIdImage"
                @resetRequest="imageResetIdentityImage"
              ></ImageInput>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-2 py-0">
        <v-btn
          class="bg-indigo"
          size="small"
          :loading="saveParentLoading"
          @click="saveParent"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </v-btn>
        <v-btn size="small" @click="addParentDialog = false">{{
          $t("globals.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DatetimePicker from "@/components/Globals/DatetimePicker.vue";
import ImageInput from "@/components/Globals/ImageInput.vue";
import useValidate from "@vuelidate/core";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
  maxValue,
  minLength,
} from "@vuelidate/validators";
const englishOnly = helpers.regex(/^[a-zA-Z\-'\s]+$/);
const mobile_reg = helpers.regex(/^[0-9+]+$/);
const phone_reg = helpers.regex(/^[0-9]+$/);
const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);
const nameRegular = helpers.regex(/^[\u0621-\u064A\u0660-\u0669a-zA-Z\s]*$/);

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      v$: useValidate(),
      saveParentLoading: false,
      addParentDialog: false,
      parent_list: [],
      school_phases: [],
      max_date: undefined,
      date_now: undefined,
      selected: true,
      in_progress: false,
      dialog: false,
      required_docs: [],
      documents: [],
      summer_schools: [],
      image_reset_identity_image: 0,
      image_reset_student_image: 0,
      image_reset_registration_form: 0,
      student: {
        school_type: undefined,
        student_type: undefined,
        accommodation_type: undefined,
        fk_parent: null,
        fk_country: undefined,
        fk_governorate: undefined,
        fk_directorate: undefined,
        governorate: undefined,
        directorate: undefined,
        fk_summer: undefined,
        school_name: undefined,
        schoollevel: undefined,
        active: false,
        name: undefined,
        gender: undefined,
        nationality: undefined,
        birthdate: undefined,
        birth_place: undefined,
        parent_relation: undefined,
        address: undefined,
        phone_number: undefined,
        id_card: undefined,
        home_number: undefined,
        student_image: undefined,
        registration_form: undefined,
        note: undefined,
        files: undefined,

        class: {
          fk_student: 0,
          fk_year: undefined,
          fk_level: undefined,
          fk_division: undefined,
          note: undefined,
        },
        documents: [
          {
            files: undefined,
            document: undefined,
            released_date: undefined,
            issuer: undefined,
          },
        ],
      },
      parent: {
        fk_user: 0,
        name: "",
        identity_type: undefined,
        identity_number: undefined,
        identity_image: undefined,
        job: undefined,
        address: undefined,
        phone_number: undefined,
        id_card: undefined,
        home_number: undefined,
        note: undefined,
      },
      tab: undefined,
      process_alert: false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      rules: {
        governorate: [(value) => !!value || this.$t("globals.required_field")],
        directorate: [(value) => !!value || this.$t("globals.required_field")],
        accommodation: [
          (value) => !!value || this.$t("globals.required_field"),
        ],
        fk_summer: [(value) => !!value || this.$t("globals.required_field")],
      },

      // tabs:[
      //     this.$t('school.parent_info'),
      //     this.$t('school.student_info'),
      //     this.$t('school.student_academic_info')
      //     ],
      parent_rule: [(value) => !!value || this.$t("errors.required")],
    };
  },
  components: {
    ImageInput,
    DatetimePicker,
  },
  validations() {
    return {
      student: {
        // fk_parent:{required:helpers.withMessage(this.$t('globals.required_field'),required)},
        school_name: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(150)
          ),
        },
        name: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          arabicOnly: helpers.withMessage(
            this.$t("globals.must_be_letters"),
            arabicOnly
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        gender: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        birthdate: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          date: helpers.withMessage(
            this.$t("globals.max_date") + this.max_date,
            this.validators.moreDate(this.max_date)
          ),
        },
        accommodation_type: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        fk_country: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        fk_governorate: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        fk_directorate: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        birth_place: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          maxLength: helpers.withMessage(
            `${this.$t("globals.max_characters")} 100`,
            maxLength(100)
          ),
        },
        student_type: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        school_type: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        parent_relation: {
          // required: helpers.withMessage(
          //   this.$t("globals.required_field"),
          //   required
          // ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        address: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(150)
          ),
        },
        phone_number: {
          // required:helpers.withMessage(this.$t('globals.required_field'),required),
          mobile_reg: helpers.withMessage(
            this.$t("errors.numeric"),
            mobile_reg
          ),
          numeric: helpers.withMessage(
            this.$t("globals.must_be_numbers"),
            numeric
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_numbers") + " 14",
            maxLength(14)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.lowest_number_of_character") + "9",
            minLength(9)
          ),
        },
        id_card: {
          // required:helpers.withMessage(this.$t('globals.required_field'),required),
          mobile_reg: helpers.withMessage(
            this.$t("errors.numeric"),
            mobile_reg
          ),
          numeric: helpers.withMessage(
            this.$t("globals.must_be_numbers"),
            numeric
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_numbers") + "14",
            maxLength(14)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.min_numbers") + "4",
            minLength(4)
          ),
        },
        home_number: {
          numeric: helpers.withMessage(
            this.$t("globals.must_be_numbers"),
            numeric
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_numbers") + "8",
            maxLength(8)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.min_numbers") + "6",
            minLength(6)
          ),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },

        class: {
          fk_level: {
            required: helpers.withMessage(
              this.$t("globals.required_field"),
              required
            ),
          },
          fk_division: {
            required: helpers.withMessage(
              this.$t("globals.required_field"),
              required
            ),
          },
          note: {
            maxLength: helpers.withMessage(
              this.$t("globals.max_characters"),
              maxLength(250)
            ),
          },
        },
      },
      parent: {
        name: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
        },
        identity_type: {},
        identity_number: {
          numeric: helpers.withMessage(
            this.$t("globals.must_be_numbers"),
            numeric
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_numbers") + " 14 ",
            maxLength(14)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.lowest_number_of_character") + "4",
            minLength(4)
          ),
        },
        job: {
          // required: helpers.withMessage(
          //   this.$t("globals.required_field"),
          //   required
          // ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        address: {
          // required: helpers.withMessage(
          //   this.$t("globals.required_field"),
          //   required
          // ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(150)
          ),
        },
        phone_number: {
          // required: helpers.withMessage(
          //   this.$t("globals.required_field"),
          //   required
          // ),
          mobile_reg: helpers.withMessage(
            this.$t("errors.numeric"),
            mobile_reg
          ),
          numeric: helpers.withMessage(
            this.$t("globals.must_be_numbers"),
            numeric
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_numbers") + "14",
            maxLength(14)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.min_numbers") + "9",
            minLength(9)
          ),
        },
        id_card: {
          mobile_reg: helpers.withMessage(
            this.$t("errors.numeric"),
            mobile_reg
          ),
          numeric: helpers.withMessage(
            this.$t("globals.must_be_numbers"),
            numeric
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_numbers") + "14",
            maxLength(14)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.min_numbers") + "4",
            minLength(4)
          ),
        },
        home_number: {
          numeric: helpers.withMessage(
            this.$t("globals.must_be_numbers"),
            numeric
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_numbers") + "8",
            maxLength(8)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.min_numbers") + "6",
            minLength(6)
          ),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
      },
    };
  },
  async created() {
    try {
      this.$store.commit("updateLoadingValue", true);
      let date = new Date();
      this.max_date = new Date(
        date.getFullYear() - 7,
        date.getMonth(),
        date.getDay()
      )
        .toISOString()
        .split("T")[0];
      this.date_now = date.toISOString().split("T")[0];
      if (this.divisions.length == 0) this.getDivisions();
      await this.getSchoolStudentType();
      await this.getStudentTypeName();
      await this.getGenders();
      await this.getIdentifyType();
      await this.getCountries();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getLevels();
      await this.getAccommodationType();
      await this.getSchoolPhases();
      await this.getRoles();
      await this.getSummerSchools()
      this.getClassAssignHall();

      if (this.role == 2) {
        this.student.governorate = this.user_governorate;
      }
      if (this.role == 3) {
        this.student.directorate = this.user_directorate;
        this.getSummerSchools();
      }
      if (this.id != undefined) {
        this.is_update = true;
        await this.axios
          .get(`${this.base_url}api/summer/student/${this.id}/`)
          .then((response) => {
            this.student = response.data?.student;
            this.student["class"] = response.data?.academic_data;
            this.getClassDivisions(this.student.class.fk_level);
          });
      }
      this.student.fk_country = this.countries[0].id;
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      genders: (state) => state.summer.genders,
      identify_type: (state) => state.summer_globals.identify_type,
      levels: (state) => state.summer.levels,
      divisions: (state) => state.summer.divisions,
      class_assign_hall: (state) => state.summer.class_assign_hall,
      countries: (state) => state.school.countries,
      user: (state) => state.User,
      student_type: (state) => state.summer_globals.study_type,
      school_student_type: (state) => state.summer_globals.school_student_type,
      accommodation_types: (state) => state.summer_globals.accommodation_types,
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
      current_year: (state) => state.current_year,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      getGovernoratesByCountry: "school/getGovernoratesByCountry",
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
      getClassDivisions: "summer/getClassDivisions",
    }),
    
    tabs() {
      return [
        this.$t("school.student_info"),
        this.$t("school.student_academic_info"),
      ];
    },
  },
  methods: {
    async getSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          params: {
            directorate: this.student.directorate,
            governorate: this.student.governorate,
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    resetParentForm() {
      this.$refs.parent_form.reset();
      this.v$.$reset();
      this.addParentDialog = false;
    },
    async saveParent() {
      this.v$.parent.$validate();
      if (!this.v$.parent.$error) {
        this.saveParentLoading = true;
        this.parent["fk_summer"] = this.student.fk_summer;
        await this.axios
          .post(this.base_url + "api/summer/parent/", this.parent, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveParentLoading = false;
            this.getSummerParents();
            this.resetParentForm();
          })
          .catch((error) => {
            if (error?.response?.data?.fk_summer) {
              this.$emit(
                "errorAlert",
                "لم يتم اختيار المدرسة التي ينتمي اليها ولي الأمر"
              );
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveParentLoading = false;
          });
      }
    },
    async getClassAssignHall() {
      await this.axios
        .get(this.base_url + "/api/summer/level-division-data", {
          params: {
            fk_summer: this.student.fk_summer,
          },
        })
        .then((res) => {
          this.$store.commit("summer/getClassAssignHall", res.data);
        });
    },
    async getSchoolPhases() {
      await this.axios(this.base_url + "api/summer/choices/school-phases").then(
        (response) => {
          this.school_phases = response.data;
        }
      );
    },
    async getSummerParents() {
      await this.axios(`${this.base_url}api/summer/parent/`, {
        params: {
          page_size: 1000,
          fk_summer: this.student.fk_summer,
        },
      }).then((response) => {
        this.parent_list = response.data.results;
      });
    },
    ...mapActions({
      getSchoolStudentType: "summer_globals/getSchoolStudentType",
      getStudentTypeName: "summer_globals/getStudyType",
      getGenders: "summer/getGenders",
      getIdentifyType: "summer_globals/getIdentifyType",
      getLevels: "summer/getLevels",
      getDivisions: "summer/getDivisions",
      // getClassAssignHall: "summer/getClassAssignHall",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getAccommodationType: "summer_globals/getAccommodationType",
      getRoles: "summer/getRoles",
    }),
    
    saveIdImage(event) {
      this.parent.identity_image = event;
    },
    imageResetIdentityImage(event) {
      this.image_reset_identity_image = event;
    },
    imageResetStudentImage(event) {
      this.image_reset_student_image = event;
    },
    imageResetRegistrationForm(event) {
      this.image_reset_registration_form = event;
    },
    resetForm() {
      this.tab = 0;
      this.v$.student.$reset();
      this.$refs.form.reset();
      this.image_reset++;
      this.image_reset_identity_image++,
        this.image_reset_student_image++,
        this.image_reset_registration_form++,
        (this.student.registration_form = undefined);
      this.student.student_image = undefined;
      this.student = {
        class: {},
      };
      this.selected = true;
      this.in_progress = false;
      if (this.is_update) this.$router.push({ name: "summer-student-list" });
      this.is_update = false;
      this.$refs.studentName.focus();
    },
    saveRegistrationForm(event) {
      this.student.registration_form = event;
    },
    saveStudentImage(event) {
      this.student.student_image = event;
    },
    async saveStudent() {
      this.v$.student.$validate();
      const { valid } = await this.$refs.form.validate();
      console.log(this.v$.student.$errors);
      if (!this.v$.student.$error) {
        this.in_progress = true;
        this.student.data_entry = this.user;
        let form = new FormData();
        form.append("student_image", this.student.student_image);
        form.append("registration_form", this.student.registration_form);
        form.append("data", JSON.stringify(this.student));
        let result = await this.axios
          .post(this.base_url + "/api/summer/student/", form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.getSummerParents();
            this.resetForm();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
      this.in_progress = false;
    },
    async updateStudent() {
      this.v$.student.$validate();
      if (!this.v$.student.$error && this.id != undefined) {
        this.in_progress = true;
        this.student.updated_by = this.user;
        let form = new FormData();
        form.append("student_image", this.student.student_image);
        form.append("registration_form", this.student.registration_form);
        form.append("data", JSON.stringify(this.student));
        let result = await this.axios
          .put(`${this.base_url}/api/summer/student/${this.id}/`, form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            setTimeout(() => {
              this.$router.push({ name: "summer-student-list" });
            }, 2000);
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.in_progress = false;
      }
    },
  },
  watch: {
    addParentDialog(val) {
      if (!val) {
        this.resetParentForm();
      }
    },
    id() {
      this.resetForm();
    },
    "student.fk_summer"(val) {
      if (val) {
        this.getSummerParents();

        this.getClassAssignHall();
      }
    },
  },
};
</script>

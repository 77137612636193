import custom_axios from "@/plugins/axios.js";
import { api_url } from "@/main";
import store from "@/store";

let inactivity_timer = null;

const inactivity = {
  timeout_duration: 15 * 60 * 1000,
  // timeout_duration: 10000,
  event_handlers: {},

  startInactivityTimer() {
    this.clearInactivityTimer();
    inactivity_timer = setTimeout(async () => {
      await custom_axios.post(`${api_url}api/logout`)
        .then((res) => {
          store.dispatch("logout");
        })
        .catch(() => {});
    }, this.timeout_duration);
  },

  clearInactivityTimer() {
    if (inactivity_timer) {
      clearTimeout(inactivity_timer);
    }
  },
  initialize() {
    this.event_handlers.mousemove = this.startInactivityTimer.bind(this);
    this.event_handlers.keydown = this.startInactivityTimer.bind(this);
    this.event_handlers.click = this.startInactivityTimer.bind(this);

    window.addEventListener("mousemove", this.event_handlers.mousemove);
    window.addEventListener("keydown", this.event_handlers.keydown);
    window.addEventListener("click", this.event_handlers.click);

    this.startInactivityTimer();
  },

  destroy() {
    this.clearInactivityTimer();
    if (this.event_handlers.mousemove) {
      window.removeEventListener("mousemove", this.event_handlers.mousemove);
    }
    if (this.event_handlers.keydown) {
      window.removeEventListener("keydown", this.event_handlers.keydown);
    }
    if (this.event_handlers.click) {
      window.removeEventListener("click", this.event_handlers.click);
    }
    this.event_handlers = {};
  },
};

export default inactivity;
<template>
  <v-expansion-panels v-if="role < 3" class="mb-2">
    <v-expansion-panel>
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <VCol cols="6" md="3" v-if="role < 2">
            <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="filter.governorate"
              item-title="name_ar"
              item-value="id"
              hide-details="auto"
              :label="$t('globals.governorate')"
              density="compact"
              clearable
              prepend-inner-icon="mdi-city"
              @update:modelValue="filter.directorate = null"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="3" v-if="role <= 2 || role == 9 || role == 8">
            <VAutocomplete
              :items="filterDirectorates(filter.governorate)"
              v-model="filter.directorate"
              item-title="name_ar"
              item-value="id"
              hide-details="auto"
              clearable
              :label="$t('globals.directorate')"
              density="compact"
              prepend-inner-icon="mdi-map-outline"
            ></VAutocomplete>
          </VCol>

          <VCol cols="6" md="2" class="mt-2">
            <v-btn
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100 text-white"
              color="indigo"
              :loading="loading"
              @click="getSummerCamp((page = 1))"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </VCol>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <DataTable
    v-if="$checkPermissions('globaltable.view_assigntask')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="AddAssignTask"
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="$checkPermissions('globaltable.delete_assigntask')"
    :edit_perm="$checkPermissions('globaltable.change_assigntask')"
    :add_perm="$checkPermissions('globaltable.add_assigntask')"
  >
  </DataTable>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      filter: {
        governorate: undefined,
        directorate: undefined,
      },
      addDialog: false,
      editDataLoading: false,
      deleteDataLoading: false,
      items: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      item: {},

      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.task"), key: "task_name"  ,show:true},
        { title: this.$t("summer.task-type"), key: "task_type_name"  ,show:true},
        { title: this.$t("summer.duration"), key: "period"  ,show:true},
        { title: this.$t("summer.mission-start-date"), key: "task_start"  ,show:true},
        { title: this.$t("summer.mission-end-date"), key: "task_end"  ,show:true},
        { title: this.$t("summer.place-of-residence"), key: "place"  ,show:true},
        { title: this.$t("summer.target-audience"), key: "target"  ,show:true},
        { title: this.$t("summer.positives-of-the-workshop"), key: "pros"  ,show:true},
        { title: this.$t("summer.obstacles-in-the-workshop"), key: "cons"  ,show:true},

        {
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      role: (state) => state.role,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      // getGovernorateName: "school/getGovernorateName",
      // getDirectorateName: "school/getDirectorateName",
    }),
    
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      await this.getGovernorates();
      await this.getDirectorate();
      if ((this.role = 2 || this.role == 3)) {
        this.getGovernorateData();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    AddAssignTask(){
      this.$router.push({name:'task_assign'})
    },
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    
    editItem(item) {
      this.$router.push({name:'task_assign_edit',params:{id:item.id}});
    },
    async updateItem() {
      
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        this.delete_id = delete_id;
        var status;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}api/assign-task/${delete_id}/`)
          .then((response) => {
            status = true;
          })
          .catch((error) => {
            this.deleteDataLoading = false;
            status = error;
          });
        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/assign-task/`, {
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.items = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  
};
</script>

<template>
  <div :dir="currentDir">
    <!------------------------------------ الهيدر-------------------------------- -->
    <v-app-bar :elevation="12" class="app-bar">
      <template v-slot:prepend>
        <div class="school-info">
          <v-avatar size="40" class="mr-2">
            <img :src="logoPath" />
          </v-avatar>
          <span class="school-name"> الانشطة الطلابية</span>
        </div>
      </template>
      <v-spacer></v-spacer>

      <template v-slot:append>
        <div class="actions">
          <v-btn text small @click="toggleLanguage" class="pa-1">
            <v-icon class="mr-2" color="black">mdi-translate</v-icon>
            <span class="hide-on-small">{{currentLang === 'ar' ? 'English' : 'العربية'}}</span>
          </v-btn>
        </div>
      </template>
    </v-app-bar>

    <!------------------------------------ عارص الصور-------------------------------- -->
    <v-carousel hide-delimiters height="900">
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" cover>
        <div class="text-overlay">
          {{ item.text }}
        </div>
        <div class="p-overlay">
          {{ item.p }}
        </div>
        <div class="overlay"></div>
      </v-carousel-item>

    </v-carousel>


    <!------------------------------------ footer-------------------------------- -->
    <v-footer class="d-flex flex-column pa-0">
      <div
        class="px-4 py-5 bg-black text-center w-100"
        style="font-size: 22px !important"
      >
              {{ new Date().getFullYear() }} <strong class="text-white">حقوق الطبع محفوظة لدى الانشطة الطلابية</strong>
      </div>
    </v-footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentLang: this.$i18n.locale,
      items: [
        {
          src: new URL("@/assets/img/carousel/a8.jpg", import.meta.url).href,
          text: "تعليم متميز",
        },
        {
          src: new URL("@/assets/img/carousel/a1.jpg", import.meta.url).href,
          text: " الانشطة الطلابية",
        },
        {
          src: new URL("@/assets/img/carousel/a2.jpg", import.meta.url).href,
          text: "تحسين مستوى الطلاب",
        }
        
      ],
    };
  },
  computed:{
    currentDir(){
      return this.currentLang === 'ar' ? 'rtl' : 'ltr';
    }
  },
  methods: {
    toggleLanguage(){
      this.currentLang = this.currentLang === 'ar' ? 'en' : 'ar';
      this.$i18n.locale = this.currentLang;
    },
    loginAction() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style>
.fontsize {
  font-size: 14px !important;
}
.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  color: white !important;
  font-size: 40px !important;
  font-weight: bold;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
  text-align: center;
}
.p-overlay {
  position: absolute;
  top: 58%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  color: white !important;
  font-size: 20px !important;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
  text-align: center;
}
.school-info {
  display: flex;
  align-items: center;
}
.school-name {
  font-weight: bold;
  font-size: 18px !important;
}
.actions {
  display: flex;
  align-items: center;
}
.hide-on-small {
  display: initial;
}
.app-bar {
  padding-inline: 30px;
}
.v-toolbar__append {
  padding-inline: 30px;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
@media (max-width: 600px) {
  .school-name {
    font-size: 14;
  }
  .hide-on-small {
    display: none;
  }
  .actions v-btn {
    padding: 0 4px;
  }
  .app-bar {
    padding-inline: 10px;
  }
  .v-toolbar__append {
    padding-inline: 0;
  }
  .text-overlay {
  position: absolute;
  top: 35%;
  left: 50%;
  white-space: nowrap;
  z-index: 2;
  transform: translate(-50%, -50%);
  color: white !important;
  font-size: 35px !important;
  font-weight: bold;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
  text-align: center;
}
.p-overlay {
  position: absolute;
  top: 47%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  color: white !important;
  font-size: 15px !important;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
  text-align: center;
}
  
}
</style>

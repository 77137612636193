<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.StatisticsTypeMaleFemaleSchools") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.StatisticsTypeMaleFemaleSchools") }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ totals ? totals.totals_all_school : "" }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-container>
        <v-row class="" style="gap: 1rem">
          <v-col cols="12">
            <table class="report-t">
              <thead>
                <tr>
                <th rowspan="2" style="width: 50px;">{{$t("globals.#")}}</th>

                  <th rowspan="2">{{ $t("reportpublic.Governorate") }}</th>
                  <th colspan="2">{{ $t("reportpublic.Open") }}</th>
                  <th colspan="2">{{ $t("reportpublic.Typical") }}</th>
                  <th rowspan="2">
                    {{ $t("reportpublic.TotalNumberOfStudents") }}
                  </th>
                  <th rowspan="2">
                    {{ $t("reportpublic.TotalNumberOfFStudents") }}
                  </th>
                  <th rowspan="2">
                    {{
                      $t("reportpublic.ATotal") +
                      " " +
                      $t("reportpublic.Students")
                    }}
                  </th>
                </tr>
                <tr>
                  <template v-for="index in 2" :key="index">
                    
                    <th>{{ $t("reportpublic.NumberOfStudents") }}</th>
                    <th>{{ $t("reportpublic.NumberOfFStudents") }}</th>
                  </template>
                </tr>

                <tr></tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, index) in results.governorate_data"
                  :key="index"
                >
                <td>{{index+1}}</td>

                  <td>
                    {{
                      this.$i18n.locale == "ar"
                        ? data.governorate_name_ar
                        : data.governorate_name_en
                    }}
                  </td>

                  <td class="text-center">{{ data.males_student_summer_opened_counts }}</td>
                  <td class="text-center">{{ data.females_student_summer_opened_counts }}</td>
                  <td class="text-center">{{ data.males_student_summer_typical_counts }}</td>
                  <td class="text-center">{{ data.females_student_summer_typical_counts }}</td>
                  <td class="text-center">{{ data.males_student_summer_all_counts }}</td>
                  <td class="text-center">{{ data.females_student_summer_all_counts }}</td>
                  <td class="text-center">{{ data.all_student_summer_counts }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="2" class="right-footer rounded-be">
                    {{ $t("reportpublic.GrandTotal") }}
                  </th>

                  <th>{{ results.total_males_student_summer_opened }}</th>
                  <th>{{ results.total_females_student_summer_opened }}</th>
                  <th>{{ results.total_males_student_summer_typical }}</th>
                  <th>{{ results.total_females_student_summer_typical }}</th>
                  <th>{{ results.total_males_student_summer }}</th>
                  <th>{{ results.total_females_student_summer }}</th>
                  <th>{{ results.total_student_summer }}</th>
                </tr>
              </tfoot>
            </table>
          </v-col>
          <v-col cols="12">
            <table class="report-t">
              <thead>
                <tr>
                <th  style="width: 50px;">{{$t("globals.#")}}</th>

                  <th>{{ $t("reportpublic.Governorate") }}</th>
                  <th>{{ $t("reportpublic.MalesSchools") }}</th>
                  <th>{{ $t("reportpublic.FemalesSchools") }}</th>
                  <th>{{ $t("reportpublic.GenerlSchools") }}</th>
                  <th>{{ $t("reportpublic.Total") }}</th>
                </tr>
              </thead>
              <tbody v-for="(val, key) in data" :key="key">
                <tr>
                  <td>{{key+1}}</td>
                  <td>
                    {{
                      this.$i18n.locale == "ar"
                        ? val.governorate_name_ar
                        : val.governorate_name_en
                    }}
                  </td>
                  <td class="text-center">{{ val.shcool_male_number }}</td>
                  <td class="text-center">{{ val.shcool_fmale_male }}</td>
                  <td class="text-center">{{ val.shcool_general_number }}</td>
                  <td class="text-center">{{ val.total_school }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="2">
                    {{ $t("reportpublic.GrandTotal") }}
                  </th>
                  <th>{{ totals.totals_school_male }}</th>
                  <th>{{ totals.totals_school_fmale }}</th>
                  <th>{{ totals.totals_school_general }}</th>
                  <th>{{ totals.totals_all_school }}</th>
                </tr>
              </tfoot>
            </table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.fetchData2();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      await  this.axios
        .get(`${this.base_url}public-reports/daily-reports/summer-statistics-by-school-type-and-student-type?gov_id=${1}`)
        .then((response) => {
          this.results = response.data.results;
        })
        .catch((error) => {});
    },
    async fetchData2() {
      await  this.axios
        .get(
          `${this.base_url}public-reports/daily-reports/summer-statistics-by-student-type`
        )
        .then((response) => {
          this.data = response.data.results.data;
          this.totals = response.data.results.totals;
        })

        .catch((error) => {});
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      results: [],
      data: [],
      totals: {},
    };
  },
  computed: {
    
  },
  components: {
    ReportHeader,
  },
};
</script>

<template>
  <!-- filter data  -->
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("report.employee-by-role") }}</div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <!-- search box  -->
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
          
        </div>
      </div>

      <v-row
        v-if="items.length > 0"
        class="mt-4 mb-5 text-grey-darken-2 borders pt-2 pb-2 mr-1 ml-1"
      >
        <v-col cols="3">
          <h3 class="fontsize text-grey-darken-2 mb-2">
            <span v-if="this.filter_data.fk_level">
              {{ $t("summer.level.name_ar") }} :
              {{ getLevelName(this.filter_data.fk_level) }}
            </span>

            <br />

            <span v-if="this.filter_data.fk_division">
              {{ $t("school.committee_name") }} :
              {{ getDivisionName(this.filter_data.fk_division) }}
            </span>
          </h3>
        </v-col>
      </v-row>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" rounded="0" flat>
          <table class="report-t">
            <thead class="table-light">
              <th style="width: 50px;" >{{$t("globals.#")}}</th>

              <th v-if="$i18n.locale === 'ar'">الدور</th>
              <th v-if="$i18n.locale === 'en'">Role</th>

              <th v-if="$i18n.locale === 'ar'">متوفر</th>
              <th v-if="$i18n.locale === 'en'">
                Available
              </th>
            </thead>
            <tbody>
              <tr v-for="(item ,index) in items.employees" :key="index" class="text-center">
                <td class="text-center">{{index+1}}</td>

                <td>
                  {{ item.role_name }}
                </td>

                <td>
                  <v-icon color="success" v-if="item.exist == true">
                    mdi-check-circle
                  </v-icon>
                  <v-icon color="error" v-else> mdi-close-circle </v-icon>
                </td>
                <!-- <td>
                    {{item.count}}
                  </td> -->
              </tr>
            </tbody>
          </table>

          <br />
        </v-card>
      </div>
    </v-card-text>
  </v-card>

  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);

      await this.getData();
      await this.getYears();
      this.current_year = this.current_year_name;
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },

      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      class_assign_hall: (state) => state.school.class_assign_hall,
      divisions: (state) => state.school.divisions,
      semesters: (state) => state.school.semesters,
      subjects: (state) => state.school.subjects,
      user: (state) => state.User,
      committee: (state) => state.summer.committee,
      levels: (state) => state.summer.levels,
      current_year: (state) => state.current_year,
      current_year_name: (state) => state.current_year_name,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getDivisions: "school/getDivisions",
      getSemesters: "school/getSemesters",
      getClassAssignHall: "school/getClassAssignHall",
      getSubjects: "school/getSubjects",
      getCommittee: "summer/getCommittee",
      getLevels: "summer/getLevels",
    }),
    getYears() {
      this.year_data = this.current_year_name;
      this.filter_data.year = this.current_year;
      return this.year_data;
    },

    getLevelName(driver_id) {
      if (driver_id) {
        const level = this.levels.find((level) => level.id === level.id);
        return level.name_ar;
      }
      return "===========";
    },
    getDivisionName(driver_id) {
      if (driver_id) {
        const division = this.divisions.find((division) => division.id === division.id);
        return division.name;
      }
      return "===========";
    },

    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/employees-by-role`)
        .then((response) => {
          this.items = response.data;
          this.loading = false;
          this.exist = true;
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("globals.title");
            this.alert_message = this.$t("globals.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {},
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

.pp2 {
  padding: 2px !important;
}
#stable th {
  padding: 10px;
  border: 1px solid #649add !important;
  background-color: #fff;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>

<template>
  <v-form ref="form" @submit.prevent>
    <v-card class="pa-2" v-if="!exist">
      <h3 class="text-grey-darken-2 px-2">
        {{ $t("globals.select-criteria") }}
      </h3>
      <v-container class="pa-0">
        <div
          class="my-2 mt-6 d-flex align-center flex-wrap flex-grow-1"
          style="gap: 1.5rem"
        >
          <v-col
            class="pt-0 px-3"
            cols="12"
            xs="12"
            sm="12"
            md="3"
            lg="3"
            v-if="visiGovernorat"
          >
            
              <v-autocomplete
                v-model="dropList.gov"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-city"
                :items="governorates"
                :label="$t('globals.governorate')"
                hide-details
                
                density="compact"
                clearable
                @update:modelValue="
                  (dropList.dir = undefined),
                    (dropList.schoolSummer = undefined)
                "
              ></v-autocomplete>
        
          </v-col>
          <v-col
            v-if="visiDirectorate"
            align-self=""
            class="pt-0 px-3"
            cols="12"
            xs="12"
            sm="12"
            md="3"
            lg="3"
          >
               <v-autocomplete
                v-model="dropList.dir"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-map-outline"
                :items="getDirectorateByGov(dropList.gov)"
                :label="$t('globals.directorate')"
                persistent-hint
                  hide-details
                density="compact"
                clearable
                @update:modelValue="
                  sendSummerCenterRequest(dropList.dir),
                    (dropList.schoolSummer = undefined),
                    (fk_role = undefined)
                "
              ></v-autocomplete>
           </v-col>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-autocomplete
              v-model="dropList.schoolSummer"
              item-value="id"
              item-title="name_ar"
              prepend-inner-icon="mdi-domain"
              :items="summerSchools"
              :label="$t('globals.summer-centre')"
              persistent-hint
              density="compact"
              clearable
                hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3" sm="3">
            <v-autocomplete
              color="indigo"
              v-model="dropList.start_data"
              :items="calendar"
              density="compact"
              item-title="date"
              item-value="date"
              prepend-inner-icon="mdi-calendar-week"
              clearable
                hide-details
              :label="$t('globals.from')"
            />
          </v-col>
          <v-col cols="6" md="3" sm="3">
            <v-autocomplete
              color="indigo"
              v-model="dropList.end_data"
              :items="calendar"
              density="compact"
              item-title="date"
              item-value="date"
              prepend-inner-icon="mdi-calendar-week"
                hide-details
              clearable
              :label="$t('globals.to')"
            />
          </v-col>
          <div>
            <v-btn
              :loading="dataLoading"
              max-width="100px"
              density="comfortable"
              append-icon="mdi-presentation"
              class="table"
              style="width: 100%; border-collapse: collapse"
              color="indigo"
              type="submit"
              @click="fetchData((page = 1))"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-form>
  <v-card id="report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.data-student-employee-present-actual") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="mb-5 pa-0">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          
          <v-btn-group density="compact"  divided >
              <v-btn  size="small"  @click="exportToExcel" :loading="exportProgress">
                  <span>{{$t('export.export')}} </span>
                  <v-icon size="large" color="green-darken-2" end>mdi-file-excel</v-icon>
              </v-btn>
              <v-btn
                @click="printTable((all = true))"
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>

          <v-btn
            v-if="visiBack"
            density="compact"
            @click="exist = false , data=[]"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
          </v-btn-group>

        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.data-student-employee-present-actual") }}

              {{ title }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ length }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-item v-if="data?.length > 0">
      <table class="report-t">
        <thead>
          <tr>
            <th style="width:50px">{{$t('globals.#')}}</th>
           
            <template v-if="dropList.gov==undefined">
              <th>{{ headers[0].title }}</th>
            </template>
            <template v-if="dropList.dir==undefined">
              <th>{{ headers[1].title }}</th>
            </template>
            <template v-if="dropList.schoolSummer==undefined">
              <th>{{ headers[2].title }}</th>
            </template>
            <th>{{ headers[3].title }}</th>
            <th>{{ headers[4].title }}</th>
            <th>{{ headers[5].title }}</th>
            <th>{{ headers[6].title }}</th>
            <th>{{ headers[7].title }}</th>
            <th>{{ headers[8].title }}</th>
            <th>{{ headers[9].title }}</th>
    
           </tr>
        </thead>
        <tbody v-for="(i, index) in data" :key="index">                         
            <tr>                             
         
            <template v-if="dropList.gov==undefined"> 
              <td  style="border-bottom: 0 !important" v-if="i.index_gov !== ''">
                  {{i.index_gov}}
              </td> 
              <td
                  v-else
                  style="border-top: 0 !important; border-bottom: 0 !important">
              </td>
           </template>  
            <template v-else-if="dropList.dir==undefined"> 
              <td  style="border-bottom: 0 !important" v-if="i.index_dir !== ''">
                  {{i.index_dir}}
              </td> 
              <td
                  v-else
                  style="border-top: 0 !important; border-bottom: 0 !important">
              </td>
           </template>  
            <template v-else-if="dropList.schoolSummer==undefined"> 
              <td  style="border-bottom: 0 !important" v-if="i.index_summer !== ''">
                  {{i.index_summer}}
              </td> 
              <td
                  v-else
                  style="border-top: 0 !important; border-bottom: 0 !important">
              </td>
           </template>  
            <td v-else>{{index+1}}</td> 
           
           
           <template v-if="dropList.gov==undefined">
              <td style="border-bottom: 0 !important" v-if="i.gov_name !== ''">
                {{  i.gov_name }}
              </td>
              <td
                v-else
                style="border-top: 0 !important; border-bottom: 0 !important"
              >
              </td>
            </template>
           <template v-if="dropList.dir==undefined">
                <td style="border-bottom: 0 !important" v-if=" i.dir_name !== ''" >
                  {{  i.dir_name }}
                </td>
                <td
                v-else
                style="border-top: 0 !important; border-bottom: 0 !important"
              >
                </td>            
            </template>   
            <template v-if="dropList.schoolSummer==undefined">
              <td style="border-bottom: 0 !important" v-if=" i.school_name !== ''">
                  {{  i.school_name }}
              </td>
              <td
                v-else
                style="border-top: 0 !important; border-bottom: 0 !important"
              >
              </td>          
            </template>  
              <td class="text-center">{{ i.manager_name }}</td>
               
              <td class="text-center">{{ i.manager_phone }}</td>
              <td class="text-center">{{ i.date }}</td>
               <td class="text-center" style="border-bottom: 0 !important" v-if=" i.school_name !== ''">
                {{  i.students }}
              </td>
              <td
                v-else
                style="border-top: 0 !important; border-bottom: 0 !important"
              >
              </td>
            
              <td class="text-center">{{ i.students_attendance }}</td>
                <td  class="text-center" style="border-bottom: 0 !important" v-if=" i.school_name !== ''">
                {{  i.employees }}
              </td>
              <td
                v-else
                style="border-top: 0 !important; border-bottom: 0 !important"
              >
              </td>
               <td class="text-center">{{ i.employees_attendance }}</td>
            </tr>
        </tbody>
        <tfoot>
          <tr>
            <th  :colspan="colspan" class="text-center">{{ $t("reportpublic.GrandTotal") }}</th>
            <th>
              {{total.total_stu}}
            </th>
            <th>
              {{total.total_stu_attendance}}
            </th>
            <th>
              {{total.total_emp}}
            </th>
            <th>
              {{total.total_emp_attendance}}
            </th>
          </tr>
        </tfoot>
      </table>
    </v-card-item>

    <!-- <div v-if="data?.length != 0" class="d-flex" id="pagination-bar">
      <v-pagination
        v-model="page"
        :length="length"
        density="compact"
        show-first-last-page
      >
      </v-pagination>
       <div class="d-flex">
        <v-select
          v-model="perPage"
          class="pa-0"
          :items="itemsPerPage"
          item-value="value"
          item-title="text"
          density="compact"
          variant="text"
        >
        </v-select>
        <span class="mt-2 px-2"
          >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
          {{ totalItems }} ] {{ $t("globals.item") }}</span
        >
      </div>
    </div> -->
    <v-card-item v-else>
      <h3 class="text-center text-grey-darken-2">
        {{ $t("globals.not-found-items") }}
      </h3>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    ReportHeader,
  },
  data() {
    return {
       title: "",
       exportProgress:false,
      dropList: {},
      calendar: [],
      summerSchools: [],
      length: 0,
      exist: false,
      visiBack: true,
      visiGovernorat: false,
      visiDirectorate: false,
      governorates: [],
      data: [],
      dataLoading: false,
      headers: [
        {
          title: this.$t("globals.governorate"),
          key: "gov_name",
          sortable: false,
        },
          {
          title: this.$t("globals.directorate"),
          key: "dir_name",
          sortable: false,
        },
        {
          title: this.$t("globals.summer-centre"),
          key: "school_name",
          sortable: false,
        },
        {
          title: this.$t("summer.manager"),
          key: "manager_name",
          sortable: false,
        },
        {
          title: this.$t("globals.phone"),
          key: "manager_phone",
          sortable: false,
        },
        {
          title: this.$t("financial.date"),
          key: "date",
          sortable: false,
        },
        {
          title: this.$t("school.student_number"),
          key: "students",
          sortable: false,
        },
        {
          title: this.$t("globals.present-students"),
          key: "students_attendance",
          sortable: false,
        },
        {
          title: this.$t("summer.employee_count"),
          key: "employees",
          sortable: false,
        },
        {
          title: this.$t("statistics.male-employee"),
          key: "employees_attendance",
          sortable: false,
        },
      ],
      total : {},
      rules: [(value) => (value ? true : this.$t("globals.required_field"))],
    };
  },
  created() {
    if (this.role >= 2)
    {
      var gov = this.user_governorate;
      this.dropList.gov = parseInt(gov);
    } 
      
  },
  async beforeCreate() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getCalendar();
      this.visiGovernorat = true;
      this.visiDirectorate = true;
      // var role = this.role;
      var gov = this.user_governorate;
      this.governorates = this.governorate;
      if (this.role >= 2 && this.role!=9) {
        this.dropList.gov = parseInt(gov);
        this.visiGovernorat = false;
      } 
       if (this.role >= 3 && this.role !=8 && this.role!=9) {    
        this.dropList.dir = this.user_directorate;
        this.visiGovernorat = false;
        this.visiDirectorate = false;
        this.sendSummerCenterRequest(this.dropList.dir);
      }
      if(this.role==9){
        this.visiGovernorat = true;
        this.dropList.gov=null
      }
      await this.$store.commit("updateLoadingValue", false);
    } catch {
      await this.$store.commit("updateLoadingValue", false);

    }
  },
  computed: {
    ...mapState({
      governorate: (state) => state.school.governorates,
      all_summer_centers: (state) => state.summer.all_summer_centers,
      role: (state) => state.role,
      user_governorate: (state) => state.user_governorate,
      user_directorate: (state) => state.user_directorate,
    }),
    ...mapGetters({
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getAllSummerCenters: "summer/getAllSummerCenters",
    }),
    async exportToExcel(){
      this.exportProgress=true
      const headers=this.headers.map(item=>{
        return{ header:item.title,key:item.key }
      })
      let custom_data = Object.assign([],this.data)
      this.custom_data?.forEach((school,index)=>{
        try {
          school.dir_name=school.dir_name!=""?school.dir_name:custom_data[index-1].dir_name
          school.gov_name=school.gov_name!=""?school.gov_name:custom_data[index-1].gov_name
        } catch (error) {
          
        }
      })
      await this.$ExportToExcel(headers,this.data,this.title).then(()=>{
        this.exportProgress=false
      }).catch(err=>{
        this.exportProgress=false
      })
    },
    getTitle() {
      this.title = "";
       let dir = '';
      if (this.dropList.gov) {
        this.title +=
          " في محافظة " +
          " ( " +
          this.governorate.find((item) => item.id == this.dropList.gov)
            .name_ar +
          " ) ";
         dir=  this.getDirectorateByGov(this.dropList.gov)
      } else {
        this.title += " في كل المحافظات ";
      }
      if (this.dropList.dir) {
       
        
        this.title +=
          " في مديرية " +
          " ( " +
          dir.find((item) => item.id == this.dropList.dir).name_ar +
          " ) ";
      } else {
        this.title += " في كل المديريات ";
      }

      if (this.dropList.schoolSummer) {
        this.title +=
          " الدورة المدرسية " +
          " ( " +
          this.all_summer_centers.find(
            (item) => item.id == this.dropList.schoolSummer
          ).name_ar +
          " ) ";
      } else {
        this.title += "  لكل المراكز المدرسية ";
      }
      if (this.dropList.start_data && !this.dropList.end_data)
        this.title += " في تاريخ " + this.dropList.start_data;
      else if (this.dropList.start_data && this.dropList.end_data)
        this.title +=
          " من " +
          "(" +
          this.dropList.start_data +
          ")" +
          " الى " +
          "(" +
          this.dropList.end_data +
          ")";
      else if (this.dropList.end_data)
        this.title += " الى تاريخ " + this.dropList.end_data;
    },
    async sendSummerCenterRequest(dir) {
      if (dir) {
        await this.getAllSummerCenters(dir);
        this.summerSchools = this.all_summer_centers;      
      } else this.summerSchools = [];
    },
    getDirectorateByGov(gov) {
      if (this.role == 3) {
        var l = this.getDirectorateByGovernorates(gov);
        return l.filter(
          (item) => item.id == this.user_directorate
        );
      }

      return this.getDirectorateByGovernorates(gov);
    },

    async getCalendar() {
      await this.axios
        .get(this.base_url + "api/summer/calendar")
        .then((response) => {
          if (response.data) {
            this.calendar = response.data.results;
          }
        });
    },
    async fetchData(page) {    
      
      if(this.dropList.schoolSummer)this.colspan=4
      else if(this.dropList.dir)this.colspan=5
      else if(this.dropList.gov)this.colspan=6
      else this.colspan=7

      this.getTitle();
      this.dataLoading = true;
      await this.axios(
        `${this.base_url}public-reports/data-report-student-stagg-present-actual`,
        {
          params: {
            gov: this.dropList.gov,
            dir: this.dropList.dir,
            summer_id: this.dropList.schoolSummer,
            start_data: this.dropList.start_data,
            end_data: this.dropList.end_data,
          }}
      )
        .then((response) => {
          this.data = response.data.data;
          this.total = response.data.total
          this.length = this.data.length;
          this.exist = true;
          // this.pagination = response.data.pagination;
          // this.length = response.data.pagination.num_pages;
          // this.itemsPerPage[4].value = response.data.pagination.count;
          // this.totalItems = response.data.pagination.count;
        })
        .catch((err) => {});
      this.dataLoading = false;
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  watch: {
    perPage() {
      this.fetchData();
    },
    page() {
      this.fetchData(this.page);
    },
  },
};
</script>
<style>
.report-t{
  width: 100%;
}
.report-t ,.report-t th,.report-t td{
  border: 1px solid #649add;
  border-collapse: collapse;
  padding: 5px;
 
}
.report-t th{
   text-align: center;
   background-color: #cfd8dc
} 
</style>
